// WARNING: This file has been generated. Do not edit it manually, your changes might get lost.
import { decorate, inject, injectable, multiInject } from "inversify";
import nodeModulesFruiTsScreensDistNavigationRouter from "../node_modules/@frui.ts/screens/dist/navigation/router";
import entitiesCompanyService from "./entities/companyService";
import repositoriesBackendConnector from "./repositories/backendConnector";
import repositoriesCompanyCredentialsRepository from "./repositories/companyCredentialsRepository";
import repositoriesCompanyProfileRepository from "./repositories/companyProfileRepository";
import repositoriesComplianceRepository from "./repositories/complianceRepository";
import repositoriesConnectionsRepository from "./repositories/connectionsRepository";
import repositoriesContractorPortalRepository from "./repositories/contractorPortalRepository";
import repositoriesEmployeeRepository from "./repositories/employeeRepository";
import repositoriesEnumsRepository from "./repositories/enumsRepository";
import repositoriesLoginRepository from "./repositories/loginRepository";
import repositoriesMessagesRepository from "./repositories/messagesRepository";
import repositoriesNotificationsRepository from "./repositories/notificationsRepository";
import repositoriesProjectRepository from "./repositories/projectRepository";
import repositoriesSignupRepository from "./repositories/signupRepository";
import repositoriesWorkerPortalRepository from "./repositories/workerPortalRepository";
import repositoriesWorkerProfileRepository from "./repositories/workerProfileRepository";
import servicesDialogService from "./services/dialogService";
import servicesEnumsService from "./services/enumsService";
import { EventsService as servicesEventsEventsService } from "./services/events";
import servicesInitializationService from "./services/initializationService";
import servicesLocaleProvider from "./services/localeProvider";
import servicesNotificationService from "./services/notificationService";
import servicesSecurityService from "./services/securityService";
import servicesStorageProvider from "./services/storageProvider";
import servicesUserContext from "./services/userContext";
import servicesValidationValidationService from "./services/validation/validationService";
import servicesWorkerImportService from "./services/workerImportService";
import viewModelsAnonymousRootViewModel from "./viewModels/anonymousRootViewModel";
import viewModelsCompanyCompanyCredentialsSubModuleViewModel from "./viewModels/company/companyCredentials/subModuleViewModel";
import viewModelsCompanyCompanyDashboardSubModuleViewModel from "./viewModels/company/companyDashboard/subModuleViewModel";
import viewModelsCompanyCompanyInfoCompanyProfileViewModel from "./viewModels/company/companyInfo/companyProfileViewModel";
import viewModelsCompanyCompanyInfoModalsAddSafetyPolicyViewModel from "./viewModels/company/companyInfo/modals/addSafetyPolicyViewModel";
import viewModelsCompanyCompanyInfoModalsCompanyContactsViewModel from "./viewModels/company/companyInfo/modals/companyContactsViewModel";
import viewModelsCompanyCompanyInfoModalsCompanyInfoViewModel from "./viewModels/company/companyInfo/modals/companyInfoViewModel";
import viewModelsCompanyCompanyInfoModalsCompanyLocationsViewModel from "./viewModels/company/companyInfo/modals/companyLocationsViewModel";
import viewModelsCompanyCompanyInfoModalsCompanyServicesViewModel from "./viewModels/company/companyInfo/modals/companyServicesViewModel";
import viewModelsCompanyCompanyInfoModalsCompleteCompanyProfileViewModel from "./viewModels/company/companyInfo/modals/completeCompanyProfileViewModel";
import viewModelsCompanyCompanyInfoSubModuleViewModel from "./viewModels/company/companyInfo/subModuleViewModel";
import viewModelsCompanyComplianceExperienceModifierViewModel from "./viewModels/company/compliance/experienceModifierViewModel";
import viewModelsCompanyComplianceInsuranceEditViewModel from "./viewModels/company/compliance/insuranceEditViewModel";
import viewModelsCompanyCompliancePointsAllocationEditViewModel from "./viewModels/company/compliance/pointsAllocationEditViewModel";
import viewModelsCompanyComplianceQuestionaireExperienceModifierViewModel from "./viewModels/company/compliance/questionaire/experienceModifierViewModel";
import viewModelsCompanyComplianceQuestionaireInsuranceUploadModalViewModel from "./viewModels/company/compliance/questionaire/insuranceUploadModalViewModel";
import viewModelsCompanyComplianceQuestionaireSafetyCultureQuestionaireViewModel from "./viewModels/company/compliance/questionaire/SafetyCultureQuestionaireViewModel";
import viewModelsCompanyComplianceQuestionaireSafetyMetricsQuestionnaireViewModel from "./viewModels/company/compliance/questionaire/safetyMetricsQuestionnaireViewModel";
import viewModelsCompanyComplianceQuestionaireSafetyProgramsViewModel from "./viewModels/company/compliance/questionaire/safetyProgramsViewModel";
import viewModelsCompanyComplianceQuestionaireWorkHoursQuestionnaireViewModel from "./viewModels/company/compliance/questionaire/workHoursQuestionnaireViewModel";
import viewModelsCompanyComplianceSafetyIndicatorsViewModel from "./viewModels/company/compliance/safetyIndicatorsViewModel";
import viewModelsCompanyComplianceSafetyProgramsEditViewModel from "./viewModels/company/compliance/safetyProgramsEditViewModel";
import viewModelsCompanyComplianceSubModuleViewModel from "./viewModels/company/compliance/subModuleViewModel";
import viewModelsCompanyComplianceThresholdEditModalViewModel from "./viewModels/company/compliance/thresholdEditModalViewModel";
import viewModelsCompanyCredentialsExportCredentialsViewModel from "./viewModels/company/credentials/exportCredentialsViewModel";
import viewModelsCompanyCredentialsImportCredentialsViewModel from "./viewModels/company/credentials/importCredentialsViewModel";
import viewModelsCompanyCredentialsSubModuleViewModel from "./viewModels/company/credentials/subModuleViewModel";
import viewModelsCompanyEmployeesActiveEmployeesViewModel from "./viewModels/company/employees/activeEmployeesViewModel";
import viewModelsCompanyEmployeesExportWorkersViewModel from "./viewModels/company/employees/exportWorkersViewModel";
import viewModelsCompanyEmployeesImportWorkersViewModel from "./viewModels/company/employees/importWorkersViewModel";
import viewModelsCompanyEmployeesPendingEmployeesViewModel from "./viewModels/company/employees/pendingEmployeesViewModel";
import viewModelsCompanyEmployeesRemoveWorkerViewModel from "./viewModels/company/employees/removeWorkerViewModel";
import viewModelsCompanyEmployeesSubModuleViewModel from "./viewModels/company/employees/subModuleViewModel";
import viewModelsCompanyModulePageViewModel from "./viewModels/company/modulePageViewModel";
import viewModelsCompanyMyConnectionsConnectionDetailViewModel from "./viewModels/company/myConnections/connectionDetailViewModel";
import viewModelsCompanyMyConnectionsSubModuleViewModel from "./viewModels/company/myConnections/subModuleViewModel";
import viewModelsCompanyProjectsDocumentsDocumentsListViewModel from "./viewModels/company/projects/documents/documentsListViewModel";
import viewModelsCompanyProjectsDocumentsUploadDocumentViewModel from "./viewModels/company/projects/documents/uploadDocumentViewModel";
import viewModelsCompanyProjectsEditProjectViewModel from "./viewModels/company/projects/editProjectViewModel";
import viewModelsCompanyProjectsNewProjectViewModel from "./viewModels/company/projects/newProjectViewModel";
import viewModelsCompanyProjectsProjectDetailViewModel from "./viewModels/company/projects/projectDetailViewModel";
import viewModelsCompanyProjectsSubModuleViewModel from "./viewModels/company/projects/subModuleViewModel";
import viewModelsCompanyProjectsTeamsAddWorkersViewModel from "./viewModels/company/projects/teams/addWorkersViewModel";
import viewModelsCompanyProjectsTeamsEditTeamViewModel from "./viewModels/company/projects/teams/editTeamViewModel";
import viewModelsCompanyProjectsTeamsFormTeamViewModel from "./viewModels/company/projects/teams/formTeamViewModel";
import viewModelsCompanyProjectsTeamsNewTeamViewModel from "./viewModels/company/projects/teams/newTeamViewModel";
import viewModelsCompanyProjectsTeamsTeamsListViewModel from "./viewModels/company/projects/teams/teamsListViewModel";
import viewModelsConfirmDialogViewModel from "./viewModels/confirmDialogViewModel";
import viewModelsContractorPortalModulePageViewModel from "./viewModels/contractorPortal/modulePageViewModel";
import viewModelsJoinJoinViewModel from "./viewModels/join/joinViewModel";
import viewModelsMessagesDetailMessageViewModel from "./viewModels/messages/detailMessageViewModel";
import viewModelsMessagesModulePageViewModel from "./viewModels/messages/modulePageViewModel";
import viewModelsMessagesNotifyMessagesViewModel from "./viewModels/messages/notifyMessagesViewModel";
import viewModelsMessagesSendMessageViewModel from "./viewModels/messages/sendMessageViewModel";
import viewModelsNotificationsNotificationItemsConnectionRequestViewModel from "./viewModels/notifications/notificationItems/connectionRequestViewModel";
import viewModelsNotificationsNotificationItemsCredentialExpireViewModel from "./viewModels/notifications/notificationItems/credentialExpireViewModel";
import viewModelsNotificationsNotificationItemsNotificationItemViewModel from "./viewModels/notifications/notificationItems/notificationItemViewModel";
import viewModelsNotificationsNotificationItemsProjectViewModel from "./viewModels/notifications/notificationItems/projectViewModel";
import viewModelsNotificationsNotificationItemsWorkerJoinedViewModel from "./viewModels/notifications/notificationItems/workerJoinedViewModel";
import viewModelsNotificationsNotificationsViewModel from "./viewModels/notifications/notificationsViewModel";
import viewModelsProfileAccountSettingsAccountSettingsListViewModel from "./viewModels/profile/accountSettings/accountSettingsListViewModel";
import viewModelsProfileAccountSettingsAccountSettingsViewModel from "./viewModels/profile/accountSettings/accountSettingsViewModel";
import viewModelsProfileAccountSettingsEmailConfirmedViewModel from "./viewModels/profile/accountSettings/emailConfirmedViewModel";
import viewModelsProfileAccountSettingsSuccessEmailChangeViewModel from "./viewModels/profile/accountSettings/successEmailChangeViewModel";
import viewModelsProfileChangePasswordViewModel from "./viewModels/profile/changePasswordViewModel";
import viewModelsProfileForgotPasswordViewModel from "./viewModels/profile/forgotPasswordViewModel";
import viewModelsProfileLoginViewModel from "./viewModels/profile/loginViewModel";
import viewModelsProfileSetupPasswordViewModel from "./viewModels/profile/setupPasswordViewModel";
import viewModelsProfileStatusViewModel from "./viewModels/profile/statusViewModel";
import viewModelsPublicProfileViewModel from "./viewModels/public/profileViewModel";
import viewModelsRootViewModel from "./viewModels/rootViewModel";
import viewModelsSignupCompanyFormViewModel from "./viewModels/signup/companyFormViewModel";
import viewModelsSignupConfirmViewModel from "./viewModels/signup/confirmViewModel";
import viewModelsSignupSelectRoleViewModel from "./viewModels/signup/selectRoleViewModel";
import viewModelsSignupSignupRootViewModel from "./viewModels/signup/signupRootViewModel";
import viewModelsSignupSuccessEmailSentViewModel from "./viewModels/signup/successEmailSentViewModel";
import viewModelsSignupWorkerFormViewModel from "./viewModels/signup/workerFormViewModel";
import viewModelsToastViewModel from "./viewModels/toastViewModel";
import viewModelsWorkerCredentialsCredentialOverviewViewModel from "./viewModels/worker/credentials/credentialOverviewViewModel";
import viewModelsWorkerCredentialsCredentialsAddViewModel from "./viewModels/worker/credentials/credentialsAddViewModel";
import viewModelsWorkerCredentialsCredentialsOverviewViewModel from "./viewModels/worker/credentials/credentialsOverviewViewModel";
import viewModelsWorkerModalsAboutDetailViewModel from "./viewModels/worker/modals/aboutDetailViewModel";
import viewModelsWorkerModalsAddEducationViewModel from "./viewModels/worker/modals/addEducationViewModel";
import viewModelsWorkerModalsAvailabilityViewModel from "./viewModels/worker/modals/availabilityViewModel";
import viewModelsWorkerModalsCompleteWorkerProfileViewModel from "./viewModels/worker/modals/completeWorkerProfileViewModel";
import viewModelsWorkerModalsContactInformationViewModel from "./viewModels/worker/modals/contactInformationViewModel";
import viewModelsWorkerModalsEducationDetailViewModel from "./viewModels/worker/modals/educationDetailViewModel";
import viewModelsWorkerModalsExperienceDetailViewModel from "./viewModels/worker/modals/experienceDetailViewModel";
import viewModelsWorkerModalsPersonalQrViewModel from "./viewModels/worker/modals/personalQrViewModel";
import viewModelsWorkerModalsSkillsDetailViewModel from "./viewModels/worker/modals/skillsDetailViewModel";
import viewModelsWorkerModulePageViewModel from "./viewModels/worker/modulePageViewModel";
import viewModelsWorkerWorkerProfileViewModel from "./viewModels/worker/workerProfileViewModel";
import viewModelsWorkerImportViewModel from "./viewModels/workerImportViewModel";
import viewModelsWorkerPortalModulePageViewModel from "./viewModels/workerPortal/modulePageViewModel";
decorate(injectable(), entitiesCompanyService);
decorate(injectable(), repositoriesCompanyCredentialsRepository);
decorate(inject(repositoriesBackendConnector) as any, repositoriesCompanyCredentialsRepository, 0);
decorate(inject("IEventBus") as any, repositoriesCompanyCredentialsRepository, 1);
decorate(injectable(), repositoriesCompanyProfileRepository);
decorate(inject(repositoriesBackendConnector) as any, repositoriesCompanyProfileRepository, 0);
decorate(inject("IEventBus") as any, repositoriesCompanyProfileRepository, 1);
decorate(injectable(), repositoriesComplianceRepository);
decorate(inject(repositoriesBackendConnector) as any, repositoriesComplianceRepository, 0);
decorate(inject("IEventBus") as any, repositoriesComplianceRepository, 1);
decorate(injectable(), repositoriesConnectionsRepository);
decorate(inject(repositoriesBackendConnector) as any, repositoriesConnectionsRepository, 0);
decorate(inject("IEventBus") as any, repositoriesConnectionsRepository, 1);
decorate(injectable(), repositoriesContractorPortalRepository);
decorate(inject(repositoriesBackendConnector) as any, repositoriesContractorPortalRepository, 0);
decorate(inject("IEventBus") as any, repositoriesContractorPortalRepository, 1);
decorate(injectable(), repositoriesEmployeeRepository);
decorate(inject(repositoriesBackendConnector) as any, repositoriesEmployeeRepository, 0);
decorate(inject("IEventBus") as any, repositoriesEmployeeRepository, 1);
decorate(injectable(), repositoriesEnumsRepository);
decorate(inject(repositoriesBackendConnector) as any, repositoriesEnumsRepository, 0);
decorate(inject("IEventBus") as any, repositoriesEnumsRepository, 1);
decorate(injectable(), repositoriesLoginRepository);
decorate(inject(repositoriesBackendConnector) as any, repositoriesLoginRepository, 0);
decorate(inject("IEventBus") as any, repositoriesLoginRepository, 1);
decorate(injectable(), repositoriesMessagesRepository);
decorate(inject(repositoriesBackendConnector) as any, repositoriesMessagesRepository, 0);
decorate(inject("IEventBus") as any, repositoriesMessagesRepository, 1);
decorate(injectable(), repositoriesNotificationsRepository);
decorate(inject(repositoriesBackendConnector) as any, repositoriesNotificationsRepository, 0);
decorate(inject("IEventBus") as any, repositoriesNotificationsRepository, 1);
decorate(injectable(), repositoriesProjectRepository);
decorate(inject(repositoriesBackendConnector) as any, repositoriesProjectRepository, 0);
decorate(inject("IEventBus") as any, repositoriesProjectRepository, 1);
decorate(injectable(), repositoriesSignupRepository);
decorate(inject(repositoriesBackendConnector) as any, repositoriesSignupRepository, 0);
decorate(inject("IEventBus") as any, repositoriesSignupRepository, 1);
decorate(injectable(), repositoriesWorkerPortalRepository);
decorate(inject(repositoriesBackendConnector) as any, repositoriesWorkerPortalRepository, 0);
decorate(inject("IEventBus") as any, repositoriesWorkerPortalRepository, 1);
decorate(injectable(), repositoriesWorkerProfileRepository);
decorate(inject(repositoriesBackendConnector) as any, repositoriesWorkerProfileRepository, 0);
decorate(inject("IEventBus") as any, repositoriesWorkerProfileRepository, 1);
decorate(injectable(), servicesDialogService);
decorate(inject("ILocalizationService") as any, servicesDialogService, 0);
decorate(injectable(), servicesEnumsService);
decorate(inject(repositoriesEnumsRepository) as any, servicesEnumsService, 0);
decorate(injectable(), servicesEventsEventsService);
decorate(injectable(), servicesInitializationService);
decorate(inject(servicesUserContext) as any, servicesInitializationService, 0);
decorate(inject("ILocalizationService") as any, servicesInitializationService, 1);
decorate(inject(servicesValidationValidationService) as any, servicesInitializationService, 2);
decorate(inject(servicesSecurityService) as any, servicesInitializationService, 3);
decorate(inject(servicesEnumsService) as any, servicesInitializationService, 4);
decorate(injectable(), servicesLocaleProvider);
decorate(injectable(), servicesNotificationService);
decorate(inject("IEventBus") as any, servicesNotificationService, 0);
decorate(injectable(), servicesSecurityService);
decorate(inject(repositoriesLoginRepository) as any, servicesSecurityService, 0);
decorate(inject(repositoriesWorkerProfileRepository) as any, servicesSecurityService, 1);
decorate(inject(repositoriesCompanyProfileRepository) as any, servicesSecurityService, 2);
decorate(inject(repositoriesSignupRepository) as any, servicesSecurityService, 3);
decorate(inject(servicesStorageProvider) as any, servicesSecurityService, 4);
decorate(inject(servicesUserContext) as any, servicesSecurityService, 5);
decorate(inject("IEventBus") as any, servicesSecurityService, 6);
decorate(injectable(), servicesStorageProvider);
decorate(injectable(), servicesWorkerImportService);
decorate(injectable(), viewModelsAnonymousRootViewModel);
decorate(inject("ILocalizationService") as any, viewModelsAnonymousRootViewModel, 0);
decorate(inject(viewModelsProfileLoginViewModel) as any, viewModelsAnonymousRootViewModel, 1);
decorate(inject(viewModelsProfileForgotPasswordViewModel) as any, viewModelsAnonymousRootViewModel, 2);
decorate(inject(viewModelsProfileSetupPasswordViewModel) as any, viewModelsAnonymousRootViewModel, 3);
decorate(inject(viewModelsSignupSignupRootViewModel) as any, viewModelsAnonymousRootViewModel, 4);
decorate(inject(viewModelsJoinJoinViewModel) as any, viewModelsAnonymousRootViewModel, 5);
decorate(inject(viewModelsWorkerImportViewModel) as any, viewModelsAnonymousRootViewModel, 6);
decorate(inject(viewModelsPublicProfileViewModel) as any, viewModelsAnonymousRootViewModel, 7);
decorate(inject("INotificationService") as any, viewModelsAnonymousRootViewModel, 8);
decorate(injectable(), viewModelsConfirmDialogViewModel);
decorate(inject("ButtonParams") as any, viewModelsConfirmDialogViewModel, 2);
decorate(inject("ButtonParams") as any, viewModelsConfirmDialogViewModel, 3);
decorate(injectable(), viewModelsRootViewModel);
decorate(multiInject("IModule") as any, viewModelsRootViewModel, 0);
decorate(inject(viewModelsProfileStatusViewModel) as any, viewModelsRootViewModel, 1);
decorate(inject(viewModelsMessagesNotifyMessagesViewModel) as any, viewModelsRootViewModel, 2);
decorate(inject(viewModelsNotificationsNotificationsViewModel) as any, viewModelsRootViewModel, 3);
decorate(inject(servicesUserContext) as any, viewModelsRootViewModel, 4);
decorate(inject(servicesDialogService) as any, viewModelsRootViewModel, 5);
decorate(inject("INotificationService") as any, viewModelsRootViewModel, 6);
decorate(inject("Factory<CompleteCompanyProfileViewModel>") as any, viewModelsRootViewModel, 7);
decorate(inject("Factory<CompleteWorkerProfileViewModel>") as any, viewModelsRootViewModel, 8);
decorate(inject("Factory<AccountSettingsListViewModel>") as any, viewModelsRootViewModel, 9);
decorate(inject(viewModelsWorkerImportViewModel) as any, viewModelsRootViewModel, 10);
decorate(inject(viewModelsPublicProfileViewModel) as any, viewModelsRootViewModel, 11);
decorate(injectable(), viewModelsToastViewModel);
decorate(injectable(), viewModelsWorkerImportViewModel);
decorate(inject(servicesUserContext) as any, viewModelsWorkerImportViewModel, 0);
decorate(inject(servicesStorageProvider) as any, viewModelsWorkerImportViewModel, 1);
decorate(inject(repositoriesLoginRepository) as any, viewModelsWorkerImportViewModel, 2);
decorate(inject(nodeModulesFruiTsScreensDistNavigationRouter) as any, viewModelsWorkerImportViewModel, 3);
decorate(inject(servicesWorkerImportService) as any, viewModelsWorkerImportViewModel, 4);
decorate(inject("ILocalizationService") as any, viewModelsWorkerImportViewModel, 5);
decorate(injectable(), servicesValidationValidationService);
decorate(inject("ILocalizationService") as any, servicesValidationValidationService, 0);
decorate(inject(repositoriesSignupRepository) as any, servicesValidationValidationService, 1);
decorate(injectable(), viewModelsCompanyModulePageViewModel);
decorate(multiInject("ISubModule") as any, viewModelsCompanyModulePageViewModel, 0);
decorate(inject(repositoriesCompanyProfileRepository) as any, viewModelsCompanyModulePageViewModel, 1);
decorate(inject(servicesUserContext) as any, viewModelsCompanyModulePageViewModel, 2);
decorate(inject("ILocalizationService") as any, viewModelsCompanyModulePageViewModel, 3);
decorate(inject(nodeModulesFruiTsScreensDistNavigationRouter) as any, viewModelsCompanyModulePageViewModel, 4);
decorate(injectable(), viewModelsContractorPortalModulePageViewModel);
decorate(inject(repositoriesContractorPortalRepository) as any, viewModelsContractorPortalModulePageViewModel, 0);
decorate(inject(repositoriesEnumsRepository) as any, viewModelsContractorPortalModulePageViewModel, 1);
decorate(inject("ILocalizationService") as any, viewModelsContractorPortalModulePageViewModel, 2);
decorate(inject(nodeModulesFruiTsScreensDistNavigationRouter) as any, viewModelsContractorPortalModulePageViewModel, 3);
decorate(inject(servicesEnumsService) as any, viewModelsContractorPortalModulePageViewModel, 4);
decorate(injectable(), viewModelsJoinJoinViewModel);
decorate(inject("ILocalizationService") as any, viewModelsJoinJoinViewModel, 0);
decorate(inject(repositoriesSignupRepository) as any, viewModelsJoinJoinViewModel, 1);
decorate(inject(repositoriesConnectionsRepository) as any, viewModelsJoinJoinViewModel, 2);
decorate(inject(servicesValidationValidationService) as any, viewModelsJoinJoinViewModel, 3);
decorate(inject(nodeModulesFruiTsScreensDistNavigationRouter) as any, viewModelsJoinJoinViewModel, 4);
decorate(injectable(), viewModelsMessagesDetailMessageViewModel);
decorate(injectable(), viewModelsMessagesModulePageViewModel);
decorate(inject(repositoriesMessagesRepository) as any, viewModelsMessagesModulePageViewModel, 0);
decorate(inject(servicesEnumsService) as any, viewModelsMessagesModulePageViewModel, 1);
decorate(inject("ILocalizationService") as any, viewModelsMessagesModulePageViewModel, 2);
decorate(inject(viewModelsMessagesDetailMessageViewModel.Factory) as any, viewModelsMessagesModulePageViewModel, 3);
decorate(inject("IEventBus") as any, viewModelsMessagesModulePageViewModel, 4);
decorate(injectable(), viewModelsMessagesNotifyMessagesViewModel);
decorate(inject(repositoriesMessagesRepository) as any, viewModelsMessagesNotifyMessagesViewModel, 0);
decorate(inject(servicesEnumsService) as any, viewModelsMessagesNotifyMessagesViewModel, 1);
decorate(inject("ILocalizationService") as any, viewModelsMessagesNotifyMessagesViewModel, 2);
decorate(inject(viewModelsMessagesDetailMessageViewModel.Factory) as any, viewModelsMessagesNotifyMessagesViewModel, 3);
decorate(inject("IEventBus") as any, viewModelsMessagesNotifyMessagesViewModel, 4);
decorate(injectable(), viewModelsMessagesSendMessageViewModel);
decorate(injectable(), viewModelsNotificationsNotificationsViewModel);
decorate(inject(repositoriesNotificationsRepository) as any, viewModelsNotificationsNotificationsViewModel, 0);
decorate(inject("ILocalizationService") as any, viewModelsNotificationsNotificationsViewModel, 1);
decorate(inject("IEventBus") as any, viewModelsNotificationsNotificationsViewModel, 2);
decorate(inject(viewModelsNotificationsNotificationItemsNotificationItemViewModel.Factory) as any, viewModelsNotificationsNotificationsViewModel, 3);
decorate(injectable(), viewModelsProfileChangePasswordViewModel);
decorate(inject("ILocalizationService") as any, viewModelsProfileChangePasswordViewModel, 0);
decorate(inject(repositoriesLoginRepository) as any, viewModelsProfileChangePasswordViewModel, 1);
decorate(injectable(), viewModelsProfileForgotPasswordViewModel);
decorate(inject("ILocalizationService") as any, viewModelsProfileForgotPasswordViewModel, 0);
decorate(inject(repositoriesLoginRepository) as any, viewModelsProfileForgotPasswordViewModel, 1);
decorate(injectable(), viewModelsProfileLoginViewModel);
decorate(inject("ILocalizationService") as any, viewModelsProfileLoginViewModel, 0);
decorate(inject(servicesSecurityService) as any, viewModelsProfileLoginViewModel, 1);
decorate(inject(nodeModulesFruiTsScreensDistNavigationRouter) as any, viewModelsProfileLoginViewModel, 2);
decorate(inject(servicesStorageProvider) as any, viewModelsProfileLoginViewModel, 3);
decorate(injectable(), viewModelsProfileSetupPasswordViewModel);
decorate(inject("ILocalizationService") as any, viewModelsProfileSetupPasswordViewModel, 0);
decorate(inject(repositoriesLoginRepository) as any, viewModelsProfileSetupPasswordViewModel, 1);
decorate(inject(nodeModulesFruiTsScreensDistNavigationRouter) as any, viewModelsProfileSetupPasswordViewModel, 2);
decorate(injectable(), viewModelsProfileStatusViewModel);
decorate(inject("ILocalizationService") as any, viewModelsProfileStatusViewModel, 0);
decorate(inject(servicesSecurityService) as any, viewModelsProfileStatusViewModel, 1);
decorate(inject(servicesUserContext) as any, viewModelsProfileStatusViewModel, 2);
decorate(inject("Factory<ChangePasswordViewModel>") as any, viewModelsProfileStatusViewModel, 3);
decorate(inject(nodeModulesFruiTsScreensDistNavigationRouter) as any, viewModelsProfileStatusViewModel, 4);
decorate(inject(repositoriesWorkerProfileRepository) as any, viewModelsProfileStatusViewModel, 5);
decorate(inject("IEventBus") as any, viewModelsProfileStatusViewModel, 6);
decorate(injectable(), viewModelsPublicProfileViewModel);
decorate(inject(viewModelsWorkerWorkerProfileViewModel.Factory) as any, viewModelsPublicProfileViewModel, 0);
decorate(inject(viewModelsCompanyCompanyInfoCompanyProfileViewModel.Factory) as any, viewModelsPublicProfileViewModel, 1);
decorate(injectable(), viewModelsSignupCompanyFormViewModel);
decorate(inject("ILocalizationService") as any, viewModelsSignupCompanyFormViewModel, 0);
decorate(inject(repositoriesSignupRepository) as any, viewModelsSignupCompanyFormViewModel, 1);
decorate(inject(nodeModulesFruiTsScreensDistNavigationRouter) as any, viewModelsSignupCompanyFormViewModel, 2);
decorate(inject(servicesValidationValidationService) as any, viewModelsSignupCompanyFormViewModel, 3);
decorate(injectable(), viewModelsSignupConfirmViewModel);
decorate(inject("ILocalizationService") as any, viewModelsSignupConfirmViewModel, 0);
decorate(inject(servicesSecurityService) as any, viewModelsSignupConfirmViewModel, 1);
decorate(injectable(), viewModelsSignupSelectRoleViewModel);
decorate(inject("ILocalizationService") as any, viewModelsSignupSelectRoleViewModel, 0);
decorate(inject(nodeModulesFruiTsScreensDistNavigationRouter) as any, viewModelsSignupSelectRoleViewModel, 1);
decorate(injectable(), viewModelsSignupSignupRootViewModel);
decorate(inject("ILocalizationService") as any, viewModelsSignupSignupRootViewModel, 0);
decorate(inject(viewModelsSignupSelectRoleViewModel) as any, viewModelsSignupSignupRootViewModel, 1);
decorate(inject(viewModelsSignupWorkerFormViewModel) as any, viewModelsSignupSignupRootViewModel, 2);
decorate(inject(viewModelsSignupCompanyFormViewModel) as any, viewModelsSignupSignupRootViewModel, 3);
decorate(inject(viewModelsSignupSuccessEmailSentViewModel) as any, viewModelsSignupSignupRootViewModel, 4);
decorate(inject(viewModelsSignupConfirmViewModel) as any, viewModelsSignupSignupRootViewModel, 5);
decorate(injectable(), viewModelsSignupSuccessEmailSentViewModel);
decorate(inject("ILocalizationService") as any, viewModelsSignupSuccessEmailSentViewModel, 0);
decorate(inject(repositoriesSignupRepository) as any, viewModelsSignupSuccessEmailSentViewModel, 1);
decorate(injectable(), viewModelsSignupWorkerFormViewModel);
decorate(inject("ILocalizationService") as any, viewModelsSignupWorkerFormViewModel, 0);
decorate(inject(repositoriesSignupRepository) as any, viewModelsSignupWorkerFormViewModel, 1);
decorate(inject(nodeModulesFruiTsScreensDistNavigationRouter) as any, viewModelsSignupWorkerFormViewModel, 2);
decorate(inject(servicesValidationValidationService) as any, viewModelsSignupWorkerFormViewModel, 3);
decorate(inject(servicesWorkerImportService) as any, viewModelsSignupWorkerFormViewModel, 4);
decorate(inject(servicesStorageProvider) as any, viewModelsSignupWorkerFormViewModel, 5);
decorate(injectable(), viewModelsWorkerModulePageViewModel);
decorate(inject("ILocalizationService") as any, viewModelsWorkerModulePageViewModel, 0);
decorate(inject(servicesUserContext) as any, viewModelsWorkerModulePageViewModel, 1);
decorate(inject(viewModelsWorkerWorkerProfileViewModel.Factory) as any, viewModelsWorkerModulePageViewModel, 2);
decorate(inject(nodeModulesFruiTsScreensDistNavigationRouter) as any, viewModelsWorkerModulePageViewModel, 3);
decorate(injectable(), viewModelsWorkerWorkerProfileViewModel);
decorate(injectable(), viewModelsWorkerPortalModulePageViewModel);
decorate(inject(repositoriesWorkerPortalRepository) as any, viewModelsWorkerPortalModulePageViewModel, 0);
decorate(inject(repositoriesEnumsRepository) as any, viewModelsWorkerPortalModulePageViewModel, 1);
decorate(inject(servicesEnumsService) as any, viewModelsWorkerPortalModulePageViewModel, 2);
decorate(inject("ILocalizationService") as any, viewModelsWorkerPortalModulePageViewModel, 3);
decorate(inject(nodeModulesFruiTsScreensDistNavigationRouter) as any, viewModelsWorkerPortalModulePageViewModel, 4);
decorate(inject(viewModelsMessagesSendMessageViewModel.Factory) as any, viewModelsWorkerPortalModulePageViewModel, 5);
decorate(injectable(), viewModelsCompanyCompanyCredentialsSubModuleViewModel);
decorate(inject(servicesUserContext) as any, viewModelsCompanyCompanyCredentialsSubModuleViewModel, 0);
decorate(inject(servicesEnumsService) as any, viewModelsCompanyCompanyCredentialsSubModuleViewModel, 1);
decorate(inject(viewModelsWorkerCredentialsCredentialsOverviewViewModel.Factory) as any, viewModelsCompanyCompanyCredentialsSubModuleViewModel, 2);
decorate(inject("ILocalizationService") as any, viewModelsCompanyCompanyCredentialsSubModuleViewModel, 3);
decorate(inject(repositoriesWorkerProfileRepository) as any, viewModelsCompanyCompanyCredentialsSubModuleViewModel, 4);
decorate(inject("IEventBus") as any, viewModelsCompanyCompanyCredentialsSubModuleViewModel, 5);
decorate(inject(servicesUserContext) as any, viewModelsCompanyCompanyCredentialsSubModuleViewModel, 6);
decorate(injectable(), viewModelsCompanyCompanyDashboardSubModuleViewModel);
decorate(inject(servicesUserContext) as any, viewModelsCompanyCompanyDashboardSubModuleViewModel, 0);
decorate(inject("ILocalizationService") as any, viewModelsCompanyCompanyDashboardSubModuleViewModel, 1);
decorate(inject(repositoriesProjectRepository) as any, viewModelsCompanyCompanyDashboardSubModuleViewModel, 2);
decorate(inject(repositoriesWorkerPortalRepository) as any, viewModelsCompanyCompanyDashboardSubModuleViewModel, 3);
decorate(inject(repositoriesCompanyCredentialsRepository) as any, viewModelsCompanyCompanyDashboardSubModuleViewModel, 4);
decorate(inject(repositoriesConnectionsRepository) as any, viewModelsCompanyCompanyDashboardSubModuleViewModel, 5);
decorate(inject(servicesEnumsService) as any, viewModelsCompanyCompanyDashboardSubModuleViewModel, 6);
decorate(injectable(), viewModelsCompanyCompanyInfoCompanyProfileViewModel);
decorate(injectable(), viewModelsCompanyCompanyInfoSubModuleViewModel);
decorate(inject(servicesUserContext) as any, viewModelsCompanyCompanyInfoSubModuleViewModel, 0);
decorate(inject("ILocalizationService") as any, viewModelsCompanyCompanyInfoSubModuleViewModel, 1);
decorate(inject(viewModelsCompanyCompanyInfoCompanyProfileViewModel.Factory) as any, viewModelsCompanyCompanyInfoSubModuleViewModel, 2);
decorate(injectable(), viewModelsCompanyComplianceExperienceModifierViewModel);
decorate(injectable(), viewModelsCompanyComplianceInsuranceEditViewModel);
decorate(injectable(), viewModelsCompanyCompliancePointsAllocationEditViewModel);
decorate(injectable(), viewModelsCompanyComplianceSafetyIndicatorsViewModel);
decorate(injectable(), viewModelsCompanyComplianceSafetyProgramsEditViewModel);
decorate(injectable(), viewModelsCompanyComplianceSubModuleViewModel);
decorate(inject("ILocalizationService") as any, viewModelsCompanyComplianceSubModuleViewModel, 0);
decorate(inject(viewModelsCompanyComplianceInsuranceEditViewModel.Factory) as any, viewModelsCompanyComplianceSubModuleViewModel, 1);
decorate(inject(viewModelsCompanyComplianceThresholdEditModalViewModel.Factory) as any, viewModelsCompanyComplianceSubModuleViewModel, 2);
decorate(inject(viewModelsCompanyComplianceExperienceModifierViewModel.Factory) as any, viewModelsCompanyComplianceSubModuleViewModel, 3);
decorate(inject(viewModelsCompanyComplianceSafetyProgramsEditViewModel.Factory) as any, viewModelsCompanyComplianceSubModuleViewModel, 4);
decorate(inject(viewModelsCompanyCompliancePointsAllocationEditViewModel.Factory) as any, viewModelsCompanyComplianceSubModuleViewModel, 5);
decorate(inject(viewModelsCompanyComplianceSafetyIndicatorsViewModel.Factory) as any, viewModelsCompanyComplianceSubModuleViewModel, 6);
decorate(inject(repositoriesComplianceRepository) as any, viewModelsCompanyComplianceSubModuleViewModel, 7);
decorate(inject(servicesUserContext) as any, viewModelsCompanyComplianceSubModuleViewModel, 8);
decorate(inject("IEventBus") as any, viewModelsCompanyComplianceSubModuleViewModel, 9);
decorate(injectable(), viewModelsCompanyComplianceThresholdEditModalViewModel);
decorate(injectable(), viewModelsCompanyCredentialsExportCredentialsViewModel);
decorate(injectable(), viewModelsCompanyCredentialsImportCredentialsViewModel);
decorate(injectable(), viewModelsCompanyCredentialsSubModuleViewModel);
decorate(inject(repositoriesCompanyCredentialsRepository) as any, viewModelsCompanyCredentialsSubModuleViewModel, 0);
decorate(inject("ILocalizationService") as any, viewModelsCompanyCredentialsSubModuleViewModel, 1);
decorate(inject(servicesEnumsService) as any, viewModelsCompanyCredentialsSubModuleViewModel, 2);
decorate(inject(servicesUserContext) as any, viewModelsCompanyCredentialsSubModuleViewModel, 3);
decorate(inject("IEventBus") as any, viewModelsCompanyCredentialsSubModuleViewModel, 4);
decorate(inject(nodeModulesFruiTsScreensDistNavigationRouter) as any, viewModelsCompanyCredentialsSubModuleViewModel, 5);
decorate(inject(viewModelsWorkerCredentialsCredentialsOverviewViewModel.Factory) as any, viewModelsCompanyCredentialsSubModuleViewModel, 6);
decorate(inject(viewModelsWorkerWorkerProfileViewModel.Factory) as any, viewModelsCompanyCredentialsSubModuleViewModel, 7);
decorate(inject(viewModelsCompanyCredentialsExportCredentialsViewModel.Factory) as any, viewModelsCompanyCredentialsSubModuleViewModel, 8);
decorate(inject(viewModelsCompanyCredentialsImportCredentialsViewModel.Factory) as any, viewModelsCompanyCredentialsSubModuleViewModel, 9);
decorate(inject(viewModelsMessagesSendMessageViewModel.Factory) as any, viewModelsCompanyCredentialsSubModuleViewModel, 10);
decorate(injectable(), viewModelsCompanyEmployeesActiveEmployeesViewModel);
decorate(injectable(), viewModelsCompanyEmployeesExportWorkersViewModel);
decorate(injectable(), viewModelsCompanyEmployeesImportWorkersViewModel);
decorate(injectable(), viewModelsCompanyEmployeesPendingEmployeesViewModel);
decorate(injectable(), viewModelsCompanyEmployeesRemoveWorkerViewModel);
decorate(injectable(), viewModelsCompanyEmployeesSubModuleViewModel);
decorate(inject(servicesUserContext) as any, viewModelsCompanyEmployeesSubModuleViewModel, 0);
decorate(inject("ILocalizationService") as any, viewModelsCompanyEmployeesSubModuleViewModel, 1);
decorate(inject(viewModelsCompanyEmployeesExportWorkersViewModel.Factory) as any, viewModelsCompanyEmployeesSubModuleViewModel, 2);
decorate(inject(viewModelsCompanyEmployeesImportWorkersViewModel.Factory) as any, viewModelsCompanyEmployeesSubModuleViewModel, 3);
decorate(inject(viewModelsCompanyEmployeesActiveEmployeesViewModel.Factory) as any, viewModelsCompanyEmployeesSubModuleViewModel, 4);
decorate(inject(servicesEnumsService) as any, viewModelsCompanyEmployeesSubModuleViewModel, 5);
decorate(injectable(), viewModelsCompanyMyConnectionsConnectionDetailViewModel);
decorate(injectable(), viewModelsCompanyMyConnectionsSubModuleViewModel);
decorate(inject("ILocalizationService") as any, viewModelsCompanyMyConnectionsSubModuleViewModel, 0);
decorate(inject(viewModelsCompanyMyConnectionsConnectionDetailViewModel.Factory) as any, viewModelsCompanyMyConnectionsSubModuleViewModel, 1);
decorate(inject(viewModelsMessagesSendMessageViewModel.Factory) as any, viewModelsCompanyMyConnectionsSubModuleViewModel, 2);
decorate(inject(repositoriesConnectionsRepository) as any, viewModelsCompanyMyConnectionsSubModuleViewModel, 3);
decorate(inject(servicesDialogService) as any, viewModelsCompanyMyConnectionsSubModuleViewModel, 4);
decorate(inject(servicesUserContext) as any, viewModelsCompanyMyConnectionsSubModuleViewModel, 5);
decorate(injectable(), viewModelsCompanyProjectsEditProjectViewModel);
decorate(injectable(), viewModelsCompanyProjectsNewProjectViewModel);
decorate(injectable(), viewModelsCompanyProjectsProjectDetailViewModel);
decorate(injectable(), viewModelsCompanyProjectsSubModuleViewModel);
decorate(inject(servicesUserContext) as any, viewModelsCompanyProjectsSubModuleViewModel, 0);
decorate(inject("ILocalizationService") as any, viewModelsCompanyProjectsSubModuleViewModel, 1);
decorate(inject(repositoriesProjectRepository) as any, viewModelsCompanyProjectsSubModuleViewModel, 2);
decorate(inject(viewModelsCompanyProjectsProjectDetailViewModel.Factory) as any, viewModelsCompanyProjectsSubModuleViewModel, 3);
decorate(inject(viewModelsCompanyProjectsNewProjectViewModel.Factory) as any, viewModelsCompanyProjectsSubModuleViewModel, 4);
decorate(inject(viewModelsCompanyProjectsEditProjectViewModel.Factory) as any, viewModelsCompanyProjectsSubModuleViewModel, 5);
decorate(inject("IEventBus") as any, viewModelsCompanyProjectsSubModuleViewModel, 6);
decorate(inject(servicesEnumsService) as any, viewModelsCompanyProjectsSubModuleViewModel, 7);
decorate(inject(servicesDialogService) as any, viewModelsCompanyProjectsSubModuleViewModel, 8);
decorate(injectable(), viewModelsNotificationsNotificationItemsConnectionRequestViewModel);
decorate(injectable(), viewModelsNotificationsNotificationItemsCredentialExpireViewModel);
decorate(injectable(), viewModelsNotificationsNotificationItemsNotificationItemViewModel);
decorate(injectable(), viewModelsNotificationsNotificationItemsProjectViewModel);
decorate(injectable(), viewModelsNotificationsNotificationItemsWorkerJoinedViewModel);
decorate(injectable(), viewModelsProfileAccountSettingsAccountSettingsListViewModel);
decorate(inject("ILocalizationService") as any, viewModelsProfileAccountSettingsAccountSettingsListViewModel, 0);
decorate(inject("Factory<AccountSettingsViewModel>") as any, viewModelsProfileAccountSettingsAccountSettingsListViewModel, 1);
decorate(inject("Factory<EmailConfirmedViewModel>") as any, viewModelsProfileAccountSettingsAccountSettingsListViewModel, 2);
decorate(inject("Factory<SuccessEmailChangeViewModel>") as any, viewModelsProfileAccountSettingsAccountSettingsListViewModel, 3);
decorate(injectable(), viewModelsProfileAccountSettingsAccountSettingsViewModel);
decorate(inject("ILocalizationService") as any, viewModelsProfileAccountSettingsAccountSettingsViewModel, 0);
decorate(inject(repositoriesLoginRepository) as any, viewModelsProfileAccountSettingsAccountSettingsViewModel, 1);
decorate(inject(nodeModulesFruiTsScreensDistNavigationRouter) as any, viewModelsProfileAccountSettingsAccountSettingsViewModel, 2);
decorate(injectable(), viewModelsProfileAccountSettingsEmailConfirmedViewModel);
decorate(inject("ILocalizationService") as any, viewModelsProfileAccountSettingsEmailConfirmedViewModel, 0);
decorate(inject(nodeModulesFruiTsScreensDistNavigationRouter) as any, viewModelsProfileAccountSettingsEmailConfirmedViewModel, 1);
decorate(inject(servicesSecurityService) as any, viewModelsProfileAccountSettingsEmailConfirmedViewModel, 2);
decorate(injectable(), viewModelsProfileAccountSettingsSuccessEmailChangeViewModel);
decorate(inject("ILocalizationService") as any, viewModelsProfileAccountSettingsSuccessEmailChangeViewModel, 0);
decorate(inject(repositoriesLoginRepository) as any, viewModelsProfileAccountSettingsSuccessEmailChangeViewModel, 1);
decorate(injectable(), viewModelsWorkerCredentialsCredentialOverviewViewModel);
decorate(injectable(), viewModelsWorkerCredentialsCredentialsAddViewModel);
decorate(injectable(), viewModelsWorkerCredentialsCredentialsOverviewViewModel);
decorate(injectable(), viewModelsWorkerModalsAboutDetailViewModel);
decorate(injectable(), viewModelsWorkerModalsAddEducationViewModel);
decorate(injectable(), viewModelsWorkerModalsAvailabilityViewModel);
decorate(injectable(), viewModelsWorkerModalsCompleteWorkerProfileViewModel);
decorate(inject(servicesUserContext) as any, viewModelsWorkerModalsCompleteWorkerProfileViewModel, 0);
decorate(inject("ILocalizationService") as any, viewModelsWorkerModalsCompleteWorkerProfileViewModel, 1);
decorate(inject(servicesEnumsService) as any, viewModelsWorkerModalsCompleteWorkerProfileViewModel, 2);
decorate(inject(repositoriesWorkerProfileRepository) as any, viewModelsWorkerModalsCompleteWorkerProfileViewModel, 3);
decorate(injectable(), viewModelsWorkerModalsContactInformationViewModel);
decorate(injectable(), viewModelsWorkerModalsEducationDetailViewModel);
decorate(injectable(), viewModelsWorkerModalsExperienceDetailViewModel);
decorate(injectable(), viewModelsWorkerModalsPersonalQrViewModel);
decorate(injectable(), viewModelsWorkerModalsSkillsDetailViewModel);
decorate(injectable(), viewModelsCompanyCompanyInfoModalsAddSafetyPolicyViewModel);
decorate(injectable(), viewModelsCompanyCompanyInfoModalsCompanyContactsViewModel);
decorate(injectable(), viewModelsCompanyCompanyInfoModalsCompanyInfoViewModel);
decorate(injectable(), viewModelsCompanyCompanyInfoModalsCompanyLocationsViewModel);
decorate(injectable(), viewModelsCompanyCompanyInfoModalsCompanyServicesViewModel);
decorate(injectable(), viewModelsCompanyCompanyInfoModalsCompleteCompanyProfileViewModel);
decorate(inject(servicesUserContext) as any, viewModelsCompanyCompanyInfoModalsCompleteCompanyProfileViewModel, 0);
decorate(inject("ILocalizationService") as any, viewModelsCompanyCompanyInfoModalsCompleteCompanyProfileViewModel, 1);
decorate(inject(repositoriesCompanyProfileRepository) as any, viewModelsCompanyCompanyInfoModalsCompleteCompanyProfileViewModel, 2);
decorate(inject(servicesEnumsService) as any, viewModelsCompanyCompanyInfoModalsCompleteCompanyProfileViewModel, 3);
decorate(injectable(), viewModelsCompanyComplianceQuestionaireExperienceModifierViewModel);
decorate(injectable(), viewModelsCompanyComplianceQuestionaireInsuranceUploadModalViewModel);
decorate(injectable(), viewModelsCompanyComplianceQuestionaireSafetyCultureQuestionaireViewModel);
decorate(injectable(), viewModelsCompanyComplianceQuestionaireSafetyMetricsQuestionnaireViewModel);
decorate(injectable(), viewModelsCompanyComplianceQuestionaireSafetyProgramsViewModel);
decorate(injectable(), viewModelsCompanyComplianceQuestionaireWorkHoursQuestionnaireViewModel);
decorate(injectable(), viewModelsCompanyProjectsDocumentsDocumentsListViewModel);
decorate(injectable(), viewModelsCompanyProjectsDocumentsUploadDocumentViewModel);
decorate(injectable(), viewModelsCompanyProjectsTeamsAddWorkersViewModel);
decorate(injectable(), viewModelsCompanyProjectsTeamsEditTeamViewModel);
decorate(injectable(), viewModelsCompanyProjectsTeamsFormTeamViewModel);
decorate(injectable(), viewModelsCompanyProjectsTeamsNewTeamViewModel);
decorate(injectable(), viewModelsCompanyProjectsTeamsTeamsListViewModel);
