import { observable } from "mobx";

// This entity has been generated, do not change its content, your changes might get lost. You CAN modify the rest of the file.
export default class CompanyProjectTeamWorkerAssigned {
  /**
   * Worker ID
   */
  id!: string;

  @observable
  firstName?: string;

  @observable
  middleName?: string;

  @observable
  lastName?: string;

  @observable
  profilePhotoUrl?: string;

  @observable
  hasExpiredCredential?: boolean;

  static ValidationRules = {
    id: { required: true },
  };
}
