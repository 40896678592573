import { registerView, ViewComponent, View } from "@frui.ts/views";
import { observer } from "mobx-react-lite";
import React from "react";
import SubModuleViewModel from "viewModels/company/credentials/subModuleViewModel";
import "./credentialsCompany.scss";
import LoadingContainer from "views/common/loadingContainer";
import EmployeeCredentials from "./components/employeeCredentials";
import Credential from "entities/credential";
import Pager from "controls/pager";

const credentialsSubModuleView: ViewComponent<SubModuleViewModel> = observer(({ vm }) => {
  return (
    <div className="credentials-company">
      <View vm={vm} context="header" fallbackMode="children" />
      <LoadingContainer loading={vm.busyWatcher.isBusy}>
        {(vm.items ?? []).map((employee, idx) => (
          <EmployeeCredentials
            enums={vm.enums}
            key={idx}
            uploadCredentials={vm.uploadCredentials}
            worker={employee}
            openProfile={vm.openProfile}
            localization={vm.localization}
            openOverview={(credential: Credential) => vm.openOverview(employee.accountId, credential)}
            onSendMessage={vm.openSendMessageModal}
          />
        ))}

        {vm.canLoadData && (
          <div className="paginationBar">
            <Pager paging={vm.currentPaging} filter={vm.pagingFilter} onPageChanged={vm.changePage} />
          </div>
        )}
      </LoadingContainer>

      <View vm={vm.activeChild} />
    </div>
  );
});

registerView(credentialsSubModuleView, SubModuleViewModel);
