import { createEventDefinition, EventBus, IEventBus } from "@emanprague/shared-services";
import CompanyProfile from "entities/companyProfile";
import WorkerProfile from "entities/workerProfile";
import WorkerProfileEducation from "entities/workerProfileEducation";
import WorkerProfileExperience from "entities/workerProfileExperience";
import ApiErrorResponse from "repositories/apiModels";
import ComplianceRequirementsPointsAllocation from "entities/complianceRequirementsPointsAllocation";
import ExperienceModifierDefinition from "entities/experienceModifierDefinition";
import InsuranceDocument from "entities/insuranceDocument";
import CompanySafetyPolicies from "entities/companySafetyPolicies";

const GeneralEvents = {
  apiError: createEventDefinition<{
    response: ApiErrorResponse;
    errorMessage: string;
  }>()("api.error"),
  info: createEventDefinition<string>()("info"),
};

const WorkerEvents = {
  profileUpdated: createEventDefinition<WorkerProfile>()("worker.profileUpdated"),
  educationUpdated: createEventDefinition<WorkerProfileEducation | undefined>()("worker.educationUpdated"),
  experienceUpdated: createEventDefinition<WorkerProfileExperience | undefined>()("worker.experienceUpdated"),
  userImageUpdated: createEventDefinition<undefined>()("worker.userImageUpdated"),
  credentialCreated: createEventDefinition<undefined>()("worker.credentialCreated"),
  credentialDeleted: createEventDefinition<undefined>()("worker.credentialDeleted"),
  credentialPhotoUploaded: createEventDefinition<undefined>()("worker.credentialPhotoUploaded"),
};

const CompanyEvents = {
  profileUpdated: createEventDefinition<CompanyProfile>()("company.profileUpdated"),
  safetyPoliciesUpdated: createEventDefinition<CompanySafetyPolicies>()("compnay.policiesUpdated"),
  safetyPoliciesApproved: createEventDefinition<undefined>()("compnay.policies.approved"),
  connectionPointsUpdated: createEventDefinition<undefined>()("connection.compliance.points.updated"),
};

const EmployeesEvents = {
  workerRemoved: createEventDefinition<undefined>()("employees.workerRemoved"),
  workersImported: createEventDefinition<undefined>()("employees.workersImported"),
};

const ProjectsEvents = {
  listUpdated: createEventDefinition<undefined>()("projects.listUpdated"),
  teamsUpdated: createEventDefinition<undefined>()("projects.teamsUpdated"),
  documentsUpdated: createEventDefinition<undefined>()("projects.documentsUpdated"),
};

const StatusbarEvents = {
  notificationRead: createEventDefinition<undefined>()("statusbar.notificationRead"),
};

const MessagesEvents = {
  messageRead: createEventDefinition<undefined>()("messages.messageRead"),
};

const ComplianceDefinitionEvents = {
  pointsAllocationUpdated: createEventDefinition<ComplianceRequirementsPointsAllocation>()("compliance.pointAllocation.updated"),
  experienceModifierUpdated: createEventDefinition<ExperienceModifierDefinition>()("compliance.experienceModifier.updated"),
  insuranceUploaded: createEventDefinition<InsuranceDocument>()("compliance.insuranceDocuments.updated"),
  insuranceRemoved: createEventDefinition<InsuranceDocument>()("compliance.insuranceDocuments.removed"),
};

export {
  GeneralEvents,
  MessagesEvents,
  ComplianceDefinitionEvents,
  WorkerEvents,
  CompanyEvents,
  EmployeesEvents,
  ProjectsEvents,
  StatusbarEvents,
};

export class EventsService extends EventBus implements IEventBus {}
