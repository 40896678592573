import { registerView, ViewComponent } from "@frui.ts/views";
import { observer } from "mobx-react-lite";
import React from "react";
import FullHeightContainer from "views/common/fullHeightContainer";
import LinkSent from "views/common/linkSent";
import SuccessEmailChangeViewModel from "viewModels/profile/accountSettings/successEmailChangeViewModel";

const successEmailChangeView: ViewComponent<SuccessEmailChangeViewModel> = observer(({ vm }) => (
  <FullHeightContainer>
    <LinkSent
      isDisabled={vm.busyWatcher.isBusy}
      email={vm.email}
      title={vm.translate("title")}
      message={vm.translate("description")}
      help={vm.translate("help")}
      resend={vm.translate("sendAgain")}
      onResend={vm.resend}
    />
  </FullHeightContainer>
));

registerView(successEmailChangeView, SuccessEmailChangeViewModel);
