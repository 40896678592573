import React from "react";
import { ScreenBase } from "@frui.ts/screens";
import { Modal } from "@emanprague/veralinkkit";
import { ViewProps } from "@frui.ts/views";
import { Observer } from "mobx-react-lite";
import { bound } from "@frui.ts/helpers";

export type ModalSize = "sm" | "lg" | "xl";

export default abstract class ModalView<TViewModel extends ScreenBase> extends React.Component<ViewProps<TViewModel>> {
  protected get showCloseButton() {
    return true;
  }
  protected size?: ModalSize;
  protected overrides: Record<string, string>;

  @bound
  handleModalClose() {
    this.props.vm.requestClose();
  }

  renderHeader(vm: TViewModel) {
    return <h4 className="display-6">{vm.name}</h4>;
  }

  abstract renderBody(vm: TViewModel): React.ReactElement;

  renderFooter(vm: TViewModel) {
    return <></>;
  }

  render() {
    return (
      <Modal size={this.size} show={true} onHide={this.handleModalClose}>
        <Modal.Header closeButton={this.showCloseButton}>
          <Observer>{() => this.renderHeader(this.props.vm)}</Observer>
        </Modal.Header>
        <Modal.Body>
          <Observer>{() => this.renderBody(this.props.vm)}</Observer>
        </Modal.Body>
        <Modal.Footer className={this.overrides?.footer}>
          <Observer>{() => this.renderFooter(this.props.vm)}</Observer>
        </Modal.Footer>
      </Modal>
    );
  }
}
