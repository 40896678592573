import { observable } from "mobx";
import { Type } from "class-transformer";
import CompanySafetyPolicy from "./companySafetyPolicy";

// This entity has been generated, do not change its content, your changes might get lost. You CAN modify the rest of the file.
export default class CompanyWorkTypes {
  /**
   * Work Type ID (from code list)
   */
  @observable
  workTypeId!: string;

  @observable
  @Type(() => CompanySafetyPolicy)
  safetyPolicies!: CompanySafetyPolicy[];

  static ValidationRules = {
    workTypeId: { required: true },
    safetyPolicies: { required: true },
  };
}

export const WorkTypesValidationRules = {
  workTypeId: { required: true },
};
