import { observable } from "mobx";
import { Type } from "class-transformer";
import AuthenticationIdentity from "./authenticationIdentity";

// This entity has been generated, do not change its content, your changes might get lost. You CAN modify the rest of the file.
export default class AuthenticationCredentials {
  /**
   * Access token for authorized parts of Veralink APIs. Access token will not be provided when verifying email of company.
   */
  @observable
  accessToken?: string;

  @observable
  @Type(() => AuthenticationIdentity)
  identity!: AuthenticationIdentity;

  static ValidationRules = {
    identity: { required: true },
  };
}
