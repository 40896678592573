import { ILocaleProvider } from "@emanprague/shared-services";
import CookieLocalizationService from "@emanprague/shared-services/dist/localization/cookieLocalizationService";
import { Router } from "@frui.ts/screens";
import { Container } from "inversify";
import BackendConnector from "repositories/backendConnector";
import UserContext from "services/userContext";
import "./di.decorators";
import registerServices from "./di.registry";

export default function createContainer(apiBaseUrl: string) {
  const container = new Container({ skipBaseClassChecks: true });
  registerServices(container);

  const userContext = new UserContext();
  container.bind(UserContext).toConstantValue(userContext);
  container.bind(BackendConnector).toConstantValue(new BackendConnector(apiBaseUrl, userContext));
  container.bind(Router).toConstantValue(new Router());

  const localeProvider = container.get<ILocaleProvider>("ILocaleProvider");
  container.bind("ILocalizationService").toConstantValue(new CookieLocalizationService(localeProvider));

  return container;
}
