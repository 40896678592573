export function openSelf(url: string) {
  window.open(url, "_self");
}

export function openBlank(url: string) {
  window.open(url, "_blank");
}

export function openBlankBlob(blob: Blob) {
  const blobUrl = window.URL.createObjectURL(blob);
  window.open(blobUrl, "_blank");
}

export function downloadFile(blob: Blob, fileName: string) {
  const link = document.createElement("a");
  link.href = URL.createObjectURL(blob);
  link.download = fileName;
  document.body.append(link);
  link.click();
  link.remove();
}

export function scrollTop(behavior: ScrollBehavior = "auto") {
  window.scrollTo({ top: 0, left: 0, behavior });
}
