import { Col, LoaderOverlay, Row } from "@emanprague/veralinkkit";
import { registerView, View, ViewComponent } from "@frui.ts/views";
import { observer } from "mobx-react-lite";
import React from "react";
import WorkerProfileViewModel from "viewModels/worker/workerProfileViewModel";
import EducationSectionView from "./sections/educationSectionView";
import SkillsSectionView from "./sections/skillsSectionView";
import AboutMeSectionView from "./sections/aboutMeSectionView";
import UserProfileView from "./userProfileView";
import CredentialsSectionView from "./credentials/credentialsSectionView";
import "./worker.scss";
import WorkExperienceSectionView from "./sections/workExperienceSectionView";
import BannerSectionView from "./sections/bannerSectionView";
import StrengthSectionView from "./sections/strengthSectionView";

const modulePageListView: ViewComponent<WorkerProfileViewModel> = observer(({ vm }) => {
  const { item } = vm;

  if (item === undefined) {
    return <LoaderOverlay loading={true} variant="light" />;
  }

  return (
    <>
      <LoaderOverlay loading={vm.busyWatcher.isBusy} variant="light">
        <div className="worker-profile__layout ">
          <div className="left-section">
            <UserProfileView vm={vm} />
          </div>
          <div className="right-section">
            <Row>
              <Col lg={6} className="greeting">
                <h3>
                  {vm.mode === "edit"
                    ? `${vm.translate("greeting")} ${item?.firstName}!`
                    : `${item?.firstName} ${item?.lastName}`}
                </h3>
              </Col>
              <Col lg={6}>{vm.mode === "edit" && <StrengthSectionView vm={vm} />}</Col>
              {vm.mode === "edit" && (
                <Col xs={12} className="mb-4">
                  <BannerSectionView vm={vm} />
                </Col>
              )}
              <Col xs={12} className="mb-4">
                <SkillsSectionView vm={vm} />
              </Col>
              <Col xs={12} className="mb-4">
                <AboutMeSectionView vm={vm} />
              </Col>
              <Col xs={12} className="mb-4">
                <CredentialsSectionView scrollable vm={vm} />
              </Col>
              <Col xs={12} className="mb-4">
                <WorkExperienceSectionView vm={vm} />
              </Col>
              <Col xs={12} className="mb-4">
                <EducationSectionView vm={vm} />
              </Col>
            </Row>
          </div>
        </div>
      </LoaderOverlay>
      <View vm={vm.activeChild} />
    </>
  );
});

registerView(modulePageListView, WorkerProfileViewModel);
