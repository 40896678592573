import React from "react";
import Phone from "@emanprague/veralinkkit/dist/Icons/Phone";
import Mail from "@emanprague/veralinkkit/dist/Icons/Mail";
import { observer } from "mobx-react-lite";
import CompanyContact from "entities/companyContact";

interface ICompanyContactProps {
  contact: CompanyContact;
}

const CompanyContactView: React.FunctionComponent<ICompanyContactProps> = observer(({ contact }) => {
  return (
    <div className="company-info__contact">
      <h6>{contact.fullName}</h6>
      <span>{contact.role}</span>

      {contact.phoneNumber && (
        <p>
          <Phone fontSize={20} color="#00949E" />
          {contact.phoneNumber}
        </p>
      )}

      {contact.email && (
        <p>
          <Mail fontSize={20} color="#00949E" />
          {contact.email}
        </p>
      )}
    </div>
  );
});

export default CompanyContactView;
