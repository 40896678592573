import { ILocalizationService } from "@emanprague/shared-services";
import { bound } from "@frui.ts/helpers";
import { BusyWatcher, ScreenBase, watchBusy, Router } from "@frui.ts/screens";
import { action, observable, runInAction } from "mobx";
import { API_URI } from "config";
import SignupRepository from "repositories/signupRepository";
import AsyncValidator from "services/validation/asyncValidator";
import NewWorkerPassword from "entities/newWorker";
import ValidationService from "services/validation/validationService";
import { validate } from "@frui.ts/validation";
import SuccessEmailSentViewModel from "viewModels/signup/successEmailSentViewModel";
import AccountType from "entities/accountType";
import ConnectionsRepository from "repositories/connectionsRepository";

type Strategy = null | "username" | "connection";

class JoinStrategy {
  type: Strategy = null;
  value: null | string = null;

  constructor(parsed: Record<string, any>) {
    if (parsed.u) {
      this.type = "username";
      this.value = parsed.u as string;
    }

    if (parsed.c) {
      this.type = "connection";
      this.value = parsed.c as string;
    }
  }
}

const navigationName = "join";

@Router.registerRoute({ name: Router.Self, route: navigationName })
export default class JoinViewModel extends ScreenBase {
  navigationName = navigationName;
  busyWatcher = new BusyWatcher();
  @observable item: NewWorkerPassword;
  asyncValidator: AsyncValidator<NewWorkerPassword>;
  @observable errorMessage?: string;
  @observable strategy: JoinStrategy;

  constructor(
    public localization: ILocalizationService,
    private repository: SignupRepository,
    private connectionRepository: ConnectionsRepository,
    validationService: ValidationService,
    private router: Router
  ) {
    super();

    this.name = this.translate("title");
    this.item = new NewWorkerPassword();
    this.asyncValidator = validationService.attachUserNameValidation(this.item, NewWorkerPassword.ValidationRules);
  }

  @action
  handleStrategy(params: Record<string, string>) {
    this.strategy = new JoinStrategy(params);
  }

  async navigate(subpath: any, params: any) {
    if (params?.u || params?.c) {
      this.handleStrategy(params);
      if (this.strategy.type === "username") {
        this.loadWorker();
      }

      if (this.strategy.type === "connection") {
        this.acceptRequest();
      }
    }
    return super.navigate(subpath, params);
  }

  @watchBusy
  async loadWorker() {
    const resp = await this.repository.getPendingProfile(this.strategy.value as string);
    if (resp.success) {
      runInAction(() => {
        this.item = resp.payload as NewWorkerPassword;
      });
    }
  }

  @watchBusy
  async acceptRequest() {
    const resp = await this.connectionRepository.acceptJoinConnection(this.strategy.value as string);

    if (resp.success) {
      this.router.navigatePath("/");
    }
  }

  @bound
  @watchBusy
  async join() {
    this.setErrorMessage();
    if (validate(this.item) && this.strategy.type === "username") {
      const result = await this.repository.reclaimPendingProfile(this.strategy.value as string, {
        type: AccountType.Worker,
        details: this.item,
      });

      if (result.success) {
        this.router.navigate(SuccessEmailSentViewModel, undefined, { email: this.item.email });
      } else {
        this.setErrorMessage(result.payload.errorDescription);
      }
    }
  }

  @action private setErrorMessage(message?: string) {
    this.errorMessage = message;
  }

  @watchBusy
  async resend() {}

  get termsUrl() {
    return `${API_URI}/auth/terms_and_conditions`;
  }

  @bound translate(key: string) {
    return this.localization.translateGeneral(`join.joinForm.${key}`);
  }

  onDeactivate(isClosing: boolean) {
    if (isClosing) {
      this.asyncValidator?.dispose();
    }
    return super.onDeactivate(isClosing);
  }
}
