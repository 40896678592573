import { registerView, View, ViewComponent } from "@frui.ts/views";
import { observer } from "mobx-react-lite";
import React from "react";
import NotificationItemViewModel from "viewModels/notifications/notificationItems/notificationItemViewModel";

const notificationItemView: ViewComponent<NotificationItemViewModel> = observer(({ vm }) => {
  if (!vm.notificationItemVM) {
    return <></>;
  }

  const { formatDateTime } = vm.localization;
  const { notification } = vm;
  const className = "notification-item" + (notification.read ? "" : " unread");

  return (
    <div className={className}>
      <p>{formatDateTime(notification.dateReceived)}</p>
      <View vm={vm.notificationItemVM} />
    </div>
  );
});

registerView(notificationItemView, NotificationItemViewModel);
