import { observable } from "mobx";
import { Type } from "class-transformer";
import ContractorProfileSearchResult from "./contractorProfileSearchResult";

// This entity has been generated, do not change its content, your changes might get lost. You CAN modify the rest of the file.
export default class ContractorProfileSearchResults {
  @observable
  @Type(() => ContractorProfileSearchResult)
  contractors!: ContractorProfileSearchResult[];

  @observable
  total!: number;

  static ValidationRules = {
    contractors: { required: true },
    total: { required: true },
  };
}
