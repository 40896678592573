import { Button, Col, LoaderOverlay, Row } from "@emanprague/veralinkkit";
import { registerView } from "@frui.ts/views";
import React from "react";
import ChangePasswordViewModel from "viewModels/profile/changePasswordViewModel";
import ModalView from "views/common/modalView";
import { PasswordInput } from "controls/passwordInput";

class ChangePasswordModalView extends ModalView<ChangePasswordViewModel> {
  renderBody(vm: ChangePasswordViewModel) {
    const ta = vm.localization.translateAttribute;

    return (
      <LoaderOverlay loading={vm.busyWatcher.isBusy} variant="light">
        <Row>
          <Col xs={12}>
            <PasswordInput label={ta("userSettings", "oldPassword")} target={vm.userSettings} property="oldPassword" />
          </Col>
          <Col xs={12}>
            <PasswordInput label={ta("userSettings", "newPassword")} target={vm.userSettings} property="newPassword" />
          </Col>
          <Col xs={12}>
            <PasswordInput label={ta("userSettings", "repeatPassword")} target={vm.userSettings} property="repeatPassword" />
          </Col>
        </Row>
      </LoaderOverlay>
    );
  }

  renderFooter(vm: ChangePasswordViewModel) {
    return (
      <Button variant="primary" disabled={vm.busyWatcher.isBusy} onClick={vm.changePassword}>
        {vm.translate("save")}
      </Button>
    );
  }
}

registerView(ChangePasswordModalView as any, ChangePasswordViewModel);
