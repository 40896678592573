import { observable } from "mobx";
import { Type } from "class-transformer";
import QuestionnaireQuestionOption from "./questionnaireQuestionOption";
import QuestionnaireQuestionShowOnlyIf from "./questionnaireQuestionShowOnlyIf";
import QuestionnaireQuestionType from "./questionnaireQuestionType";

// This entity has been generated, do not change its content, your changes might get lost. You CAN modify the rest of the file.
export default class QuestionnaireQuestion {
  /**
   * Question ID
   */
  id?: string;

  @observable
  type!: QuestionnaireQuestionType;

  /**
   * Text of the question
   */
  @observable
  questionText!: string;

  /**
   * Only relevant if `type` is `SINGLE` or `MULTI`
   */
  @observable
  @Type(() => QuestionnaireQuestionOption)
  options?: QuestionnaireQuestionOption[];

  @observable
  @Type(() => QuestionnaireQuestionShowOnlyIf)
  showOnlyIf?: QuestionnaireQuestionShowOnlyIf;

  static ValidationRules = {
    type: { required: true },
    questionText: { required: true },
  };
}
