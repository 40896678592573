import { ILocalizationService } from "@emanprague/shared-services";
import { bound } from "@frui.ts/helpers";
import { BusyWatcher, ScreenBase, watchBusy } from "@frui.ts/screens";
import { observable, runInAction, action, toJS } from "mobx";
import { interfaces } from "inversify";
import ComplianceRepository from "repositories/complianceRepository";
import SafetyMetricPointsRatio from "entities/safetyMetricPointsRatio";
import { attachAutomaticValidator, validateAll, validate } from "@frui.ts/validation";
import SafetyMetricSettings from "entities/safetyMetricSettings";
import { ValidationRules } from "entities/safetyMetricIndicatorThreshold";

const pointsValidation = {
  indicators: {
    hasTotal: { other: "questionnaire", total: 100, isAboveZero: true },
  },
  questionnaire: {
    hasTotal: { other: "indicators", total: 100, isAboveZero: true },
  },
};

export default class SafetyIndicatorsViewModel extends ScreenBase {
  navigationName = "safetyIndicators";
  busyWatcher = new BusyWatcher();
  @observable indicators: SafetyMetricSettings;
  initialIndicators: SafetyMetricSettings;

  constructor(private companyId: string, public localization: ILocalizationService, private repository: ComplianceRepository) {
    super();

    this.name = this.translate("title");
  }

  async onInitialize() {
    await this.loadMetrics();
  }

  get ratio() {
    const keys = Object.keys(this.indicators?.pointsRatio ?? {}).filter(name => !name.startsWith("_")) as Array<
      keyof SafetyMetricPointsRatio
    >;

    return keys;
  }

  @bound
  @watchBusy
  async loadMetrics() {
    const res = await this.repository.loadSafetyMetrics(this.companyId);

    if (res.success) {
      this.initialIndicators = Object.freeze({ ...toJS(res.payload) });

      runInAction(() => {
        this.indicators = res.payload;
        attachAutomaticValidator(this.indicators, SafetyMetricSettings.ValidationRules);
        attachAutomaticValidator(this.indicators.pointsRatio, pointsValidation);

        this.indicators.indicatorThresholds.forEach(threshold => attachAutomaticValidator(threshold, ValidationRules));
      });
    }
  }

  @bound translate(key: string) {
    return this.localization.translateGeneral(`compliance.safetyIndicators.${key}`);
  }

  @bound
  @action
  reset() {
    this.indicators = { ...this.initialIndicators };
  }

  @bound
  @watchBusy
  async save() {
    if (validate(this.indicators) && validate(this.indicators.pointsRatio) && validateAll(this.indicators.indicatorThresholds)) {
      const res = await this.repository.saveSafetyMetrics(this.companyId, this.indicators);

      if (res.success) {
        this.requestClose();
      }
    }
  }

  @bound cancel() {
    this.requestClose();
  }

  static Factory({ container }: interfaces.Context) {
    return (companyId: string) =>
      new SafetyIndicatorsViewModel(companyId, container.get("ILocalizationService"), container.get(ComplianceRepository));
  }
}
