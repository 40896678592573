import { ILocalizationService } from "@emanprague/shared-services";
import { bound } from "@frui.ts/helpers";
import { BusyWatcher, ScreenBase } from "@frui.ts/screens";
import { interfaces } from "inversify";

export default class PersonalQrViewModel extends ScreenBase {
  navigationName = "qr";
  busyWatcher = new BusyWatcher();

  constructor(public workerId: string, public workerName: string, public localization: ILocalizationService) {
    super();
    this.name = this.translate("title");
  }

  @bound translate(key: string) {
    return this.localization.translateGeneral(`worker.qr.${key}`);
  }

  static Factory({ container }: interfaces.Context) {
    return (workerId: string, workerName: string) =>
      new PersonalQrViewModel(workerId, workerName, container.get("ILocalizationService"));
  }
}
