import { Card, RoundedButton } from "@emanprague/veralinkkit";
import DeleteIcon from "@emanprague/veralinkkit/dist/Icons/Delete";
import PlusIcon from "@emanprague/veralinkkit/dist/Icons/Plus";
import { ViewComponent } from "@frui.ts/views";
import React from "react";
import { formatDateRange } from "utils/viewUtils";
import WorkerProfileViewModel from "viewModels/worker/workerProfileViewModel";

const workExperienceSectionView: ViewComponent<WorkerProfileViewModel> = ({ vm }) => {
  const { mode, item } = vm;
  const isEdit = mode === "edit";

  const handleAddOpen = () => vm.openScreen("addExperience");

  return (
    <Card>
      <Card.Header>
        <Card.Title className="text-uppercase">{vm.translate("workExperience")}</Card.Title>
        {isEdit && (
          <RoundedButton onClick={handleAddOpen}>
            <PlusIcon fontSize={20} />
          </RoundedButton>
        )}
      </Card.Header>
      <Card.Body>
        <div className="history-list">
          {item.workExperience.map(ex => (
            <div className="history-list__item" key={ex.id}>
              {ex.currentJob && <span className="status status-green">{vm.translate("stillWorkHere")}</span>}
              <h6
                className={`item__title ${isEdit ? "edit" : ""}`}
                onClick={() => {
                  if (isEdit) {
                    vm.openEditExperienceModal(ex);
                  }
                }}>
                {vm.enums.getProfessionById(ex.role)?.name}&nbsp;<span>{vm.translate("at")}</span>
                {` ${ex.company}`}
              </h6>
              <p className="item__date">{formatDateRange({ startDate: ex.fromDate, endDate: ex.toDate }, vm.localization)}</p>
              <div className="item__description">{ex.description}</div>
              {isEdit && (
                <DeleteIcon className="item__action" color="#888F91" onClick={() => vm.deleteExperienceItem(ex.id ?? "")} />
              )}
            </div>
          ))}
        </div>
      </Card.Body>
    </Card>
  );
};

export default workExperienceSectionView;
