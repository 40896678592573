import { Button, SupportDialog, Container, Nav, Navbar } from "@emanprague/veralinkkit";
import Logo from "@emanprague/veralinkkit/dist/assets/icons/Logo/Positive.svg";
import Assistance from "@emanprague/veralinkkit/dist/Icons/Assistance";
import { Router } from "@frui.ts/screens";
import { registerView, View, ViewComponent } from "@frui.ts/views";
import { observer, Observer } from "mobx-react-lite";
import React, { useState } from "react";
import AnonymousRootViewModel from "viewModels/anonymousRootViewModel";

const excludeModels = ["setup-password", "join", "forgot-password", "worker-import", "publicProfile"];

const HeaderView: ViewComponent<AnonymousRootViewModel> = observer(({ vm }) => {
  const getUrl = Router.getChildUrlFactory(vm);
  const [isOpen, setOpen] = useState(false);
  const toggleModal = () => setOpen(!isOpen);

  return (
    <Navbar className="shadow" as="header" fixed="top">
      <Navbar.Brand href="#">
        <img src={Logo} alt="Logo" />
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="basic-navbar-nav" />

      <Navbar.Collapse id="basic-navbar-nav">
        <Nav>
          {vm.children
            .filter(xs => !excludeModels.includes(xs.navigationName))
            .map(x => (
              <Nav.Link key={x.navigationName} href={getUrl(x.navigationName)} active={vm.activeChild === x}>
                {x.name}
              </Nav.Link>
            ))}
        </Nav>

        <Button variant="primary" size="lg" onClick={toggleModal}>
          <Assistance />
          &nbsp; {vm.translate("needHelp")}
        </Button>
        <SupportDialog
          message={vm.translate("message")}
          email="info@veralink.io"
          isOpen={isOpen}
          toggleClose={() => setOpen(false)}
        />
      </Navbar.Collapse>
    </Navbar>
  );
});

const anonymousRootView: ViewComponent<AnonymousRootViewModel> = observer(({ vm }) => {
  return (
    <>
      <HeaderView vm={vm} />
      <Container className="root-container">
        <Observer>
          {() => (
            <div className="toast-container">
              {vm.toasts.map(item => (
                <View key={item.key} vm={item} useLifecycle />
              ))}
            </div>
          )}
        </Observer>
        <Observer>{() => <View vm={vm.activeChild} />}</Observer>
      </Container>
    </>
  );
});

registerView(anonymousRootView, AnonymousRootViewModel);
