import { observable } from "mobx";
import { Type } from "class-transformer";
import QuestionnaireSection from "./questionnaireSection";

// This entity has been generated, do not change its content, your changes might get lost. You CAN modify the rest of the file.
export default class Questionnaire {
  @observable
  @Type(() => QuestionnaireSection)
  sections!: QuestionnaireSection[];

  static ValidationRules = {
    sections: { required: true },
  };
}
