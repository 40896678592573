import React from "react";
import { Button } from "@emanprague/veralinkkit";
import { useState } from "react";

type Props = {
  tg: (a: string) => string;
  submitReview: (approved: boolean) => void;
  children: React.ReactNode;
  textBox?: React.ReactNode;
  showButtons: boolean;
};

const SafetyProgramsApproveRequest: React.FC<Props> = ({ children, tg, submitReview, textBox, showButtons }) => {
  const [opened, setState] = useState(false);

  const openClose = () => {
    setState(!opened);
  };

  return (
    <>
      {!opened && children}
      {opened && (
        <>
          {tg("additionalSafety")}
          {textBox}
        </>
      )}
      <br />
      <div className="safety_action__container">
        {!opened && showButtons && (
          <>
            <Button onClick={() => submitReview(true)}>{tg("approve")}</Button>
            <Button onClick={openClose} variant="outline-primary">
              {tg("request")}
            </Button>
          </>
        )}
        {opened && showButtons && (
          <>
            <Button onClick={() => submitReview(false)}>{tg("request")}</Button>
            <Button onClick={openClose} variant="outline-primary">
              {tg("cancel")}
            </Button>
          </>
        )}
      </div>
    </>
  );
};

export default SafetyProgramsApproveRequest;
