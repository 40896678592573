import { observable } from "mobx";
import { Type } from "class-transformer";
import SafetyMetricIndicatorThreshold from "./safetyMetricIndicatorThreshold";
import SafetyMetricPointsRatio from "./safetyMetricPointsRatio";

// This entity has been generated, do not change its content, your changes might get lost. You CAN modify the rest of the file.
export default class SafetyMetricSettings {
  @observable
  @Type(() => SafetyMetricIndicatorThreshold)
  indicatorThresholds!: SafetyMetricIndicatorThreshold[];

  @observable
  @Type(() => SafetyMetricPointsRatio)
  pointsRatio!: SafetyMetricPointsRatio;

  static ValidationRules = {
    indicatorThresholds: { required: true },
    pointsRatio: { required: true },
  };
}
