import { SortingDirection } from "@frui.ts/data";
import Availability from "entities/availability";
import CodeListProfession from "entities/codeListProfession";
import WorkerProfileSearchResult from "entities/workerProfileSearchResult";
import SortOption from "models/sortOption";
import RepositoryBase from "./repositoryBase";
import TrainingEntity from "entities/trainingEntity";
import CredentialType from "entities/credentialType";
import CompanyEmployee from "entities/companyEmployee";
import ContractorProfileSearchResult from "entities/contractorProfileSearchResult";
import CodeListState from "entities/codeListState";
import CodeListWorkType from "entities/codeListWorkType";
import CodeListSafetyPolicy from "entities/codeListSafetyPolicy";
import CompanyEmployeesPendingRecord from "entities/companyEmployeesPendingRecord";
import CodeListIndustry from "entities/codeListIndustry";
import CodeListUnion from "entities/codeListUnion";

export default class EnumsRepository extends RepositoryBase {
  async getStates() {
    const data = await this.callApi(api => api.all("userprofile/codelists").all("states").get<CodeListState[]>());

    if (data.success) {
      return data.payload;
    } else {
      return [];
    }
  }

  async getProfessions() {
    const data = await this.callApi(api => api.all("userprofile/codelists").all("professions").get<CodeListProfession[]>());

    if (data.success) {
      return data.payload;
    } else {
      return [];
    }
  }

  async getIndustries() {
    const data = await this.callApi(api => api.all("userprofile/codelists").all("industries").get<CodeListIndustry[]>());

    if (data.success) {
      return data.payload;
    } else {
      return [];
    }
  }

  async getUnions() {
    const data = await this.callApi(api => api.all("userprofile/codelists").all("unions").get<CodeListUnion[]>());

    if (data.success) {
      return data.payload;
    } else {
      return [];
    }
  }

  async getCredentialTypes() {
    const data = await this.callApi(api => api.all("credentials/types/company").getEntities(CredentialType));

    if (data.success) {
      return data.payload;
    } else {
      return [];
    }
  }

  async getCompanyCredentialTypes() {
    const data = await this.callApi(api => api.all("credentials/types/company").getEntities(CredentialType));

    if (data.success) {
      return data.payload;
    } else {
      return [];
    }
  }

  async getWorkerCredentialTypes() {
    const data = await this.callApi(api => api.all("credentials/types/worker").getEntities(CredentialType));

    if (data.success) {
      return data.payload;
    } else {
      return [];
    }
  }

  async getTrainingEntities() {
    const data = await this.callApi(api => api.all("credentials/training_entities").getEntities(TrainingEntity));

    if (data.success) {
      return data.payload;
    } else {
      return [];
    }
  }

  async getSafetyPolicies() {
    const data = await this.callApi(api => api.all("userprofile/codelists/safety_policies").getEntities(CodeListSafetyPolicy));

    if (data.success) {
      return data.payload;
    } else {
      return [];
    }
  }

  async getWorkTypes() {
    const data = await this.callApi(api => api.all("userprofile/codelists/work_types").getEntities(CodeListWorkType));

    if (data.success) {
      return data.payload;
    } else {
      return [];
    }
  }

  getWorkerPortalSortOptions() {
    return [
      ["lastName", SortingDirection.Ascending],
      ["lastName", SortingDirection.Descending],
      ["primaryProfessionName", SortingDirection.Ascending],
      ["primaryProfessionName", SortingDirection.Descending],
    ] as SortOption<WorkerProfileSearchResult>[];
  }

  getContractorPortalSortOptions() {
    return [
      ["contractorName", SortingDirection.Ascending],
      ["contractorName", SortingDirection.Descending],
    ] as SortOption<ContractorProfileSearchResult>[];
  }

  getWorkerAvailabilityOptions() {
    return [Availability.Now, Availability.Future, Availability.Not];
  }

  getCompanyEmployeesSortOptions() {
    return [
      ["lastName", SortingDirection.Ascending],
      ["lastName", SortingDirection.Descending],
      ["primaryProfessionName", SortingDirection.Ascending],
      ["primaryProfessionName", SortingDirection.Descending],
    ] as SortOption<CompanyEmployee>[];
  }

  getCompanyPendingEmployeesSortOptions() {
    return [
      ["lastName", SortingDirection.Ascending],
      ["dateCreated", SortingDirection.Descending],
    ] as SortOption<CompanyEmployeesPendingRecord>[];
  }
}
