import React from "react";
import { Pending, StateOk, StateNotOk } from "@emanprague/veralinkkit/dist/Icons";
import Messages from "@emanprague/veralinkkit/dist/Icons/Comment";
import { Tooltip, OverlayTrigger } from "react-bootstrap";
import InsuranceDocumentStatus from "entities/insuranceDocumentStatus";

type Props = {
  status: InsuranceDocumentStatus;
  rejectedMessage?: string;
  translate: (a: string) => string;
};

const InsuranceStatus: React.FC<Props> = ({ status, rejectedMessage, translate }) => {
  switch (status) {
    case "REJECTED": {
      return (
        <div className="insurance__status">
          <StateNotOk />
          {translate("rejected")}
          {!!rejectedMessage && (
            <OverlayTrigger placement="top" overlay={<Tooltip id="tooltip-2">{rejectedMessage}</Tooltip>}>
              <Messages />
            </OverlayTrigger>
          )}
        </div>
      );
    }
    case "PENDING": {
      return (
        <div className="insurance__status">
          <Pending />
          {translate("pending")}
        </div>
      );
    }
    case "APPROVED": {
      return (
        <div className="insurance__status">
          <StateOk />
          {translate("approved")}
        </div>
      );
    }
    default:
      return null;
  }
};

export default InsuranceStatus;
