import React from "react";
import { observer } from "mobx-react-lite";
import { Badge, Button, Card } from "@emanprague/veralinkkit";
import RequestLink from "@emanprague/veralinkkit/dist/Icons/RequestLink";
import Check from "@emanprague/veralinkkit/dist/Icons/Check";
import { ILocalizationService } from "@emanprague/shared-services";
import ContractorProfileSearchResult from "entities/contractorProfileSearchResult";
import EnumsService from "services/enumsService";

export interface ContractorSearchCardProps {
  contractor: ContractorProfileSearchResult;
  localization: ILocalizationService;
  onViewProfile: () => void;
  onRequestLink: () => void;
  enums: EnumsService;
}

const ContractorSearchCard: React.FunctionComponent<ContractorSearchCardProps> = observer(
  ({ contractor, localization, onViewProfile, onRequestLink, enums }) => {
    const tg = (code: string) => localization.translateGeneral(`workerPortal.workerSearchCard.${code}`);

    return (
      <Card className="contractor-search-card">
        <h5>{contractor.contractorName}</h5>

        {!!contractor.services?.length && (
          <span>
            {contractor.services?.map((service, index) => (
              <Badge key={index} variant="light">
                {service}
              </Badge>
            ))}
          </span>
        )}

        <p>{contractor.locations?.map(loc => [loc.name, enums.getStateById(loc.state)?.name].join(" ")).join(", ")}</p>
        <div className="contractor-search-card__footer">
          <Button onClick={onViewProfile}>{tg("viewProfile")}</Button>

          {contractor.connectionAccepted === undefined ? (
            <Button variant="outline-primary" onClick={onRequestLink}>
              <RequestLink fontSize={24} color="#00949E" />
              {tg("requestLink")}
            </Button>
          ) : (
            <Button variant="success">
              <Check fontSize={24} color="#50A01C" />
              {tg("linkRequested")}
            </Button>
          )}
        </div>
      </Card>
    );
  }
);

export default ContractorSearchCard;
