import React from "react";
import { registerView, ViewComponent } from "@frui.ts/views";
import { observer } from "mobx-react-lite";
import ConnectionRequestViewModel from "viewModels/notifications/notificationItems/connectionRequestViewModel";
import { Button } from "@emanprague/veralinkkit";
import NotificationConnectionRequestContentType from "entities/notificationConnectionRequestContentType";

const connectionRequestView: ViewComponent<ConnectionRequestViewModel> = observer(({ vm }) => {
  const { content, notification, translate } = vm;

  // new connection request
  if (content.type === NotificationConnectionRequestContentType.New) {
    return (
      <>
        <header>
          <a href={vm.profileUrl}>{content.accountFullName}</a> {translate("new")}
        </header>
        {!notification.read && (
          <Button variant="success" onClick={vm.acceptConnectionRequest}>
            {translate("accept")}
          </Button>
        )}
      </>
    );
  }

  // accepted connection request
  if (content.type === NotificationConnectionRequestContentType.Accepted) {
    return (
      <>
        <header>
          <a href={vm.profileUrl} onClick={vm.markAsRead}>
            {content.accountFullName}
          </a>{" "}
          {translate("accepted")}
        </header>
      </>
    );
  }

  return <></>;
});

registerView(connectionRequestView, ConnectionRequestViewModel);
