import { registerView } from "@frui.ts/views";
import React from "react";
import ThresholdEditModalViewModel from "viewModels/company/compliance/thresholdEditModalViewModel";
import ModalView, { ModalSize } from "views/common/modalView";
import { Row, Col, Form, Button } from "@emanprague/veralinkkit";
import Input from "controls/input";
import "./compliance.scss";

class ThresholdEditModalView extends ModalView<ThresholdEditModalViewModel> {
  size: ModalSize = "lg";

  renderFooter(vm: ThresholdEditModalViewModel) {
    return (
      <Row className="justify-content-end">
        <Button variant="outline-primary" disabled={vm.busyWatcher.isBusy} onClick={vm.cancel}>
          {vm.translate("cancel")}
        </Button>
        <Button variant="primary" disabled={vm.busyWatcher.isBusy} onClick={vm.save}>
          {vm.translate("save")}
        </Button>
      </Row>
    );
  }

  renderBody(vm: ThresholdEditModalViewModel) {
    return (
      <Row>
        <Col>
          {vm.thresholds.map(threshold => {
            return (
              <Row key={threshold.id} className="edit-row threshold">
                <b>{threshold.name}</b>

                <Form.Group>
                  <Input type="number" placeholder="0" target={threshold} property="min" />
                </Form.Group>
                <hr />
                <Form.Group>
                  <Input placeholder="0" type="number" target={threshold} property="max" />
                </Form.Group>
              </Row>
            );
          })}
        </Col>
      </Row>
    );
  }
}
registerView(ThresholdEditModalView, ThresholdEditModalViewModel);
