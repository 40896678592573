import { INotificationService } from "@emanprague/shared-services";
import { action, computed } from "mobx";
import { Router, ScreenBase, splitUrlSegments } from "@frui.ts/screens";
import DialogService from "services/dialogService";
import UserContext from "services/userContext";
import CompleteCompanyProfileViewModel from "./company/companyInfo/modals/completeCompanyProfileViewModel";
import RootViewModelBase from "./rootViewModelBase";
import ToastViewModel from "./toastViewModel";
import { IModule } from "./types";
import CompleteWorkerProfileViewModel from "./worker/modals/completeWorkerProfileViewModel";
import NotifyMessagesViewModel from "viewModels/messages/notifyMessagesViewModel";
import SuccessEmailSentViewModel from "./signup/successEmailSentViewModel";
import AccountSettingsListViewModel from "./profile/accountSettings/accountSettingsListViewModel";
import AccountSettingsViewModel from "./profile/accountSettings/accountSettingsViewModel";
import StatusViewModel from "./profile/statusViewModel";
import NotificationsViewModel from "./notifications/notificationsViewModel";
import WorkerImportViewModel from "./workerImportViewModel";
import PublicProfileViewModel from "./public/profileViewModel";

@Router.registerRoute({ name: CompleteCompanyProfileViewModel, route: "completeCompanyProfile" })
@Router.registerRoute({ name: CompleteWorkerProfileViewModel, route: "completeWorkerProfile" })
@Router.registerRoute({
  name: AccountSettingsListViewModel,
  route: "accountSettings",
  children: [SuccessEmailSentViewModel, AccountSettingsViewModel],
})
export default class RootViewModel extends RootViewModelBase<IModule, ScreenBase> {
  get toasts() {
    const results: ToastViewModel[] = [];

    const service = this.notificationService;
    service.notifications.forEach((notification, key) =>
      results.push(new ToastViewModel(key, notification.message, notification.severity, () => service.removeNotification(key)))
    );

    return results;
  }

  constructor(
    modules: IModule[],
    public statusVM: StatusViewModel,
    public notifyMessagesVM: NotifyMessagesViewModel,
    public notificationsVM: NotificationsViewModel,
    userContext: UserContext,
    public dialogService: DialogService,
    private notificationService: INotificationService,
    private completeCompanyViewModelFactory: () => CompleteCompanyProfileViewModel,
    private completeWorkerViewModelFactory: () => CompleteWorkerProfileViewModel,
    private accountSettingsViewModelFactory: () => AccountSettingsListViewModel,
    workerImportVM: WorkerImportViewModel,
    publicProfile: PublicProfileViewModel
  ) {
    super();

    const currentIdentity = userContext.identity;
    const allowedModules = currentIdentity ? modules.filter(x => x.isVisible(currentIdentity)) : [];
    this.setModules(allowedModules);
    this.notifyMessagesVM.activate();

    this.children.push(notificationsVM as any);
    this.notificationsVM.initialize();

    this.children.push(workerImportVM as any);
    this.children.push(publicProfile as any);
  }

  @computed
  get visibleModules() {
    return !this.appendedViewModels.length && this.children.length > 1 ? this.children.filter(c => c.hideFromMenu !== true) : [];
  }

  @action
  public setModules(modules: IModule[]) {
    modules.sort((a, b) => a.orderIndex - b.orderIndex);
    this.children.push(...modules);
  }

  protected async onInitialize() {
    if (!this.activeChild && this.children.length) {
      await this.tryActivateChild(this.children[0]);
    }
  }

  async navigate(subPath: string | undefined, params: any) {
    const segments = splitUrlSegments(subPath);

    let viewModel;
    switch (segments[0]) {
      case "completeCompanyProfile":
        viewModel = this.completeCompanyViewModelFactory();
        this.navigateToAppendedViewModel(viewModel, segments[1]);
        break;
      case "completeWorkerProfile":
        viewModel = this.completeWorkerViewModelFactory();
        this.navigateToAppendedViewModel(viewModel, segments[1]);
        break;
      case "change-email":
        viewModel = this.accountSettingsViewModelFactory();
        this.navigateToAppendedViewModel(viewModel, "emailConfirmed", params);
        break;
      case "accountSettings":
        viewModel = this.accountSettingsViewModelFactory();
        this.navigateToAppendedViewModel(viewModel, segments[1], params);
        break;
      default:
        await super.navigate(subPath, params);
    }
  }
}
