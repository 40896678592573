export default class StorageProvider {
  set(key: string, value: any) {
    localStorage.setItem(key, JSON.stringify(value));
  }

  get<T>(key: string) {
    const value = localStorage.getItem(key);
    return value ? (JSON.parse(value) as T) : undefined;
  }

  remove(key: string) {
    localStorage.removeItem(key);
  }

  clearAll() {
    localStorage.clear();
  }
}
