import { Card, RoundedButton, Row, Col } from "@emanprague/veralinkkit";
import { ViewComponent } from "@frui.ts/views";
import React from "react";
import Edit from "@emanprague/veralinkkit/dist/Icons/Edit";
import { observer } from "mobx-react-lite";
import CompanyProfileViewModel from "viewModels/company/companyInfo/companyProfileViewModel";

const CompanyContactsSection: ViewComponent<CompanyProfileViewModel> = observer(({ vm }) => {
  const { translateGeneral } = vm.localization;
  const tg = (code: string) => translateGeneral(`companyInfo.subModule.${code}`);

  return (
    <Card className="company-info__card">
      <Card.Header>
        <h4>{tg("locations")}</h4>
        {vm.isEdit && (
          <RoundedButton onClick={vm.openEditCompanyLocations}>
            <Edit fontSize={20} />
          </RoundedButton>
        )}
      </Card.Header>
      <Card.Body>
        <Row>
          {vm.companyLocations?.map(location => (
            <Col md={6} key={location.id} className="company-info__location">
              <h5>{location.name}</h5>
              <p>{location.street}</p>
              <p>
                {location.city}, {vm.enums.getStateById(location.state)?.name} {location.zip}
              </p>
            </Col>
          ))}
        </Row>
      </Card.Body>
    </Card>
  );
});

export default CompanyContactsSection;
