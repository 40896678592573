import { observable } from "mobx";
import { Type } from "class-transformer";
import ComplianceRequirementApprovalResult from "./complianceRequirementApprovalResult";
import ComplianceRequirementQuestionnaireResult from "./complianceRequirementQuestionnaireResult";
import ContractorConnectionPointsExperienceModifier from "./contractorConnectionPointsExperienceModifier";

// This entity has been generated, do not change its content, your changes might get lost. You CAN modify the rest of the file.
export default class ContractorConnectionPoints {
  @observable
  @Type(() => ComplianceRequirementQuestionnaireResult)
  generalInformation?: ComplianceRequirementQuestionnaireResult;

  @observable
  @Type(() => ComplianceRequirementQuestionnaireResult)
  contractorSafetyCulture?: ComplianceRequirementQuestionnaireResult;

  @observable
  @Type(() => ContractorConnectionPointsExperienceModifier)
  experienceModifier?: ContractorConnectionPointsExperienceModifier;

  @observable
  @Type(() => ComplianceRequirementApprovalResult)
  insurance?: ComplianceRequirementApprovalResult;

  @observable
  @Type(() => ComplianceRequirementQuestionnaireResult)
  safetyMetrics?: ComplianceRequirementQuestionnaireResult;

  @observable
  @Type(() => ComplianceRequirementQuestionnaireResult)
  workHours?: ComplianceRequirementQuestionnaireResult;

  @observable
  @Type(() => ComplianceRequirementApprovalResult)
  healthAndSafetyPrograms?: ComplianceRequirementApprovalResult;
}
