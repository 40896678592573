import React from "react";
import { Col, Row, Card, RoundedButton } from "@emanprague/veralinkkit";
import EditIcon from "@emanprague/veralinkkit/dist/Icons/Edit";
import "./requirementCard.scss";
import classnames from "classnames";

type RequirementValue = {
  name: string;
  required?: boolean;
  value: string | number;
};

type Props = {
  testId?: string;
  title: string;
  onEdit?: () => void;
  data: RequirementValue[];
  classNames?: any;
};

const RequirementCard = ({ testId, title, onEdit, data, classNames = {} }: Props) => {
  const { childRow } = classNames;
  return (
    <Card>
      <Card.Header>
        <h4>{title}</h4>
        {onEdit && (
          <RoundedButton onClick={onEdit}>
            <EditIcon fontSize={20} />
          </RoundedButton>
        )}
      </Card.Header>

      <Card.Body as={Row}>
        <Col className="compliance-section__list" data-testId={testId}>
          {data.map(threshold => {
            const classes = classnames({
              "text-muted": !threshold.required,
            });
            return (
              <Row className={classes} key={threshold.name} data-testid={`${testId}-child`}>
                <b className={childRow}>{threshold.name}</b>
                {threshold.value && <p>{threshold.value}</p>}
              </Row>
            );
          })}
        </Col>
      </Card.Body>
    </Card>
  );
};
export default RequirementCard;
