import { ILocalizationService } from "@emanprague/shared-services";
import { bound } from "@frui.ts/helpers";
import { BusyWatcher, ScreenBase, watchBusy } from "@frui.ts/screens";
import ProjectRepository from "repositories/projectRepository";
import { interfaces } from "inversify";
import CompanyProjectTeam from "entities/companyProjectTeam";
import { observable, runInAction } from "mobx";
import FormTeamViewModel from "viewModels/company/projects/teams/formTeamViewModel";
import { attachAutomaticValidator, validate } from "@frui.ts/validation";

export default class NewTeamViewModel extends ScreenBase {
  navigationName = "new";
  busyWatcher = new BusyWatcher();

  @observable item: CompanyProjectTeam = new CompanyProjectTeam();
  @observable formVM: FormTeamViewModel;

  constructor(
    private companyId: string,
    private projectId: string,
    public localization: ILocalizationService,
    private repository: ProjectRepository,
    formTeamFactory: ReturnType<typeof FormTeamViewModel.Factory>
  ) {
    super();

    this.name = this.translate("title");

    runInAction(() => {
      this.formVM = formTeamFactory(this.item);
    });

    attachAutomaticValidator(this.formVM.team, CompanyProjectTeam.ValidationRules);
  }

  @bound
  @watchBusy
  async createTeam() {
    if (validate(this.formVM.team)) {
      this.formVM.prepareTeam();
      const result = await this.repository.createProjectTeam(this.companyId, this.projectId, this.formVM.team);
      if (result.success) {
        this.requestClose();
      }
    }
  }

  @bound translate(key: string) {
    return this.localization.translateGeneral(`projects.teams.new.${key}`);
  }

  static Factory({ container }: interfaces.Context) {
    return (companyId: string, projectId: string) =>
      new NewTeamViewModel(
        companyId,
        projectId,
        container.get("ILocalizationService"),
        container.get(ProjectRepository),
        container.get(FormTeamViewModel.Factory)
      );
  }
}
