import { PropertyName } from "@frui.ts/helpers";
import { BusyWatcher } from "@frui.ts/screens";
import { ValidationErrors } from "@frui.ts/validation";
import { debounce } from "lodash";
import { action, IReactionDisposer, observable, reaction, set } from "mobx";
import { IAsyncValidator } from "./types";

export type IAsyncEntityValidationRules<TTarget> = Partial<Record<PropertyName<TTarget>, IAsyncValidator>>;

export default class AsyncValidator<TTarget extends Record<string, any>> {
  @observable errors: ValidationErrors<TTarget> = {};
  busyWatcher = new BusyWatcher();
  private reactions: IReactionDisposer[] = [];

  constructor(target: TTarget, entityValidationRules: IAsyncEntityValidationRules<TTarget>, timeout = 500) {
    for (const propertyName in entityValidationRules) {
      if (entityValidationRules.hasOwnProperty(propertyName)) {
        set(this.errors, propertyName, undefined);

        const validator = (entityValidationRules as Record<string, IAsyncValidator>)[propertyName];
        const validationFunction = async (value: any) => {
          const error = await this.busyWatcher.watch(validator(value, propertyName, target, undefined));
          this.setError(propertyName, error);
        };

        const validationReaction = reaction(() => target[propertyName], debounce(validationFunction, timeout), {
          delay: timeout,
        });

        this.reactions.push(validationReaction);
      }
    }
  }

  @action private setError(property: string, message: string | undefined) {
    set(this.errors, property, message);
  }

  dispose() {
    this.reactions.forEach(x => x());
  }
}
