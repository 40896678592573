import { observable } from "mobx";

// This entity has been generated, do not change its content, your changes might get lost. You CAN modify the rest of the file.
export default class CompanyProjectDocument {
  /**
   * read-only
   */
  id?: string;

  @observable
  name?: string;

  @observable
  fileName?: string;
}
