import { observable } from "mobx";
import CompanyLocation from "./companyLocation";

// This entity has been generated, do not change its content, your changes might get lost. You CAN modify the rest of the file.
export default class ContractorProfileSearchResult {
  /**
   * Company ID (read-only)
   */
  @observable
  accountId!: string;

  /**
   * Company name
   */
  @observable
  contractorName!: string;

  /**
   * Services provided by company
   */
  @observable
  services?: string[];

  @observable
  locations?: CompanyLocation[];

  @observable
  connectionId?: string;

  @observable
  connectionAccepted?: boolean;

  static ValidationRules = {
    accountId: { required: true },
    contractorName: { required: true },
  };
}
