import { CredentialPhoto } from "entities/credential";
import React from "react";
import CredentialItem from "./credentialItem";
import { Row, Col } from "@emanprague/veralinkkit";

export type CredentialsSectionGrouped = {
  name: string;
  data: CredentialPhoto[];
  id: string;
};

const CredentialSection: React.FC<{
  item: CredentialsSectionGrouped;
  onItemClick: (a: CredentialPhoto) => void;
  tg: (key: string) => string;
}> = ({ item, onItemClick, tg }) => {
  return (
    <Row key={item.id} className="credential-section">
      <p className="credential-section__title">{item.name}</p>

      <Col xs={12}>
        {item.data.map(credential => (
          <CredentialItem onClick={() => onItemClick(credential)} key={credential.id} item={credential} tg={tg} />
        ))}
      </Col>
    </Row>
  );
};

export default CredentialSection;
