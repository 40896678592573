import { combinePath, ConductorSingleChild, ScreenBase, splitUrlSegments } from "@frui.ts/screens";
import AuthenticationIdentity from "entities/authenticationIdentity";
import { scrollTop } from "utils/browserUtils";
import CompanyProfileViewModel from "viewModels/company/companyInfo/companyProfileViewModel";
import WorkerProfileViewModel from "viewModels/worker/workerProfileViewModel";

export default class ProfileViewModel extends ConductorSingleChild<ScreenBase> {
  navigationName = "publicProfile";
  orderIndex = 999;
  hideFromMenu = true;

  constructor(
    private workerProfileFactory: ReturnType<typeof WorkerProfileViewModel.Factory>,
    private companyProfileFactory: ReturnType<typeof CompanyProfileViewModel.Factory>
  ) {
    super();
  }

  async onActivate() {
    scrollTop();
    await super.onActivate();
  }

  getNavigationPath() {
    const original = super.getNavigationPath();

    if (this.activeChild instanceof WorkerProfileViewModel) {
      return combinePath(super.getNavigationPath(), "worker");
    } else if (this.activeChild instanceof CompanyProfileViewModel) {
      return combinePath(super.getNavigationPath(), "company");
    } else {
      return original;
    }
  }

  async navigate(subPath: string | undefined, params: any) {
    const [accountType, accountId] = splitUrlSegments(subPath);
    if (!accountType || !accountId) return;

    switch (accountType) {
      case "worker":
        const workerProfile = this.workerProfileFactory(accountId, "read");
        return this.tryActivateChild(workerProfile);
      case "company":
        const companyProfile = this.companyProfileFactory(accountId, "read");
        return this.tryActivateChild(companyProfile);
      default:
        return undefined;
    }
  }

  protected findNavigationChild(navigationName: string | undefined) {
    return undefined;
  }

  get childReplacesNavigationPath() {
    return true;
  }

  isVisible(user: AuthenticationIdentity) {
    return !!user;
  }
}
