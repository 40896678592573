import React from "react";
import EditIcon from "@emanprague/veralinkkit/dist/Icons/Edit";
import { Col, Row, Card, RoundedButton } from "@emanprague/veralinkkit";
import "./requirementCheck.scss";

const RequirementCheck = ({ onEdit, isEditable, requirement, title }: any) => {
  return (
    <Row className="compliance-check-row">
      <Col>
        <Card>
          <Card.Body>
            <Row>
              <Col md={11} xs={10}>
                <h5>{title}</h5>
                {requirement && (
                  <p className="subtitle">
                    <b>&nbsp;{requirement}</b>
                  </p>
                )}
              </Col>
              <Col md={1} xs={2} className="compliance-check-actions">
                {isEditable && (
                  <RoundedButton onClick={onEdit}>
                    <EditIcon fontSize={20} />
                  </RoundedButton>
                )}
              </Col>
            </Row>
          </Card.Body>
        </Card>
      </Col>
    </Row>
  );
};

export default RequirementCheck;
