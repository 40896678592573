import { Button, Col, Form, Row } from "@emanprague/veralinkkit";
import { Input, Select } from "@frui.ts/bootstrap";
import { registerView } from "@frui.ts/views";
import React from "react";
import ModalView from "views/common/modalView";
import "./credentials.scss";
import CredentialsAddViewModel from "viewModels/worker/credentials/credentialsAddViewModel";
import FileUpload from "controls/fileUpload";
import CustomInput from "controls/input";

class AddCredentialModalView extends ModalView<CredentialsAddViewModel> {
  renderBody(vm: CredentialsAddViewModel) {
    const ta = vm.localization.translateAttribute;
    const tg = vm.localization.translateGeneral;
    const willNotifyCompany = !vm.isWorker && vm.variant === "worker";

    return (
      <>
        {willNotifyCompany && (
          <Row className="mb-4 mt-4">
            <Col>
              <strong className="d-block text-center">{tg("worker.credentials.longUpdate")}</strong>
            </Col>
          </Row>
        )}
        <Row>
          <Col xs={12}>
            <Form.Group>
              <Form.Label>{ta("credential", "credentialName")}</Form.Label>
              <Input type="text" disabled={vm.isDisabled} target={vm.credential} property="credentialName" />
            </Form.Group>
          </Col>

          <Col xs={12}>
            <Form.Group>
              <Form.Label>{ta("credential", "externalId")}</Form.Label>
              <Input type="text" target={vm.credential} disabled={vm.isDisabled} property="externalId" />
            </Form.Group>
          </Col>
          <Col xs={12}>
            <Form.Group>
              <Form.Label>{ta("credential", "credentialType")}</Form.Label>
              <Select
                disabled={vm.isDisabled}
                mode="key"
                value={vm.credential?.credentialType}
                target={vm.credential}
                property="credentialType"
                items={vm.credentialTypes}
                keyProperty="name"
                textProperty="name"
              />
            </Form.Group>
          </Col>

          <Col xs={12}>
            <Form.Group>
              <Form.Label>{ta("credential", "nameOnCredential")}</Form.Label>
              <Input disabled={vm.isDisabled} type="text" target={vm.credential} property="holderName" />
            </Form.Group>
          </Col>

          <Col xs={6}>
            <Form.Group>
              <Form.Label>{ta("credential", "validFrom")}</Form.Label>
              <CustomInput disabled={vm.isDisabled} type="date" target={vm.credential} property="validityFrom" />
            </Form.Group>
          </Col>
          <Col xs={6}>
            <Form.Group>
              <Form.Label>{ta("credential", "validTo")}</Form.Label>
              <CustomInput disabled={vm.isDisabled} type="date" target={vm.credential} property="validityTo" />
            </Form.Group>
          </Col>
          <Col xs={12}>
            <Form.Group>
              <Form.Label>{ta("credential", "trainingEntityId")}</Form.Label>
              <Select
                disabled={vm.isDisabled}
                mode="key"
                value={vm.credential?.trainingEntityId}
                target={vm.credential}
                property="trainingEntityId"
                items={vm.trainingEntities}
                textProperty="name"
              />
            </Form.Group>
          </Col>
          <Col xs={12}>
            <FileUpload
              onDrop={(files: File[]) => {
                vm.replaceFile(files[0]);
              }}
              localization={{
                button: vm.translate("upload"),
                or: vm.translate("dragFile"),
                types: vm.translate("fileValidation"),
              }}
              files={vm.model.file && [vm.model]}
              onRemove={vm.removeFile}
              error={vm.errors.photo}
            />
          </Col>
        </Row>
      </>
    );
  }

  renderFooter(vm: CredentialsAddViewModel) {
    return (
      <Button variant="primary" disabled={vm.busyWatcher.isBusy} onClick={vm.uploadCredential}>
        {vm.translate("save")}
      </Button>
    );
  }
}

registerView(AddCredentialModalView as any, CredentialsAddViewModel);
