import { ILocalizationService } from "@emanprague/shared-services";
import { bound } from "@frui.ts/helpers";
import { BusyWatcher, Router, watchBusy } from "@frui.ts/screens";
import AccountType from "entities/accountType";
import AuthenticationIdentity from "entities/authenticationIdentity";
import Availability from "entities/availability";
import WorkerProfileSearchResult from "entities/workerProfileSearchResult";
import { action, computed, extendObservable, reaction, runInAction } from "mobx";
import { WorkerPortalFilter } from "models/workerPortalFilter";
import EnumsRepository from "repositories/enumsRepository";
import WorkerPortalRepository from "repositories/workerPortalRepository";
import EnumsService from "services/enumsService";
import ContinuousListViewModelBase from "viewModels/continuousListViewModelBase";
import { IModule } from "viewModels/types";
import SendMessageViewModel from "viewModels/messages/sendMessageViewModel";
import WorkerPortalSearchResult from "entities/workerPortalSearchResult";

export default class ModulePageViewModel
  extends ContinuousListViewModelBase<WorkerProfileSearchResult, WorkerPortalFilter>
  implements IModule {
  orderIndex = 2;
  navigationName = "workerPortal";
  busyWatcher = new BusyWatcher();

  constructor(
    private workerPortalRepository: WorkerPortalRepository,
    private enumsRepository: EnumsRepository,
    public enums: EnumsService,
    public localization: ILocalizationService,
    private router: Router,
    private sendMessageFactory: ReturnType<typeof SendMessageViewModel.Factory>
  ) {
    super();

    this.name = this.translate("title");
    this.pagingFilter.sortColumn = this.sortOptions[0]?.[0];
    this.pagingFilter.limit = 20;
  }

  get sortOptions() {
    return this.enumsRepository.getWorkerPortalSortOptions();
  }

  get availabilityOptions() {
    return this.enumsRepository.getWorkerAvailabilityOptions();
  }

  @computed get states() {
    return this.enums.states;
  }

  @computed get professions() {
    return this.enums.professions;
  }

  onInitialize() {
    this.loadData();

    reaction(
      () => [
        this.pagingFilter.sortColumn,
        this.pagingFilter.sortDirection,
        this.filter.search,
        this.filter.primaryProfessionName,
        this.filter.availability.length,
        this.filter.credentialNames,
        this.filter["location.city"],
        this.filter["location.state"],
      ],
      this.applyFilterAndLoadDebounced
    );
  }

  @bound
  @watchBusy
  async loadData() {
    const result = await this.workerPortalRepository.searchWorkers(this.appliedFilter, this.pagingFilter);

    if (result.success) {
      this.setData(result.payload);
      // assure optional observable property 'connectionAccepted'
      this.items.forEach(item => {
        if (item.connectionAccepted === undefined) extendObservable(item, { connectionAccepted: undefined });
      });
    }
  }

  @bound
  openWorkerProfile(worker: WorkerPortalSearchResult) {
    const { accountId } = worker;
    this.router.navigatePath(`publicProfile/worker/${accountId}`);
  }

  @bound
  async openSendMessageModal(worker: WorkerPortalSearchResult) {
    const { accountId, firstName, middleName, lastName } = worker;
    const fullName = [firstName, middleName, lastName].filter(w => w !== undefined).join(" ");

    const vm = this.sendMessageFactory(accountId, fullName);
    this.tryActivateChild(vm);
  }

  @bound
  async sendConnection(worker: WorkerPortalSearchResult) {
    const { accountId } = worker;
    const result = await this.workerPortalRepository.sendConnection(accountId);

    if (result.success) {
      runInAction(() => (worker.connectionAccepted = false));
    }
  }

  protected resetFilterValues(filter: WorkerPortalFilter) {
    filter.primaryProfessionName = undefined;
    filter["location.city"] = undefined;
    filter["location.state"] = undefined;
    filter.credentialNames = undefined;
    filter.availability = [];
    filter.search = undefined;
  }

  @action.bound setProfession(professionName?: string) {
    this.filter.primaryProfessionName = professionName;
  }

  @action.bound toggleAvailability(availability: Availability) {
    const index = this.filter.availability?.indexOf(availability);
    if (index > -1) {
      this.filter.availability?.splice(index, 1);
    } else {
      this.filter.availability?.push(availability);
    }
  }

  isVisible(user: AuthenticationIdentity) {
    return user.accountType === AccountType.Contractor || user.accountType === AccountType.Operator;
  }

  @bound translate(key: string) {
    return this.localization.translateGeneral(`workerPortal.modulePage.${key}`);
  }
}
