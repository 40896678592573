import { ILocalizationService } from "@emanprague/shared-services";
import { bound } from "@frui.ts/helpers";
import { BusyWatcher, ScreenBase, watchBusy } from "@frui.ts/screens";
import { interfaces } from "inversify";
import { action, computed, observable, reaction, runInAction } from "mobx";
import EmployeeRepository from "repositories/employeeRepository";
import ProjectRepository from "repositories/projectRepository";
import CompanyEmployee from "entities/companyEmployee";
import ContinuousListViewModelBase from "viewModels/continuousListViewModelBase";
import EmployeeFilter from "models/employeeFilter";
import CompanyProjectTeam from "entities/companyProjectTeam";
import { classToClass } from "class-transformer";
import CompanyProjectTeamWorkerAssigned from "entities/companyProjectTeamWorkerAssigned";

export default class AddWorkersViewModel extends ContinuousListViewModelBase<CompanyEmployee, EmployeeFilter, ScreenBase> {
  navigationName = "addWorkers";
  busyWatcher = new BusyWatcher();

  @observable team: CompanyProjectTeam;
  @observable selectedWorkers: CompanyEmployee[] = [];

  constructor(
    private companyId: string,
    private projectId: string,
    team: CompanyProjectTeam,
    public localization: ILocalizationService,
    private repository: EmployeeRepository,
    private projectRepository: ProjectRepository
  ) {
    super();

    this.name = this.translate("title");
    this.pagingFilter.limit = 20;

    this.team = classToClass(team);
  }

  onInitialize() {
    this.loadData();
    reaction(() => [this.filter.search], this.applyFilterAndLoadDebounced);
  }

  createFilter(): EmployeeFilter {
    return new EmployeeFilter();
  }

  get canSave() {
    return this.selectedWorkers.length > 0;
  }

  @bound
  @watchBusy
  async loadData() {
    const id = this.team.assignedContractorId ? this.team.assignedContractorId : this.companyId;
    const result = await this.repository.searchEmployees(id, this.appliedFilter, this.pagingFilter);
    if (result.success) {
      this.setData(result.payload);
    }
  }

  @bound
  @watchBusy
  async addWorkers() {
    const ids: string[] = [];
    this.team.workersAssigned?.forEach(w => {
      ids.push(w.id);
    });

    this.selectedWorkers.forEach(w => {
      ids.push(w.accountId!);
    });

    runInAction(() => {
      this.team.workersAssigned = ids.map(id => {
        const w = new CompanyProjectTeamWorkerAssigned();
        w.id = id;
        return w;
      });
    });

    const result = await this.projectRepository.updateProjectTeamWorkers(
      this.companyId,
      this.projectId,
      this.team.id!,
      this.team
    );
    if (result) {
      this.requestClose();
    }
  }

  @computed
  get canAdd() {
    return (this.team.workersAssigned || []).length + this.selectedWorkers.length < (this.team.nWorkersWanted || 0);
  }

  @action.bound
  selectWorker(worker: CompanyEmployee) {
    this.selectedWorkers.push(worker);
  }

  @action.bound
  unselectWorker(worker: CompanyEmployee) {
    const workerIndex = this.selectedWorkers.findIndex(sw => sw.accountId === worker.accountId);
    this.selectedWorkers.splice(workerIndex, 1);
  }

  @computed
  get availableWorkers() {
    return this.items?.filter(w => {
      const fw = this.selectedWorkers.find(sw => sw.accountId === w.accountId);
      const aw = (this.team.workersAssigned || []).find(sw => sw.id === w.accountId);
      if (!!fw || !!aw) {
        return false;
      } else {
        return true;
      }
    });
  }

  @bound translate(key: string) {
    return this.localization.translateGeneral(`projects.teams.addWorkers.${key}`);
  }

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  protected resetFilterValues(filter: EmployeeFilter) {}

  static Factory({ container }: interfaces.Context) {
    return (companyId: string, projectId: string, team: CompanyProjectTeam) =>
      new AddWorkersViewModel(
        companyId,
        projectId,
        team,
        container.get("ILocalizationService"),
        container.get(EmployeeRepository),
        container.get(ProjectRepository)
      );
  }
}
