import { Button, Col, Form, LoaderOverlay, Row } from "@emanprague/veralinkkit";
import { Input } from "@frui.ts/bootstrap";
import { registerView } from "@frui.ts/views";
import React from "react";
import EducationDetailViewModel from "viewModels/worker/modals/educationDetailViewModel";
import ModalView from "views/common/modalView";

class EducationDetailModalView extends ModalView<EducationDetailViewModel> {
  renderBody(vm: EducationDetailViewModel) {
    const ta = vm.localization.translateAttribute;

    return (
      <LoaderOverlay loading={vm.busyWatcher.isBusy} variant="light">
        <Row>
          <Col xs={12}>
            <Form.Group>
              <Form.Label>{ta("education", "institution")}</Form.Label>
              <Input type="text" target={vm.item} property="institution" />
            </Form.Group>
          </Col>
          <Col xs={12}>
            <Form.Group>
              <Form.Label>
                {ta("education", "fieldOfStudy")}

                {<span className="optional-text"> {ta("skills", "optional")} </span>}
              </Form.Label>
              <Input type="text" target={vm.item} property="fieldOfStudy" />
            </Form.Group>
          </Col>
          <Col xs={12}>
            <Form.Group>
              <Form.Label>
                {ta("education", "degree")}

                {<span className="optional-text"> {ta("skills", "optional")} </span>}
              </Form.Label>
              <Input type="text" target={vm.item} property="degree" />
            </Form.Group>
          </Col>
          <Col xs={6}>
            <Form.Group>
              <Form.Label>{ta("education", "endYear")}</Form.Label>
              <Input type="number" target={vm.item} property="endYear" />
            </Form.Group>
          </Col>
        </Row>
      </LoaderOverlay>
    );
  }

  renderFooter(vm: EducationDetailViewModel) {
    return (
      <Button variant="primary" disabled={vm.busyWatcher.isBusy} onClick={vm.save}>
        {vm.translate("save")}
      </Button>
    );
  }
}

registerView(EducationDetailModalView as any, EducationDetailViewModel);
