import { observable } from "mobx";
import { Transform } from "class-transformer";
import AccountType from "./accountType";
import { formatISO } from "date-fns";
import parseISO from "date-fns/parseISO";

// This entity has been generated, do not change its content, your changes might get lost. You CAN modify the rest of the file.
export default class Message {
  readonly id?: string;

  /**
   * Recipient account ID
   */
  @observable
  recipientAccountId!: string;

  /**
   * Worker's full name or company name
   */
  readonly recipientName?: string;

  /**
   * Worker's or company photo URL
   */
  readonly recipientPhotoUrl?: string;

  @observable
  recipientAccountType?: AccountType;

  /**
   * Sender account ID
   */
  readonly senderAccountId?: string;

  /**
   * Worker's full name or company name
   */
  readonly senderAccountName?: string;

  /**
   * Worker's or company photo URL
   */
  readonly senderPhotoUrl?: string;

  @observable
  senderAccountType?: AccountType;

  /**
   * Indicates whether message has been read
   */
  readonly read?: boolean;

  /**
   * Content of message
   */
  @observable
  content!: string;

  @Transform(value => (value ? (typeof value === "string" ? parseISO(value) : value) : undefined), { toClassOnly: true })
  @Transform(value => (value ? formatISO(value) : undefined), { toPlainOnly: true })
  readonly dateReceived?: Date;

  static ValidationRules = {
    recipientAccountId: { required: true },
    content: { required: true },
    dateReceived: { format: "date-time" },
  };
}
