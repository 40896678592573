import { PagedQueryResult } from "@frui.ts/data";
import { FilteredListViewModel } from "@frui.ts/datascreens";
import { ScreenBase } from "@frui.ts/screens";
import debounce from "lodash/debounce";
import { action } from "mobx";

export default abstract class ContinuousListViewModelBase<
  TEntity,
  TFilter extends {} = {},
  TDetail extends ScreenBase = ScreenBase
> extends FilteredListViewModel<TEntity, TFilter, TDetail> {
  applyFilterAndLoadDebounced = debounce(this.applyFilterAndLoad, 500);

  protected alwaysDisplayLoadMore = false;

  get canLoadData() {
    return this.currentPaging && (this.items.length < this.currentPaging.totalItems || this.alwaysDisplayLoadMore);
  }

  @action
  protected setData([items, paging]: PagedQueryResult<TEntity>) {
    if (!this.items?.length || paging.offset === 0) {
      this.items = items;
    } else {
      this.items = this.items.slice(0, paging.offset).concat(items.slice()); // `items.slice` is required because `items` might be mobx observable array
    }

    this.currentPaging = {
      offset: 0,
      limit: this.items.length,
      totalItems: paging.totalItems,
    };

    // prepare for next load
    this.pagingFilter.offset = this.items.length;
  }

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  protected resetFilterValues(filter: TFilter) {}
}
