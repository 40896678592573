import React from "react";
import { Button } from "@emanprague/veralinkkit";
import InsuranceDocument from "entities/insuranceDocument";
import { useState } from "react";

type Props = {
  visible: boolean;
  tg: (a: string) => string;
  certificate: InsuranceDocument;
  submitReview: (id: string, approved: boolean) => void;
  children: React.ReactNode;
};

const InsuranceApproveReject: React.FC<Props> = ({ children, certificate, visible, tg, submitReview }) => {
  const [opened, setState] = useState(false);

  const openClose = () => {
    setState(!opened);
  };

  return (
    <tr className="action__row">
      <td colSpan={4}>
        {visible && (
          <>
            {opened && children}
            <br />
            {!opened && (
              <div>
                <Button onClick={() => submitReview(certificate.id, true)}>{tg("approve")}</Button>
                <Button onClick={openClose} variant="outline-primary">
                  {tg("reject")}
                </Button>
              </div>
            )}
            {opened && (
              <div>
                <Button onClick={() => submitReview(certificate.id, false)}>{tg("reject")}</Button>
                <Button onClick={openClose} variant="outline-primary">
                  {tg("cancel")}
                </Button>
              </div>
            )}
          </>
        )}
      </td>
    </tr>
  );
};

export default InsuranceApproveReject;
