import { ILocalizationService } from "@emanprague/shared-services";
import { bound } from "@frui.ts/helpers";
import { BusyWatcher, ScreenBase, watchBusy } from "@frui.ts/screens";
import { attachAutomaticValidator, hasVisibleErrors, validateAll } from "@frui.ts/validation";
import { classToClass } from "class-transformer";
import CompanyLocation from "entities/companyLocation";
import CompanyProfile from "entities/companyProfile";
import { interfaces } from "inversify";
import { action, computed, observable } from "mobx";
import CompanyProfileRepository from "repositories/companyProfileRepository";
import EnumsService from "services/enumsService";

export default class CompanyLocationsViewModel extends ScreenBase {
  navigationName = "locations";
  busyWatcher = new BusyWatcher();

  @observable.shallow model: CompanyLocation[] = [];
  @observable.shallow toAdd: CompanyLocation[] = [];
  @observable.shallow toDelete: CompanyLocation[] = [];

  constructor(
    private companyProfile: CompanyProfile,
    originalLocations: CompanyLocation[],
    private onSuccess: () => void,
    private companyProfileRepository: CompanyProfileRepository,
    public localization: ILocalizationService,
    private enums: EnumsService
  ) {
    super();
    this.name = this.translate("title");

    this.model = classToClass(originalLocations);
    this.model.forEach(location => attachAutomaticValidator(location, CompanyLocation.ValidationRules));

    if (!this.model.length) {
      this.addEmptyLocation();
    }
  }

  get states() {
    return this.enums.states;
  }

  @computed get locations() {
    return [...this.model, ...this.toAdd];
  }

  @bound
  @watchBusy
  async save() {
    const toEdit = validateAll(this.model);
    const toDelete = validateAll(this.toDelete);
    const toAdd = validateAll(this.toAdd);

    if (toEdit && toDelete && toAdd) {
      await Promise.all([
        ...this.toAdd.map(l => this.companyProfileRepository.addLocation(this.companyProfile.id, l)),
        ...this.model.map(l => this.companyProfileRepository.updateLocation(this.companyProfile.id, l.id!, l)),
        ...this.toDelete.map(l => this.companyProfileRepository.deleteLocation(this.companyProfile.id, l.id!) as any),
      ]);

      this.onSuccess();
      return this.requestClose();
    }
  }

  @computed get canSave() {
    const toEdit = this.model.filter(c => hasVisibleErrors(c));
    const toAdd = this.toAdd.filter(c => hasVisibleErrors(c));
    return ![...toEdit, ...toAdd].length;
  }

  @action.bound
  addEmptyLocation() {
    const newLocation = new CompanyLocation();
    attachAutomaticValidator(newLocation, CompanyLocation.ValidationRules);
    this.toAdd.push(newLocation);
  }

  @action.bound
  markToDelete(location: CompanyLocation) {
    const indexModel = this.model.indexOf(location);
    if (indexModel > -1) {
      this.model.splice(indexModel, 1);
      this.toDelete.push(location);
    }

    const indexToAdd = this.toAdd.indexOf(location);
    if (indexToAdd > -1) {
      this.toAdd.splice(indexToAdd, 1);
    }
  }

  @bound translate(key: string) {
    return this.localization.translateGeneral(`companyInfo.companyLocationsModal.${key}`);
  }

  static Factory({ container }: interfaces.Context) {
    return (companyProfile: CompanyProfile, originalLocations: CompanyLocation[], onSuccess: () => void) =>
      new CompanyLocationsViewModel(
        companyProfile,
        originalLocations,
        onSuccess,
        container.get(CompanyProfileRepository),
        container.get("ILocalizationService"),
        container.get(EnumsService)
      );
  }
}
