import { Button } from "@emanprague/veralinkkit";
import { registerView } from "@frui.ts/views";
import React from "react";
import ConfirmDialogViewModel from "viewModels/confirmDialogViewModel";
import ModalView from "./common/modalView";

class ConfirmDialogView extends ModalView<ConfirmDialogViewModel> {
  protected get showCloseButton() {
    return !!this.props.vm.settings.closeButton;
  }

  renderBody(vm: ConfirmDialogViewModel) {
    return <p className="text-center">{vm.message}</p>;
  }

  renderFooter(vm: ConfirmDialogViewModel) {
    return (
      <>
        <Button onClick={vm.cancel} variant={vm.cancelButtonParams.variant}>
          {vm.cancelButtonParams.text}
        </Button>
        <Button onClick={vm.confirm} variant={vm.confirmButtonParams.variant}>
          {vm.confirmButtonParams.text}
        </Button>
      </>
    );
  }
}

registerView(ConfirmDialogView, ConfirmDialogViewModel);
