import { ILocalizationService } from "@emanprague/shared-services";
import { ScreenBase } from "@frui.ts/screens";
import Notification from "entities/notification";
import NotificationType from "entities/notificationType";
import { interfaces } from "inversify";
import ConnectionRequestViewModel from "./connectionRequestViewModel";
import ProjectViewModel from "./projectViewModel";
import CredentialExpireViewModel from "./credentialExpireViewModel";
import WorkerJoinedViewModel from "./workerJoinedViewModel";

export default class NotificationItemViewModel extends ScreenBase {
  constructor(
    public notification: Notification,
    public localization: ILocalizationService,
    private connectionRequestFactory: ReturnType<typeof ConnectionRequestViewModel.Factory>,
    private projectFactory: ReturnType<typeof ProjectViewModel.Factory>,
    private workerJoinedFactory: ReturnType<typeof WorkerJoinedViewModel.Factory>,
    private credentialExpireFactory: ReturnType<typeof CredentialExpireViewModel.Factory>
  ) {
    super();
  }

  get notificationItemVM() {
    switch (this.notification.type) {
      case NotificationType.ConnectionRequest:
        return this.connectionRequestFactory(this.notification);
      case NotificationType.Project:
        return this.projectFactory(this.notification);
      case NotificationType.WorkerJoined:
        return this.workerJoinedFactory(this.notification);
      case NotificationType.CredentialExpire:
        return this.credentialExpireFactory(this.notification);
      default:
        return undefined;
    }
  }

  static Factory({ container }: interfaces.Context) {
    return (notification: Notification) =>
      new NotificationItemViewModel(
        notification,
        container.get("ILocalizationService"),
        container.get(ConnectionRequestViewModel.Factory),
        container.get(ProjectViewModel.Factory),
        container.get(WorkerJoinedViewModel.Factory),
        container.get(CredentialExpireViewModel.Factory)
      );
  }
}
