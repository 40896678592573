import { Card } from "@emanprague/veralinkkit";
import { registerView, View, ViewComponent } from "@frui.ts/views";
import { observer } from "mobx-react-lite";
import React from "react";
import ModulePageViewModel from "viewModels/messages/modulePageViewModel";
import Pager from "controls/pager";
import MessageItem from "./components/messageItem";

import "./index.scss";
import LoadingContainer from "views/common/loadingContainer";

const modulePageView: ViewComponent<ModulePageViewModel> = observer(({ vm }) => (
  <>
    <div className="messages">
      <div className="messages__container">
        <h4>{vm.name}</h4>
        <LoadingContainer loading={vm.busyWatcher.isBusy}>
          {vm.items?.length > 0 ? (
            <>
              <Card>
                <ul className="messages__list">
                  {vm.items?.map((message, index) => {
                    return (
                      <MessageItem
                        key={`message-${index}`}
                        message={message}
                        localization={vm.localization}
                        onUserClick={vm.requestClose}
                        openDetail={vm.openDetailMessage}
                      />
                    );
                  })}
                </ul>
              </Card>
              {vm.items?.length > 0 && (
                <div className="paginationBar">
                  <Pager paging={vm.currentPaging} filter={vm.pagingFilter} onPageChanged={vm.changePage} />
                </div>
              )}
            </>
          ) : (
            !vm.busyWatcher.isBusy && (
              <Card>
                <Card.Body>
                  <span>{vm.translate("noMessages")}</span>
                </Card.Body>
              </Card>
            )
          )}
        </LoadingContainer>
      </div>
    </div>
    <View vm={vm.activeChild} />
  </>
));

registerView(modulePageView, ModulePageViewModel);
