import { ILocalizationService } from "@emanprague/shared-services";
import { bound } from "@frui.ts/helpers";
import { BusyWatcher, ScreenBase, watchBusy } from "@frui.ts/screens";
import { observable } from "mobx";
import { interfaces } from "inversify";
import { attachAutomaticValidator } from "@frui.ts/validation";

export default class SafetyProgramsEditViewModel extends ScreenBase {
  navigationName = "safetyProgramsEdit";
  busyWatcher = new BusyWatcher();

  @observable model: any = { safetyPrograms: [] };

  constructor(private companyId: string, safetyProgram: any, public localization: ILocalizationService) {
    super();

    this.name = this.translate("title");
    this.model = safetyProgram;
  }

  @bound translate(key: string) {
    return this.localization.translateGeneral(`compliance.safetyProgramsEdit.${key}`);
  }

  @bound
  @watchBusy
  async save() {}

  @bound removeService(idx: number) {
    this.model.splice(idx, 1);
  }

  @bound addService() {
    const newService = { name: "" };
    attachAutomaticValidator(newService, {});
    this.model.push(newService);
  }

  @bound cancel() {
    this.requestClose();
  }

  static Factory({ container }: interfaces.Context) {
    return (companyId: string, safetyProgram: any) =>
      new SafetyProgramsEditViewModel(
        companyId,
        safetyProgram,
        container.get("ILocalizationService")
        // container.get(CompanyCredentialsRepository)
      );
  }
}
