import { ILocalizationService } from "@emanprague/shared-services";
import { bound } from "@frui.ts/helpers";
import { BusyWatcher, ScreenBase, watchBusy } from "@frui.ts/screens";
import { attachAutomaticValidator, hasVisibleErrors, validate } from "@frui.ts/validation";
import CompanyLocation from "entities/companyLocation";
import CompanyProfile from "entities/companyProfile";
import { action, computed, observable } from "mobx";
import CompanyProfileRepository from "repositories/companyProfileRepository";
import EnumsService from "services/enumsService";
import UserContext from "services/userContext";
import { EntityValidationRules } from "services/validation/entityValidationRules";

class Model {
  @observable services: { name: string }[] = [];
  @observable about?: string;
  @observable location: CompanyLocation;
}

const ModelValidationRules: EntityValidationRules<Model> = {
  services: { required: true, minLength: 1 },
  location: { required: true },
};

const HEADQUARTERS_LOCATION_NAME = "Headquarters";

export default class CompleteCompanyProfileViewModel extends ScreenBase {
  navigationName = "companyInfo";
  busyWatcher = new BusyWatcher();

  @observable model: Model;

  // handles empty services
  @computed get servicesValidationError() {
    return !(this.model as any).__validation.isErrorsVisible || this.model.services.filter(x => x.name).length
      ? undefined
      : this.translate("missingServices");
  }

  @observable errorMessage?: string;

  constructor(
    private userContext: UserContext,
    public localization: ILocalizationService,
    private repository: CompanyProfileRepository,
    private enums: EnumsService
  ) {
    super();

    this.name = this.translate("title");
  }

  get states() {
    return this.enums.states;
  }

  onInitialize() {
    return this.initializeModel();
  }

  @action
  async initializeModel() {
    const profile = this.userContext.profile as CompanyProfile;
    this.model = new Model();
    this.model.about = profile.bio;

    if (profile.services?.length) {
      this.model.services.push(
        ...profile.services.map(x => ({
          name: x,
        }))
      );
    } else {
      this.addService();
    }

    const currentLocations = await this.repository.getLocations(profile.id);
    let headquartersLocation: CompanyLocation | undefined = currentLocations.success
      ? currentLocations.payload.find(x => x.name === HEADQUARTERS_LOCATION_NAME)
      : undefined;
    if (!headquartersLocation) {
      headquartersLocation = new CompanyLocation();
      headquartersLocation.name = HEADQUARTERS_LOCATION_NAME;
    }
    this.model.location = headquartersLocation;

    attachAutomaticValidator(this.model, ModelValidationRules);
    attachAutomaticValidator(this.model.location, CompanyLocation.ValidationRules);
  }

  @action.bound
  addService() {
    this.model.services.push({ name: "" });
  }

  @action.bound
  removeService(index: number) {
    this.model.services.splice(index, 1);
  }

  get canConfirm() {
    return !hasVisibleErrors(this.model);
  }

  @bound
  @watchBusy
  async confirm() {
    this.setErrorMessage();
    if (validate(this.model) && !this.servicesValidationError) {
      const profile = this.userContext.profile as CompanyProfile;
      const services = this.model.services.filter(x => x.name).map(x => x.name);
      const infoResult = await this.repository.updateCompanyProfile(profile.id, { services, bio: this.model.about });

      if (!infoResult.success) {
        this.setErrorMessage(infoResult.payload.errorDescription);
        return;
      }

      const location = this.model.location;
      if (validate(location)) {
        const locationResult = location.id
          ? await this.repository.updateLocation(profile.id, location.id, location)
          : await this.repository.addLocation(profile.id, location);
        if (!locationResult.success) {
          this.setErrorMessage(locationResult.payload.errorDescription);
          return;
        }
      }

      this.requestClose();
    }
  }

  @action.bound
  setErrorMessage(message?: string) {
    this.errorMessage = message;
  }

  @bound translate(key: string) {
    return this.localization.translateGeneral(`signup.confirmCompany.${key}`);
  }
}
