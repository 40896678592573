import { observable } from "mobx";

// This entity has been generated, do not change its content, your changes might get lost. You CAN modify the rest of the file.
export default class CompanyCredentialsStats {
  /**
   * Number of employees that have credentials that expire in 30 days
   */
  @observable
  days30!: number;

  /**
   * Number of employees that have credentials that expire in 60 days
   */
  @observable
  days60!: number;

  /**
   * Number of employees that have credentials that expire in 90 days
   */
  @observable
  days90!: number;

  /**
   * Number of employees that have expired credentials
   */
  @observable
  expired!: number;

  /**
   * Total employees
   */
  @observable
  totalEmployees!: number;

  static ValidationRules = {
    days30: { required: true, number: true },
    days60: { required: true, number: true },
    days90: { required: true, number: true },
    expired: { required: true, number: true },
    totalEmployees: { required: true, number: true },
  };
}
