import React from "react";
import { registerView, View, ViewComponent } from "@frui.ts/views";
import { observer } from "mobx-react-lite";
import DocumentsListViewModel from "viewModels/company/projects/documents/documentsListViewModel";

import LoadingContainer from "views/common/loadingContainer";
import { Button, Card } from "@emanprague/veralinkkit";
import { Plus } from "@emanprague/veralinkkit/dist/Icons";
import { PROJECT_DETAIL_SECTIONS } from "../projectDetailView";

const documentsListView: ViewComponent<DocumentsListViewModel> = observer(({ vm }) => {
  return (
    <section id={PROJECT_DETAIL_SECTIONS.PROJECT_DOCUMENTS} className="cardSection">
      <div className="cardSection__header">
        <h4 className="text-uppercase">{vm.translate("projectDocuments")}</h4>
        {vm.projectOwner && (
          <Button variant="primary" onClick={vm.openUploadDocument}>
            <Plus fontSize={24} />
            {vm.translate("addDocument")}
          </Button>
        )}
      </div>
      <LoadingContainer loading={vm.busyWatcher.isBusy}>
        {vm.items?.length > 0 && (
          <Card className="projectDocuments">
            <Card.Body>
              {vm.items?.map(doc => (
                <div key={doc.id} className="projectDocuments__item">
                  <b>
                    {doc.name ? doc.name : doc.fileName}
                    {doc.name ? <span>{doc.fileName}</span> : <span>&nbsp;</span>}
                  </b>
                  <div>
                    <span onClick={() => vm.downloadProjectDocument(doc.id!)}>{vm.translate("download")}</span>
                    {vm.projectOwner && (
                      <span onClick={() => vm.deleteProjectDocument(doc.id ?? "")}>{vm.translate("delete")}</span>
                    )}
                  </div>
                </div>
              ))}
            </Card.Body>
          </Card>
        )}
      </LoadingContainer>
      <View vm={vm.activeChild} />
    </section>
  );
});

registerView(documentsListView, DocumentsListViewModel);
