import { ILocalizationService, IEventBus } from "@emanprague/shared-services";
import { bound } from "@frui.ts/helpers";
import { BusyWatcher, ScreenBase, watchBusy } from "@frui.ts/screens";
import { interfaces } from "inversify";
import { observable, action, autorun, runInAction, computed } from "mobx";
import InsuranceDocument from "entities/insuranceDocument";
import ComplianceRepository from "repositories/complianceRepository";
import { ComplianceDefinitionEvents } from "services/events";
import { FileBox } from "controls/fileUpload";

export default class InsuranceEditViewModel extends ScreenBase {
  navigationName = "insuranceEdit";
  busyWatcher = new BusyWatcher();

  @observable insuranceDocuments: Map<string, FileBox<File | InsuranceDocument>> = new Map();

  @computed get fileList() {
    return Array.from(this.insuranceDocuments.values());
  }

  constructor(
    private companyId: string,
    insuranceDocuments: InsuranceDocument[] = [],
    public localization: ILocalizationService,
    private repository: ComplianceRepository,
    private eventBus: IEventBus
  ) {
    super();

    this.name = this.translate("title");
    insuranceDocuments.forEach((document: InsuranceDocument) => {
      const file = new FileBox<InsuranceDocument>();
      file.file = document;
      this.insuranceDocuments.set(document.id, file);
    });

    // setup autorun to upload the file
    autorun(() => {
      const newFiles = this.fileList.filter(({ url }: any) => !url);
      if (newFiles.length > 0) {
        Promise.all(newFiles.map(this.upload));
      }
    });
  }

  @bound
  @watchBusy
  async upload(file: FileBox<File | InsuranceDocument>): Promise<void> {
    if (file.file instanceof InsuranceDocument) {
      return;
    }
    const oldFile = this.insuranceDocuments.get(file.file!.name);
    runInAction(() => {
      oldFile!.error = undefined;
    });
    const result = await this.repository.saveInsuranceDocuments(this.companyId, file);

    if (!result.success) {
      runInAction(() => {
        oldFile!.error = result.payload?.errorDescription;
      });
    } else {
      const body = await result.payload.text().then(data => JSON.parse(data));
      const doc = new InsuranceDocument();
      doc.id = body.id;
      doc.fileName = body.name;
      (doc as any).url = body.url;
      (doc as any).name = body.name;

      runInAction(() => {
        oldFile!.file = doc;
        this.insuranceDocuments.delete(body.name);
        this.insuranceDocuments.set(doc.id, oldFile!);
      });
      this.eventBus.publish(ComplianceDefinitionEvents.insuranceUploaded(doc));
    }
  }

  @bound translate(key: string) {
    return this.localization.translateGeneral(`compliance.insuranceEdit.${key}`);
  }

  @bound @action replaceFile(files: File[]) {
    files.forEach(document => {
      const fileName = document.name;
      // const id = Math.random() * 10000;
      const model = new FileBox<File>();
      model.file = document;
      this.insuranceDocuments.set(fileName, model);
    });
  }

  @bound
  async removeFile(file: FileBox<InsuranceDocument | File>) {
    if (file.file instanceof InsuranceDocument && file.file.id) {
      await this.repository.deleteInsuranceDocuments(this.companyId, file.file.id);
      runInAction(() => {
        this.insuranceDocuments.delete((file.file as any).id);
      });
      this.eventBus.publish(ComplianceDefinitionEvents.insuranceRemoved(file.file));
    } else {
      runInAction(() => {
        this.insuranceDocuments.delete((file.file as any).name);
      });
    }
  }

  @bound
  async save() {
    this.requestClose();
  }

  static Factory({ container }: interfaces.Context) {
    return (companyId: string, insuranceDocuments: InsuranceDocument[]) =>
      new InsuranceEditViewModel(
        companyId,
        insuranceDocuments,
        container.get("ILocalizationService"),
        container.get(ComplianceRepository),
        container.get("IEventBus")
      );
  }
}
