import { Col, Row } from "@emanprague/veralinkkit";
import { registerView, ViewComponent } from "@frui.ts/views";
import { observer } from "mobx-react-lite";
import React from "react";
import SubModuleViewModel from "viewModels/company/companyDashboard/subModuleViewModel";

import ConnectionsSection from "./sections/connections";
import WorkforceSection from "./sections/workforce";
import ProjectsSection from "./sections/projects";
import CredentialsSection from "./sections/credentials";

import "./index.scss";

const subModuleView: ViewComponent<SubModuleViewModel> = observer(({ vm }) => {
  return (
    <>
      <div className="company__header">
        <h4>{vm.companyProfileName}</h4>
        <span>{`${vm.translate("accountId")} ${vm.companyProfileId}`}</span>
      </div>
      <Row>
        <Col lg={6} md={12} className="mb-4">
          <ConnectionsSection vm={vm} />
        </Col>
        <Col lg={6} md={12} className="mb-4">
          <WorkforceSection vm={vm} />
        </Col>
        <Col lg={6} md={12} className="mb-4">
          <ProjectsSection vm={vm} />
        </Col>
        <Col lg={6} md={12} className="mb-4">
          <CredentialsSection vm={vm} />
        </Col>
      </Row>
    </>
  );
});

registerView(subModuleView, SubModuleViewModel);
