import React from "react";
import { Navbar, Nav, Container } from "@emanprague/veralinkkit";
import Logo from "@emanprague/veralinkkit/dist/assets/icons/Logo/Positive.svg";
import { Router } from "@frui.ts/screens";
import { registerView, View, ViewComponent } from "@frui.ts/views";
import { observer, Observer } from "mobx-react-lite";
import RootViewModel from "../viewModels/rootViewModel";
import { Row } from "@emanprague/veralinkkit";

const HeaderView: ViewComponent<RootViewModel> = observer(({ vm }) => {
  const getUrl = Router.getChildUrlFactory(vm);
  return (
    <Navbar className="shadow" as="header" fixed="top" expand="lg" collapseOnSelect>
      <Navbar.Brand href="#/">
        <img src={Logo} alt="logo" />
      </Navbar.Brand>

      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav>
          {vm.visibleModules.map(m => (
            <Nav.Link key={m.navigationName} href={getUrl(m.navigationName)} active={vm.activeChild === m}>
              {m.name}
            </Nav.Link>
          ))}
        </Nav>
        <Row className="navbar-status">
          <View vm={vm.notifyMessagesVM} />
          <View vm={vm.notificationsVM} context="statusbar" />
          <View vm={vm.statusVM} useLifecycle />
        </Row>
      </Navbar.Collapse>
    </Navbar>
  );
});

const rootView: ViewComponent<RootViewModel> = observer(({ vm }) => {
  return (
    <>
      <HeaderView vm={vm} />
      <Container className="root-container">
        <Observer>
          {() => (
            <div className="toast-container">
              {vm.toasts.map(item => (
                <View key={item.key} vm={item} useLifecycle />
              ))}
            </div>
          )}
        </Observer>
        <Observer>{() => <View vm={vm.appendedViewModels.length ? vm.appendedViewModels[0] : vm.activeChild} />}</Observer>
        <Observer>
          {() => (
            <>
              {vm.dialogService.dialogs.map((dialog, i) => (
                <View key={i} vm={dialog} useLifecycle />
              ))}
            </>
          )}
        </Observer>
      </Container>
    </>
  );
});

registerView(rootView, RootViewModel);
