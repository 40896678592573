import { configure, isObservableArray } from "mobx";
import Raven from "raven-js";
import "reflect-metadata";
import runApp from "./app";
import "./assets/themes/default.theme.scss";
import createContainer from "./di.config";
import * as serviceWorker from "./serviceWorker";
import "./views";
import { API_URI } from "config";

// Start raven
// filter error with 401 status
if (process.env.SENTRY_DSN && process.env.CI_COMMIT_SHA) {
  Raven.config(process.env.SENTRY_DSN, {
    release: process.env.CI_COMMIT_SHA,
    ignoreErrors: [/Request failed with status code 401/],
  }).install();
}

const container = createContainer(API_URI);

configure({
  enforceActions: "observed",
});

// monkey patch the Array.isArray to support observable arrays (until mobx 6 is out or class-transformer fixed)
const originalIsArray = Array.isArray;
Array.isArray = function (arg: any): arg is any[] {
  return originalIsArray(arg) || isObservableArray(arg);
};

if (process.env.REACT_APP_MOCK_API === "true") {
  import("./mocks/apiMock").then(x => x.initialize()).then(() => runApp(container));
} else {
  runApp(container);
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
