import React from "react";
import { registerView, View, ViewComponent } from "@frui.ts/views";
import { observer } from "mobx-react-lite";
import PublicProfileViewModel from "viewModels/public/profileViewModel";
import "./publicProfile.scss";

const publicProfileView: ViewComponent<PublicProfileViewModel> = observer(({ vm }) => {
  return (
    <div className="public-profile">
      <View vm={vm.activeChild} />
    </div>
  );
});

registerView(publicProfileView, PublicProfileViewModel);
