import { observable } from "mobx";

// This entity has been generated, do not change its content, your changes might get lost. You CAN modify the rest of the file.
export default class ContractorConnectionPointsExperienceModifier {
  /**
   * True if both the number and file are filled by contractor
   */
  @observable
  filled!: boolean;

  @observable
  givenPoints!: number;

  @observable
  totalPoints!: number;

  static ValidationRules = {
    filled: { required: true },
    givenPoints: { required: true, number: true },
    totalPoints: { required: true, number: true },
  };
}
