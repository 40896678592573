import { observable } from "mobx";
import { Type } from "class-transformer";
import ContractorConnection from "./contractorConnection";

// This entity has been generated, do not change its content, your changes might get lost. You CAN modify the rest of the file.
export default class ContractorConnections {
  /**
   * Total number of connections
   */
  @observable
  total: number;

  @observable
  @Type(() => ContractorConnection)
  connections: ContractorConnection[];

  static ValidationRules = {
    total: { number: true },
  };
}
