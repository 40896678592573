import { Card, RoundedButton, Badge } from "@emanprague/veralinkkit";
import { ViewComponent } from "@frui.ts/views";
import React from "react";
import EditIcon from "@emanprague/veralinkkit/dist/Icons/Edit";
import { observer } from "mobx-react-lite";
import CompanyProfileViewModel from "viewModels/company/companyInfo/companyProfileViewModel";

const CompanyWorkTypesSection: ViewComponent<CompanyProfileViewModel> = observer(({ vm }) => {
  const { translateGeneral } = vm.localization;
  const tg = (code: string) => translateGeneral(`companyInfo.subModule.${code}`);
  if (!vm.isContractor) {
    return null;
  }

  return (
    <Card className="company-info__card">
      <Card.Header>
        <h4>{tg("workTypes")}</h4>
        {vm.isEdit && (
          <RoundedButton onClick={vm.openWorkTypesModal}>
            <EditIcon fontSize={20} />
          </RoundedButton>
        )}
      </Card.Header>
      <Card.Body>
        {vm.workTypesModel?.map(({ workType }) => (
          <Badge key={workType!.id} variant="light">
            {workType!.name}
          </Badge>
        ))}

        <p className="company-info__policies">
          <b>{vm.translate("policiesUploaded", { count: vm.safetyPoliciesCount ?? 0 })}</b>
        </p>
      </Card.Body>
    </Card>
  );
});

export default CompanyWorkTypesSection;
