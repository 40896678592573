import { toJS } from "mobx";
import { omit, sortBy, groupBy } from "lodash";
import dateFormat from "date-fns/format";
import TrainingEntity from "entities/trainingEntity";
import Credential from "entities/credential";
import CompanyEmployeeByCredentialCredentialsItem from "entities/companyEmployeeByCredentialCredentialsItem";

export function pureCloneFrom<T extends object>(sourceObject: T): T {
  return omit(toJS(sourceObject), ["__validation"]) as T;
}

const findEntity = (credential: Credential | CompanyEmployeeByCredentialCredentialsItem, trainingEntities: TrainingEntity[]) => {
  if (credential instanceof CompanyEmployeeByCredentialCredentialsItem) {
    return {
      name: credential.trainingEntityName,
    };
  }
  return trainingEntities.find(({ id }) => credential.trainingEntityId === id);
};

export const makeCredentialsSections = (credentials: Credential[] = [], trainingEntities: TrainingEntity[]) => {
  const sectionisedCredentials = groupBy(
    credentials.map(credential => ({
      ...credential,
      trainingEntity: findEntity(credential, trainingEntities),
    })),
    ({ credentialType }) => credentialType
  );
  return sortBy(Object.keys(sectionisedCredentials), (key: string) => key).map(credentialType => {
    const currentCredentials = sectionisedCredentials[credentialType];
    return {
      id: credentialType,
      name: credentialType,
      data: currentCredentials,
    };
  });
};
export const fmtDate = (date?: Date, format = "MM/dd/yyyy") => {
  if (!date) {
    return undefined;
  }
  return dateFormat(new Date(date), format);
};
