import { Card, Col, RoundedButton, Row } from "@emanprague/veralinkkit";
import { ViewComponent } from "@frui.ts/views";
import React from "react";
import EditIcon from "@emanprague/veralinkkit/dist/Icons/Edit";
import { observer } from "mobx-react-lite";
import CompanyProfileViewModel from "viewModels/company/companyInfo/companyProfileViewModel";
import { getClickableLink } from "utils/viewUtils";

const CompanyInfoSection: ViewComponent<CompanyProfileViewModel> = observer(({ vm }) => {
  const { translateGeneral, translateAttribute } = vm.localization;
  const ta = (code: string) => translateAttribute("companyInfo", code);
  const tg = (code: string) => translateGeneral(`companyInfo.subModule.${code}`);

  return (
    <Card className="company-info__card">
      <Card.Header>
        <h4>{tg("companyInfo")}</h4>

        {vm.isEdit && (
          <RoundedButton onClick={vm.openEditCompanyInfo}>
            <EditIcon fontSize={20} />
          </RoundedButton>
        )}
      </Card.Header>

      <Card.Body as={Row}>
        <Col md={6} className="company-info__list">
          <Row>
            <p>{ta("companyName")}</p>
            <p>{vm.companyProfile.companyName}</p>
          </Row>

          <Row>
            <p>{ta("taxId")}</p>
            <p>{vm.companyProfile.taxId}</p>
          </Row>

          <Row>
            <p>{ta("yearFounded")}</p>
            <p>{vm.companyProfile.yearFounded}</p>
          </Row>

          <Row>
            <p>{ta("website")}</p>
            <p>{getClickableLink(vm.companyProfile.website)}</p>
          </Row>

          <Row>
            <p>{ta("contactPhone")}</p>
            <p>{vm.companyProfile.phoneNumber}</p>
          </Row>
        </Col>

        <Col md={6}>
          <p>{ta("aboutUs")}</p>
          <span>{vm.companyProfile.bio}</span>
        </Col>
      </Card.Body>
    </Card>
  );
});

export default CompanyInfoSection;
