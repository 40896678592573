import { observable } from "mobx";

// This entity has been generated, do not change its content, your changes might get lost. You CAN modify the rest of the file.
export default class CodeListWorkType {
  id?: string;

  /**
   * @example
   * Construction Services
   */
  @observable
  name?: string;
}

export const CodeListWorkTypeValidation = {
  id: { required: true },
};
