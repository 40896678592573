import { ILocalizationService } from "@emanprague/shared-services";
import { bound } from "@frui.ts/helpers";
import { BusyWatcher, ScreenBase, ConductorOneChildActive } from "@frui.ts/screens";
import AccountSettingsViewModel from "./accountSettingsViewModel";
import EmailConfirmedViewModel from "./emailConfirmedViewModel";
import SuccessEmailChangeViewModel from "./successEmailChangeViewModel";

const navigationName = "accountSettings";

export default class AccountSettingsListViewModel extends ConductorOneChildActive<ScreenBase> {
  navigationName = navigationName;
  busyWatcher = new BusyWatcher();

  constructor(
    public localization: ILocalizationService,
    private accountSettingsFactory: () => AccountSettingsViewModel,
    private emailConfirmedFactory: () => EmailConfirmedViewModel,
    private emailChangeFactory: () => SuccessEmailChangeViewModel
  ) {
    super();

    this.name = this.translate("title");
  }

  @bound translate(key: string) {
    return this.localization.translateGeneral(`profile.accountSettingsList.${key}`);
  }

  findNavigationChild(name: string): ScreenBase | undefined {
    switch (name) {
      case "settings":
        return this.accountSettingsFactory();
      case "emailSent":
        return this.emailChangeFactory();
      case "emailConfirmed":
        return this.emailConfirmedFactory();

      default:
        return this.accountSettingsFactory();
    }
  }
}
