import { registerView, View, ViewComponent } from "@frui.ts/views";
import { observer } from "mobx-react-lite";
import React from "react";
import { Dropdown } from "@emanprague/veralinkkit";
import Notifications from "@emanprague/veralinkkit/dist/Icons/Notifications";
import UnreadBadge from "views/common/unreadBadge";
import ModulePageViewModel from "viewModels/notifications/notificationsViewModel";
import "./notifications.scss";

const notifyNotificationsView: ViewComponent<ModulePageViewModel> = observer(({ vm }) => (
  <>
    <Dropdown
      id="notification"
      variant="outlined"
      className="notification__menu"
      title={
        <>
          <UnreadBadge count={vm.statusbarCount} maxCount={99} />
          <Notifications fontSize={20} />
        </>
      }>
      <h5>
        <span>{vm.translate("title")}</span>
        <Dropdown.Item href="#/notifications">{vm.translate("showAll")}</Dropdown.Item>
      </h5>
      <div>
        {!vm.statusbarList?.length && <p>{vm.translate("noNewNotifications")}</p>}

        {vm.statusbarList?.map((notification, index) => (
          <View vm={notification} key={index} />
        ))}
      </div>
    </Dropdown>
  </>
));

registerView(notifyNotificationsView, ModulePageViewModel, "statusbar");
