import { Button, Col, Container, Row } from "@emanprague/veralinkkit";
import ContractorIcon from "@emanprague/veralinkkit/dist/assets/icons/Contractor Big.svg";
import WorkerIcon from "@emanprague/veralinkkit/dist/assets/icons/Helmet Big.svg";
import OperatorIcon from "@emanprague/veralinkkit/dist/assets/icons/Operator Big.svg";
import { registerView, ViewComponent } from "@frui.ts/views";
import { observer } from "mobx-react-lite";
import React from "react";
import SelectRoleViewModel from "viewModels/signup/selectRoleViewModel";
import "assets/themes/signup.scss";

const icons = {
  WORKER: WorkerIcon,
  CONTRACTOR: ContractorIcon,
  OPERATOR: OperatorIcon,
};

const selectRoleListView: ViewComponent<SelectRoleViewModel> = observer(({ vm }) => (
  <Container>
    <Row className="signup">
      <Col className="text-center justify-content-between">
        <h1 className="text-capitalize ">{vm.translate("title")}</h1>
        <p className="subtitle ">{vm.translate("subtitle")}</p>

        <Row className="justify-content-center">
          {vm.roles.map(role => (
            <Col lg={4} md={6} sm={8} key={role}>
              <div className="card">
                <img src={icons[role]} className="card__icon" alt={vm.translate("altIcon")} />
                <h3 className="card__title text-capitalize">{vm.translate(role)}</h3>
                <p className="card__role-description text-center font-weight-normal">{vm.translate(`description.${role}`)}</p>
                <Button className="card__cta" variant="primary" href={vm.getFormUrl(role)}>
                  {vm.translate("select")}
                </Button>
              </div>
            </Col>
          ))}
        </Row>

        <Row className="select-footer text-center font-weight-normal">
          <Col>
            <span>{vm.translate("betterLogin")}</span>
            <a href="/#/login">{vm.translate("login")}</a>
          </Col>
        </Row>
      </Col>
    </Row>
  </Container>
));

registerView(selectRoleListView, SelectRoleViewModel);
