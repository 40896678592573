import { ILocalizationService, IEventBus } from "@emanprague/shared-services";
import { bound } from "@frui.ts/helpers";
import { BusyWatcher, ScreenBase, watchBusy } from "@frui.ts/screens";
import { interfaces } from "inversify";
import ComplianceRepository from "repositories/complianceRepository";
import { computed, observable, runInAction } from "mobx";
import QuestionnaireAnswer from "entities/questionnaireAnswer";
import { SectionWithAnswer, QuestionaireWithAnswer } from "./SafetyCultureQuestionaireViewModel";
import QuestionnaireAnswers from "entities/questionnaireAnswers";
import UserContext from "services/userContext";
import AccountType from "entities/accountType";
import { CompanyEvents } from "services/events";

type Model = {
  section?: SectionWithAnswer;
};

export default class WorkHoursQuestionnaireViewModel extends ScreenBase {
  navigationName = "workHoursQuestionnaire";
  busyWatcher = new BusyWatcher();
  @observable model: Model = { section: undefined };

  constructor(
    private connectionId: string,
    public localization: ILocalizationService,
    private repository: ComplianceRepository,
    private userContext: UserContext,
    private eventBus: IEventBus
  ) {
    super();

    this.name = this.translate("title");
  }

  @computed
  get isOperator() {
    return this.userContext.identity?.accountType === AccountType.Operator;
  }

  @bound translate(key: string, values?: any) {
    return this.localization.translateGeneral(`compliance.workHours.${key}`, values);
  }

  @bound
  @watchBusy
  async save() {
    const answers = this.model.section!.questions.map(question => (question as QuestionaireWithAnswer).answer).flat();
    const quesitonaireAnswer = new QuestionnaireAnswers();
    quesitonaireAnswer.answers = answers as QuestionnaireAnswer[];
    const saved = await this.repository.saveAnswers("work_hours", this.connectionId, quesitonaireAnswer);

    if (saved.success) {
      this.requestClose();
      this.eventBus.publish(CompanyEvents.connectionPointsUpdated(undefined));
    }
  }

  @bound cancel() {
    this.requestClose();
  }

  async onInitialize() {
    await this.loadQuestionnaire();
  }

  @bound
  @watchBusy
  async loadQuestionnaire() {
    const result = await this.repository.loadQuestions(this.connectionId, "work_hours");
    const answers = await this.repository.loadAnswers("work_hours", this.connectionId);
    if (!result.success || !answers.success) {
      return;
    }
    runInAction(() => {
      const sections = result.payload.sections.map(section => {
        return {
          ...section,
          questions: section.questions.map(question => {
            const answer =
              answers.payload.answers?.find(({ questionId }) => questionId === question.id) ?? new QuestionnaireAnswer();
            // attach validator
            answer.questionId = question.id as string;
            return {
              ...question,
              answer,
            };
          }),
        };
      });
      this.model.section = sections[0];
    });
  }

  static Factory({ container }: interfaces.Context) {
    return (connectionId: string) =>
      new WorkHoursQuestionnaireViewModel(
        connectionId,
        container.get("ILocalizationService"),
        container.get(ComplianceRepository),
        container.get(UserContext),
        container.get("IEventBus")
      );
  }
}
