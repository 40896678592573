import { Button, LoaderOverlay } from "@emanprague/veralinkkit";
import { registerView } from "@frui.ts/views";
import React from "react";
import DetailMessageViewModel from "viewModels/messages/detailMessageViewModel";
import ModalView, { ModalSize } from "views/common/modalView";

import MessageItem from "../components/messageItem";

class DetailMessageModalView extends ModalView<DetailMessageViewModel> {
  size: ModalSize = "lg";
  renderBody(vm: DetailMessageViewModel) {
    const { message } = vm;
    return (
      <>
        <LoaderOverlay loading={vm.busyWatcher.isBusy} variant="light">
          {!message ? null : (
            <ul className="messages__list">
              <MessageItem message={message} localization={vm.localization} onUserClick={vm.requestClose} />
            </ul>
          )}
        </LoaderOverlay>
      </>
    );
  }

  renderFooter(vm: DetailMessageViewModel) {
    const condition = false;
    return (
      <div className={`detailMessageModal__footer ${condition ? "hasAlert" : ""}`}>
        {condition && <h6>Your link with Exxon Inc. has ended</h6>}
        <Button variant="primary" disabled={condition} onClick={vm.replyMessage}>
          {vm.translate("reply")}
        </Button>
      </div>
    );
  }
}

registerView(DetailMessageModalView as any, DetailMessageViewModel);
