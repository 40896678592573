import RepositoryBase from "./repositoryBase";
import CompanyProject from "entities/companyProject";
import CompanyProjectStats from "entities/companyProjectStats";
import CompanyProjectDocument from "entities/companyProjectDocument";
import CompanyProjectTeam from "entities/companyProjectTeam";
import { ProjectsEvents } from "services/events";

export default class ProjectRepository extends RepositoryBase {
  getProjects(companyId: string) {
    return this.callApi(api => api.one("userprofile/company", companyId).all("projects").getEntities(CompanyProject));
  }

  getProject(companyId: string, projectId: string) {
    return this.callApi(api => api.one("userprofile/company", companyId).one("projects", projectId).getEntity(CompanyProject));
  }

  async createProject(companyId: string, item: CompanyProject) {
    const result = await this.callApi(api =>
      api.one("userprofile/company", companyId).all("projects").postEntity(item, CompanyProject)
    );

    if (result.success) {
      this.eventBus.publish(ProjectsEvents.listUpdated(undefined));
    }

    return result;
  }

  async updateProject(companyId: string, projectId: string, item: CompanyProject) {
    const result = await this.callApi(api =>
      api.one("userprofile/company", companyId).one("projects", projectId).patchEntity(item, CompanyProject)
    );

    if (result.success) {
      this.eventBus.publish(ProjectsEvents.listUpdated(undefined));
    }

    return result;
  }

  async removeProject(companyId: string, projectId: string) {
    const result = await this.callApi(api => api.one("userprofile/company", companyId).one("projects", projectId).delete());

    if (result.success) {
      this.eventBus.publish(ProjectsEvents.listUpdated(undefined));
    }

    return result;
  }

  getProjectDocuments(companyId: string, projectId: string) {
    return this.callApi(api =>
      api.one("userprofile/company", companyId).one("projects", projectId).all("documents").getEntities(CompanyProjectDocument)
    );
  }

  createProjectDocument(companyId: string, projectId: string, document: CompanyProjectDocument) {
    return this.callApi(api =>
      api
        .one("userprofile/company", companyId)
        .one("projects", projectId)
        .all("documents")
        .postEntity(document, CompanyProjectDocument)
    );
  }

  async deleteProjectDocument(companyId: string, projectId: string, documentId: string) {
    const result = await this.callApi(api =>
      api.one("userprofile/company", companyId).one("projects", projectId).one("documents", documentId).delete()
    );

    if (result.success) {
      this.eventBus.publish(ProjectsEvents.documentsUpdated(undefined));
    }

    return result;
  }

  async uploadProjectDocument(companyId: string, projectId: string, documentId: string, file: File) {
    const result = await this.callApi(api =>
      api
        .one("userprofile/company", companyId)
        .one("projects", projectId)
        .one("documents", documentId)
        .all("content")
        .putData(file)
    );

    if (result.success) {
      this.eventBus.publish(ProjectsEvents.documentsUpdated(undefined));
    }

    return result;
  }

  downloadProjectDocument(companyId: string, projectId: string, documentId: string) {
    return this.callApi(api =>
      api.one("userprofile/company", companyId).one("projects", projectId).one("documents", documentId).all("content").getRaw()
    );
  }

  getProjectTeams(companyId: string, projectId: string) {
    return this.callApi(api =>
      api.one("userprofile/company", companyId).one("projects", projectId).all("teams").getEntities(CompanyProjectTeam)
    );
  }

  async createProjectTeam(companyId: string, projectId: string, team: CompanyProjectTeam) {
    const result = await this.callApi(api =>
      api.one("userprofile/company", companyId).one("projects", projectId).all("teams").postEntity(team, CompanyProjectTeam)
    );

    if (result.success) {
      this.eventBus.publish(ProjectsEvents.teamsUpdated(undefined));
    }

    return result;
  }

  async updateProjectTeam(companyId: string, projectId: string, teamId: string, team: CompanyProjectTeam) {
    const body: any = {
      ...team,
      assignedContractorId: team.assignedContractorId ? team.assignedContractorId : null,
      assignedSubcontractorName: team.assignedSubcontractorName ? team.assignedSubcontractorName : null,
    };

    const result = await this.callApi(api =>
      api
        .one("userprofile/company", companyId)
        .one("projects", projectId)
        .one("teams", teamId)
        .patchEntity(body, CompanyProjectTeam)
    );

    if (result.success) {
      this.eventBus.publish(ProjectsEvents.teamsUpdated(undefined));
    }

    return result;
  }

  async updateProjectTeamWorkers(companyId: string, projectId: string, teamId: string, team: CompanyProjectTeam) {
    const result = await this.callApi(api =>
      api
        .one("userprofile/company", companyId)
        .one("projects", projectId)
        .one("teams", teamId)
        .patchEntity({ workersAssigned: team.workersAssigned || [] }, CompanyProjectTeam)
    );

    if (result.success) {
      this.eventBus.publish(ProjectsEvents.teamsUpdated(undefined));
    }

    return result;
  }

  async deleteProjectTeam(companyId: string, projectId: string, teamId: string) {
    const result = await this.callApi(api =>
      api.one("userprofile/company", companyId).one("projects", projectId).one("teams", teamId).delete()
    );

    if (result.success) {
      this.eventBus.publish(ProjectsEvents.teamsUpdated(undefined));
    }

    return result;
  }

  getConnections() {
    return this.callApi(api => api.all("userprofile/connections/outbound").get());
  }

  getStats(companyId: string) {
    return this.callApi(api => api.one("userprofile/company", companyId).all("projects/stats").getEntities(CompanyProjectStats));
  }
}
