import { observable } from "mobx";

// This entity has been generated, do not change its content, your changes might get lost. You CAN modify the rest of the file.
export default class ComplianceRequirementsPointsAllocation {
  /**
   * Points allocated to General Information
   * @example
   * 150
   */
  @observable
  generalInformation?: number;

  /**
   * Points allocated to Contractor Safety Culture
   * @example
   * 80
   */
  @observable
  contractorSafetyCulture?: number;

  /**
   * Points allocated to Experience Modifier
   * @example
   * 50
   */
  @observable
  experienceModifier?: number;

  /**
   * Points allocated to Insurance
   * @example
   * 20
   */
  @observable
  insurance?: number;

  /**
   * Points allocated to SafetyMetrics
   * @example
   * 50
   */
  @observable
  safetyMetrics?: number;

  /**
   * Points allocated to Work Hours
   * @example
   * 20
   */
  @observable
  workHours?: number;

  /**
   * Points allocated to Health And Safety Programs
   * @example
   * 30
   */
  @observable
  healthAndSafetyPrograms?: number;

  static ValidationRules = {
    generalInformation: { number: true },
    contractorSafetyCulture: { number: true },
    experienceModifier: { number: true },
    insurance: { number: true },
    safetyMetrics: { number: true },
    workHours: { number: true },
    healthAndSafetyPrograms: { number: true },
  };
}

export const CompliancePointsAllocationValidation = {
  generalInformation: { isPositiveNumber: true },

  contractorSafetyCulture: { isPositiveNumber: true },

  experienceModifier: { isPositiveNumber: true },

  insurance: { isPositiveNumber: true },

  safetyMetrics: { isPositiveNumber: true },

  workHours: { isPositiveNumber: true },
  healthAndSafetyPrograms: { isPositiveNumber: true },
};
