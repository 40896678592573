import React from "react";
import { Badge, Card, Dropdown, RoundedButton } from "@emanprague/veralinkkit";
import { Edit, Menu, Plus, StateOk, StateNotOk } from "@emanprague/veralinkkit/dist/Icons";
import CompanyProjectTeam from "entities/companyProjectTeam";
import { ILocalizationService } from "@emanprague/shared-services";
import UserAvatarPlaceholder from "@emanprague/veralinkkit/dist/Icons/User";
import { getProfileUrl } from "utils/viewUtils";
import AccountType from "entities/accountType";

interface ProjectTeamCardProps {
  team: CompanyProjectTeam;
  localization: ILocalizationService;
  onEdit: (team: CompanyProjectTeam) => void;
  onWorkerRemove: (workerId: string) => void;
  onWorkerAdd: () => void;
  projectOwner: boolean;
  userId?: string;
}

const projectTeamCard = (props: ProjectTeamCardProps) => {
  const { team, localization, onEdit, onWorkerRemove, onWorkerAdd, projectOwner, userId } = props;
  const tg = (code: string) => localization.translateGeneral(`projects.teamCard.${code}`);
  const tm = localization.translateModel;

  const canManageWorkers =
    (!team.assignedContractorId && !team.assignedSubcontractorName && projectOwner) ||
    (team.assignedContractorId && team.assignedContractorId === userId);

  const wantedWorkers = team.nWorkersWanted ? team.nWorkersWanted : 0;
  let workersAssigned = team.workersAssigned ? team.workersAssigned.length : 0;
  workersAssigned = !!team.assignedSubcontractorName ? wantedWorkers : workersAssigned;
  const canAddWorker = workersAssigned < wantedWorkers && canManageWorkers;

  return (
    <Card className="projectTeamCard">
      <Card.Header>
        <Card.Title>{`${team.name} ${workersAssigned}/${wantedWorkers}`}</Card.Title>
        {projectOwner && (
          <div>
            {team.assignedContractorId && (
              <span>
                {`${tg("assignedToContractor")}: `}
                <a href={getProfileUrl(team.assignedContractorId, AccountType.Contractor)}>{team.assignedContractorName}</a>
              </span>
            )}
            &nbsp;
            <RoundedButton onClick={() => onEdit(team)}>
              <Edit fontSize={24} />
            </RoundedButton>
          </div>
        )}
      </Card.Header>
      <Card.Body>
        <section className="requirements">
          <b>{tg("requirements")}:</b>
          {team.requiredCredentials?.map((skill, index) => (
            <Badge key={index} variant="light">
              {skill}
            </Badge>
          ))}
        </section>
        <section>
          {!!team.assignedSubcontractorName && (
            <div className="workerStatusCard">
              <div className="workerStatusCard__content">
                <span>
                  <span>{tg("assignedToSubcontractor")}:</span>
                </span>
                <h6>{team.assignedSubcontractorName}</h6>
              </div>
            </div>
          )}
          {team.workersAssigned?.map(worker => (
            <div key={worker.id} className={`workerStatusCard --${worker.hasExpiredCredential ? "update" : "ready"}`}>
              <div className="workerStatusCard__avatar">
                {worker.profilePhotoUrl ? (
                  <img width={40} height={40} src={worker.profilePhotoUrl} alt="avatar" />
                ) : (
                  <UserAvatarPlaceholder fontSize={40} />
                )}
              </div>
              <div className="workerStatusCard__content">
                <h6>
                  {worker.firstName} {worker.middleName} {worker.lastName}
                </h6>
                <span>
                  {worker.hasExpiredCredential ? <StateNotOk fontSize={16} /> : <StateOk fontSize={16} />}
                  <b>{tg(worker.hasExpiredCredential ? "credentialUpdateNeeded" : "readyForWork")}</b>
                </span>
              </div>

              {canManageWorkers && (
                <Dropdown
                  className="workerStatusCard__options"
                  title={<Menu fontSize={24} color="#888F91" />}
                  variant="link"
                  id="options">
                  <Dropdown.Item onClick={() => onWorkerRemove(worker.id)}>{tg("removeWorker")}</Dropdown.Item>
                </Dropdown>
              )}
            </div>
          ))}
          {canAddWorker && (
            <div className="addWorkerToTeam" onClick={onWorkerAdd}>
              <RoundedButton>
                <Plus fontSize={24} />
              </RoundedButton>
              <h6>{`${tg("add")} ${tm("worker", wantedWorkers - workersAssigned)}`}</h6>
            </div>
          )}
        </section>
      </Card.Body>
    </Card>
  );
};

export default projectTeamCard;
