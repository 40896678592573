import { ILocalizationService } from "@emanprague/shared-services";
import { bound } from "@frui.ts/helpers";
import { BusyWatcher, ScreenBase, watchBusy } from "@frui.ts/screens";
import CompanyEmployee from "entities/companyEmployee";
import { interfaces } from "inversify";
import EmployeeRepository from "repositories/employeeRepository";

export default class RemoveWorkersViewModel extends ScreenBase {
  navigationName = "remove";
  busyWatcher = new BusyWatcher();

  constructor(
    private companyId: string,
    public worker: CompanyEmployee,
    public localization: ILocalizationService,
    private repository: EmployeeRepository
  ) {
    super();

    this.name = this.translate("title");
  }

  @bound
  @watchBusy
  async removeWorker() {
    const result = await this.repository.removeWorker(this.companyId, this.worker.accountId ?? "");
    if (result.success) {
      this.requestClose();
    }
  }

  @bound translate(key: string) {
    return this.localization.translateGeneral(`employees.remove.${key}`);
  }

  static Factory({ container }: interfaces.Context) {
    return (companyId: string, worker: CompanyEmployee) =>
      new RemoveWorkersViewModel(companyId, worker, container.get("ILocalizationService"), container.get(EmployeeRepository));
  }
}
