import { observable } from "mobx";

// This entity has been generated, do not change its content, your changes might get lost. You CAN modify the rest of the file.
export default class QuestionnaireAnswer {
  @observable
  questionId!: string;

  /**
   * One answerId. Only relevant if question is of type `SINGLE`
   */
  @observable
  singleAnswer?: string;

  /**
   * Multiple answerIds. Only relevant if question is of type `MULTI`
   */
  @observable
  multiAnswer?: string[];

  /**
   * Only relevant if question is of type `TEXT`
   */
  @observable
  textAnswer?: string;

  /**
   * Only relevant if question is of type `NUMBER`
   */
  @observable
  numberAnswer?: number;

  /**
   * Only relevant if question is of type `BOOLEAN`
   */
  @observable
  booleanAnswer?: boolean;

  static ValidationRules = {
    questionId: { required: true },
    numberAnswer: { number: true },
  };
}
