import { ILocalizationService } from "@emanprague/shared-services";
import { ScreenBase } from "@frui.ts/screens";
import { action, observable, runInAction } from "mobx";
import ButtonParams from "models/buttonParams";
import ConfirmDialogViewModel from "viewModels/confirmDialogViewModel";

export default class DialogService {
  @observable.shallow dialogs: ScreenBase[] = [];

  constructor(private localization: ILocalizationService) {}

  @action
  showConfirmation(
    message: string,
    title?: string,
    confirmButton?: string | ButtonParams,
    cancelButton?: string | ButtonParams,
    settings: { closeButton?: boolean } = { closeButton: true }
  ) {
    const confirmText =
      typeof confirmButton === "string"
        ? confirmButton
        : (confirmButton as ButtonParams)?.text ?? this.localization.translateGeneral("confirmDialog.confirm");
    const confirmButtonParams: ButtonParams = {
      text: confirmText,
      variant: (confirmButton as ButtonParams)?.variant || "primary",
    };

    const cancelText =
      typeof cancelButton === "string"
        ? cancelButton
        : (cancelButton as ButtonParams)?.text ?? this.localization.translateGeneral("confirmDialog.cancel");
    const cancelButtonParams: ButtonParams = {
      text: cancelText,
      variant: (cancelButton as ButtonParams)?.variant || "outline-secondary",
    };

    const viewModel = new ConfirmDialogViewModel(message, title || undefined, confirmButtonParams, cancelButtonParams, settings);
    this.dialogs.push(viewModel);

    const result = viewModel.show();
    result.then(() =>
      runInAction(() => {
        const index = this.dialogs.indexOf(viewModel);
        this.dialogs.splice(index, 1);
      })
    );

    return result;
  }
}
