import { Transform } from "class-transformer";
import { observable } from "mobx";
import TrainingEntity from "./trainingEntity";
import { formatISO } from "date-fns";
import parseISO from "date-fns/parseISO";

// This entity has been generated, do not change its content, your changes might get lost. You CAN modify the rest of the file.
export default class Credential {
  /**
   * read-only
   */
  id?: string;

  /**
   * read-only
   */
  @observable
  accountId?: string;

  /**
   * ID provided by issuer
   */
  @observable
  externalId?: string;

  /**
   * Has to be selected from a list of available credential names
   * @example
   * Conduct Pressure Test
   */
  @observable
  credentialName!: string;

  /**
   * @example
   * Safety trainings
   */
  @observable
  credentialType!: string;

  @observable
  trainingEntityId!: string;

  @observable
  holderName!: string;

  @observable
  @Transform(value => (value ? (typeof value === "string" ? parseISO(value) : value) : undefined), { toClassOnly: true })
  @Transform(value => (value ? formatISO(value, { representation: "date" }) : undefined), { toPlainOnly: true })
  validityFrom!: Date;

  @observable
  @Transform(value => (value ? (typeof value === "string" ? parseISO(value) : value) : undefined), { toClassOnly: true })
  @Transform(value => (value ? formatISO(value, { representation: "date" }) : undefined), { toPlainOnly: true })
  validityTo!: Date;

  /**
   * read-only
   */
  @observable
  photoUrl?: string;

  /**
   * True if credential is verified by Veralink (read-only)
   */
  @observable
  verified!: boolean;

  /**
   * If credential is verified, this field includes date-time of last verification (read-only)
   */
  @observable
  @Transform(value => (value ? (typeof value === "string" ? parseISO(value) : value) : undefined), { toClassOnly: true })
  @Transform(value => (value ? formatISO(value, { representation: "date" }) : undefined), { toPlainOnly: true })
  verifiedAt?: Date;

  static ValidationRules = {
    credentialName: { required: true },
    credentialType: { required: true },
    trainingEntityId: { required: true },
    holderName: { required: true },
    validityFrom: { format: "date", required: true },
    validityTo: { format: "date", required: true },
    verified: { required: true },
  };
}

export class CredentialPhoto extends Credential {
  @observable
  photo?: File;

  @observable
  trainingEntity?: TrainingEntity;

  static ValidationRules = Object.assign(Credential.ValidationRules, {
    validityFrom: { required: true, isDateBefore: { otherField: "validityTo" } },
    externalId: { required: true },
    verified: { required: false },
  });
}
