import { Button, Col, Form, LoaderOverlay, Row } from "@emanprague/veralinkkit";
import Delete from "@emanprague/veralinkkit/dist/assets/icons/Delete.svg";
import Plus from "@emanprague/veralinkkit/dist/Icons/Plus";
import { Input, Select } from "@frui.ts/bootstrap";
import { registerView } from "@frui.ts/views";
import React from "react";
import SkillsDetailViewModel from "viewModels/worker/modals/skillsDetailViewModel";
import ModalView, { ModalSize } from "views/common/modalView";
import Field from "controls/field";
import "../worker.scss";

class SkillsDetailModalView extends ModalView<SkillsDetailViewModel> {
  size: ModalSize = "lg";

  renderBody(vm: SkillsDetailViewModel) {
    const ta = vm.localization.translateAttribute;

    return (
      <LoaderOverlay loading={vm.busyWatcher.isBusy} variant="light">
        <Row>
          <Col md={12}>
            <Field label={vm.translate("industry")} info={vm.translate("industryInfo")} localization={vm.localization}>
              <Select
                mode="key"
                className="right-input"
                target={vm.item.skills}
                placeholder={vm.translate("industryPlaceholder")}
                property="industryId"
                items={vm.industries}
                textProperty="name"
                keyProperty="id"
              />
            </Field>
          </Col>
          <Col md={12}>
            <Field
              label={vm.translate("professionTitle")}
              info={vm.translate("professionTitleInfo")}
              localization={vm.localization}>
              <Input target={vm.item.skills} property="professionTitle" type="text" />
            </Field>
          </Col>
          <Col md={6}>
            <Field label={vm.translate("profession")} info={vm.translate("professionInfo")} localization={vm.localization}>
              <Select
                mode="key"
                className="right-input"
                target={vm.item.skills}
                placeholder={vm.translate("professionPlaceholder")}
                property="professionId"
                items={vm.professions}
                textProperty="name"
                keyProperty="id"
                disabled={!vm.item.skills?.industryId}
              />
            </Field>
          </Col>
          <Col md={6}>
            <Field label={vm.translate("professionYears")} localization={vm.localization}>
              <Input target={vm.item.skills} property="professionYears" type="number" disabled={!vm.item.skills?.professionId} />
            </Field>
          </Col>
          <Col md={12}>
            <Form.Group>
              <Form.Label>
                <b>{ta("workerProfile", "skills")}</b>
              </Form.Label>

              <Row>
                <Col>
                  {vm.formSkills.map((skill, index) => (
                    <div key={index} className="d-flex mt-2 mb-2">
                      <Input className="font-bold" target={skill} property="name" />

                      <Button variant="link" onClick={() => vm.removeSkill(index)}>
                        <img src={Delete} alt="delete" className="ml-4" />
                      </Button>
                    </div>
                  ))}
                </Col>
              </Row>

              <Row>
                <Col>
                  <Button variant="outline-primary" onClick={vm.addOtherSkill}>
                    <Plus className="mr-2" />
                    {vm.translate("addSkill")}
                  </Button>
                </Col>
              </Row>
            </Form.Group>
          </Col>
        </Row>
      </LoaderOverlay>
    );
  }

  renderFooter(vm: SkillsDetailViewModel) {
    return (
      <Button variant="primary" disabled={vm.busyWatcher.isBusy} onClick={vm.updateSkills}>
        {vm.translate("save")}
      </Button>
    );
  }
}

registerView(SkillsDetailModalView as any, SkillsDetailViewModel);
