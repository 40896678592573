import { Button, Col, Form, LoaderOverlay, Row } from "@emanprague/veralinkkit";
import { Input, Select } from "@frui.ts/bootstrap";
import { registerView } from "@frui.ts/views";
import React from "react";
import ContactInformationViewModel from "viewModels/worker/modals/contactInformationViewModel";
import ModalView from "views/common/modalView";

class ContactInformationModalView extends ModalView<ContactInformationViewModel> {
  renderBody(vm: ContactInformationViewModel) {
    const ta = vm.localization.translateAttribute;

    return (
      <LoaderOverlay loading={vm.busyWatcher.isBusy} variant="light">
        <Row>
          <Col xs={12}>
            <Form.Group>
              <Form.Label>{ta("contact", "phoneNumber")}</Form.Label>
              <Input type="phone" target={vm.contact} property="phoneNumber" />
            </Form.Group>
          </Col>
          <Col xs={12}>
            <Form.Group>
              <Form.Label>{ta("contact", "email")}</Form.Label>
              <Input type="email" target={vm.contact} property="email" />
            </Form.Group>
          </Col>
          <Col xs={6}>
            <Form.Group>
              <Form.Label>{ta("location", "city")}</Form.Label>
              <Input target={vm.location} property="city" />
            </Form.Group>
          </Col>
          <Col xs={6}>
            <Form.Group>
              <Form.Label>{ta("location", "state")}</Form.Label>
              <Select
                mode="key"
                value={vm.location?.state}
                target={vm.location}
                property="state"
                items={vm.states}
                keyProperty="id"
                textProperty="name"
              />
            </Form.Group>
          </Col>
          <Col xs={12}>
            <Form.Group>
              <Form.Label>{ta("contact", "website")}</Form.Label>
              <Input placeholder="https://www.example.com" target={vm.contact} property="website" />
            </Form.Group>
          </Col>
          <Col xs={12}>
            <Form.Group>
              <Form.Label>{ta("contact", "linkedin")}</Form.Label>
              <Input placeholder="https://www.linkedin.com/in/example" target={vm.contact} property="linkedin" />
            </Form.Group>
          </Col>
        </Row>
      </LoaderOverlay>
    );
  }

  renderFooter(vm: ContactInformationViewModel) {
    return (
      <Button variant="primary" disabled={vm.busyWatcher.isBusy} onClick={vm.updateContact}>
        {vm.translate("save")}
      </Button>
    );
  }
}

registerView(ContactInformationModalView as any, ContactInformationViewModel);
