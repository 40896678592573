import { Alert, Button, Card, Col, Form, LoaderOverlay, Row } from "@emanprague/veralinkkit";
import Plus from "@emanprague/veralinkkit/dist/Icons/Plus";
import { Input, Select } from "@frui.ts/bootstrap";
import { preventDefault, registerView, ViewComponent } from "@frui.ts/views";
import { observer } from "mobx-react-lite";
import React from "react";
import CompleteCompanyProfileViewModel from "viewModels/company/companyInfo/modals/completeCompanyProfileViewModel";
import "assets/themes/signup.scss";

const completeCompanyProfileView: ViewComponent<CompleteCompanyProfileViewModel> = observer(({ vm }) => {
  const ta = vm.localization.translateAttribute;
  return (
    <Card>
      <Card.Body className="signup-success signup">
        <Row className="justify-content-center">
          <Col md={8}>
            <h1 className="display-5 text-center">{vm.translate("title")}</h1>
            <p className="subtitle  text-center">{vm.translate("description")}</p>

            <LoaderOverlay loading={vm.busyWatcher.isBusy} variant="light">
              <fieldset>
                <Form onSubmit={preventDefault(vm.confirm)}>
                  <Form.Row>
                    <Col>
                      <Form.Group>
                        <Form.Label>{vm.translate("services")}</Form.Label>

                        {vm.model.services.map((service, index) => {
                          return (
                            <Input
                              key={index}
                              placeholder={index === 0 ? vm.translate("servicesPlaceholder") : ""}
                              target={service}
                              property="name"
                              className="mb-2"
                              errorMessage={index === 0 ? vm.servicesValidationError : undefined}
                            />
                          );
                        })}

                        <Button className="add-btn" onClick={vm.addService}>
                          <Plus className="add-btn__icon" />
                          {vm.translate("addService")}
                        </Button>
                      </Form.Group>
                    </Col>
                  </Form.Row>
                  <Form.Row>
                    <Col>
                      <Form.Group>
                        <Form.Label>
                          {vm.translate("about")} {vm.translate("optional")}
                        </Form.Label>
                        <Input
                          as="textarea"
                          className="text-area"
                          target={vm.model}
                          placeholder={vm.translate("aboutPlaceholder")}
                          property="about"
                        />
                      </Form.Group>
                    </Col>
                  </Form.Row>

                  <Form.Row>
                    <Col>
                      <Form.Label className="location__title">
                        {vm.translate("location")} <span className="text-muted">{vm.translate("optional")} </span>
                      </Form.Label>
                    </Col>
                  </Form.Row>

                  <Form.Group>
                    <Form.Row>
                      <Col>
                        <Form.Label>{ta("location", "street")}</Form.Label>
                        <Input target={vm.model.location} property="street" />
                      </Col>
                    </Form.Row>
                  </Form.Group>

                  <Form.Group>
                    <Form.Row>
                      <Col xs={4}>
                        <Form.Label>{ta("location", "city")}</Form.Label>
                        <Input target={vm.model.location} property="city" />
                      </Col>
                      <Col xs={4}>
                        <Form.Label>{ta("location", "state")}</Form.Label>
                        <Select
                          mode="key"
                          value={vm.model.location?.state}
                          target={vm.model.location}
                          property="state"
                          items={vm.states}
                          keyProperty="id"
                          textProperty="name"
                        />
                      </Col>
                      <Col xs={4}>
                        <Form.Label>{ta("location", "zipcode")}</Form.Label>
                        <Input target={vm.model.location} property="zip" />
                      </Col>
                    </Form.Row>
                  </Form.Group>

                  {vm.errorMessage && <Alert variant="danger">{vm.errorMessage}</Alert>}

                  <Button block disabled={vm.busyWatcher.isBusy || !vm.canConfirm} type="submit">
                    {vm.translate("submit")}
                  </Button>
                </Form>
              </fieldset>
            </LoaderOverlay>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
});

registerView(completeCompanyProfileView, CompleteCompanyProfileViewModel);
