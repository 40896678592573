import React, { useContext } from "react";
import { useAccordionToggle, AccordionContext } from "react-bootstrap";
import ArrowDown from "@emanprague/veralinkkit/dist/Icons/Arrow Simple/Down";
import ArrowUp from "@emanprague/veralinkkit/dist/Icons/Arrow Simple/Up";

const Toggle: React.FC<{ eventKey: string; callback?: (e: string) => void }> = ({ eventKey, callback }) => {
  const currentEventKey = useContext(AccordionContext);

  const decoratedOnClick = useAccordionToggle(eventKey, () => callback && callback(eventKey));

  const isCurrentEventKey = currentEventKey === eventKey;

  return (
    <div onClick={decoratedOnClick}>
      {isCurrentEventKey ? <ArrowUp width="2rem" height="1rem" /> : <ArrowDown width={"2rem"} height="1rem" />}
    </div>
  );
};

export default Toggle;
