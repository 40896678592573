import React from "react";
import { observer } from "mobx-react-lite";
import ModulePageViewModel from "viewModels/workerPortal/modulePageViewModel";
import { Card, Form } from "@emanprague/veralinkkit";
import FilterItem from "./filterItem";
import { Input, Select } from "@frui.ts/bootstrap";
import "./workerPortalFilter.scss";

const WorkerPortalFilter: React.FunctionComponent<{ vm: ModulePageViewModel }> = observer(({ vm }) => {
  const ta = (code: string) => vm.localization.translateAttribute("workerPortalFilter", code);
  const taLocation = (code: string) => vm.localization.translateAttribute("location", code);
  const taAvailability = (code: string) => vm.localization.translateAttribute("availability", code);

  return (
    <Card className="worker-filter">
      <h5>{ta("filterBy")}</h5>

      <FilterItem
        label={ta("profession")}
        collapsedContent={vm.filter.primaryProfessionName || ta("any")}
        content={
          <Select
            target={vm.filter}
            property="primaryProfessionName"
            items={vm.professions}
            mode="key"
            keyProperty="name"
            textProperty="name"
            allowEmpty
          />
        }
      />

      <FilterItem
        label={ta("location")}
        collapsedContent={
          [vm.filter["location.city"], vm.enums.getStateById(vm.filter["location.state"])?.name].filter(e => !!e).join(", ") ||
          ta("any")
        }
        content={
          <>
            <Input placeholder={taLocation("city")} target={vm.filter} property="location.city" />
            <Select
              target={vm.filter}
              property="location.state"
              items={vm.states}
              mode="key"
              keyProperty="id"
              textProperty="name"
              allowEmpty
              placeholder={taLocation("chooseState")}
            />
          </>
        }
      />

      <FilterItem
        label={ta("credential")}
        collapsedContent={vm.filter.credentialNames || ta("any")}
        content={<Input placeholder={ta("credential")} target={vm.filter} property="credentialNames" />}
      />

      <FilterItem
        label={ta("availability")}
        collapsedContent={vm.filter.availability?.map(a => taAvailability(a)).join(", ") || ta("any")}
        content={
          <>
            {vm.availabilityOptions.map(option => (
              <Form.Check
                key={option}
                id={option}
                label={taAvailability(option)}
                checked={vm.filter.availability?.includes(option)}
                onChange={() => vm.toggleAvailability(option)}
                custom
              />
            ))}
          </>
        }
      />
    </Card>
  );
});

export default WorkerPortalFilter;
