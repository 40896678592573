import { ILocalizationService, ValidationServiceBase } from "@emanprague/shared-services";
import { bound } from "@frui.ts/helpers";
import { attachAutomaticValidator, IEntityValidationRules, IValidatorsRepository } from "@frui.ts/validation";
import { action } from "mobx";
import SignupRepository from "repositories/signupRepository";
import validator from "validator";
import AsyncValidator, { IAsyncEntityValidationRules } from "./asyncValidator";
import { VeralinkValidationRules } from "./entityValidationRules";
import { IAsyncValidator } from "./types";
import isBefore from "date-fns/isBefore";
import parseISO from "date-fns/parseISO";
import getYear from "date-fns/getYear";

export default class ValidationService extends ValidationServiceBase {
  constructor(private localization: ILocalizationService, private repository: SignupRepository) {
    super(localization);
  }
  initialize(validators: IValidatorsRepository<string>) {
    super.initialize(validators);

    validators.set("format", this.validateFormat);
    validators.set("isEmail", this.validateEmail);
    validators.set("isPhone", this.validatePhone);
    validators.set("hasTotal", this.validateTotal);
    validators.set("isDateBefore", this.validateDateBefore);
    validators.set("isPositiveNumber", this.validatePositiveNumber);
    validators.set("isAboveZero", this.validateZero);
    validators.set("maxDecimals", this.validateDecimals);
    validators.set("isChecked", this.validateCheck);
    validators.set("notBeOlderThan", this.notBeOlderThan);
    validators.set("digit", this.validateDigit);
  }

  @bound
  validateFormat(value: any, propertyName: string, entity: any, params: VeralinkValidationRules["format"]) {
    if (!value) {
      return undefined;
    }

    let isValid: boolean;
    switch (params) {
      case "email":
        isValid = validator.isEmail(value);
        break;
      case "date":
        isValid = value instanceof Date && !isNaN(value.valueOf());
        break;
      default:
        isValid = true;
        break;
    }

    return this.getValidationMessage(isValid, params, `validators.${params}`);
  }

  @bound
  validateDecimals(value: any, propertyName: string, entity: any, params: any) {
    if (!value) {
      return undefined;
    }
    const decimal = value.toString().split(".").pop();
    const isValid = (decimal ?? "").length <= params.size ?? 2;
    const message = this.getValidationMessage(isValid, params, "validators.maxDecimals");
    return this.processError(message as string, {
      size: params.size ?? 2,
    });
  }

  @bound
  validateZero(value: any, propertyName: string, entity: any, params: any) {
    const isValid = value > 0;

    return this.getValidationMessage(isValid, params, "validators.isAboveZero");
  }

  @bound
  validatePositiveNumber(value: any, propertyName: string, entity: any, params: { otherField: string }) {
    if (!value) {
      return undefined;
    }

    const isValid = Math.sign(value) === 1;

    return this.getValidationMessage(isValid, params, "validators.isPositiveNumber");
  }

  @bound
  validateDateBefore(value: any, propertyName: string, entity: any, params: { otherField: string }) {
    const otherValue = entity[params.otherField];
    const valueDate = typeof value === "string" ? parseISO(value) : value;
    const otherValueDate = typeof value === "string" ? parseISO(otherValue) : otherValue;

    if (!value || !otherValue) {
      return undefined;
    }

    const isValid = isBefore(valueDate, otherValueDate);

    return this.getValidationMessage(isValid, params, "validators.isDateBefore");
  }

  @bound
  validateEmail(value: any, propertyName: string, entity: any, params: VeralinkValidationRules["isEmail"]) {
    const isValid = !value || validator.isEmail(value);
    return this.getValidationMessage(isValid, params, "validators.isEmail");
  }

  @bound
  validatePhone(value: any, propertyName: string, entity: any, params: VeralinkValidationRules["isPhone"]) {
    const isValid = !value || validator.isMobilePhone(value);
    return this.getValidationMessage(isValid, params, "validators.isPhone");
  }

  @bound
  validateTotal(value: any, propertyName: string, entity: any, params: VeralinkValidationRules["hasTotal"]) {
    const data = params as any;
    const otherValue = entity[data.other];

    let isValidZero;
    if ((params as any).isAboveZero) {
      isValidZero = this.validateZero(value, propertyName, entity, {});
    } else if (!value) {
      isValidZero = this.validateRequired(value, propertyName, entity, {});
    }

    if (isValidZero) {
      return isValidZero;
    }

    const isValid = value && value + otherValue === data.total;
    return this.processError(this.getValidationMessage(isValid, params, "validators.total") as string, {
      total: data.total.toString(),
    });
  }

  @bound
  processError(template: string, args: Record<string, string>) {
    if (!template) {
      return template;
    }
    return this.localization.processTemplate(template, args);
  }

  @bound
  async validateUsernameAsync(value: any, propertyName: string, entity: any, params: VeralinkValidationRules["userName"]) {
    if (!value || typeof value !== "string" || value.length <= 3) {
      return undefined;
    }

    const payload = await this.repository.verifyUserName(value);
    return this.getValidationMessage(payload.available, params, "validators.isUsernameTaken");
  }

  attachUserNameValidation<TTarget extends { username: string }>(
    target: TTarget,
    entityValidationRules: IEntityValidationRules<TTarget>,
    errorsImmediatelyVisible = false
  ) {
    const asyncValidations: Record<string, IAsyncValidator> = {
      username: this.validateUsernameAsync,
    };

    return this.attachValidatorWithAsync(target, entityValidationRules, asyncValidations, errorsImmediatelyVisible);
  }

  attachValidatorWithAsync<TTarget>(
    target: TTarget,
    entityValidationRules: IEntityValidationRules<TTarget>,
    asyncValidations: Record<string, IAsyncValidator>,
    errorsImmediatelyVisible = false
  ) {
    const asyncValidator = new AsyncValidator(target, asyncValidations as IAsyncEntityValidationRules<TTarget>);
    const extendedValidationRules = { ...entityValidationRules } as any;

    for (const key in asyncValidations) {
      const existingPropertyRule = extendedValidationRules[key];
      if (existingPropertyRule) {
        existingPropertyRule.manualErrors = asyncValidator.errors;
      } else {
        extendedValidationRules[key] = { manualErrors: asyncValidator.errors };
      }
    }

    action(attachAutomaticValidator)(target, extendedValidationRules, errorsImmediatelyVisible);
    return asyncValidator;
  }

  @bound
  validateCheck(value: any, propertyName: string, entity: any, params: any) {
    return this.getValidationMessage(!!value, params, "validators.checked");
  }

  @bound
  notBeOlderThan(value: number | string | Date, propertyName: string, entity: any, params: any) {
    if (!value) {
      return undefined;
    }

    let isValid = false;

    if (value instanceof Date) {
      isValid = getYear(value) >= params.min;
    } else if (Number(value)) {
      isValid = Number(value) >= params.min;
    }

    const message = this.getValidationMessage(isValid, params, "validators.notBeOlderThan");
    return this.processError(message as string, {
      min: params.min,
    });
  }

  @bound
  validateDigit(value: any, propertyName: string, entity: any, params: any) {
    const isValid = value.match(/^\d+$/);
    return this.getValidationMessage(isValid, params, "validators.digit");
  }
}
