import { observer } from "mobx-react-lite";
import React from "react";
import { Card, RoundedButton } from "@emanprague/veralinkkit";
import PlusIcon from "@emanprague/veralinkkit/dist/Icons/Plus";
import "./credentials.scss";
import CredentialsSection from "views/common/credentialsSection";
import { ICredentialsViewModel } from "viewModels/types";

type Props = React.FC<{ vm: ICredentialsViewModel; scrollable?: boolean }>;

const credentialsSectionView: Props = observer(({ vm, scrollable }) => {
  const isEdit = vm.mode === "edit";
  return (
    <Card>
      <Card.Header>
        <Card.Title className="text-uppercase">{vm.translate("credentials")}</Card.Title>
        {isEdit && (
          <RoundedButton onClick={() => vm.openCredentialsModal()}>
            <PlusIcon fontSize={20} />
          </RoundedButton>
        )}
      </Card.Header>
      <Card.Body>
        <CredentialsSection
          scrollable={scrollable}
          isEdit={isEdit}
          localization={vm.localization}
          credentials={vm.credentials}
          uploadCredentials={() => vm.openCredentialsModal()}
          openCredentialsOverview={vm.openCredentialsOverviewModal}
        />
      </Card.Body>
    </Card>
  );
});

export default credentialsSectionView;
