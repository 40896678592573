import { registerView, ViewComponent, preventDefault } from "@frui.ts/views";
import { observer } from "mobx-react-lite";
import React from "react";
import ForgotPasswordViewModel from "viewModels/profile/forgotPasswordViewModel";
import { Row, Col, Card, Form, Button, Container } from "@emanprague/veralinkkit";
import { Input } from "@frui.ts/bootstrap";
import FullHeightContainer from "views/common/fullHeightContainer";
import LinkSent from "views/common/linkSent";
import "./login.scss";

const ForgotPasswordForm: ViewComponent<ForgotPasswordViewModel> = observer(({ vm }) => (
  <Container className="forgot-password">
    <Row className="justify-content-center mt-4">
      <Col md={6}>
        <Card>
          <Card.Body>
            <Row className="justify-content-center forgot-password">
              <Col md={10}>
                <h1 className="text-center title">{vm.name}</h1>

                <p className="text-center">{vm.translate("description")}</p>

                <Form onSubmit={preventDefault(vm.sendInstructions)}>
                  <Form.Row>
                    <Col>
                      <Form.Group>
                        <Input placeholder={vm.translate("email")} target={vm.forgotPassword} property="email" />
                      </Form.Group>
                    </Col>
                  </Form.Row>
                  <Row className="divider">
                    <Col>
                      <hr className="divider__line" />
                    </Col>
                    <Col className="col-auto divider__text">{vm.translate("or_delimiter")}</Col>
                    <Col>
                      <hr className="divider__line" />
                    </Col>
                  </Row>

                  <Form.Row>
                    <Col>
                      <Form.Group>
                        <Input placeholder={vm.translate("username")} target={vm.forgotPassword} property="username" />
                      </Form.Group>
                    </Col>
                  </Form.Row>

                  <Form.Row>
                    <Col>
                      <Button type="submit" disabled={vm.busyWatcher.isBusy} block>
                        {vm.translate("submit")}
                      </Button>
                    </Col>
                  </Form.Row>
                </Form>
              </Col>
            </Row>
          </Card.Body>
        </Card>
      </Col>
    </Row>
  </Container>
));

const SuccessMessage: ViewComponent<ForgotPasswordViewModel> = observer(({ vm }) => (
  <FullHeightContainer>
    <LinkSent
      email={vm.forgotPassword.email || vm.forgotPassword.username}
      title={vm.translate("successTitle")}
      message={vm.translate("successMessage")}
      help={vm.translate("help")}
      resend={vm.translate("sendAgain")}
      onResend={vm.sendInstructions}
    />
  </FullHeightContainer>
));

const forgotPasswordView: ViewComponent<ForgotPasswordViewModel> = observer(({ vm }) =>
  vm.isSent ? <SuccessMessage vm={vm} /> : <ForgotPasswordForm vm={vm} />
);

registerView(forgotPasswordView, ForgotPasswordViewModel);
