import { observable } from "mobx";

// This entity has been generated, do not change its content, your changes might get lost. You CAN modify the rest of the file.
class LocationSimple {
  /**
   * @example
   * Houston
   */
  @observable
  city?: string;

  /**
   * @example
   * Texas
   */
  @observable
  state?: string;
}

export default class LocationSimpleExtended extends LocationSimple {
  static ValidationRules = {
    city: { required: true },
    state: { required: true },
  };
}
