import { ILocalizationService } from "@emanprague/shared-services";
import { bound } from "@frui.ts/helpers";
import { BusyWatcher, watchBusy } from "@frui.ts/screens";
import AccountType from "entities/accountType";
import AuthenticationIdentity from "entities/authenticationIdentity";
import { ISubModule } from "viewModels/types";
import { action } from "mobx";
import ConnectionDetailViewModel from "./connectionDetailViewModel";
import ContractorConnection from "entities/contractorConnection";
import ConnectionsRepository from "repositories/connectionsRepository";
import SendMessageViewModel from "viewModels/messages/sendMessageViewModel";
import DialogService from "services/dialogService";
import ContinuousListViewModelBase from "viewModels/continuousListViewModelBase";
import UserContext from "services/userContext";

export default class SubModuleViewModel extends ContinuousListViewModelBase<ContractorConnection, any> implements ISubModule {
  orderIndex = 6;
  navigationName = "myConnections";
  busyWatcher = new BusyWatcher();
  connectionId: string;
  selectedConnection: ContractorConnection;

  async onInitialize() {
    await this.loadData();
  }

  get identity() {
    return this.context.identity;
  }

  get isOperator() {
    return this.identity?.accountType === AccountType.Operator;
  }

  constructor(
    public localization: ILocalizationService,
    private detailFactory: ReturnType<typeof ConnectionDetailViewModel.Factory>,
    private sendMessageFactory: ReturnType<typeof SendMessageViewModel.Factory>,
    private repository: ConnectionsRepository,
    private dialog: DialogService,
    private context: UserContext
  ) {
    super();

    this.name = this.translate("title");
  }

  @bound
  canConnect(connection: ContractorConnection) {
    return !connection.accepted && this.identity?.accountType === AccountType.Contractor;
  }

  @bound
  @action
  async changePage(offset: number, _limit: number) {
    this.currentPaging.offset = offset;
    await this.loadData();
  }

  @watchBusy
  async loadData() {
    const data = await this.repository.loadConnections(this.currentPaging);

    if (data.success) {
      this.setData(data.payload);
    }
  }

  @bound
  @watchBusy
  async accept(connectionId: string) {
    const result = await this.repository.acceptConnections(connectionId);
    if (result.success) {
      // reload page
      await this.loadData();
    }
  }

  @bound
  @watchBusy
  async cancel(connectionId: string) {
    const result = await this.repository.deleteConnections(connectionId);
    if (result.success) {
      // reload page
      await this.loadData();
    }
  }

  @bound
  async decline(connectionId: string) {
    const confirmationResult = await this.dialog.showConfirmation(
      this.translate("deleteMessage"),
      this.translate("deleteTitle"),
      this.translate("deleteOk"),
      this.translate("deleteCancel")
    );

    if (!confirmationResult) {
      return;
    }

    await this.cancel(connectionId);
  }

  isVisible(identity: AuthenticationIdentity) {
    return identity.accountType === AccountType.Contractor || identity.accountType === AccountType.Operator;
  }

  @bound openDetail(connectionId: string) {
    this.navigate(connectionId, undefined);
  }

  @bound sendMessage(connection: ContractorConnection) {
    this.selectedConnection = connection;
    this.navigate("sendMessage", undefined);
  }

  protected findNavigationChild(navigationName: string | undefined) {
    if (navigationName === "sendMessage") {
      return this.sendMessageFactory(this.selectedConnection.companyId!, this.selectedConnection.companyName!);
    } else if (navigationName) {
      return this.detailFactory(navigationName);
    } else {
      return undefined;
    }
  }

  @bound translate(key: string) {
    return this.localization.translateGeneral(`myConnections.subModule.${key}`);
  }
}
