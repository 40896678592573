import WorkerProfile from "entities/workerProfile";
import Credential from "entities/credential";
import WorkerProfileContact from "entities/workerProfileContact";
import WorkerProfileEducation from "entities/workerProfileEducation";
import WorkerProfileExperience from "entities/workerProfileExperience";
import { WorkerEvents } from "services/events";
import RepositoryBase from "./repositoryBase";
import { runInAction, observable } from "mobx";
import { FileBox } from "controls/fileUpload";

const cache = new Map();

export default class WorkerProfileRepository extends RepositoryBase {
  @observable progressNotification = 0;

  getProfile(workerId: string) {
    return this.callApi(api => api.all("userprofile").one("worker", workerId).all("profile").getEntity(WorkerProfile));
  }

  updateAvailability(workerId: string, availableFrom: Date | undefined) {
    return this.updateWorkerProfile(workerId, { availableFrom: availableFrom || null, publicProfile: !!availableFrom } as any);
  }

  updateContact(workerId: string, contact: WorkerProfileContact) {
    return this.updateWorkerProfile(workerId, { contact });
  }

  async updateWorkerProfile(workerId: string, profile: Partial<WorkerProfile>) {
    const result = await this.callApi(api =>
      api.all("userprofile").one("worker", workerId).all("profile").patchEntity(profile, WorkerProfile)
    );

    if (result.success) {
      this.eventBus.publish(WorkerEvents.profileUpdated(result.payload));
    }

    return result;
  }

  async addEducation(workerId: string, newEducation: WorkerProfileEducation) {
    const result = await this.callApi(
      api =>
        api.all("userprofile").one("worker", workerId).all("profile/education").postEntity(newEducation, WorkerProfileEducation),
      false
    );

    if (result.success) {
      this.eventBus.publish(WorkerEvents.educationUpdated(result.payload));
    }

    return result;
  }

  async deleteEducation(workerId: string, educationId: string) {
    const result = await this.callApi(api =>
      api.all("userprofile").one("worker", workerId).all("profile").one("education", educationId).delete()
    );

    if (result.success) {
      this.eventBus.publish(WorkerEvents.educationUpdated(undefined));
    }

    return result;
  }

  async addExperience(workerId: string, newExperience: WorkerProfileExperience) {
    const result = await this.callApi(api =>
      api.all("userprofile").one("worker", workerId).all("profile/experience").postEntity(newExperience, WorkerProfileExperience)
    );

    if (result.success) {
      this.eventBus.publish(WorkerEvents.experienceUpdated(result.payload));
    }

    return result;
  }

  async editExperience(workerId: string, experience: WorkerProfileExperience) {
    const result = await this.callApi(api =>
      api
        .all("userprofile")
        .one("worker", workerId)
        .all("profile")
        .patchEntity({ workExperience: [experience] }, WorkerProfile)
    );

    if (result.success) {
      this.eventBus.publish(WorkerEvents.experienceUpdated(undefined));
    }

    return result;
  }

  async deleteExperience(workerId: string, experienceId: string) {
    const result = await this.callApi(api =>
      api.all("userprofile").one("worker", workerId).all("profile").one("experience", experienceId).delete()
    );

    if (result.success) {
      this.eventBus.publish(WorkerEvents.experienceUpdated(undefined));
    }

    return result;
  }

  loadCredentials(workerId: string) {
    return this.callApi(api => api.one("credentials/account", workerId).all("credentials").getEntities(Credential));
  }

  loadCredential(workerId: string, credentialId: string) {
    return this.callApi(api => api.one("credentials/account", workerId).one("credentials", credentialId).getEntity(Credential));
  }

  async createCredential(credential: Credential, workerId: string) {
    const result = await this.callApi(api =>
      api.one("credentials/account", workerId).all("credentials").postEntity(credential, Credential)
    );
    if (result.success) {
      this.eventBus.publish(WorkerEvents.credentialCreated(undefined));
    }
    return result;
  }

  deleteCredentialPhoto(credentialId: string, workerId: string) {
    return this.callApi(api => api.one("credentials/account", workerId).one("credentials", credentialId).all("photo").delete());
  }

  async deleteCredential(credentialId: string, workerId: string) {
    const result = await this.callApi(api => api.one("credentials/account", workerId).one("credentials", credentialId).delete());
    if (result.success) {
      this.eventBus.publish(WorkerEvents.credentialDeleted(undefined));
    }
    return result;
  }

  async getPhotoObjectUrl(credentialId: string, workerId: string): Promise<string | undefined> {
    const cacheId = `${credentialId}:${workerId}`;

    const existsInCache = cache.get(cacheId);
    if (existsInCache) {
      return existsInCache;
    }
    const response = await this.callApi(api =>
      api.one("credentials/account", workerId).one("credentials", credentialId).all("photo").getRaw()
    );

    if (response.success) {
      const mimeType = response.payload.headers.get("Content-Type") as string;
      const body = await response.payload.blob();

      const url = URL.createObjectURL(new Blob([body], { type: mimeType }));
      cache.set(cacheId, url);
      return url;
    }

    return undefined;
  }

  async uploadCredentialPhoto(file: FileBox<any>, workerId: string, credentialId: string) {
    runInAction(() => {
      file.progress = 0;
    });

    const response = await this.callApi(api =>
      api
        .one("credentials/account", workerId)
        .one("credentials", credentialId)
        .all("photo")
        .putBlob(file.file, num => {
          runInAction(() => {
            file.progress = num;
          });
        })
    );

    if (response && response.payload.status === 200) {
      const cacheId = `${credentialId}:${workerId}`;
      cache.delete(cacheId);

      this.eventBus.publish(WorkerEvents.credentialPhotoUploaded(undefined));
      return {
        success: true,
      };
    }

    runInAction(() => {
      this.progressNotification = 0;
    });

    return {
      success: false,
      payload: response.payload,
    };
  }

  uploadWorkerPhoto(file: File, workerId: string) {
    return this.callApi(api => api.one("userprofile/worker", workerId).all("profile/photo").postData(file));
  }

  async getWorkerPhotoObjectUrl(imageUrl: string): Promise<string | undefined> {
    const response = await this.callApi(api => api.withBaseUrl(imageUrl).getRaw());

    if (response.success) {
      const body = await response.payload.blob();
      return URL.createObjectURL(body);
    }

    return undefined;
  }
}
