import { ILocalizationService } from "@emanprague/shared-services";
import { bound } from "@frui.ts/helpers";
import { BusyWatcher, ScreenBase, watchBusy } from "@frui.ts/screens";
import { interfaces } from "inversify";
import { action, observable } from "mobx";
import EmployeeRepository from "repositories/employeeRepository";
import { API_URI } from "config";
import CompanyEmployeesImportResult from "entities/companyEmployeesImportResult";

export default class ImportWorkersViewModel extends ScreenBase {
  navigationName = "import";
  busyWatcher = new BusyWatcher();
  @observable file?: File;
  @observable importResult?: CompanyEmployeesImportResult;
  @observable templateUrl?: string;

  constructor(private companyId: string, public localization: ILocalizationService, private repository: EmployeeRepository) {
    super();

    this.name = this.translate("title");
    this.templateUrl = `${API_URI}/userprofile/company/${companyId}/employees/import/template`;
  }

  @action
  selectFile(file: File) {
    this.file = file;
  }

  @action.bound
  removeFile() {
    this.file = undefined;
  }

  @action.bound
  @watchBusy
  async importWorkers() {
    if (this.file) {
      const response = await this.repository.importEmployees(this.companyId, this.file);
      if (response.success) {
        const result = await response.payload.json();
        this.setImportResult(result);
      }
    }
  }

  @action setImportResult(result: CompanyEmployeesImportResult) {
    this.importResult = result;
  }

  @bound translate(key: string) {
    return this.localization.translateGeneral(`employees.import.${key}`);
  }

  static Factory({ container }: interfaces.Context) {
    return (companyId: string) =>
      new ImportWorkersViewModel(companyId, container.get("ILocalizationService"), container.get(EmployeeRepository));
  }
}
