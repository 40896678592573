import React from "react";
import { Media } from "@emanprague/veralinkkit";

import { ILocalizationService } from "@emanprague/shared-services";
import Message from "entities/message";
import { getProfileUrl } from "utils/viewUtils";
import UserAvatar from "views/common/userAvatar";

interface MessageItemProps {
  message: Message;
  localization: ILocalizationService;
  onUserClick?: () => void;
  openDetail?: (id: string) => void;
}

const messageItem = (props: MessageItemProps) => {
  const { message, localization, onUserClick, openDetail } = props;

  const handleOnUserClick = () => {
    if (onUserClick) {
      onUserClick();
    }
  };

  const handleOnOpenDetail = () => {
    if (openDetail) {
      openDetail(message.id!);
    }
  };

  const profileUrl = getProfileUrl(message.senderAccountId!, message.senderAccountType!);

  let cn = message.read === false ? "new" : "";
  if (!openDetail) {
    cn = "";
  }

  return (
    <Media as="li" className={cn}>
      <Media.Body>
        <label>
          <div>
            <UserAvatar className="message__avatar" url={message.senderPhotoUrl} />
            <a href={profileUrl} onClick={handleOnUserClick}>
              {message.senderAccountName}
            </a>
          </div>
          <span>{localization.formatDateTime(message.dateReceived)}</span>
        </label>
        <p className={openDetail ? "hovered" : ""} onClick={handleOnOpenDetail}>
          {message.content}
        </p>
      </Media.Body>
    </Media>
  );
};

export default messageItem;
