import React from "react";
import { ViewComponent } from "@frui.ts/views";
import WorkerProfileViewModel from "viewModels/worker/workerProfileViewModel";

const strengthSectionView: ViewComponent<WorkerProfileViewModel> = ({ vm }) => {
  return (
    <div className="strength">
      <div className="strength__texts">
        <span>{vm.translate("profileStrength")}</span>
        <h6>{vm.score.text}</h6>
      </div>
      <div className="strength__score">
        <div className="score__wrapper">
          <div className="score__value" style={{ width: `${vm.score.value}%` }}>
            &nbsp;
          </div>
        </div>
      </div>
    </div>
  );
};

export default strengthSectionView;
