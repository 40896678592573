import React from "react";
import { Pending, Check, StateNotOk } from "@emanprague/veralinkkit/dist/Icons";

const SafetyStatus = ({ status, rejectedMessage, translate }: any) => {
  if (status === "PENDING") {
    return (
      <p>
        <Pending className="safetyPrograms-status__icon" />
        <span>{translate("pending")}</span>
      </p>
    );
  } else if (status === "APPROVED") {
    return (
      <p>
        <Check className="safetyPrograms-status__icon" />
        <span>{translate("approved")}</span>
      </p>
    );
  } else if (status === "REJECTED") {
    return (
      <div>
        <p className="safetyPrograms-status__red">
          <StateNotOk className="safetyPrograms-status__icon" />
          {translate("rejected")}
        </p>

        {rejectedMessage && (
          <div>
            <b>{translate("note")}</b>
            <p className="text-muted">{rejectedMessage}</p>
          </div>
        )}
      </div>
    );
  }

  return null;
};

export default SafetyStatus;
