import React from "react";
import QRCode from "qrcode.react";
import { ViewProps } from "@frui.ts/views";
import WorkerProfileViewModel from "viewModels/worker/workerProfileViewModel";
import { Button, Card, RoundedButton } from "@emanprague/veralinkkit";
import EditIcon from "@emanprague/veralinkkit/dist/Icons/Edit";
import Linkedin from "@emanprague/veralinkkit/dist/Icons/Linkedin";
import Mail from "@emanprague/veralinkkit/dist/Icons/Mail";
import Phone from "@emanprague/veralinkkit/dist/Icons/Phone";
import Web from "@emanprague/veralinkkit/dist/Icons/Web";
import Document from "@emanprague/veralinkkit/dist/Icons/Document";
import { Observer } from "mobx-react-lite";
import { getClickableLink, getClickableLinkedIn, getClickableMail } from "utils/viewUtils";
import UserAvatar from "views/common/userAvatar";

export default class UserProfileView extends React.Component<ViewProps<WorkerProfileViewModel>> {
  upload: HTMLInputElement | null;

  handleFileSelect = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    e.preventDefault();
    if (this.upload) {
      this.upload.click();
    }
  };

  onFileChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.stopPropagation();
    e.preventDefault();
    if (e.target.files && e.target.files[0]) {
      const file: File = e.target.files[0];
      this.props.vm.uploadWorkerPhoto(file);
    }
  };

  render() {
    const { vm } = this.props;
    const { mode, item, enums } = vm;

    const isEdit = mode === "edit";
    const isPendingRegistration = vm.item.pendingRegistration;

    const handleQrOpen = () => {
      if (isPendingRegistration) {
        return;
      }
      return vm.openScreen("qr");
    };
    const handleContactOpen = () => vm.openScreen("contact");
    const handleAvailabilityOpen = () => vm.openScreen("availability");

    return (
      <Observer>
        {() => (
          <Card>
            <Card.Body>
              <div className="worker-profile">
                <div className="worker-profile__image">
                  <UserAvatar variant="default" url={vm.workerImage} />
                  <input
                    type="file"
                    id="file"
                    ref={ref => (this.upload = ref)}
                    style={{ display: "none" }}
                    onChange={this.onFileChanged}
                    accept="image/*"
                  />
                  {isEdit && (
                    <div className="editImage">
                      <RoundedButton size="sm" onClick={this.handleFileSelect}>
                        <EditIcon />
                      </RoundedButton>
                    </div>
                  )}
                </div>
                <div className="worker-profile__qr">
                  <QRCode size={100} value={`${window.location.origin}/#/worker/${item.id ?? ""}`} onClick={handleQrOpen} />
                </div>

                <h6 className="worker-profile__text">{vm.fullName}</h6>
                <span className="worker-profile__text">{enums.getProfessionById(item.skills.professionId)?.name}</span>
                <span className="worker-profile__text light">{vm.printLocation}</span>

                <div className={`worker-profile__status ${vm.isAvailable && "available"}`}>
                  <section>
                    <label>{vm.translate(vm.isAvailable ? "availableForHire" : "nonAvailableForHire")}</label>
                    {vm.isAvailable && (
                      <span>
                        {vm.translate("availableFrom")}&nbsp;{vm.localization.formatDate(item.availableFrom)}
                      </span>
                    )}
                  </section>
                  {isEdit && (
                    <div className="action">
                      <RoundedButton size="sm" onClick={handleAvailabilityOpen}>
                        <EditIcon />
                      </RoundedButton>
                    </div>
                  )}
                </div>

                <section>
                  <h6>
                    {vm.translate("contact")}
                    {isEdit && (
                      <RoundedButton size="sm" onClick={handleContactOpen}>
                        <EditIcon />
                      </RoundedButton>
                    )}
                  </h6>
                  <span>
                    <Phone />
                    &nbsp;{item.contact?.phoneNumber ?? "-"}
                  </span>
                  <span>
                    <Mail />
                    &nbsp;{getClickableMail(item.contact?.email)}
                  </span>
                  <span>
                    <Linkedin />
                    &nbsp;{getClickableLinkedIn(item.contact?.linkedin)}
                  </span>
                  <span>
                    <Web />
                    &nbsp;
                    {getClickableLink(item.contact?.website)}
                  </span>
                </section>
                <Button onClick={() => window.print()}>
                  <Document fontSize={20} />
                  &nbsp;{vm.translate("printProfile")}
                </Button>
                <br />
                <Button onClick={vm.copyLinkToClipboard} variant="link">
                  {vm.translate("copyLink")}
                </Button>
              </div>
            </Card.Body>
          </Card>
        )}
      </Observer>
    );
  }
}
