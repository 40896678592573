import React from "react";
import { ViewComponent } from "@frui.ts/views";
import WorkerProfileViewModel from "viewModels/worker/workerProfileViewModel";

const bannerSectionView: ViewComponent<WorkerProfileViewModel> = ({ vm }) => {
  return (
    <div className="banner">
      <div className="banner__texts">
        <h6>{vm.translate("bannerTitle")}</h6>
        <h4>{vm.translate("bannerText")}</h4>
      </div>
      <div className="image__wrapper">
        <div>&nbsp;</div>
      </div>
    </div>
  );
};

export default bannerSectionView;
