import React from "react";
import { observer } from "mobx-react-lite";
import { Button, Card } from "@emanprague/veralinkkit";
import WorkerProfileSearchResult from "entities/workerProfileSearchResult";
import RequestLink from "@emanprague/veralinkkit/dist/Icons/RequestLink";
import Comment from "@emanprague/veralinkkit/dist/Icons/Comment";
import Check from "@emanprague/veralinkkit/dist/Icons/Check";
import { ILocalizationService } from "@emanprague/shared-services";
import AvailabilityStatus from "./availabilityStatus";
import UserAvatar from "views/common/userAvatar";
import EnumsService from "services/enumsService";

export interface WorkerSearchCardProps {
  worker: WorkerProfileSearchResult;
  localization: ILocalizationService;
  onViewProfile: () => void;
  onSendMessage: () => void;
  onRequestLink: () => void;
  enums: EnumsService;
}

const WorkerSearchCard: React.FunctionComponent<WorkerSearchCardProps> = observer(
  ({ worker, localization, onViewProfile, onSendMessage, onRequestLink, enums }) => {
    const tg = (code: string) => localization.translateGeneral(`workerPortal.workerSearchCard.${code}`);
    const tm = localization.translateModel;

    return (
      <Card className="worker-search-card">
        <div className="worker-search-card__header">
          <UserAvatar url={worker.profilePhotoUrl} />

          <div>
            <h5>
              {worker.firstName} {worker.middleName} {worker.lastName}
            </h5>
            <span>
              {[worker.location?.city, enums.getStateById(worker.location?.state)?.name]
                .filter(x => x !== undefined)
                .join(", ") || tg("unknownLocation")}
            </span>
          </div>

          <AvailabilityStatus availableFrom={worker.availableFrom} localization={localization} />
        </div>

        <div className="worker-search-card__body">
          <p>
            {worker.primaryProfessionName || tg("unknownProfession")}
            &nbsp;
            <span>{`(${worker.primaryProfessionYears} ${tm("year", worker.primaryProfessionYears)} ${tg("experience")})`}</span>
          </p>
          <span>
            {tg("workedFor")}: {worker.workedFor?.join(", ")}
          </span>
        </div>

        <div className="worker-search-card__footer">
          <Button onClick={onViewProfile}>{tg("viewProfile")}</Button>

          <Button variant="outline-primary" onClick={onSendMessage}>
            <Comment fontSize={24} color="#00949E" />
            {tg("sendMessage")}
          </Button>

          {worker.connectionAccepted === undefined ? (
            <Button variant="outline-primary" onClick={onRequestLink}>
              <RequestLink fontSize={24} color="#00949E" />
              {tg("requestLink")}
            </Button>
          ) : (
            <Button variant="success">
              <Check fontSize={24} color="#50A01C" />
              {tg("linkRequested")}
            </Button>
          )}
        </div>
      </Card>
    );
  }
);

export default WorkerSearchCard;
