import { Button, Card } from "@emanprague/veralinkkit";
import { registerView, ViewComponent } from "@frui.ts/views";
import WorkerImportPendingAction from "entities/workerImportPendingAction";
import { observer } from "mobx-react-lite";
import React from "react";
import WorkerImportViewModel from "viewModels/workerImportViewModel";
import LoadingContainer from "./common/loadingContainer";

const workerImportView: ViewComponent<WorkerImportViewModel> = observer(({ vm }) => {
  let title = vm.translate("connectionRequest");
  let messageForWorker = vm.translate("messageForWorker", {
    TYPE: vm.translate("newConnection"),
    BUTTON: vm.translate("confirmTheConnection"),
  });
  let buttonLabel = vm.translate("confirmTheConnection");
  if (vm.workerImport?.action === WorkerImportPendingAction.Registration) {
    title = vm.translate("invitation");
    messageForWorker = vm.translate("messageForWorker", {
      TYPE: vm.translate("invited"),
      BUTTON: vm.translate("createMyAccount"),
    });
    buttonLabel = vm.translate("createMyAccount");
  }

  return (
    <Card>
      <LoadingContainer loading={vm.busyWatcher.isBusy}>
        {vm.workerImport && (
          <>
            <Card.Header>
              <Card.Title>{title}</Card.Title>
            </Card.Header>
            <Card.Body>
              <p>
                {vm.translate("greeting")}
                {vm.workerImport.firstName ? ` ${vm.workerImport.firstName}` : ""},
              </p>
              <p>{messageForWorker}</p>
            </Card.Body>
            <Card.Footer>
              <Button onClick={vm.next} disabled={!vm.workerImport}>
                {buttonLabel}
              </Button>
              <Button variant="outline-primary" onClick={vm.cancel}>
                {vm.translate("cancel")}
              </Button>
            </Card.Footer>
          </>
        )}
      </LoadingContainer>
    </Card>
  );
});

registerView(workerImportView, WorkerImportViewModel);
