import { Row, Col, Button } from "@emanprague/veralinkkit";
import { Attachment, Close } from "@emanprague/veralinkkit/dist/Icons";
import { ProgressBar } from "react-bootstrap";
import React from "react";
import "./fileUpload.scss";
import { observer } from "mobx-react-lite";
import classnames from "classnames";

const FileUploadProgress: React.FC<{ progressBar: number; error?: string; onRemove: () => void; file?: File }> = observer(
  ({ error, file, onRemove, progressBar }) => {
    if (!file) {
      return null;
    }

    const progressVariant = error ? "danger" : "success";

    const classes = classnames("attachment", {
      "background-gray-96": !progressBar,
      "background-red-47 attachment__error": !!error,
      "background-green-47 attachment__done": progressBar === 1 && !error,
    });

    return (
      <>
        <Row className={classes}>
          <Col xs={2}>
            <Attachment className="attachment__icon" />
          </Col>

          <Col className="attachment__name" xs={8}>
            {file.name}
          </Col>

          <Col xs={2}>
            <Button variant="link" onClick={onRemove}>
              <Close className="attachment__delete" />
            </Button>
          </Col>
          <ProgressBar className="attachment__progress" variant={progressVariant} now={progressBar * 100} />
        </Row>
        {error && <p className="attachment__error-message">{error}</p>}
      </>
    );
  }
);

export default FileUploadProgress;
