import {
  IEventBus,
  INotificationService,
  NotificationService as NotificationServiceBase,
  SeverityLevel,
} from "@emanprague/shared-services";
import { GeneralEvents } from "./events";

export default class NotificationService extends NotificationServiceBase implements INotificationService {
  constructor(eventBus: IEventBus) {
    super();
    this.subscribeToEvents(eventBus);
  }

  private subscribeToEvents(eventBus: IEventBus) {
    eventBus.subscribe(GeneralEvents.apiError, event => {
      this.addNotification(event.payload.errorMessage, SeverityLevel.critical);
    });

    eventBus.subscribe(GeneralEvents.info, event => {
      this.addNotification(event.payload, SeverityLevel.information);
    });
  }
}
