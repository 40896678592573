import { observable } from "mobx";

// This entity has been generated, do not change its content, your changes might get lost. You CAN modify the rest of the file.
export default class QuestionnaireQuestionOption {
  /**
   * Option answer ID
   */
  id!: string;

  /**
   * @example
   * Yes
   */
  @observable
  text!: string;

  static ValidationRules = {
    id: { required: true },
    text: { required: true },
  };
}
