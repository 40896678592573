import { observer } from "mobx-react-lite";
import React from "react";
import StatCard from "../components/statCard";
import NoDataPlaceholder from "../components/noDataPlaceholder";
import LoadingContainer from "views/common/loadingContainer";

import SubModuleViewModel from "viewModels/company/companyDashboard/subModuleViewModel";
import "../index.scss";

const connections: React.FC<{
  vm: SubModuleViewModel;
}> = observer(({ vm }) => {
  return (
    <LoadingContainer loading={vm.busyWatcher.isBusy}>
      <StatCard
        title={vm.translate("veralink")}
        subtitle={vm.translate("connections")}
        link={vm.translate("viewAll")}
        path="/#/dashboard/myConnections">
        {vm.connections.length > 0 ? (
          <table className="connectionsStatTable">
            <tbody>
              {vm.connections.map((item, index) => (
                <tr key={`connection_${index}`}>
                  <td>
                    <b>{item.companyName ?? "-"}</b>
                  </td>
                  <td>
                    <b>{item.grade ?? "-"}</b>
                  </td>
                  <td>{"-"}</td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          <NoDataPlaceholder text={vm.translate("noConnectionsFound")} hidden={vm.busyWatcher.isBusy} />
        )}
      </StatCard>
    </LoadingContainer>
  );
});

export default connections;
