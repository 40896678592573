import { observable } from "mobx";
import ComplianceRequirementGrades from "./complianceRequirementGrades";

// This entity has been generated, do not change its content, your changes might get lost. You CAN modify the rest of the file.
export default class ComplianceRequirementsGradeThreshold {
  @observable
  name!: ComplianceRequirementGrades;

  /**
   * Grade threshold minimum inclusive
   * @example
   * 360
   */
  @observable
  min!: number;

  /**
   * Grade threshold maximum inclusive
   * @example
   * 400
   */
  @observable
  max!: number;

  static ValidationRules = {
    name: { required: true },
    min: { required: true, number: true },
    max: { required: true, number: true },
  };
}
