import { Badge, Card, Col, RoundedButton, Row } from "@emanprague/veralinkkit";
import EditIcon from "@emanprague/veralinkkit/dist/Icons/Edit";
import { ViewComponent } from "@frui.ts/views";
import { observer } from "mobx-react-lite";
import React from "react";
import WorkerProfileViewModel from "viewModels/worker/workerProfileViewModel";
import Tooltip from "@emanprague/basekit/dist/Tooltip";

const skillsSectionView: ViewComponent<WorkerProfileViewModel> = observer(({ vm }) => {
  const { mode, item, enums } = vm;
  const isEdit = mode === "edit";

  const handleOpen = () => vm.openScreen("skills");
  const ta = vm.localization.translateAttribute;
  const tm = vm.localization.translateModel;

  return (
    <Card>
      <Card.Header>
        <Card.Title className="text-uppercase">{vm.translate("mySkills")}</Card.Title>
        {isEdit && (
          <RoundedButton onClick={handleOpen}>
            <EditIcon fontSize={20} />
          </RoundedButton>
        )}
      </Card.Header>
      <Card.Body>
        <div className="skills">
          <Row className="skills__content">
            <Col xl={4} md={12}>
              <div className="skill__item primary">
                <label>{ta("workerProfile", "professionTitle")}</label>
                <Tooltip id="title-tooltip" tooltip={item.skills.professionTitle}>
                  <b>{item.skills.professionTitle}</b>
                </Tooltip>
              </div>
            </Col>
            <Col xl={4} md={12}>
              <div className="skill__item secondary">
                <label>{ta("workerProfile", "profession")}</label>
                <Tooltip id="profession-tooltip" tooltip={enums.getProfessionById(item.skills.professionId)?.name}>
                  <b>{enums.getProfessionById(item.skills.professionId)?.name}</b>
                </Tooltip>
                <div>
                  <b>{`${item.skills.professionYears ?? "-"} ${tm("year", item.skills.professionYears ?? "-")}`}</b>
                </div>
              </div>
            </Col>
            <Col xl={4} md={12}>
              <div className="skill__item secondary">
                <label>{ta("workerProfile", "industry")}</label>
                <Tooltip id="industry-tooltip" tooltip={enums.getIndustryById(item.skills.industryId)?.name}>
                  <b>{enums.getIndustryById(item.skills.industryId)?.name}</b>
                </Tooltip>
              </div>
            </Col>
          </Row>
          <section>
            <h6>{ta("workerProfile", "skills")}</h6>
            <div>
              {item.skills.otherSkills?.map((skill, index) => (
                <Badge key={index} variant="light">
                  {skill}
                </Badge>
              ))}
            </div>
          </section>
        </div>
      </Card.Body>
    </Card>
  );
});

export default skillsSectionView;
