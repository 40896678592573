import React from "react";
import { registerView, ViewComponent } from "@frui.ts/views";
import { observer } from "mobx-react-lite";
import ProjectViewModel from "viewModels/notifications/notificationItems/projectViewModel";
import NotificationProjectContentType from "entities/notificationProjectContentType";

const projectView: ViewComponent<ProjectViewModel> = observer(({ vm }) => {
  const { notification, translate } = vm;
  const { content } = notification;

  // new connection request
  if (content.type === NotificationProjectContentType.Update) {
    return (
      <>
        <header onClick={vm.markAsRead}>
          <a href={vm.profileUrl}>{content.operatorFullName}</a>{" "}
          {translate("updated")}{" "}
          <a href={vm.projectUrl}>{content.projectName}</a>
        </header>
      </>
    );
  }

  // accepted connection request
  if (content.type === NotificationProjectContentType.Remove) {
    return (
      <>
        <header onClick={vm.markAsRead}>
          <a href={vm.profileUrl}>{content.operatorFullName}</a>{" "}
          {translate("removed")}{" "}
          <s>{content.projectName}</s>
        </header>
      </>
    );
  }

  return null;
});

registerView(projectView, ProjectViewModel);
