import { observer } from "mobx-react-lite";
import React from "react";
import StatCard from "../components/statCard";
import StatChartItem from "../components/statChartItem";
import LoadingContainer from "views/common/loadingContainer";
import SubModuleViewModel from "viewModels/company/companyDashboard/subModuleViewModel";
import "../index.scss";

const credentials: React.FC<{
  vm: SubModuleViewModel;
}> = observer(({ vm }) => {
  const tm = vm.localization.translateModel;
  return (
    <LoadingContainer loading={vm.busyWatcher.isBusy}>
      <StatCard
        title={vm.translate("worker")}
        subtitle={vm.translate("credentials")}
        link={vm.translate("viewAll")}
        path="/#/dashboard/credentials">
        {!vm.busyWatcher.isBusy && (
          <div className="statChart">
            {vm.workerCredentialsData.items.map((item, index) => (
              <StatChartItem
                key={`statChart__item-${index}`}
                label={item.label}
                count={item.value}
                total={vm.workerCredentialsData.totalEmployees}
                text={`${item.value} ${tm("worker", item.value)}`}
              />
            ))}
          </div>
        )}
      </StatCard>
    </LoadingContainer>
  );
});

export default credentials;
