import { ILocalizationService } from "@emanprague/shared-services";
import { bound } from "@frui.ts/helpers";
import { BusyWatcher, ScreenBase, watchBusy } from "@frui.ts/screens";
import { interfaces } from "inversify";
import WorkerProfileRepository from "repositories/workerProfileRepository";
import EnumsService from "services/enumsService";
import Credential, { CredentialPhoto } from "entities/credential";
import { observable, action, runInAction } from "mobx";
import { attachAutomaticValidator, validate } from "@frui.ts/validation";
import { omit } from "lodash";
import { FileBox } from "controls/fileUpload";
import { Mode, Variant } from "viewModels/types";
import UserContext from "services/userContext";
import AccountType from "entities/accountType";

export type AddCredentialState = "full" | "photo";

export default class CredentialsAddViewModel extends ScreenBase {
  navigationName = "credentialsAdd";
  busyWatcher = new BusyWatcher();

  get trainingEntities() {
    return this.enums.trainingEntities;
  }

  get credentialTypes() {
    return this.mode.variant === "worker" ? this.enums.workerCredentialTypes : this.enums.companyCredentialTypes;
  }

  @observable credential: CredentialPhoto = {} as any;
  @observable errors: { photo?: string } = { photo: undefined };
  @observable model = new FileBox<File>();

  get isDisabled() {
    return this.mode.extra === "photo";
  }

  get isWorker() {
    return this.ctx?.identity?.accountType === AccountType.Worker;
  }
  get variant() {
    return this.mode.variant;
  }

  constructor(
    private workerId: string,
    private mode: { mode: Mode; variant: Variant; extra?: AddCredentialState },
    public localization: ILocalizationService,
    private repository: WorkerProfileRepository,
    private enums: EnumsService,
    private ctx: UserContext,
    credential?: Credential
  ) {
    super();
    this.name = this.translate("title");
    let credentialPhoto = new CredentialPhoto();
    if (credential) {
      credentialPhoto = Object.assign(credentialPhoto, credential);
      if (credential.photoUrl) {
        const fileId = credentialPhoto.id as string;
        credentialPhoto.photo = new File([], fileId, {});
        this.model.file = new File([], fileId, {});
      }
    }
    this.credential = credentialPhoto;
    this.mode.extra = this.mode.extra ?? "full";

    const rules = { ...CredentialPhoto.ValidationRules, photo: { manualErrors: this.errors } };
    attachAutomaticValidator(this.credential, rules);
  }

  @bound translate(key: string) {
    return this.localization.translateGeneral(`worker.credentials.${key}`);
  }

  @bound
  async createCredential() {
    let result;
    const isValid = validate(this.credential);
    if (isValid) {
      result = await this.repository.createCredential(omit(this.credential, ["photo"]), this.workerId);
    }

    return result;
  }

  @bound
  async uploadPhoto(credentialId: string) {
    if (this.model.file) {
      return this.repository.uploadCredentialPhoto(this.model, this.workerId, credentialId);
    }

    return undefined;
  }

  @bound
  async getCredentialId() {
    let credentialId = this.credential?.id;
    if (!credentialId) {
      const result = await this.createCredential();
      if (result?.success) {
        credentialId = (result.payload as Credential).id as string;
      }

      if (!result) {
        return undefined;
      }
    }

    return credentialId;
  }

  @action.bound
  @watchBusy
  async uploadCredential() {
    const credentialId = await this.getCredentialId();

    if (credentialId && this.model?.file && this.model?.file?.name !== credentialId) {
      if (this.credential.photoUrl) {
        await this.repository.deleteCredentialPhoto(credentialId, this.workerId);
      }
      const uploadResult = await this.uploadPhoto(credentialId);

      if (uploadResult && uploadResult.success) {
        this.requestClose();
      } else {
        runInAction(() => {
          this.errors.photo = this.localization.translateGeneral("validators.fileError");
        });
      }
    } else if (!credentialId) {
      // this.requestClose();
    } else {
      this.requestClose();
    }
  }

  @action.bound
  async removeFile() {
    this.model.file = undefined;

    runInAction(() => {
      this.errors.photo = undefined;
    });
  }

  @action.bound
  replaceFile(file: File) {
    this.model.file = file;

    runInAction(() => {
      this.errors.photo = undefined;
    });
  }

  static Factory({ container }: interfaces.Context) {
    return (
      workerId: string,
      credential?: CredentialPhoto,
      mode?: { variant: Variant; mode: Mode; extra?: AddCredentialState }
    ) => {
      return new CredentialsAddViewModel(
        workerId,
        mode!,
        container.get("ILocalizationService"),
        container.get(WorkerProfileRepository),
        container.get(EnumsService),
        container.get(UserContext),
        credential
      );
    };
  }
}
