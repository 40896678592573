import { ILocalizationService, IEventBus } from "@emanprague/shared-services";
import { bound } from "@frui.ts/helpers";
import { BusyWatcher, ScreenBase, watchBusy } from "@frui.ts/screens";
import { observable, action, runInAction, computed } from "mobx";
import ComplianceRepository from "repositories/complianceRepository";
import { interfaces } from "inversify";
import InsuranceDocument from "entities/insuranceDocument";
import { FileBox } from "controls/fileUpload";
import InsuranceRequirementDocument from "entities/insuranceRequirementDocument";
import UserContext from "services/userContext";
import AccountType from "entities/accountType";
import { CompanyEvents } from "services/events";

type Model = {
  certificates: InsuranceDocument[];
  fileBox: FileBox<File>;
  insuranceRequirements: InsuranceRequirementDocument[];
};

export default class InsuranceUploadModalViewModel extends ScreenBase {
  navigationName = "insuranceUploadModal";
  busyWatcher = new BusyWatcher();
  @observable model: Model = { fileBox: new FileBox(), insuranceRequirements: [], certificates: [] };

  @observable rejectMessages: Record<string, string> = {};

  get firstDoc() {
    return this.model.certificates[0];
  }

  constructor(
    private connectionId: string,
    private operatorId: string,
    public localization: ILocalizationService,
    private repository: ComplianceRepository,
    private userContext: UserContext,
    private eventBus: IEventBus
  ) {
    super();

    this.name = this.translate("title");
  }

  onInitialize() {
    this.loadData();
  }

  @computed
  get isOperator() {
    return this.userContext.identity?.accountType === AccountType.Operator;
  }

  @bound
  @watchBusy
  async save() {
    // Upload file
    const result = await this.repository.saveInsuranceCertificate(
      this.connectionId,
      this.model.fileBox.file!.name,
      this.model.fileBox
    );
    if (result.success) {
      this.loadData();
      this.eventBus.publish(CompanyEvents.connectionPointsUpdated(undefined));
      runInAction(() => {
        this.model.fileBox.file = undefined;
      });
    }
  }

  @bound cancel() {
    this.requestClose();
  }

  @bound
  @watchBusy
  async loadData() {
    const [result, operatorResult] = await Promise.all([
      this.repository.loadInsuranceCertificates(this.connectionId),
      this.repository.loadInsuranceDocuments(this.operatorId),
    ]);

    if (result.success) {
      runInAction(() => {
        this.model.certificates = result.payload;
      });
    }

    if (operatorResult.success) {
      runInAction(() => {
        this.model.insuranceRequirements = operatorResult.payload;
      });
    }
  }

  @bound
  @watchBusy
  async submitDocumentReview(documentId: string, approved: boolean) {
    const rejectedMessage = this.rejectMessages[documentId] || "";
    const result = await this.repository.submitInsuranceReview(this.connectionId, documentId, approved, rejectedMessage);
    if (result.success) {
      this.eventBus.publish(CompanyEvents.connectionPointsUpdated(undefined));
      this.loadData();
    }
  }

  @action
  replaceFile(file: File) {
    this.model.fileBox.file = file;
  }

  @action
  removeFile() {
    this.model.fileBox.file = undefined;
  }

  @bound translate(key: string) {
    return this.localization.translateGeneral(`compliance.insurance.${key}`);
  }

  static Factory({ container }: interfaces.Context) {
    return (connectionId: string, operatorId: string) =>
      new InsuranceUploadModalViewModel(
        connectionId,
        operatorId,
        container.get("ILocalizationService"),
        container.get(ComplianceRepository),
        container.get(UserContext),
        container.get("IEventBus")
      );
  }
}
