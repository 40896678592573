import { ILocalizationService } from "@emanprague/shared-services";
import { bound } from "@frui.ts/helpers";
import { BusyWatcher, ScreenBase, watchBusy } from "@frui.ts/screens";
import { attachAutomaticValidator, hasVisibleErrors, validateAll } from "@frui.ts/validation";
import { classToClass } from "class-transformer";
import WorkerProfile from "entities/workerProfile";
import WorkerProfileSkills from "entities/workerProfileSkills";
import LocationSimple from "entities/locationSimple";
import { action, computed, observable } from "mobx";
import WorkerProfileRepository from "repositories/workerProfileRepository";
import EnumsService from "services/enumsService";
import UserContext from "services/userContext";

export default class CompleteWorkerProfileViewModel extends ScreenBase {
  navigationName = "profile";
  busyWatcher = new BusyWatcher();

  get industries() {
    return this.enums.industries;
  }

  @computed
  get professions() {
    return this.enums.professions.filter(item => item.industryId === this.model.skills.industryId);
  }

  @observable model: WorkerProfile;
  @observable errorMessage?: string;

  constructor(
    userContext: UserContext,

    public localization: ILocalizationService,
    private enums: EnumsService,
    private repository: WorkerProfileRepository
  ) {
    super();

    this.name = this.translate("title");
    this.model = classToClass(userContext.profile as WorkerProfile);

    attachAutomaticValidator(this.model.location, LocationSimple.ValidationRules);
    attachAutomaticValidator(this.model.skills, WorkerProfileSkills.ValidationRules);
  }

  get states() {
    return this.enums.states;
  }

  get canConfirm() {
    return this.model.skills && !hasVisibleErrors(this.model.skills);
  }

  @bound
  @watchBusy
  async confirm() {
    this.setErrorMessage();

    if (!validateAll([this.model.skills, this.model.location])) {
      return;
    }

    const result = await this.repository.updateWorkerProfile(this.model.id, {
      skills: this.model.skills,
      location: this.model.location,
      unionMember: this.model.unionMember,
      USMilitaryVeteran: this.model.USMilitaryVeteran,
    });

    if (result.success) {
      this.requestClose();
    } else {
      this.setErrorMessage(result.payload.errorDescription);
    }
  }

  @action.bound
  setErrorMessage(message?: string) {
    this.errorMessage = message;
  }

  @bound translate(key: string) {
    return this.localization.translateGeneral(`signup.confirm.${key}`);
  }
}
