import { IEventBus, ILocalizationService } from "@emanprague/shared-services";
import { bound } from "@frui.ts/helpers";
import { ScreenBase } from "@frui.ts/screens";
import Notification from "entities/notification";
import { interfaces } from "inversify";
import NotificationsRepository from "repositories/notificationsRepository";
import { StatusbarEvents } from "services/events";
import { runInAction } from "mobx";

import NotificationConnectionRequestContent from "entities/notificationConnectionRequestContent";

export default class WorkerJoinedViewModel extends ScreenBase {
  content: NotificationConnectionRequestContent;
  constructor(
    public notification: Notification,
    private notificationsRepository: NotificationsRepository,
    public localization: ILocalizationService,
    private eventBus: IEventBus
  ) {
    super();

    this.content = notification.content as NotificationConnectionRequestContent;
  }

  // todo: can be inherited
  @bound
  async markAsRead() {
    const result = await this.notificationsRepository.markOneRead(this.notification.id!);

    if (result.success) {
      runInAction(() => (this.notification.read = true));
      this.eventBus.publish(StatusbarEvents.notificationRead(undefined));
    }
  }

  @bound translate(key: string, placeholders?: Record<string, string>) {
    return this.localization.translateGeneral(`notifications.workerJoined.${key}`, placeholders);
  }

  static Factory({ container }: interfaces.Context) {
    return (notification: Notification) =>
      new WorkerJoinedViewModel(
        notification,
        container.get(NotificationsRepository),
        container.get("ILocalizationService"),
        container.get("IEventBus")
      );
  }
}
