import React from "react";
import { Table } from "react-bootstrap";
import classnames from "classnames";
import { observer, Observer } from "mobx-react-lite";
import OshaFormQuarter from "entities/oshaFormQuarter";
import OSHAFormQuestion from "./oshaQuestion";

import "./oshaTable.scss";

type Props = {
  questions: any;
  data: any;
  tg: (a: string) => string;
  ta: (a: string, b: string) => string;
};

const YearTotal = ({ isInput, total, answer, name, tg, id }: any) => {
  if (isInput) {
    return <OSHAFormQuestion type="number" name={name} id={id} answer={answer} tg={tg} />;
  }

  return Number.isFinite(total) ? total : 0;
};

const OshaTable: React.FC<Props> = observer(({ questions, data, tg, ta }) => {
  return (
    <Table responsive borderless className="metrics__table">
      <thead>
        <tr>
          <td colSpan={3} />
          {data.quarters.map((quarter: { quarter: number }) => (
            <td>
              <b>{`Q${quarter.quarter}`}</b>
            </td>
          ))}
          <td>
            <b>{tg("total")}</b>
          </td>
        </tr>
      </thead>

      <tbody>
        {questions.map((question: any) => {
          const classes = classnames({
            "font-weight-bold": question.variant === "bold",
          });

          return (
            <tr>
              <td colSpan={3} className="metrics__question-osha">
                <span className={classes}>{ta("oshaQuestion", question.name)}</span>
              </td>

              <Observer>
                {() =>
                  data.quarters.map((quarter: OshaFormQuarter, idx: number) => {
                    const response = question.readonly
                      ? { [question.name]: data.calculator.readOnly[question.name][idx] }
                      : quarter.questions!;
                    const isDisabled = question.name !== "workPerformed" && !(quarter.questions! as any).workPerformed;
                    return (
                      <td>
                        <OSHAFormQuestion
                          disabled={isDisabled}
                          id={`${data.year}-${quarter.quarter}-${question.name}`}
                          {...question}
                          answer={response}
                          tg={tg}
                        />
                      </td>
                    );
                  })
                }
              </Observer>

              <td>
                {question.name !== "workPerformed" && (
                  <YearTotal
                    id={`${data.year}-${question.name}-total`}
                    name={question.name}
                    isInput={data.calculator.isInput(question.name)}
                    total={data.calculator.total[question.name]}
                    answer={data.total}
                    tg={tg}
                  />
                )}
              </td>
            </tr>
          );
        })}
      </tbody>
    </Table>
  );
});

export default OshaTable;
