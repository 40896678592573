// This entity has been generated, do not change its content, your changes might get lost. You CAN modify the rest of the file.
enum NotificationType {
  CredentialExpire = "CREDENTIAL_EXPIRE",
  Project = "PROJECT",
  WorkerJoined = "WORKER_JOINED",
  ProjectRequirement = "PROJECT_REQUIREMENT",
  ConnectionRequest = "CONNECTION_REQUEST",
  ComplianceRequirement = "COMPLIANCE_REQUIREMENT",
}
export default NotificationType;
