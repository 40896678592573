import { Transform } from "class-transformer";
import { observable } from "mobx";
import { formatISO } from "date-fns";
import parseISO from "date-fns/parseISO";

// This entity has been generated, do not change its content, your changes might get lost. You CAN modify the rest of the file.
export default class CompanyEmployeeByCredentialCredentialsItem {
  id!: string;

  @observable
  photoUrl?: string;

  @observable
  credentialName!: string;

  @observable
  credentialType!: string;

  @observable
  trainingEntityName!: string;

  @observable
  @Transform(value => (value ? (typeof value === "string" ? parseISO(value) : value) : undefined), { toClassOnly: true })
  @Transform(value => (value ? formatISO(value, { representation: "date" }) : undefined), { toPlainOnly: true })
  validityFrom!: Date;

  @observable
  @Transform(value => (value ? (typeof value === "string" ? parseISO(value) : value) : undefined), { toClassOnly: true })
  @Transform(value => (value ? formatISO(value, { representation: "date" }) : undefined), { toPlainOnly: true })
  validityTo!: Date;

  @observable
  verified!: boolean;

  /**
   * If credential is verified, this field includes date-time of last verification
   */
  @observable
  verifiedAt?: string;

  static ValidationRules = {
    id: { required: true },
    credentialName: { required: true },
    credentialType: { required: true },
    trainingEntityName: { required: true },
    validityFrom: { required: true },
    validityTo: { required: true },
    verified: { required: true },
  };
}
