import React from "react";
import UserAvatarPlaceholder from "@emanprague/veralinkkit/dist/Icons/User";
import classnames from "classnames";
import "./userAvatar.scss";

type Variant = "default" | "circle";

type Props = {
  url?: string;
  variant?: Variant;
  className?: string;
};

const UserAvatar: React.FC<Props> = ({ variant = "circle", url, className }) => {
  const classNames = classnames(
    {
      user__avatar: variant === "circle",
    },
    className
  );

  if (!url) {
    return <UserAvatarPlaceholder className={classNames} fontSize={40} />;
  }
  return <img src={url} alt="Avatar" className={classNames} />;
};

export default UserAvatar;
