import { observable } from "mobx";

// This entity has been generated, do not change its content, your changes might get lost. You CAN modify the rest of the file.
export default class TrainingEntity {
  id!: string;

  @observable
  name!: string;

  @observable
  phoneNumber!: string;

  @observable
  email?: string;

  @observable
  webPage?: string;

  @observable
  address!: string;

  static ValidationRules = {
    id: { required: true },
    name: { required: true },
    phoneNumber: { required: true },
    email: { format: "email" },
    address: { required: true },
  };
}
