import { ILocalizationService, IEventBus } from "@emanprague/shared-services";
import { bound } from "@frui.ts/helpers";
import { BusyWatcher, ScreenBase, watchBusy } from "@frui.ts/screens";
import { interfaces } from "inversify";
import { observable, observe, Lambda, computed } from "mobx";
import ComplianceRequirementsPointsAllocation, {
  CompliancePointsAllocationValidation,
} from "entities/complianceRequirementsPointsAllocation";
import ComplianceRepository from "repositories/complianceRepository";
import { isNull, isUndefined } from "util";
import { ComplianceDefinitionEvents } from "services/events";
import { attachAutomaticValidator, validate } from "@frui.ts/validation";

type InputNames = keyof ComplianceRequirementsPointsAllocation;
const maxPoints = 400;

export default class PointsAllocationEditViewModel extends ScreenBase {
  navigationName = "pointsAllocationEdit";
  busyWatcher = new BusyWatcher();
  @observable allocations: ComplianceRequirementsPointsAllocation;
  @observable requiredFields: any = {};
  eventListeners: Lambda[] = [];

  @computed get total() {
    return this.inputNames.reduce((acc: number, name) => {
      let val = this.allocations[name];

      if (typeof val === "string") {
        val = parseInt(val);
      }

      return acc + (val ?? 0);
    }, 0);
  }

  @computed get inputNames() {
    return Object.keys(this.allocations ?? {}).filter(key => !key.includes("__")) as InputNames[];
  }

  constructor(
    private companyId: string,
    allocations: ComplianceRequirementsPointsAllocation,
    public localization: ILocalizationService,
    private repository: ComplianceRepository,
    private eventBus: IEventBus
  ) {
    super();

    this.name = this.translate("title");
    this.allocations = { ...allocations };
    this.requiredFields = this.inputNames.reduce((acc, nxt) => {
      return { ...acc, [nxt]: !(isNull(this.allocations[nxt]) || isUndefined(this.allocations[nxt])) };
    }, {});

    this.allocations = attachAutomaticValidator(this.allocations, CompliancePointsAllocationValidation);

    this.eventListeners = this.inputNames.map(name => {
      return observe(this.requiredFields, name, change => {
        if (!change.newValue) {
          this.allocations[name] = undefined;
        }
      });
    });
  }

  @bound translate(key: string, params: any = {}) {
    return this.localization.translateGeneral(`compliance.pointsAllocationEdit.${key}`, params);
  }

  onDeactivate() {
    this.eventListeners.forEach(listener => {
      listener && listener();
    });
  }

  get isValid() {
    return this.total === maxPoints;
  }

  @bound
  @watchBusy
  async save() {
    const valid = validate(this.allocations);
    if (!valid) {
      return;
    }

    if (this.total === maxPoints) {
      const result = await this.repository.savePointsAllocations(this.companyId, this.allocations);

      if (result.success) {
        this.eventBus.publish(ComplianceDefinitionEvents.pointsAllocationUpdated(result.payload));
        this.requestClose();
      }
    }
  }

  @bound cancel() {
    this.requestClose();
  }

  static Factory({ container }: interfaces.Context) {
    return (companyId: string, allocations: ComplianceRequirementsPointsAllocation) =>
      new PointsAllocationEditViewModel(
        companyId,
        allocations,
        container.get("ILocalizationService"),
        container.get(ComplianceRepository),
        container.get("IEventBus")
      );
  }
}
