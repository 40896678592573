import { Button, LoaderOverlay, Form, Row, Col } from "@emanprague/veralinkkit";
import { Input, Select } from "@frui.ts/bootstrap";
import { registerView } from "@frui.ts/views";
import React from "react";
import Plus from "@emanprague/veralinkkit/dist/Icons/Plus";
import ModalView, { ModalSize } from "views/common/modalView";
import Delete from "@emanprague/veralinkkit/dist/Icons/Delete";
import CompanyLocationsViewModel from "viewModels/company/companyInfo/modals/companyLocationsViewModel";
import "./modals.scss";

class CompanyLocationsModalView extends ModalView<CompanyLocationsViewModel> {
  size: ModalSize = "lg";

  renderBody(vm: CompanyLocationsViewModel) {
    const ta = (code: string) => vm.localization.translateAttribute("companyLocation", code);

    return (
      <LoaderOverlay className="company-info__modal" loading={vm.busyWatcher.isBusy} variant="light">
        {vm.locations.map((location, index) => (
          <div key={index} className="company-info__modal-location">
            <Row>
              <h6>
                {vm.translate("location")} {index + 1}
              </h6>
              <Delete fontSize={24} color="#888F91" onClick={() => vm.markToDelete(location)} />
            </Row>

            <Row>
              <Form.Group>
                <Form.Label>{ta("name")}</Form.Label>
                <Input type="text" placeholder={ta("nameHint")} target={location} property="name" />
              </Form.Group>

              <Form.Group>
                <Form.Label>{ta("street")}</Form.Label>
                <Input type="text" placeholder={ta("streetHint")} target={location} property="street" />
              </Form.Group>

              <Row>
                <Col md={5}>
                  <Form.Group>
                    <Form.Label>{ta("city")}</Form.Label>
                    <Input type="text" target={location} property="city" />
                  </Form.Group>
                </Col>
                <Col md={5}>
                  <Form.Group>
                    <Form.Label>{ta("state")}</Form.Label>
                    <Select
                      mode="key"
                      value={location?.state}
                      target={location}
                      property="state"
                      items={vm.states}
                      keyProperty="id"
                      textProperty="name"
                    />
                  </Form.Group>
                </Col>
                <Col md={2}>
                  <Form.Group>
                    <Form.Label>{ta("zip")}</Form.Label>
                    <Input type="text" target={location} property="zip" />
                  </Form.Group>
                </Col>
              </Row>
            </Row>
          </div>
        ))}

        <Row>
          <Button variant="outline-primary" onClick={vm.addEmptyLocation}>
            <Plus fontSize={20} />
            {vm.translate("addLocation")}
          </Button>
        </Row>
      </LoaderOverlay>
    );
  }

  renderFooter(vm: CompanyLocationsViewModel) {
    return (
      <Button variant="primary" disabled={vm.busyWatcher.isBusy || !vm.canSave} onClick={vm.save}>
        {vm.translate("save")}
      </Button>
    );
  }
}

registerView(CompanyLocationsModalView as any, CompanyLocationsViewModel);
