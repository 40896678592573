import { ILocalizationService } from "@emanprague/shared-services";
import { bound } from "@frui.ts/helpers";
import { BusyWatcher, ScreenBase, watchBusy } from "@frui.ts/screens";
import { attachAutomaticValidator, validateAll } from "@frui.ts/validation";
import CompanyProfile from "entities/companyProfile";
import CompanyService from "entities/companyService";
import { interfaces } from "inversify";
import { action, observable } from "mobx";
import CompanyProfileRepository from "repositories/companyProfileRepository";

export default class CompanyServicesViewModel extends ScreenBase {
  navigationName = "services";
  busyWatcher = new BusyWatcher();

  @observable model: CompanyService[];

  constructor(
    private originalProfile: CompanyProfile,
    private onSuccess: () => void,
    private companyProfileRepository: CompanyProfileRepository,
    public localization: ILocalizationService
  ) {
    super();
    this.name = this.translate("title");

    this.model = originalProfile.services?.map(service => ({ name: service })) || [];
    this.model.forEach(service => attachAutomaticValidator(service, CompanyService.ValidationRules));
  }

  @bound
  @watchBusy
  async save() {
    if (validateAll(this.model)) {
      const result = await this.companyProfileRepository.updateServices(
        this.originalProfile.id,
        this.model.map(s => s.name)
      );

      if (result.success) {
        this.onSuccess();
        this.requestClose();
      }
    }
  }

  @action.bound
  addService() {
    const newService = new CompanyService();
    attachAutomaticValidator(newService, CompanyService.ValidationRules);
    this.model.push(newService);
  }

  @action.bound
  removeService(index: number) {
    this.model.splice(index, 1);
  }

  @bound translate(key: string) {
    return this.localization.translateGeneral(`companyInfo.companyServicesModal.${key}`);
  }

  static Factory({ container }: interfaces.Context) {
    return (originalProfile: CompanyProfile, onSuccess: () => void) =>
      new CompanyServicesViewModel(
        originalProfile,
        onSuccess,
        container.get(CompanyProfileRepository),
        container.get("ILocalizationService")
      );
  }
}
