import { ILocalizationService } from "@emanprague/shared-services";
import { DetailViewModel } from "@frui.ts/datascreens";
import { bound } from "@frui.ts/helpers";
import { BusyWatcher, watchBusy } from "@frui.ts/screens";
import { attachAutomaticValidator, validate } from "@frui.ts/validation";
import { classToClass } from "class-transformer";
import WorkerProfileExperience from "entities/workerProfileExperience";
import { interfaces } from "inversify";
import { IReactionDisposer, reaction, runInAction } from "mobx";
import WorkerProfileRepository from "repositories/workerProfileRepository";
import EnumsService from "services/enumsService";

export default class ExperienceDetailViewModel extends DetailViewModel<WorkerProfileExperience> {
  busyWatcher = new BusyWatcher();
  mode: "create" | "edit";
  reactions: IReactionDisposer[] = [];

  get professions() {
    return this.enums.professions;
  }

  constructor(
    private workerProfileId: string,
    item: WorkerProfileExperience | undefined,
    public localization: ILocalizationService,
    private repository: WorkerProfileRepository,
    private enums: EnumsService
  ) {
    super();
    if (item) {
      this.mode = "edit";
      this.navigationName = "edit-experience";
      this.setItem(classToClass(item));
    } else {
      this.mode = "create";
      this.navigationName = "experience";
      this.setItem(new WorkerProfileExperience());
    }
    this.name = this.translate(`${this.mode}Title`);
    attachAutomaticValidator(this.item, WorkerProfileExperience.ValidationRules);
  }

  async onInitialize() {
    super.onInitialize();

    reaction(
      () => this.item.currentJob,
      (value: boolean) => this.clearTo(value)
    );

    reaction(
      () => this.item.toDate,
      (value: Date | undefined) => this.clearCurrentJob(value)
    );
  }

  clearCurrentJob(date: Date | undefined) {
    if (date) {
      runInAction(() => {
        this.item.currentJob = false;
      });
    }
  }

  onDeactivate() {
    this.reactions.forEach(x => x());
  }

  clearTo(currentJob: boolean) {
    if (currentJob) {
      runInAction(() => {
        this.item.toDate = undefined;
      });
    }
  }

  protected loadDetail() {
    return this.item;
  }

  @watchBusy
  async add() {
    const result = await this.repository.addExperience(this.workerProfileId, this.item);
    if (result.success) {
      this.requestClose();
    }
  }

  @watchBusy
  async edit() {
    const result = await this.repository.editExperience(this.workerProfileId, this.item);
    if (result.success) {
      this.requestClose();
    }
  }

  @bound
  save() {
    if (validate(this.item)) {
      if (this.mode === "edit") {
        this.edit();
      } else {
        this.add();
      }
    }
  }

  @bound translate(key: string) {
    return this.localization.translateGeneral(`worker.experienceDetail.${key}`);
  }

  static Factory({ container }: interfaces.Context) {
    return (workerProfileId: string, item?: WorkerProfileExperience) =>
      new ExperienceDetailViewModel(
        workerProfileId,
        item,
        container.get("ILocalizationService"),
        container.get(WorkerProfileRepository),
        container.get(EnumsService)
      );
  }
}
