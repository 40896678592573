import { observable } from "mobx";
import { Exclude } from "class-transformer";

// This entity has been generated, do not change its content, your changes might get lost. You CAN modify the rest of the file.
class UserSettingsPassword {
  /**
   * Old password
   */
  @observable
  oldPassword!: string;

  /**
   * New password
   */
  @observable
  newPassword!: string;

  static ValidationRules = {
    oldPassword: { required: true },
    newPassword: { required: true },
  };
}

export default class UserSettingsPasswordRepeated extends UserSettingsPassword {
  @Exclude()
  @observable
  repeatPassword!: string;

  static ValidationRules = {
    oldPassword: { required: true },
    newPassword: { required: true },
    repeatPassword: { required: true, equalWith: "newPassword" },
  };
}
