import { ILocalizationService } from "@emanprague/shared-services";
import { bound } from "@frui.ts/helpers";
import { BusyWatcher, Router, ScreenBase } from "@frui.ts/screens";
import AccountType from "entities/accountType";
import CompanyFormViewModel from "./companyFormViewModel";
import WorkerFormViewModel from "./workerFormViewModel";

export default class SelectRoleViewModel extends ScreenBase {
  navigationName = "select-role";
  busyWatcher = new BusyWatcher();
  roles: AccountType[] = [AccountType.Worker, AccountType.Operator, AccountType.Contractor];
  constructor(public localization: ILocalizationService, private router: Router) {
    super();

    this.name = this.translate("title");
  }

  getFormUrl(role: AccountType) {
    if (role === AccountType.Worker) {
      return this.router.getUrl(WorkerFormViewModel);
    } else {
      return this.router.getUrl(CompanyFormViewModel, undefined, { role: role });
    }
  }

  @bound translate(key: string) {
    return this.localization.translateGeneral(`signup.selectRole.${key}`);
  }
}
