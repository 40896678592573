import RepositoryBase from "./repositoryBase";
import { IPagingFilter } from "@frui.ts/data";
import ContractorConnections from "entities/contractorConnections";
import ContractorConnectionSimple from "entities/contractorConnectionSimple";
import ContractorConnection from "entities/contractorConnection";

export default class ConnectionsRepository extends RepositoryBase {
  loadConnections(paging: IPagingFilter) {
    return this.callApi(api =>
      api.all("userprofile/contractor_connections").getPagedEntities(ContractorConnections, x => x.connections, paging)
    );
  }

  loadConnection(connectionId: string) {
    return this.callApi(api => api.one("userprofile/contractor_connections", connectionId).getEntity(ContractorConnection));
  }

  deleteConnections(connectionId: string) {
    return this.callApi(api => api.one("userprofile/contractor_connections", connectionId).delete());
  }
  acceptConnections(connectionId: string) {
    const conn = new ContractorConnectionSimple();
    conn.connectionId = connectionId;
    return this.callApi(api => api.all("userprofile/contractor_connections/accept").postEntity(conn));
  }

  acceptJoinConnection(connectionId: string) {
    return this.callApi(api => api.all(`userprofile/connections/${connectionId}/accept`).post({}));
  }
}
