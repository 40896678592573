import { IEventBus, ILocalizationService } from "@emanprague/shared-services";
import { bound, IDisposable } from "@frui.ts/helpers";
import { BusyWatcher, ScreenBase, watchBusy } from "@frui.ts/screens";
import ProjectRepository from "repositories/projectRepository";
import { FilteredListViewModel } from "@frui.ts/datascreens";
import CompanyProjectDocument from "entities/companyProjectDocument";
import { interfaces } from "inversify";
import UploadDocumentViewModel from "viewModels/company/projects/documents/uploadDocumentViewModel";
import { API_URI } from "config";
import { ProjectsEvents } from "services/events";
import DialogService from "services/dialogService";

export default class DocumentsListViewModel extends FilteredListViewModel<CompanyProjectDocument, any, ScreenBase> {
  navigationName = "documents";
  busyWatcher = new BusyWatcher();
  private eventHandlers: IDisposable[];

  constructor(
    private companyId: string,
    private projectId: string,
    public projectOwner: boolean,
    public localization: ILocalizationService,
    private repository: ProjectRepository,
    private uploadDocumentFactory: ReturnType<typeof UploadDocumentViewModel.Factory>,
    private eventBus: IEventBus,
    private dialog: DialogService
  ) {
    super();

    this.name = this.translate("title");

    this.eventHandlers = [this.eventBus.subscribe(ProjectsEvents.documentsUpdated, this.loadData)];
  }

  onInitialize() {
    this.loadData();
  }

  protected async onDeactivate(isClosing: boolean) {
    await super.onDeactivate(isClosing);

    if (isClosing) {
      this.eventHandlers?.forEach(x => x.dispose());
    }
  }

  @bound
  @watchBusy
  async loadData() {
    const result = await this.repository.getProjectDocuments(this.companyId, this.projectId);
    if (result.success) {
      this.setData([result.payload, this.currentPaging]);
    }
  }

  @bound
  @watchBusy
  async downloadProjectDocument(documentId: string) {
    const response = await this.repository.downloadProjectDocument(this.companyId, this.projectId, documentId);
    if (response.success && response.payload) {
      const url = new URL(response.payload.url);
      window.open(`${API_URI}${url.pathname}`, "_blank");
    }
  }

  @bound
  @watchBusy
  async deleteProjectDocument(documentId: string) {
    const confirmationResult = await this.dialog.showConfirmation(
      this.translate("deleteMessage"),
      this.translate("deleteTitle"),
      this.translate("deleteOk"),
      this.translate("deleteCancel")
    );
    if (confirmationResult) {
      return this.repository.deleteProjectDocument(this.companyId, this.projectId, documentId);
    }
  }

  @bound
  openUploadDocument() {
    const vm = this.uploadDocumentFactory(this.companyId, this.projectId);
    this.tryActivateChild(vm);
  }

  @bound translate(key: string) {
    return this.localization.translateGeneral(`projects.documents.${key}`);
  }

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  protected resetFilterValues(filter: any) {}

  static Factory({ container }: interfaces.Context) {
    return (companyId: string, projectId: string, projectOwner: boolean) =>
      new DocumentsListViewModel(
        companyId,
        projectId,
        projectOwner,
        container.get("ILocalizationService"),
        container.get(ProjectRepository),
        container.get(UploadDocumentViewModel.Factory),
        container.get("IEventBus"),
        container.get(DialogService)
      );
  }
}
