import React from "react";
import { registerView } from "@frui.ts/views";
import { Input, Select } from "@frui.ts/bootstrap";
import { Button, Col, Form, LoaderOverlay, Row } from "@emanprague/veralinkkit";

import ExperienceDetailViewModel from "viewModels/worker/modals/experienceDetailViewModel";
import ModalView from "views/common/modalView";
import FormCheck from "controls/formCheck";
import CustomInput from "controls/input";

class ExperienceDetailModalView extends ModalView<ExperienceDetailViewModel> {
  renderBody(vm: ExperienceDetailViewModel) {
    const ta = vm.localization.translateAttribute;
    return (
      <LoaderOverlay loading={vm.busyWatcher.isBusy} variant="light">
        <Row>
          <Col xs={12}>
            <Form.Group>
              <Form.Label>{ta("experience", "company")}</Form.Label>
              <Input type="text" target={vm.item} property="company" />
            </Form.Group>
          </Col>
          <Col xs={12}>
            <Form.Group>
              <Form.Label>
                {ta("experience", "employeeId")}
                <span className="optional-text"> {ta("skills", "optional")} </span>
              </Form.Label>
              <Input disabled type="text" target={vm.item} property="employeeId" />
            </Form.Group>
          </Col>
          <Col xs={12}>
            <Form.Group>
              <Form.Label>{ta("experience", "role")}</Form.Label>
              <Select
                mode="key"
                target={vm.item}
                property="role"
                value={vm.item.role}
                items={vm.professions}
                textProperty="name"
              />
            </Form.Group>
          </Col>
          <Col xs={6}>
            <Form.Group>
              <Form.Label>{ta("experience", "fromDate")}</Form.Label>
              <CustomInput type="date" target={vm.item} property="fromDate" />
            </Form.Group>
          </Col>
          <Col xs={6}>
            {!vm.item.currentJob && (
              <Form.Group>
                <Form.Label>{ta("experience", "toDate")}</Form.Label>
                <CustomInput type="date" target={vm.item} property="toDate" />
              </Form.Group>
            )}
          </Col>
          <Col xs={6}>
            <Form.Group>
              <FormCheck
                label={ta("experience", "currentJob")}
                id="currentJob"
                target={vm.item}
                property="currentJob"
                type="checkbox"
              />
            </Form.Group>
          </Col>
          <Col xs={12}>
            <Form.Group>
              <Form.Label>
                {ta("experience", "description")}
                {<span className="optional-text"> {ta("skills", "optional")} </span>}
              </Form.Label>
              <Input type="text" as="textarea" target={vm.item} property="description" />
            </Form.Group>
          </Col>
        </Row>
      </LoaderOverlay>
    );
  }

  renderFooter(vm: ExperienceDetailViewModel) {
    return (
      <Button variant="primary" disabled={vm.busyWatcher.isBusy} onClick={vm.save}>
        {vm.translate("save")}
      </Button>
    );
  }
}

registerView(ExperienceDetailModalView as any, ExperienceDetailViewModel);
