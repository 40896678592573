import { Button, LoaderOverlay } from "@emanprague/veralinkkit";
import { registerView } from "@frui.ts/views";
import React from "react";
import RemoveWorkerViewModel from "viewModels/company/employees/removeWorkerViewModel";
import ModalView, { ModalSize } from "views/common/modalView";

class RemoveWorkerModalView extends ModalView<RemoveWorkerViewModel> {
  size: ModalSize = "lg";
  renderBody(vm: RemoveWorkerViewModel) {
    const { firstName, middleName, lastName, currentProjects } = vm.worker;
    return (
      <LoaderOverlay loading={vm.busyWatcher.isBusy} variant="light">
        <div className="removeEmployee__content">
          <h5>{vm.translate("areYouSure")}</h5>
          <p>{`${firstName} ${middleName ?? ""} ${lastName} ${vm.translate("currentlyWorking")}:`}</p>
          {currentProjects.map(({ name, id }) => (
            <h6 key={id}>{name}</h6>
          ))}
          {currentProjects.length === 0 && " - "}
          <p>{vm.translate("note")}</p>
        </div>
      </LoaderOverlay>
    );
  }

  renderFooter(vm: RemoveWorkerViewModel) {
    return (
      <>
        <Button variant="outline-primary" disabled={vm.busyWatcher.isBusy} onClick={() => vm.requestClose()}>
          {vm.translate("cancel")}
        </Button>
        <Button variant="primary" disabled={vm.busyWatcher.isBusy} onClick={vm.removeWorker}>
          {vm.translate("remove")}
        </Button>
      </>
    );
  }
}

registerView(RemoveWorkerModalView as any, RemoveWorkerViewModel);
