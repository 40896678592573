import { Card, RoundedButton, Badge } from "@emanprague/veralinkkit";
import { ViewComponent } from "@frui.ts/views";
import React from "react";
import EditIcon from "@emanprague/veralinkkit/dist/Icons/Edit";
import { observer } from "mobx-react-lite";
import CompanyProfileViewModel from "viewModels/company/companyInfo/companyProfileViewModel";

const CompanyServicesSection: ViewComponent<CompanyProfileViewModel> = observer(({ vm }) => {
  const { translateGeneral } = vm.localization;
  const tg = (code: string) => translateGeneral(`companyInfo.subModule.${code}`);

  return (
    <Card className="company-info__card">
      <Card.Header>
        <h4>{tg("services")}</h4>
        {vm.isEdit && (
          <RoundedButton onClick={vm.openEditCompanyServices}>
            <EditIcon fontSize={20} />
          </RoundedButton>
        )}
      </Card.Header>
      <Card.Body>
        {vm.companyProfile.services?.map((skill, index) => (
          <Badge key={index} variant="light">
            {skill}
          </Badge>
        ))}
      </Card.Body>
    </Card>
  );
});

export default CompanyServicesSection;
