import { observable } from "mobx";

// This entity has been generated, do not change its content, your changes might get lost. You CAN modify the rest of the file.
export default class WorkerProfileEducation {
  /**
   * Education ID (read-only)
   */
  id?: string;

  /**
   * Institution
   */
  @observable
  institution!: string;

  /**
   * Field of study
   */
  @observable
  fieldOfStudy?: string;

  /**
   * Degree
   */
  @observable
  degree?: string;

  /**
   * Start year
   */
  @observable
  startYear!: number;

  /**
   * End year
   */
  @observable
  endYear!: number;

  static ValidationRules = {
    institution: { required: true },
    endYear: { required: true, notBeOlderThan: { min: 1920 } },
  };
}
