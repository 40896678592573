import { BusyWatcher, ScreenBase } from "@frui.ts/screens";
import { action, observable } from "mobx";
import ButtonParams from "models/buttonParams";

export default class ConfirmDialogViewModel extends ScreenBase {
  @observable isOpened = false;
  private onResult: (value: boolean) => void;

  navigationName = "confirmation";
  busyWatcher = new BusyWatcher();

  constructor(
    public message: string,
    public title: string | undefined,
    public confirmButtonParams: ButtonParams,
    public cancelButtonParams: ButtonParams,
    public settings: { closeButton?: boolean } = { closeButton: true }
  ) {
    super();

    this.name = this.title ?? "";
  }

  @action.bound public show() {
    this.isOpened = true;
    return new Promise<boolean>(resolve => {
      this.onResult = resolve;
    });
  }

  @action.bound confirm() {
    this.isOpened = false;
    this.onResult(true);
  }

  @action.bound cancel() {
    this.isOpened = false;
    this.onResult(false);
  }

  requestClose() {
    this.cancel();
    return true;
  }
}
