import { Form, FormControlProps, InputGroup } from "@emanprague/veralinkkit";
import { bound } from "@frui.ts/helpers";
import { Observer } from "mobx-react-lite";
import React, { ReactNode } from "react";
import { CommonInputProps } from "@frui.ts/bootstrap/dist/controls/commonInputProps";
import { ValidationControlBase } from "@frui.ts/bootstrap";
import parseISO from "date-fns/parseISO";
import formatISO from "date-fns/formatISO";

export interface IInputProps {
  label?: string;
  as?: "textarea";
  rows?: number;
  step?: string;
  append?: ReactNode;
  prepend?: ReactNode;
}

export default class Input<TTarget> extends ValidationControlBase<TTarget, IInputProps & FormControlProps & CommonInputProps> {
  render() {
    return <Observer render={this.renderInner} />;
  }

  @bound protected renderInner() {
    const validationError = this.getValidationError();
    const { prepend, append, label, as, rows, type } = this.props;

    let value: string | number | string[] | undefined;
    if (this.value === undefined || this.value === null) {
      value = "";
    } else {
      value = type === "date" ? formatISO(this.value, { representation: "date" }) : this.value;
    }

    const control = (
      <Form.Control
        {...this.inheritedProps}
        as={as}
        rows={rows}
        value={value}
        onChange={this.handleValueChanged}
        isInvalid={!!validationError}
        append={undefined}
      />
    );
    const feedback = <Form.Control.Feedback type="invalid">{validationError}</Form.Control.Feedback>;
    const hasElements = append || prepend;

    return (
      <Form.Group>
        {label && <Form.Label>{label}</Form.Label>}

        {hasElements ? (
          <InputGroup>
            {prepend}
            {control}
            {append}
            {feedback}
          </InputGroup>
        ) : (
          <>
            {control}
            {feedback}
          </>
        )}
      </Form.Group>
    );
  }

  @bound protected handleValueChanged(e: React.FormEvent<any>) {
    const target = e.target as HTMLInputElement;
    const value = target.value;
    if (this.props.type === "number") {
      this.setValue(value ? parseFloat(value) : value);
    } else if (this.props.type === "date") {
      if (value && value.length >= 8) {
        const date = parseISO(value);
        if (!isNaN(date.valueOf())) {
          this.setValue(date);
          return;
        }
      }

      this.setValue(value === "" ? undefined : value);
    } else {
      this.setValue(value);
    }
  }
}
