import React from "react";

import "../index.scss";

const projectRow: React.FC<{
  name: string;
  interval: string;
  complete: number;
}> = ({ name, interval, complete }) => {
  return (
    <div className="projectRow">
      <div className="projectRow__content">
        <b>{name}</b>
        <span>{interval}</span>
      </div>
      <div className="projectRow__percentage">
        <div className="percentageValue" style={{ width: `${complete}%` }}>
          &nbsp;
        </div>
      </div>
    </div>
  );
};

export default projectRow;
