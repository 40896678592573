import { IEventBus } from "@emanprague/shared-services";
import { watchBusy } from "@frui.ts/screens";
import { plainToClass } from "class-transformer";
import AccountType from "entities/accountType";
import AuthenticateUser from "entities/authenticateUser";
import AuthenticationCredentials from "entities/authenticationCredentials";
import AuthenticationIdentity from "entities/authenticationIdentity";
import CompanyProfile from "entities/companyProfile";
import VerifyUser from "entities/verifyUser";
import WorkerProfile from "entities/workerProfile";
import { action } from "mobx";
import IdentityModel from "models/identityModel";
import CompanyProfileRepository from "repositories/companyProfileRepository";
import LoginRepository from "repositories/loginRepository";
import SignupRepository from "repositories/signupRepository";
import WorkerProfileRepository from "repositories/workerProfileRepository";
import { CompanyEvents, WorkerEvents } from "./events";
import StorageProvider from "./storageProvider";
import UserContext from "./userContext";

const STORAGE_KEY = "identity";
type DeferredLoginResult = { identity?: AuthenticationIdentity; applyIdentity: () => void };

export default class SecurityService {
  private AnonymousLoginResult: DeferredLoginResult = { identity: undefined, applyIdentity: this.logout };

  constructor(
    private loginRepository: LoginRepository,
    private workerProfileRepository: WorkerProfileRepository,
    private companyProfileRepository: CompanyProfileRepository,
    private signupRepository: SignupRepository,
    private storage: StorageProvider,
    private userContext: UserContext,
    eventBus: IEventBus
  ) {
    eventBus.subscribe(WorkerEvents.profileUpdated, this.handleWorkerProfileUpdated);
    eventBus.subscribe(CompanyEvents.profileUpdated, this.handleCompanyProfileUpdated);
  }

  @action
  async checkStoredIdentity(): Promise<DeferredLoginResult> {
    const credentials = this.loadCredentials();
    if (credentials) {
      return await this.processCredentials(credentials, false);
    }

    return this.AnonymousLoginResult;
  }

  /** Returns string with error message or undefined when login is successful  */
  async logIn(login: string, password: string): Promise<string | undefined> {
    const loginPayload = plainToClass(AuthenticateUser, { username: login, password });
    const credentialsResult = await this.loginRepository.login(loginPayload);

    if (!credentialsResult.success) {
      return credentialsResult.payload?.errorDescription;
    }

    const result = await this.processCredentials(credentialsResult.payload);

    if (result.identity) {
      result.applyIdentity();
    } else {
      return "Could not load your profile";
    }
  }

  /** Returns string with error message or undefined when login is successful  */
  validateUserEmail(token: string) {
    const payload = new VerifyUser();
    payload.verificationToken = token;
    return this.signupRepository.verifyUserToken(payload);
  }

  /** Returns string with error message or undefined when login is successful  */
  async validateConfirmToken(token: string): Promise<string | undefined> {
    const payload = new VerifyUser();
    payload.verificationToken = token;
    const credentialsResult = await this.signupRepository.verifyUserToken(payload);

    if (credentialsResult.success) {
      const loginResult = await this.processCredentials(credentialsResult.payload, true);
      loginResult.applyIdentity();
    } else {
      return credentialsResult.payload.errorDescription;
    }
  }

  @action
  private async processCredentials(credentials: AuthenticationCredentials, persistLogin = true): Promise<DeferredLoginResult> {
    this.userContext.token = credentials.accessToken;

    const profile = await this.loadProfile(credentials.identity);
    if (!profile) {
      return { identity: undefined, applyIdentity: this.logout };
    }

    return {
      identity: credentials.identity,
      applyIdentity: action(() => {
        if (persistLogin) {
          this.storeCredentials(credentials);
        }

        this.userContext.identity = credentials.identity;
        this.userContext.profile = profile;
      }),
    };
  }

  @action.bound
  logout() {
    if (this.userContext.isLogged) {
      this.storage.clearAll();
      this.userContext.token = undefined;
      this.userContext.identity = undefined;
      this.userContext.profile = undefined;
    }
  }

  private async loadProfile(identity: AuthenticationIdentity): Promise<WorkerProfile | CompanyProfile | undefined> {
    switch (identity.accountType) {
      case AccountType.Worker:
        const workerResult = await this.workerProfileRepository.getProfile(identity.accountId);
        return workerResult.success ? workerResult.payload : undefined;
      case AccountType.Contractor:
      case AccountType.Operator:
        const companyResult = await this.companyProfileRepository.getProfile(identity.accountId);
        return companyResult.success ? companyResult.payload : undefined;
      default:
        return undefined;
    }
  }

  @action.bound private handleWorkerProfileUpdated(event: { payload: WorkerProfile }) {
    const identity = this.userContext.identity;

    if (event.payload && identity?.accountType === AccountType.Worker && identity?.accountId === event.payload.id) {
      this.userContext.profile = event.payload;
    }
  }

  @action.bound private handleCompanyProfileUpdated(event: { payload: CompanyProfile }) {
    const identity = this.userContext.identity;

    if (
      event.payload &&
      (identity?.accountType === AccountType.Contractor || identity?.accountType === AccountType.Operator) &&
      identity?.accountId === event.payload.id
    ) {
      this.userContext.profile = event.payload;
    }
  }

  private storeCredentials(credentials: AuthenticationCredentials) {
    const model = {
      token: credentials.accessToken,
      ...credentials.identity,
    } as IdentityModel;

    this.storage.set(STORAGE_KEY, model);
  }

  private loadCredentials(): AuthenticationCredentials | undefined {
    const model = this.storage.get<IdentityModel>(STORAGE_KEY);

    if (model) {
      return {
        accessToken: model.token,
        identity: {
          accountId: model.accountId,
          accountType: model.accountType,
          userId: model.userId,
        },
      };
    } else {
      return undefined;
    }
  }

  @watchBusy
  completeConnectionRequest(importToken: string) {
    return this.loginRepository.completeConnectionRequest(importToken);
  }
}
