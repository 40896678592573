import { ILocalizationService } from "@emanprague/shared-services";
import { bound } from "@frui.ts/helpers";
import { BusyWatcher, ScreenBase, watchBusy } from "@frui.ts/screens";
import { action, observable, autorun, runInAction } from "mobx";
import { interfaces } from "inversify";
import CompanyProfileRepository from "repositories/companyProfileRepository";
import CodeListWorkType from "entities/codeListWorkType";
import EnumsService from "services/enumsService";
import CompanyWorkTypes, { WorkTypesValidationRules } from "entities/companyWorkTypes";
import CompanySafetyPolicy from "entities/companySafetyPolicy";
import { FileBox } from "controls/fileUpload";
import CodeListSafetyPolicy from "entities/codeListSafetyPolicy";
import CompanySafetyPolicies from "entities/companySafetyPolicies";
import { attachAutomaticValidator, validateAll, validate } from "@frui.ts/validation";

class CompanySafety extends CompanySafetyPolicy {
  @observable file: FileBox<File>;
  @observable safetyPolicy: CodeListSafetyPolicy;

  static ValidationRules = {
    safetyPolicyId: { required: true },
    attachmentUrl: { required: true },
  };
}

class SafetyGroup {
  @observable workTypeId: string;
  @observable worktype: CodeListWorkType;
  @observable safetyPolicies: CompanySafety[];
}

type OldPolicy = { policy: CompanySafety; workTypeId: string };

type Model = {
  safetyPolicies: SafetyGroup[];
  oldPolices: OldPolicy[];
};

const getSafetySections = (safetyPolicies: CompanyWorkTypes[], workTypes: CodeListWorkType[], policies: CodeListSafetyPolicy[]) =>
  safetyPolicies
    .map(safety => {
      const workType = workTypes.find(({ id }) => id === safety.workTypeId) as CodeListWorkType;
      return {
        ...safety,
        worktype: workType,
        safetyPolicies: safety.safetyPolicies.map(policy => {
          const file = new FileBox();
          if (policy.attachmentName) {
            file.file = new File([], policy.attachmentName);
          }
          const safetyPolicy = policies.find(({ id }) => id === policy.safetyPolicyId) as CodeListSafetyPolicy;
          return {
            ...policy,
            safetyPolicy,
            file,
          };
        }),
      };
    })
    .filter(el => !!el) as SafetyGroup[];

export default class AddSafetyPolicyViewModel extends ScreenBase {
  navigationName = "addSafetyPolicy";
  busyWatcher = new BusyWatcher();
  @observable selectedForm = "worktypes";
  @observable model: Model = {
    safetyPolicies: [],
    oldPolices: [],
  };

  get codeListWorkTypes() {
    return this.enums.workTypes;
  }

  get codeListSafetyPolicies() {
    return this.enums.safetyPolicies;
  }

  constructor(
    private companyId: string,
    safetyPolicies: CompanyWorkTypes[],
    private repository: CompanyProfileRepository,
    public localization: ILocalizationService,
    private enums: EnumsService
  ) {
    super();

    this.model.safetyPolicies = getSafetySections(safetyPolicies ?? [], this.codeListWorkTypes, this.codeListSafetyPolicies);
    this.model.safetyPolicies?.forEach(safety => {
      const newSafety = attachAutomaticValidator(safety, WorkTypesValidationRules);
      return newSafety.safetyPolicies?.forEach(policy => attachAutomaticValidator(policy, CompanySafety.ValidationRules));
    });
    this.name = this.translate("title");
  }

  @bound
  cancel() {
    this.requestClose();
  }

  @bound
  @watchBusy
  async save() {
    const calculatedValidity = this.model.safetyPolicies.map(el => {
      return validateAll(el.safetyPolicies) && validate(el);
    });

    const valid = calculatedValidity.length > 0 ? calculatedValidity.reduce((acc, el) => acc && el) : true;

    if (valid) {
      const safety = new CompanySafetyPolicies();
      safety.workTypes = this.model.safetyPolicies.map(({ workTypeId, safetyPolicies }) => ({
        workTypeId,
        safetyPolicies: safetyPolicies.map(el => {
          return {
            safetyPolicyId: el.safetyPolicyId,
          };
        }),
      }));
      const result = await this.repository.saveSafetyPolicies(this.companyId, safety);

      if (this.model.oldPolices.length > 0) {
        // Remove all removed policy secitons
        await Promise.all(this.model.oldPolices.map(({ policy, workTypeId }) => this.removeFile(policy, workTypeId)));
      }

      if (result.success) {
        this.requestClose();
      }
    }
  }

  @bound
  @action
  async removePolicy(safety: SafetyGroup, policy: CompanySafety, index: number) {
    safety.safetyPolicies.splice(index, 1);
    this.model.oldPolices.push({ policy, workTypeId: safety.workTypeId });
  }

  @bound
  @action
  addSafety(safety: SafetyGroup) {
    const policy = new CompanySafety();
    attachAutomaticValidator(policy, CompanySafety.ValidationRules);
    const file = new FileBox<File>();
    policy.file = file;
    safety.safetyPolicies.push(policy);
  }

  @bound
  @action
  async replaceFile(policy: CompanySafety, workTypeId: string, file: File) {
    policy.file.file = file;
    const result = await this.repository.uploadPolicy(this.companyId, workTypeId, policy.safetyPolicyId, policy.file);
    if (!result.success) {
      runInAction(() => {
        policy.file.error = result.payload?.errorDescription;
      });
    } else {
      const json = await result.payload.text().then(res => JSON.parse(res));
      runInAction(() => {
        policy.attachmentUrl = json.attachmentUrl;
      });
    }
  }

  @bound
  async removeFile(policy: CompanySafety, workTypeId: string) {
    if (policy.attachmentUrl) {
      const result = await this.repository.removePolicyFile(this.companyId, workTypeId, policy.safetyPolicyId);

      if (result.success) {
        runInAction(() => {
          policy.file.file = undefined;
        });
      }
    } else {
      runInAction(() => {
        policy.file.file = undefined;
      });
    }
  }

  @bound
  @action
  addWorkType() {
    const workType = new CodeListWorkType();
    const safety = new SafetyGroup();
    attachAutomaticValidator(safety, WorkTypesValidationRules);
    safety.worktype = workType;
    safety.safetyPolicies = [];
    this.model.safetyPolicies.push(safety);

    autorun(() => {
      if (safety.workTypeId) {
        const workType = this.codeListWorkTypes.find(({ id }) => id === safety.workTypeId) as CodeListWorkType;
        safety.worktype = workType;
      }
    });
  }

  @bound
  @action
  removeWorktype(index: number) {
    this.model.safetyPolicies.splice(index, 1);
  }

  @bound
  @action
  switch(name: string) {
    this.selectedForm = name;
  }

  @bound translate(key: string) {
    return this.localization.translateGeneral(`companyInfo.addSafetyPolicy.${key}`);
  }

  static Factory({ container }: interfaces.Context) {
    return (companyId: string, safetyPolicies: CompanyWorkTypes[]) =>
      new AddSafetyPolicyViewModel(
        companyId,
        safetyPolicies,
        container.get(CompanyProfileRepository),
        container.get("ILocalizationService"),
        container.get(EnumsService)
      );
  }
}
