import { observable } from "mobx";
import { Type } from "class-transformer";
import QuestionnaireAnswer from "./questionnaireAnswer";

// This entity has been generated, do not change its content, your changes might get lost. You CAN modify the rest of the file.
export default class QuestionnaireAnswers {
  @observable
  @Type(() => QuestionnaireAnswer)
  answers!: QuestionnaireAnswer[];

  static ValidationRules = {
    answers: { required: true },
  };
}
