import React from "react";
import { Nav, Col, Row } from "@emanprague/veralinkkit";
import { registerView, View, ViewComponent } from "@frui.ts/views";
import { Observer, observer } from "mobx-react-lite";
import { Router } from "@frui.ts/screens";
import ModulePageViewModel from "viewModels/company/modulePageViewModel";
import { Dashboard, Info1, Compliance, Worker, Document, Users, Folder, FindWorker } from "@emanprague/veralinkkit/dist/Icons";
import CompanyPhoto from "./companyPhoto";
import "./company.scss";

const subModuleIcons = {
  companyDashboard: Dashboard,
  companyInfo: Info1,
  compliance: Compliance,
  employees: Worker,
  credentials: Document,
  companyCredentials: Document,
  myConnections: Users,
  projects: Folder,
} as { [key: string]: any };

const modulePageView: ViewComponent<ModulePageViewModel> = observer(({ vm }) => {
  const { translateGeneral: tg } = vm.localization;
  const getUrl = Router.getChildUrlFactory(vm);

  return (
    <>
      <Row>
        <Col md={4} lg={3}>
          <CompanyPhoto tg={vm.translate} photoUrl={vm?.profile?.profilePhotoUrl} onChange={vm.changeCompanyPhoto} />

          <Nav className="company__nav">
            {vm.children.map(x => {
              const Icon = subModuleIcons[x.navigationName];
              return (
                <Nav.Link key={x.navigationName} href={getUrl(x.navigationName)} active={vm.activeChild === x}>
                  <Icon fontSize={24} color={vm.activeChild === x ? "#FFFFFF" : "#A1AAAC"} />
                  {x.name}
                </Nav.Link>
              );
            })}

            <Nav.Link href="#/workerPortal">
              <FindWorker fontSize={24} color="#A1AAAC" />
              {tg("workerPortal.modulePage.title")}
            </Nav.Link>

            {vm.isContractorPortalLinkVisible && (
              <Nav.Link href="#/contractorPortal">
                <FindWorker fontSize={24} color="#A1AAAC" />
                {tg("contractorPortal.modulePage.title")}
              </Nav.Link>
            )}
          </Nav>
        </Col>

        <Col>
          <Observer>{() => <View vm={vm.activeChild} />}</Observer>
        </Col>
      </Row>
    </>
  );
});

registerView(modulePageView, ModulePageViewModel);
