import React from "react";
import { registerView, View, ViewComponent } from "@frui.ts/views";
import { observer } from "mobx-react-lite";
import TeamsListViewModel from "viewModels/company/projects/teams/teamsListViewModel";

import LoadingContainer from "views/common/loadingContainer";
import { Button } from "@emanprague/veralinkkit";
import { Plus } from "@emanprague/veralinkkit/dist/Icons";
import { PROJECT_DETAIL_SECTIONS } from "../projectDetailView";
import ProjectTeamCard from "../components/projectTeamCard";

const teamsListView: ViewComponent<TeamsListViewModel> = observer(({ vm }) => {
  return (
    <section id={PROJECT_DETAIL_SECTIONS.TEAM} className="cardSection">
      <div className="cardSection__header">
        <h4 className="text-uppercase">{vm.translate("team")}</h4>
        {vm.projectOwner && (
          <Button variant="primary" onClick={vm.openNewTeam}>
            <Plus fontSize={24} />
            {vm.translate("addNewProfession")}
          </Button>
        )}
      </div>
      <LoadingContainer loading={vm.busyWatcher.isBusy}>
        {vm.items?.map((team, index) => (
          <ProjectTeamCard
            key={`${team.id}-${index}`}
            team={team}
            localization={vm.localization}
            onEdit={vm.openEditTeam}
            onWorkerRemove={(workerId: string) => vm.removeWorker(team, workerId)}
            onWorkerAdd={() => vm.openAddWorkers(team)}
            projectOwner={vm.projectOwner}
            userId={vm.userId}
          />
        ))}
      </LoadingContainer>
      <View vm={vm.activeChild} />
    </section>
  );
});

registerView(teamsListView, TeamsListViewModel);
