import { ILocalizationService } from "@emanprague/shared-services";
import { bound } from "@frui.ts/helpers";
import { BusyWatcher, ScreenBase, watchBusy } from "@frui.ts/screens";
import { interfaces } from "inversify";
import { action, computed, observable, reaction } from "mobx";
import EmployeeRepository from "repositories/employeeRepository";
import downloadjs from "downloadjs";
import CompanyEmployee from "entities/companyEmployee";
import ContinuousListViewModelBase from "viewModels/continuousListViewModelBase";
import EmployeeFilter from "models/employeeFilter";
import CompanyCredentialsRepository from "repositories/companyCredentialsRepository";

export default class ExportCredentialsViewModel extends ContinuousListViewModelBase<CompanyEmployee, EmployeeFilter, ScreenBase> {
  navigationName = "exportCredentials";
  busyWatcher = new BusyWatcher();

  @observable selectedWorkers: CompanyEmployee[] = [];

  constructor(
    private companyId: string,
    public localization: ILocalizationService,
    private repository: EmployeeRepository,
    private credentialsRepository: CompanyCredentialsRepository
  ) {
    super();

    this.name = this.translate("title");
    this.pagingFilter.limit = 1000;
  }

  onInitialize() {
    this.loadData();
    reaction(() => [this.filter.search], this.applyFilterAndLoadDebounced);
  }

  createFilter(): EmployeeFilter {
    return new EmployeeFilter();
  }

  get canExport() {
    return this.selectedWorkers.length > 0;
  }

  @bound
  @watchBusy
  async loadData() {
    const result = await this.repository.searchEmployees(this.companyId, this.appliedFilter, this.pagingFilter);
    if (result.success) {
      this.setData(result.payload);
    }
  }

  @bound
  @watchBusy
  async exportWorkers() {
    if (this.selectedWorkers.length > 0) {
      const response = await this.credentialsRepository.export(
        this.companyId,
        this.selectedWorkers.filter(id => id !== undefined).map(w => w.accountId ?? "")
      );
      if (response.success) {
        const payload = await response.payload.blob();
        await this.downloadExport(payload);
        this.requestClose();
      }
    }
  }

  @action.bound
  selectWorker(worker: CompanyEmployee) {
    this.selectedWorkers.push(worker);
  }

  @action.bound
  unselectWorker(worker: CompanyEmployee) {
    const workerIndex = this.selectedWorkers.findIndex(sw => sw.accountId === worker.accountId);
    this.selectedWorkers.splice(workerIndex, 1);
  }

  @action.bound selectAll(v: boolean) {
    if (v) {
      this.selectedWorkers = [...(this.items ?? [])];
    } else {
      this.selectedWorkers = [];
    }
  }

  @computed
  get availableWorkers() {
    return this.items?.filter(w => {
      const fw = this.selectedWorkers.find(sw => sw.accountId === w.accountId);
      if (!!fw) {
        return false;
      } else {
        return true;
      }
    });
  }

  downloadExport = async (binaryXLS: Blob) => {
    const name = `[${this.companyId}]_employees-credentials-export`;

    return downloadjs(binaryXLS, `${name}.xlsx`, "application/vnd.ms-excel");
  };

  @bound translate(key: string) {
    return this.localization.translateGeneral(`employees.export.${key}`);
  }

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  protected resetFilterValues(filter: EmployeeFilter) {}

  static Factory({ container }: interfaces.Context) {
    return (companyId: string) =>
      new ExportCredentialsViewModel(
        companyId,
        container.get("ILocalizationService"),
        container.get(EmployeeRepository),
        container.get(CompanyCredentialsRepository)
      );
  }
}
