import LocalizationService from "./localizationService";
import { LocaleCode } from "src/contracts/localeProvider";
import Cookies from "js-cookie";

export default class CookieLocalizationService extends LocalizationService {
  static COOKIE_NAME = "locale";

  initialize(defaultLocale: LocaleCode) {
    const locale = Cookies.get(CookieLocalizationService.COOKIE_NAME) || defaultLocale;
    return super.initialize(locale);
  }

  async setLocale(locale: LocaleCode) {
    await super.setLocale(locale);
    Cookies.set(CookieLocalizationService.COOKIE_NAME, locale);
    document.documentElement.lang = locale;
  }
}
