import React from "react";
import { Card, Dropdown } from "@emanprague/veralinkkit";
import { MapPoint, Menu, Calendar, Users } from "@emanprague/veralinkkit/dist/Icons";
import CompanyProject from "entities/companyProject";
import { ILocalizationService } from "@emanprague/shared-services";
import EnumsService from "services/enumsService";
import { formatDateRange, projectProgress } from "utils/viewUtils";

interface ProjectCardProps {
  project: CompanyProject;
  localization: ILocalizationService;
  onProjectNameClick: (projectId: string, projectName: string, operatorId?: string) => void;
  onEditProject: (item: CompanyProject) => void;
  onRemoveProject: (item: CompanyProject) => void;
  projectOwner: boolean;
  enums: EnumsService;
}

const projectCard = (props: ProjectCardProps) => {
  const { project, localization, onProjectNameClick, onEditProject, onRemoveProject, projectOwner, enums } = props;
  const tg = (code: string) => localization.translateGeneral(`projects.card.${code}`);
  return (
    <div>
      <Card className="projectCard">
        <Card.Header>
          <div className="projectCard__header">
            <h5 onClick={() => onProjectNameClick(project.id!, project.name!, project.operatorId)}>{project.name}</h5>
            {project.operatorId && project.operatorName ? (
              <span>
                {tg("assignedBy")} <a href={`#/publicProfile/company/${project.operatorId}`}>{project.operatorName}</a>
              </span>
            ) : (
              <span>&nbsp;</span>
            )}
          </div>
          {projectOwner && (
            <Dropdown className="projectCard__options" title={<Menu fontSize={24} color="#888F91" />} variant="link" id="options">
              <Dropdown.Item onClick={() => onEditProject(project)}>{tg("editProject")}</Dropdown.Item>
              <Dropdown.Item onClick={() => onProjectNameClick(project.id!, project.name!, project.operatorId)}>
                {tg("modifyProject")}
              </Dropdown.Item>
              <Dropdown.Item onClick={() => onRemoveProject(project)}>{tg("deleteProject")}</Dropdown.Item>
            </Dropdown>
          )}
        </Card.Header>
        <Card.Body>
          <span className="projectCard__statusItem">
            <Calendar fontSize={17} />
            &nbsp;{formatDateRange({ startDate: project.startDate, endDate: project.endDate }, localization)}
          </span>
          <span className="projectCard__statusItem">
            <Users fontSize={17} />
            &nbsp;{[project.nWorkersAssigned, project.nWorkersWanted].filter(n => n !== undefined).join("/")}
          </span>
          <span className="projectCard__statusItem">
            <MapPoint fontSize={17} />
            &nbsp;
            {[project.location?.city, enums.getStateById(project.location?.state)?.name]
              .filter(v => v !== undefined)
              .join(", ") ?? tg("unknownLocation")}
          </span>
        </Card.Body>
        <div className="projectRow__percentage">
          <div className="percentageValue" style={{ width: `${projectProgress(project.startDate!, project.endDate!)}%` }}>
            &nbsp;
          </div>
        </div>
      </Card>
    </div>
  );
};

export default projectCard;
