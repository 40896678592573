import { observable } from "mobx";
import { Type } from "class-transformer";
import CompanyEmployeeByCredentialCredentialsItem from "./companyEmployeeByCredentialCredentialsItem";
import LocationSimple from "./locationSimple";

// This entity has been generated, do not change its content, your changes might get lost. You CAN modify the rest of the file.
export default class CompanyEmployeeByCredential {
  @observable
  pendingRegistration: boolean;

  @observable
  professionTitle!: string;

  @observable
  accountId!: string;

  @observable
  profilePhotoUrl!: string;

  @observable
  firstName!: string;

  @observable
  middleName?: string;

  @observable
  lastName!: string;

  @observable
  @Type(() => LocationSimple)
  location?: LocationSimple;

  @observable
  primaryProfessionName!: string;

  @observable
  primaryProfessionYears!: string;

  @observable
  @Type(() => CompanyEmployeeByCredentialCredentialsItem)
  credentials!: CompanyEmployeeByCredentialCredentialsItem[];

  static ValidationRules = {
    accountId: { required: true },
    profilePhotoUrl: { format: "url", required: true },
    firstName: { required: true },
    lastName: { required: true },
    primaryProfessionName: { required: true },
    primaryProfessionYears: { required: true },
    credentials: { required: true },
  };
}
