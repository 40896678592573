import { ILocalizationService } from "@emanprague/shared-services";
import { bound } from "@frui.ts/helpers";
import { ConductorOneChildActive, Router, ScreenBase } from "@frui.ts/screens";
import AccountType from "entities/accountType";
import AuthenticationIdentity from "entities/authenticationIdentity";
import WorkerProfile from "entities/workerProfile";
import UserContext from "services/userContext";
import { IModule } from "viewModels/types";
import WorkerProfileViewModel from "./workerProfileViewModel";
import CompleteWorkerProfileViewModel from "./modals/completeWorkerProfileViewModel";

export default class ModulePageViewModel extends ConductorOneChildActive<ScreenBase> implements IModule {
  orderIndex = 1;
  navigationName = "dashboard";
  hideFromMenu = false;

  constructor(
    public localization: ILocalizationService,
    private userContext: UserContext,
    private detailFactory: ReturnType<typeof WorkerProfileViewModel.Factory>,
    private router: Router
  ) {
    super();

    this.name = this.translate("title");
  }

  isVisible(user: AuthenticationIdentity) {
    return user.accountType === AccountType.Worker;
  }

  async onInitialize() {
    const profile = this.userContext.profile as WorkerProfile;
    const mustCompleteProfile = !profile.skills.industryId || !profile.skills.professionId;
    if (mustCompleteProfile) {
      this.router.navigate(CompleteWorkerProfileViewModel);
    }

    const detailViewModel = this.detailFactory(profile, "edit");
    detailViewModel.navigationName = "profile";
    await this.tryActivateChild(detailViewModel);
  }

  @bound translate(key: string) {
    return this.localization.translateGeneral(`worker.modulePage.${key}`);
  }

  protected findNavigationChild(navigationName: string | undefined) {
    return this.activeChild;
  }
}
