import React from "react";
import { observer } from "mobx-react-lite";
import { Card } from "@emanprague/veralinkkit";
import { Input, Select } from "@frui.ts/bootstrap";
import ModulePageViewModel from "viewModels/contractorPortal/modulePageViewModel";
import FilterItem from "views/workerPortal/filter/filterItem";
import "./contractorPortalFilter.scss";

const ContractorPortalFilter: React.FunctionComponent<{ vm: ModulePageViewModel }> = observer(({ vm }) => {
  const ta = (code: string) => vm.localization.translateAttribute("contractorPortalFilter", code);
  const taLocation = (code: string) => vm.localization.translateAttribute("location", code);

  return (
    <Card className="worker-filter">
      <h5>{ta("filterBy")}</h5>

      <FilterItem
        label={ta("service")}
        collapsedContent={vm.filter.service || ta("any")}
        content={<Input placeholder={ta("service")} target={vm.filter} property="service" />}
      />

      <FilterItem
        label={ta("location")}
        collapsedContent={
          [vm.filter["location.city"], vm.enums.getStateById(vm.filter["location.state"])?.name].filter(e => !!e).join(", ") ||
          ta("any")
        }
        content={
          <>
            <Input placeholder={taLocation("city")} target={vm.filter} property="location.city" />
            <Select
              target={vm.filter}
              property="location.state"
              items={vm.states}
              mode="key"
              keyProperty="id"
              textProperty="name"
              allowEmpty
              placeholder={taLocation("chooseState")}
            />
          </>
        }
      />
    </Card>
  );
});

export default ContractorPortalFilter;
