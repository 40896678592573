import { ILocalizationService } from "@emanprague/shared-services";
import { DetailViewModel } from "@frui.ts/datascreens";
import { bound } from "@frui.ts/helpers";
import { BusyWatcher, watchBusy } from "@frui.ts/screens";
import { classToClass } from "class-transformer";
import WorkerProfile from "entities/workerProfile";
import { interfaces } from "inversify";
import { action } from "mobx";
import WorkerProfileRepository from "repositories/workerProfileRepository";
import EnumsService from "services/enumsService";

export default class AboutMeViewModel extends DetailViewModel<WorkerProfile> {
  navigationName = "about";
  busyWatcher = new BusyWatcher();

  constructor(
    workerProfile: WorkerProfile,
    public localization: ILocalizationService,
    private repository: WorkerProfileRepository,
    private enums: EnumsService
  ) {
    super();
    this.name = this.translate("title");

    this.setItem(classToClass(workerProfile) ?? new WorkerProfile());
  }

  protected loadDetail() {
    return this.item;
  }

  @action.bound
  @watchBusy
  async updateAbout() {
    const result = await this.repository.updateWorkerProfile(this.item.id, this.item);
    if (result.success) {
      this.requestClose();
    }
  }

  @bound translate(key: string) {
    return this.localization.translateGeneral(`worker.aboutDetail.${key}`);
  }

  static Factory({ container }: interfaces.Context) {
    return (workerProfile: WorkerProfile) =>
      new AboutMeViewModel(
        workerProfile,
        container.get("ILocalizationService"),
        container.get(WorkerProfileRepository),
        container.get(EnumsService)
      );
  }
}
