import { useCallback, useState } from "react";
import { InputGroup } from "@emanprague/veralinkkit";
import { Eye, EyeCrossed } from "@emanprague/veralinkkit/dist/Icons";
import React from "react";
import Input from "./input";
import "./inputs.scss";

export const AppendEye = ({ onClick }: any) => {
  const [state, setState] = useState("closed");
  const onClickChange = useCallback(() => {
    if (state === "opened") {
      setState("closed");
    } else {
      setState("opened");
    }
    onClick(state);
  }, [state, onClick]);
  return (
    <InputGroup.Append className="password__eye" onClick={onClickChange}>
      {state === "opened" && <EyeCrossed />}
      {state === "closed" && <Eye />}
    </InputGroup.Append>
  );
};

export const PasswordInput = (props: any) => {
  const [componentType, setType] = useState("password");
  const onClickEye = useCallback(
    state => {
      if (state === "opened") {
        setType("password");
      } else {
        setType("text");
      }
    },
    [setType]
  );

  return <Input {...props} type={componentType} append={<AppendEye onClick={onClickEye} />} />;
};
