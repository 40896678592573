export const isFilled = (answer: {
  singleAnswer?: string;
  multiAnswer?: string[];
  textAnswer?: any;
  numberAnswer?: any;
  booleanAnswer?: any;
}): boolean =>
  !!answer.singleAnswer ||
  (answer.multiAnswer?.length && answer.multiAnswer.length > 0) ||
  !!answer.textAnswer ||
  !!answer.numberAnswer ||
  typeof answer.booleanAnswer !== "undefined";
