import React from "react";
import { Card, RoundedButton } from "@emanprague/veralinkkit";
import { MapPoint, Calendar, Users, Edit } from "@emanprague/veralinkkit/dist/Icons";
import CompanyProject from "entities/companyProject";
import { ILocalizationService } from "@emanprague/shared-services";
import EnumsService from "services/enumsService";
import { formatDateRange } from "utils/viewUtils";

interface ProjectCardDetailProps {
  project: CompanyProject;
  localization: ILocalizationService;
  onEditClick: () => void;
  projectOwner: boolean;
  enums: EnumsService;
}

const calcPercentage = (a: number, b: number) => {
  if (a !== 0 && b !== 0) {
    return Math.round((a / b) * 100 * 100) / 100;
  } else {
    return 0;
  }
};

const projectCardDetail = (props: ProjectCardDetailProps) => {
  const { project, localization, onEditClick, projectOwner, enums } = props;
  const tg = (code: string) => localization.translateGeneral(`projects.card.${code}`);
  return (
    <Card className="projectCard --detail">
      <Card.Header>
        <div className="info">
          <span className="projectCard__statusItem">
            <Calendar fontSize={17} />
            &nbsp;{formatDateRange({ startDate: project.startDate, endDate: project.endDate }, localization)}
          </span>
          <span className="projectCard__statusItem">
            <Users fontSize={17} />
            &nbsp;{[project.nWorkersAssigned, project.nWorkersWanted].filter(n => n !== undefined).join("/")}
          </span>
          <span className="projectCard__statusItem">
            <MapPoint fontSize={17} />
            &nbsp;
            {[project.location?.city, enums.getStateById(project.location?.state)?.name]
              .filter(v => v !== undefined)
              .join(", ") ?? tg("unknownLocation")}
          </span>
          <span className="projectCard__statusItem">
            {`${tg("projectReadiness")}: ${calcPercentage(project.nWorkersAssigned, project.nWorkersWanted)}%`}
          </span>
        </div>
        {projectOwner && (
          <RoundedButton onClick={onEditClick}>
            <Edit fontSize={24} />
          </RoundedButton>
        )}
      </Card.Header>
      <Card.Body>
        <h6>{tg("projectDescription")}</h6>
        <p>{project.description}</p>
      </Card.Body>
    </Card>
  );
};

export default projectCardDetail;
