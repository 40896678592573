import { Button, Col, Row } from "@emanprague/veralinkkit";
import { registerView, ViewComponent } from "@frui.ts/views";
// import PagingInfo from "controls/pagingInfo";
// import SortSelect from "controls/sortSelect";
import { observer } from "mobx-react-lite";
import React from "react";
import SubModuleViewModel from "viewModels/company/employees/subModuleViewModel";
// import Input from "controls/input";
import { Export, Import } from "@emanprague/veralinkkit/dist/Icons";

const headerView: ViewComponent<SubModuleViewModel> = observer(({ vm }) => {
  const tg = (code: string) => vm.translate(code);

  return (
    <>
      <Row className="company__header">
        <Col lg={3} md={12}>
          <h4>{vm.name}</h4>
        </Col>
        <Col lg={9} md={12} className="dheader__actions">
          <Button variant="outline-primary" onClick={vm.openImportModal}>
            <Import fontSize={24} color="#00949E" />
            &nbsp;
            {tg("importWorkers")}
          </Button>
          <Button variant="outline-primary" onClick={vm.openExportModal}>
            <Export fontSize={24} color="#00949E" />
            &nbsp;
            {tg("exportWorkers")}
          </Button>
        </Col>
      </Row>
      <Row className="dheader__subheader">
        <Col lg={4} md={12} className="dheader__pagination">
          {/* <PagingInfo paging={vm.currentPaging} localization={vm.localization} /> */}
        </Col>
        <Col lg={8} md={12} className="dheader__filter">
          <div>
            <span>{tg("sortBy")}:</span>
            {/* <SortSelect
              items={vm.sortOptions}
              paging={vm.pagingFilter}
              model="workerPortalSortOption"
              localization={vm.localization}
            /> */}
          </div>
          {/* <Input placeholder={tg("search")} target={vm.filter} property="search" /> */}
        </Col>
      </Row>
    </>
  );
});

registerView(headerView, SubModuleViewModel, "header");
