import { observable } from "mobx";
import AccountType from "./accountType";
import AuthenticationIdentityRole from "entities/authenticationIdentityRole";

// This entity has been generated, do not change its content, your changes might get lost. You CAN modify the rest of the file.
export default class AuthenticationIdentity {
  @observable
  accountType!: AccountType;

  /**
   * User ID
   */
  @observable
  userId!: string;

  /**
   * Account ID
   */
  @observable
  accountId!: string;

  @observable
  role?: AuthenticationIdentityRole;

  static ValidationRules = {
    accountType: { required: true },
    userId: { required: true },
    accountId: { required: true },
  };
}
