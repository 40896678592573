import { observable } from "mobx";
import { Type } from "class-transformer";
import OshaFormQuestion from "./oshaFormQuestion";

// This entity has been generated, do not change its content, your changes might get lost. You CAN modify the rest of the file.
export default class OshaFormQuarter {
  /**
   * @example
   * 1
   */
  @observable
  quarter?: number;

  @observable
  @Type(() => OshaFormQuestion)
  questions?: OshaFormQuestion;

  static ValidationRules = {
    quarter: { number: true },
  };
}
