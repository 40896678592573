import React from "react";
import { Row, Col, Button } from "@emanprague/veralinkkit";
import { DocumentBig } from "@emanprague/veralinkkit/dist/Icons";
import "./credentials.scss";
import CredentialSection, { CredentialsSectionGrouped } from "views/worker/credentials/components/credentialSection";
import { ILocalizationService } from "@emanprague/shared-services";
import { CredentialPhoto } from "entities/credential";
import classnames from "classnames";

type Props = {
  isEdit: boolean;
  scrollable?: boolean;
  credentials: CredentialsSectionGrouped[];
  localization: ILocalizationService;
  uploadCredentials: () => void;
  openCredentialsOverview: (c: CredentialPhoto) => Promise<void>;
};

const CredentialsSection: React.FC<Props> = ({
  localization,
  isEdit,
  scrollable,
  uploadCredentials,
  credentials,
  openCredentialsOverview,
}) => {
  const tg = (key: string) => localization.translateGeneral(`worker.modulePage.${key}`);

  const classNames = classnames({
    credentials: scrollable,
  });

  return (
    <Row className={classNames}>
      {credentials.length === 0 && (
        <Col className="empty-credentials">
          <div className="empty-credentials__hero">
            <DocumentBig width={100} height={100} />
          </div>
          <p className="empty-credentials__description">{tg("emptyCredentials")}</p>

          {isEdit && <Button onClick={uploadCredentials}>{tg("uploadCredential")}</Button>}
        </Col>
      )}
      {credentials.length > 0 && (
        <Col>
          {credentials.map(credentialSection => (
            <CredentialSection
              key={credentialSection.id}
              onItemClick={openCredentialsOverview}
              item={credentialSection}
              tg={tg}
            />
          ))}
        </Col>
      )}
    </Row>
  );
};

export default CredentialsSection;
