import { ILocalizationService } from "@emanprague/shared-services";
import { bound } from "@frui.ts/helpers";
import { BusyWatcher, Router, ScreenBase, watchBusy } from "@frui.ts/screens";
import { validate } from "@frui.ts/validation";
import AccountType from "entities/accountType";
import NewContractorPassword from "entities/newContractor";
import NewOperatorPassword from "entities/newOperator";
import { action, observable } from "mobx";
import SignupRepository from "repositories/signupRepository";
import AsyncValidator from "services/validation/asyncValidator";
import ValidationService from "services/validation/validationService";
import SuccessEmailSentViewModel from "./successEmailSentViewModel";
import { API_URI } from "config";

export const navigationName = "company";

@Router.registerRoute({ name: Router.Self, route: navigationName })
export default class CompanyFormViewModel extends ScreenBase {
  navigationName = navigationName;
  busyWatcher = new BusyWatcher();

  @observable role: AccountType.Operator | AccountType.Contractor;
  @observable item: NewOperatorPassword | NewContractorPassword;
  @observable asyncValidator: AsyncValidator<NewOperatorPassword> | AsyncValidator<NewContractorPassword>;
  @observable errorMessage?: string;

  get navigationParams() {
    return {
      role: this.role,
    };
  }

  constructor(
    public localization: ILocalizationService,
    private repository: SignupRepository,
    private router: Router,
    private validationService: ValidationService
  ) {
    super();

    this.name = this.translate("title");
  }

  @bound
  @watchBusy
  async signup() {
    this.setErrorMessage();
    if (validate(this.item)) {
      const result = await this.repository.createAccount({ type: this.role, details: this.item });

      if (result.success) {
        this.router.navigate(SuccessEmailSentViewModel, undefined, { email: this.item.email });
      } else {
        this.setErrorMessage(result.payload.errorDescription);
      }
    }
  }

  @action private setRole(role: AccountType) {
    switch (role) {
      case AccountType.Operator:
        this.item = new NewOperatorPassword();
        this.asyncValidator = this.validationService.attachUserNameValidation(this.item, NewOperatorPassword.ValidationRules);
        break;
      case AccountType.Contractor:
        this.item = new NewContractorPassword();
        this.asyncValidator = this.validationService.attachUserNameValidation(this.item, NewContractorPassword.ValidationRules);
        break;
      default:
        this.requestClose();
        return;
    }
    this.role = role;
    this.notifyNavigationChanged();
  }

  @action private setErrorMessage(message?: string) {
    this.errorMessage = message;
  }

  get termsUrl() {
    return `${API_URI}/auth/terms_and_conditions`;
  }

  navigate(_subpath: any, params: any) {
    if (params && params.role) {
      this.setRole(params.role);
    } else {
      this.requestClose();
    }
  }

  onDeactivate(isClosing: boolean) {
    if (isClosing) {
      this.asyncValidator?.dispose();
    }
    return super.onDeactivate(isClosing);
  }

  @bound translate(key: string) {
    return this.localization.translateGeneral(`signup.signupForm.${key}`);
  }
}
