import { observable } from "mobx";

// This entity has been generated, do not change its content, your changes might get lost. You CAN modify the rest of the file.
export default class UserSettings {
  /**
   * User’s email (read-only)
   */
  @observable
  email!: string;

  /**
   * read-only
   */
  @observable
  username?: string;

  static ValidationRules = {
    email: { format: "email", required: true },
  };
}

export class UserSettingsUserName extends UserSettings {
  @observable
  username!: string;
}
