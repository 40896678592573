import React from "react";
import { observer } from "mobx-react-lite";
import "./unreadBadge.scss";

interface UnreadBadgeProps {
  count?: number;
  maxCount: number;
}

const UnreadBadge: React.FunctionComponent<UnreadBadgeProps> = observer(({ count, maxCount }) => {
  if (!count) {
    return <></>;
  }

  return <span className="unread-badge">{count >= maxCount ? `${maxCount}+` : count}</span>;
});

export default UnreadBadge;
