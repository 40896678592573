import { appendAcceptHeader, ContentTypes, RestRequestBuilder } from "@frui.ts/apiclient";
import { IPagingFilter, PagedQueryResult, SortingDirection } from "@frui.ts/data";
import { deserialize, deserializeArray } from "class-transformer";
import { ClassType } from "class-transformer/ClassTransformer";
import FetchError from "@frui.ts/apiclient/dist/fetchError";
import { StringifyOptions } from "querystring";

export default class DeserializingRequestBuilder extends RestRequestBuilder {
  ignoreEmptyStrings(): this {
    this.queryStringOptions = Object.assign({}, this.queryStringOptions || RestRequestBuilder.DefaultQueryStringOptions, {
      skipEmptyString: true,
    } as StringifyOptions);
    return this;
  }

  getEntity<T>(returnType: ClassType<T>, queryParams?: any): Promise<T> {
    const requestUrl = this.appendQuery(this.url, queryParams);
    const params = appendAcceptHeader(this.params, ContentTypes.json);
    return this.apiConnector
      .get(requestUrl, params)
      .then(x => x.text())
      .then(x => deserialize(returnType, x));
  }

  getEntities<T>(returnType: ClassType<T>, queryParams?: any): Promise<T[]> {
    const requestUrl = this.appendQuery(this.url, queryParams);
    const params = appendAcceptHeader(this.params, ContentTypes.json);
    return this.apiConnector
      .get(requestUrl, params)
      .then(x => x.text())
      .then(x => deserializeArray(returnType, x));
  }

  getPagedEntities<TResult extends { total: number }, TEntity>(
    returnType: ClassType<TResult>,
    itemsSelector: (result: TResult) => TEntity[],
    paging: IPagingFilter,
    queryParams?: any
  ) {
    const query = {
      ...queryParams,
      sort: paging?.sortColumn
        ? `${paging.sortColumn}:${paging.sortDirection === SortingDirection.Descending ? "dsc" : "asc"}`
        : undefined,
      offset: paging?.offset ?? 0,
      limit: paging?.limit ?? 20,
    };

    const requestUrl = this.appendQuery(this.url, query);
    const params = appendAcceptHeader(this.params, ContentTypes.json);

    return this.apiConnector
      .get(requestUrl, params)
      .then(x => x.text())
      .then(x => deserialize(returnType, x))
      .then(
        x =>
          [
            itemsSelector(x),
            {
              limit: query.limit,
              offset: query.offset,
              totalItems: x.total,
            },
          ] as PagedQueryResult<TEntity>
      );
  }

  postEntity<T>(content: any, returnType: ClassType<T>): Promise<T>;
  postEntity(content: any): Promise<Response>;

  postEntity<T>(content: any, returnType?: ClassType<T>): Promise<T | Response> {
    const params = appendAcceptHeader(this.params, ContentTypes.json);
    const promise = this.apiConnector.postJson(this.url, content, params);

    if (returnType) {
      return promise.then(x => x.text()).then(x => deserialize(returnType, x));
    } else {
      return promise;
    }
  }

  putEntity<T>(content: any, returnType: ClassType<T>): Promise<T>;
  putEntity(content: any): Promise<Response>;

  putEntity<T>(content: any, returnType?: ClassType<T>): Promise<T | Response> {
    const params = appendAcceptHeader(this.params, ContentTypes.json);
    const promise = this.apiConnector.putJson(this.url, content, params);

    if (returnType) {
      return promise.then(x => x.text()).then(x => deserialize(returnType, x));
    } else {
      return promise;
    }
  }

  fetchWithUpdate(
    url: string,
    opts: any = {},
    onProgress: ((this: XMLHttpRequest, ev: ProgressEvent<EventTarget>) => any) | null
  ) {
    return new Promise((res, rej) => {
      const xhr = new XMLHttpRequest();
      xhr.open(opts.method || "get", url);
      for (const k in opts.headers || {}) xhr.setRequestHeader(k, opts.headers[k]);
      xhr.onerror = (e: any) => rej(e);
      xhr.onreadystatechange = () => {
        if (xhr.readyState === 4) {
          res(xhr);
        }
      };
      if (xhr.upload && onProgress) xhr.upload.onprogress = onProgress; // event.loaded / event.total * 100 ; //event.lengthComputable
      xhr.send(opts.body);
    });
  }

  async runBlob(method: string, data: Blob, onProgressUpdate: (a: number) => void, queryParams?: any): Promise<Response> {
    const params = appendAcceptHeader(this.params, ContentTypes.json);
    const url = this.appendQuery(this.url, queryParams);
    const res = (await this.fetchWithUpdate(
      url,
      {
        ...params,
        method,
        body: data,
      },
      (e: ProgressEvent) => {
        onProgressUpdate(e.loaded / e.total);
      }
    )) as XMLHttpRequest;

    const resp = new Response(!!res.responseText ? res.responseText : null, {
      statusText: res.statusText,
      status: res.status,
    });

    if (![201, 200, 204].includes(res.status)) {
      if (res.status === 413) {
        throw new FetchError(resp, { errorDescription: "Size is too large" });
      }
      throw new FetchError(resp, JSON.parse(res.responseText));
    }
    return resp;
  }

  postBlob(data: Blob, onProgressUpdate: (a: number) => void, queryParams?: any): Promise<Response> {
    return this.runBlob("POST", data, onProgressUpdate, queryParams);
  }

  putBlob(data: Blob, onProgressUpdate: (a: number) => void, queryParams?: any): Promise<Response> {
    return this.runBlob("PUT", data, onProgressUpdate, queryParams);
  }

  patchEntity<T>(content: any, returnType: ClassType<T>): Promise<T>;
  patchEntity(content: any): Promise<Response>;

  patchEntity<T>(content: any, returnType?: ClassType<T>): Promise<T | Response> {
    const params = appendAcceptHeader(this.params, ContentTypes.json);
    const promise = this.apiConnector.patchJson(this.url, content, params);

    if (returnType) {
      return promise.then(x => x.text()).then(x => deserialize(returnType, x));
    } else {
      return promise;
    }
  }
}
