import { ILocalizationService } from "@emanprague/shared-services";
import { BusyWatcher, ScreenBase, watchBusy } from "@frui.ts/screens";
import { interfaces } from "inversify";
import CompanyProjectTeam from "entities/companyProjectTeam";
import { action, computed, observable, reaction, runInAction } from "mobx";
import { bound } from "@frui.ts/helpers";
import ProjectRequirement from "models/projectRequirement";
import ProjectRepository from "repositories/projectRepository";
import UserContext from "services/userContext";
import AccountType from "entities/accountType";
import ConnectionOutbound from "entities/connectionOutbound";
import ConnectionOutboundCompany from "entities/connectionOutboundCompany";
import EnumsService from "services/enumsService";

export default class FormTeamViewModel extends ScreenBase {
  navigationName = "formTeam";
  busyWatcher = new BusyWatcher();

  @observable projectRequirements: ProjectRequirement[] = [];
  @observable contractors: ConnectionOutboundCompany[] = [];

  get industries() {
    return this.enums.industries;
  }

  @computed
  get professions() {
    return this.enums.professions.filter(item => item.industryId === this.team.industry?.id);
  }

  constructor(
    public team: CompanyProjectTeam,
    private repository: ProjectRepository,
    public localization: ILocalizationService,
    public user: UserContext,
    private enums: EnumsService
  ) {
    super();

    runInAction(() => {
      if (team.id === undefined) {
        // CREATE FLOW
        team.requiredCredentials = [];
        team.workersAssigned = [];
      } else {
        // EDIT FLOW
        if (team.requiredCredentials) {
          this.projectRequirements = team.requiredCredentials.map(r => {
            const req = new ProjectRequirement();
            req.name = r;
            return req;
          });
        }
        if (team.assignedSubcontractorName) {
          this.team.assignType = "assignToSubcontractor";
        }

        if (team.assignedContractorId) {
          this.team.assignType = "assignToContractor";
        }
      }
    });

    this.loadContractors();

    reaction(() => this.team.assignType, this.assignTypeChange);
  }

  @bound
  assignTypeChange() {
    runInAction(() => {
      this.team.workersAssigned = [];
      if (this.team.assignType === "assignToMe") {
        this.team.assignedContractorId = undefined;
        this.team.assignedSubcontractorName = undefined;
      }

      if (this.team.assignType === "assignToContractor") {
        this.team.assignedSubcontractorName = undefined;
      }

      if (this.team.assignType === "assignToSubcontractor") {
        this.team.assignedContractorId = undefined;
      }
    });
  }

  @computed
  get isOperator() {
    return this.user.identity?.accountType === AccountType.Operator;
  }

  @computed
  get assignOptions() {
    if (!this.isOperator) {
      return ["assignToMe", "assignToSubcontractor"];
    } else {
      return ["assignToMe", "assignToContractor", "assignToSubcontractor"];
    }
  }

  @bound
  prepareEdit() {
    runInAction(() => {
      const [profession, industry] = this.team.name?.split(" | ") as string[];
      if (profession) {
        const foundedIndustry = this.industries.find(item => item.name === industry);
        if (foundedIndustry) {
          this.team.industry = foundedIndustry;
          const foundedProfession = this.professions.find(item => item.name === profession);
          if (foundedProfession) {
            this.team.profession = foundedProfession;
          }
        }
      }
    });
  }

  @bound
  prepareTeam() {
    runInAction(() => {
      this.team.name = this.team.profession?.name + " | " + this.team.industry?.name;
      this.team.requiredCredentials = this.projectRequirements.map(r => r.name);
    });
  }

  @bound
  @watchBusy
  async loadContractors() {
    const result = await this.repository.getConnections();
    if (result.success && result.payload) {
      const filtered = (result.payload as ConnectionOutbound[]).filter(
        item => item.accountType === AccountType.Contractor
      ) as ConnectionOutboundCompany[];
      runInAction(() => {
        this.contractors = filtered;
      });
    }
  }

  @bound
  addRequirement() {
    runInAction(() => {
      this.projectRequirements.push(new ProjectRequirement());
    });
  }

  @action.bound
  removeRequirement(index: number) {
    this.projectRequirements.splice(index, 1);
  }

  static Factory({ container }: interfaces.Context) {
    return (team: CompanyProjectTeam) =>
      new FormTeamViewModel(
        team,
        container.get(ProjectRepository),
        container.get("ILocalizationService"),
        container.get(UserContext),
        container.get(EnumsService)
      );
  }
}
