import { ILocalizationService } from "@emanprague/shared-services";
import { bound } from "@frui.ts/helpers";
import { BusyWatcher, ScreenBase, watchBusy } from "@frui.ts/screens";
import { attachAutomaticValidator, hasVisibleErrors, validateAll } from "@frui.ts/validation";
import { classToClass } from "class-transformer";
import CompanyContact from "entities/companyContact";
import CompanyProfile from "entities/companyProfile";
import { interfaces } from "inversify";
import { action, computed, observable } from "mobx";
import CompanyProfileRepository from "repositories/companyProfileRepository";

export default class CompanyContactsViewModel extends ScreenBase {
  navigationName = "contacts";
  busyWatcher = new BusyWatcher();

  @observable.shallow model: CompanyContact[] = [];
  @observable.shallow toAdd: CompanyContact[] = [];
  @observable.shallow toDelete: CompanyContact[] = [];

  constructor(
    private companyProfile: CompanyProfile,
    originalContacts: CompanyContact[],
    private onSuccess: () => void,
    private companyProfileRepository: CompanyProfileRepository,
    public localization: ILocalizationService
  ) {
    super();
    this.name = this.translate("title");

    this.model = classToClass(originalContacts);
    this.model.forEach(contact => attachAutomaticValidator(contact, CompanyContact.ValidationRules));

    if (!this.model.length) {
      this.addEmptyContact();
    }
  }

  @computed get contacts() {
    return [...this.model, ...this.toAdd];
  }

  @bound
  @watchBusy
  async save() {
    const toEdit = validateAll(this.model);
    const toDelete = validateAll(this.toDelete);
    const toAdd = validateAll(this.toAdd);

    if (toEdit && toDelete && toAdd) {
      await Promise.all([
        ...this.toAdd.map(c => this.companyProfileRepository.addContact(this.companyProfile.id, c)),
        ...this.model.map(c => this.companyProfileRepository.updateContact(this.companyProfile.id, c.id, c)),
        ...this.toDelete.map(c => this.companyProfileRepository.deleteContact(this.companyProfile.id, c.id) as any),
      ]);

      this.onSuccess();
      return this.requestClose();
    }
  }

  @computed get canSave() {
    const toEdit = this.model.filter(c => hasVisibleErrors(c));
    const toAdd = this.toAdd.filter(c => hasVisibleErrors(c));
    return ![...toEdit, ...toAdd].length;
  }

  @action.bound
  addEmptyContact() {
    const newContact = new CompanyContact();
    attachAutomaticValidator(newContact, CompanyContact.ValidationRules);
    this.toAdd.push(newContact);
  }

  @action.bound
  markToDelete(contact: CompanyContact) {
    const index = this.model.indexOf(contact);
    if (index > -1) {
      this.model.splice(index, 1);
      this.toDelete.push(contact);
    }

    const indexToAdd = this.toAdd.indexOf(contact);
    if (indexToAdd > -1) {
      this.toAdd.splice(indexToAdd, 1);
    }
  }

  @bound translate(key: string) {
    return this.localization.translateGeneral(`companyInfo.companyContactsModal.${key}`);
  }

  static Factory({ container }: interfaces.Context) {
    return (companyProfile: CompanyProfile, originalContacts: CompanyContact[], onSuccess: () => void) =>
      new CompanyContactsViewModel(
        companyProfile,
        originalContacts,
        onSuccess,
        container.get(CompanyProfileRepository),
        container.get("ILocalizationService")
      );
  }
}
