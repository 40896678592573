import { ILocalizationService } from "@emanprague/shared-services";
import { bound } from "@frui.ts/helpers";
import { BusyWatcher, ScreenBase, watchBusy } from "@frui.ts/screens";
import { interfaces } from "inversify";
import { action, observable } from "mobx";
import CompanyCredentialsRepository from "repositories/companyCredentialsRepository";
import { API_URI } from "config";
import CompanyCredentialsImportResult from "entities/companyCredentialsImportResult";

export default class ImportCredentialsViewModel extends ScreenBase {
  navigationName = "import";
  busyWatcher = new BusyWatcher();
  @observable file?: File;
  @observable importResult?: CompanyCredentialsImportResult;
  @observable templateUrl?: string;

  constructor(
    private companyId: string,
    public localization: ILocalizationService,
    private repository: CompanyCredentialsRepository
  ) {
    super();

    this.name = this.translate("title");
    this.templateUrl = `${API_URI}/userprofile/company/${companyId}/credentials/import/template`;
  }

  @action
  selectFile(file: File) {
    this.file = file;
  }

  @action.bound
  removeFile() {
    this.file = undefined;
  }

  @action.bound
  @watchBusy
  async importCredentials() {
    if (this.file) {
      const response = await this.repository.import(this.companyId, this.file);
      if (response.success) {
        const result = await response.payload.json();
        this.setImportResult(result);
      }
    }
  }

  @action setImportResult(result: CompanyCredentialsImportResult) {
    this.importResult = result;
  }

  @bound translate(key: string) {
    return this.localization.translateGeneral(`credentials.import.${key}`);
  }

  static Factory({ container }: interfaces.Context) {
    return (companyId: string) =>
      new ImportCredentialsViewModel(
        companyId,
        container.get("ILocalizationService"),
        container.get(CompanyCredentialsRepository)
      );
  }
}
