import { ILocalizationService, IEventBus } from "@emanprague/shared-services";
import { bound } from "@frui.ts/helpers";
import { BusyWatcher, ScreenBase, watchBusy } from "@frui.ts/screens";
import { computed, observable, runInAction, toJS } from "mobx";
import { interfaces } from "inversify";
import ComplianceRepository from "repositories/complianceRepository";
import CompanyProfileRepository from "repositories/companyProfileRepository";
import CompanySafetyPolicies from "entities/companySafetyPolicies";
import EnumsService from "services/enumsService";
import ComplianceRequirementApprovalResult from "entities/complianceRequirementApprovalResult";
import UserContext from "services/userContext";
import AccountType from "entities/accountType";
import { CompanyEvents } from "services/events";

type Model = {
  safetyPolicies?: CompanySafetyPolicies;
};

export default class SafetyProgramsViewModel extends ScreenBase {
  navigationName = "safetyPrograms";
  busyWatcher = new BusyWatcher();
  @observable model: Model = { safetyPolicies: undefined };
  @observable requestMessage: string;

  get safetyPolicies() {
    return (
      toJS(this.model.safetyPolicies?.workTypes)
        ?.flatMap(workType =>
          workType.safetyPolicies.map(el => ({
            ...el,
            workType: this.workTypes.find(({ id }) => id === workType.workTypeId),
          }))
        )
        ?.filter(el => !!el)
        ?.map(el => {
          return {
            ...el,
            safetyPolicy: this.codeListSafetyPolicies.find(({ id }) => id === el.safetyPolicyId),
          };
        }) ?? []
    );
  }

  get workTypes() {
    return this.enumsService.workTypes;
  }

  get codeListSafetyPolicies() {
    return this.enumsService.safetyPolicies;
  }

  get status() {
    return this.safetyApproval;
  }

  constructor(
    private companyId: string,
    private connectionId: string,
    private safetyApproval: ComplianceRequirementApprovalResult,
    public localization: ILocalizationService,
    private repository: ComplianceRepository,
    private companyRepository: CompanyProfileRepository,
    private enumsService: EnumsService,
    private userContext: UserContext,
    private eventBus: IEventBus
  ) {
    super();

    this.name = this.translate("title");
  }

  @computed
  get isOperator() {
    return this.userContext.identity?.accountType === AccountType.Operator;
  }

  @bound translate(key: string) {
    return this.localization.translateGeneral(`compliance.safetyPrograms.${key}`);
  }

  onInitialize() {
    this.loadSafetyPolicies();
  }

  @bound
  @watchBusy
  async loadSafetyPolicies() {
    const result = await this.companyRepository.getSafetyPolicies(this.companyId);
    if (result.success) {
      runInAction(() => {
        this.model.safetyPolicies = result.payload;
      });
    }
  }

  @bound
  @watchBusy
  async save() {
    const result = await this.repository.requestSafetyPolicyReview(this.connectionId);

    if (result.success) {
      this.requestClose();
      this.eventBus.publish(CompanyEvents.connectionPointsUpdated(undefined));
    }
  }

  @bound
  @watchBusy
  async submitReview(approved: boolean) {
    const result = await this.repository.submitSafetyPolicyReview(this.connectionId, approved, this.requestMessage);

    if (result.success) {
      this.requestClose();
      this.eventBus.publish(CompanyEvents.safetyPoliciesApproved(undefined));
    }
  }

  @bound cancel() {
    this.requestClose();
  }

  static Factory({ container }: interfaces.Context) {
    return (companyId: string, connectionId: string, safetyApproval: ComplianceRequirementApprovalResult) =>
      new SafetyProgramsViewModel(
        companyId,
        connectionId,
        safetyApproval,
        container.get("ILocalizationService"),
        container.get(ComplianceRepository),
        container.get(CompanyProfileRepository),
        container.get(EnumsService),
        container.get(UserContext),
        container.get("IEventBus")
      );
  }
}
