import { Card, RoundedButton, Row, Col } from "@emanprague/veralinkkit";
import { ViewComponent } from "@frui.ts/views";
import React from "react";
import Edit from "@emanprague/veralinkkit/dist/Icons/Edit";
import { observer } from "mobx-react-lite";
import CompanyContactsGroup from "./companyContactsGroup";
import CompanyProfileViewModel from "viewModels/company/companyInfo/companyProfileViewModel";

const CompanyContactsSection: ViewComponent<CompanyProfileViewModel> = observer(({ vm }) => {
  const { translateGeneral } = vm.localization;
  const tg = (code: string) => translateGeneral(`companyInfo.subModule.${code}`);

  return (
    <Card className="company-info__card">
      <Card.Header>
        <h4>{tg("companyContacts")}</h4>
        {vm.isEdit && (
          <RoundedButton onClick={vm.openEditCompanyContacts}>
            <Edit fontSize={20} />
          </RoundedButton>
        )}
      </Card.Header>
      <Card.Body>
        <Row>
          {Object.keys(vm.companyContactsGrouped).map(key => (
            <Col md={6} key={key}>
              <CompanyContactsGroup label={key} contacts={vm.companyContactsGrouped[key]} />
            </Col>
          ))}
        </Row>
      </Card.Body>
    </Card>
  );
});

export default CompanyContactsSection;
