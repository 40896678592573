import { Button, LoaderOverlay } from "@emanprague/veralinkkit";
import { registerView } from "@frui.ts/views";
import React from "react";
import EditTeamViewModel from "viewModels/company/projects/teams/editTeamViewModel";
import ModalView, { ModalSize } from "views/common/modalView";
import TeamFormView from "./teamFormView";

import "../../index.scss";

class EditTeamModalView extends ModalView<EditTeamViewModel> {
  size: ModalSize = "lg";

  renderBody(vm: EditTeamViewModel) {
    return (
      <>
        <LoaderOverlay loading={vm.busyWatcher.isBusy} variant="light">
          <TeamFormView vm={vm.formVM} />
        </LoaderOverlay>
      </>
    );
  }

  renderFooter(vm: EditTeamViewModel) {
    return (
      <div className="editTeam__footer">
        <Button variant={"danger"} disabled={vm.busyWatcher.isBusy} onClick={vm.deleteTeam}>
          {vm.translate("delete")}
        </Button>
        <div>
          <Button variant={"outline-primary"} disabled={vm.busyWatcher.isBusy} onClick={() => vm.requestClose()}>
            {vm.translate("cancel")}
          </Button>
          <Button variant="primary" disabled={vm.busyWatcher.isBusy} onClick={vm.editTeam}>
            {vm.translate("save")}
          </Button>
        </div>
      </div>
    );
  }
}

registerView(EditTeamModalView as any, EditTeamViewModel);
