import { ILocalizationService } from "@emanprague/shared-services";
import differenceInDays from "date-fns/differenceInDays";
import differenceInMonths from "date-fns/differenceInMonths";
import React, { FunctionComponent } from "react";
import "./workerPortal.scss";

interface AvailabilityStatusProps {
  availableFrom?: Date;
  localization: ILocalizationService;
}

const AvailabilityStatus: FunctionComponent<AvailabilityStatusProps> = ({ availableFrom, localization }) => {
  const tg = (code: string) => localization.translateGeneral(`workerPortal.workerSearchCard.${code}`);
  const tm = localization.translateModel;

  // not available
  if (!availableFrom) {
    return <p className="worker-search-card__availability red">{tg("availableNot")}</p>;
  }

  // make sure, it's date
  availableFrom = new Date(availableFrom);

  //available now
  if (availableFrom < new Date()) {
    return <p className="worker-search-card__availability green">{tg("availableNow")}</p>;
  }

  // available in future
  const monthsDiff = differenceInMonths(availableFrom, new Date());
  const daysDiff = differenceInDays(availableFrom, new Date()) || 1;

  return (
    <p className="worker-search-card__availability orange">
      {tg("availableIn")} {monthsDiff < 1 ? daysDiff : monthsDiff}
      &nbsp;
      {monthsDiff < 1 ? tm("day", daysDiff) : tm("month", monthsDiff)}
    </p>
  );
};

export default AvailabilityStatus;
