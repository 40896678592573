import { Alert, Card, Col, LoaderOverlay, Row } from "@emanprague/veralinkkit";
import { registerView, ViewComponent } from "@frui.ts/views";
import { observer } from "mobx-react-lite";
import React from "react";
import ConfirmViewModel from "viewModels/signup/confirmViewModel";
import "assets/themes/signup.scss";
import { getClickableMail } from "utils/viewUtils";

const confirmListView: ViewComponent<ConfirmViewModel> = observer(({ vm }) => (
  <Card>
    <Card.Body className="signup-success signup">
      <Row className="justify-content-center">
        <Col md={8}>
          <h1 className="display-5 text-center">{vm.translate("title")}</h1>

          {!vm.awaiting && (
            <LoaderOverlay loading={vm.busyWatcher.isBusy} variant="light">
              {vm.errorMessage && <Alert variant="danger">{vm.errorMessage}</Alert>}
            </LoaderOverlay>
          )}

          {vm.awaiting && (
            <div className="text-center">
              <p>{vm.translate("awaiting.thankYou")}</p>
              <p>{vm.translate("awaiting.weAre")}</p>
              <p>
                {vm.translate("awaiting.contactUs")}&nbsp;{getClickableMail("info@veralink.io")}&nbsp;
                {vm.translate("awaiting.callUs")}
              </p>
              <p>
                {vm.translate("awaiting.sincerely")}
                <br />
                {vm.translate("awaiting.theVeraLinkTeam")}
              </p>
            </div>
          )}
        </Col>
      </Row>
    </Card.Body>
  </Card>
));

registerView(confirmListView, ConfirmViewModel);
