import React, { useState } from "react";
import { observer } from "mobx-react-lite";
import Down from "@emanprague/veralinkkit/dist/Icons/Arrow Simple/Down";
import Up from "@emanprague/veralinkkit/dist/Icons/Arrow Simple/Up";
import "./filterItem.scss";

export interface IFilterItemProps {
  label: string;
  collapsedContent: string;
  content: React.ReactNode;
}

const FilterItem: React.FunctionComponent<IFilterItemProps> = observer(({ label, collapsedContent, content }) => {
  const [open, setOpen] = useState(false); // Mobx observable doesn't work for FunctionComponents

  return (
    <div className="filter-item">
      <div onClick={() => setOpen(!open)}>
        {label}
        {open ? <Up fontSize={24} color="#888F91" /> : <Down fontSize={24} color="#888F91" />}
      </div>

      <div>{open ? content : collapsedContent}</div>
    </div>
  );
});

export default FilterItem;
