import { observable } from "mobx";
import { Type } from "class-transformer";
import CompanyEmployee from "./companyEmployee";

// This entity has been generated, do not change its content, your changes might get lost. You CAN modify the rest of the file.
export default class CompanyEmployeesSearchResult {
  @observable
  @Type(() => CompanyEmployee)
  employees!: CompanyEmployee[];

  @observable
  total!: number;

  static ValidationRules = {
    employees: { required: true },
    total: { required: true, number: true },
  };
}
