const questions = [
  { name: "workPerformed", type: "select", readonly: false, variant: "bold" },
  { name: "averageNumberOfEmployees", type: "number", readonly: false },
  { name: "numberOfExposureOrEmployeeHours", type: "number", readonly: false },
  { name: "totalNumberOfRecordableCases", type: "number", readonly: false },
  { name: "totalNumberOfOtherRecordableCases", type: "number", readonly: false },
  { name: "totalNumberOfRecordableCasesIncludingNumberOfOtherRecordableCases", type: "number", readonly: true },
  { name: "totalRecordableIncidentRate", type: "number", readonly: true },
  { name: "numberOfCasesWithDaysAwayFromWork", type: "number", readonly: false },
  { name: "numberOfDaysAwayFromWork", type: "number", readonly: false },
  { name: "lostTimeCasesRate", type: "number", readonly: true },
  { name: "daysAwayRestrictedOrTransferRate", type: "number", readonly: true },
  { name: "lostWorkDayRate", type: "number", readonly: true },
  { name: "numberOfCasesWithRestrictedDutyJobTransferWorkDays", type: "number", readonly: false },
  { name: "restrictedWorkDayCaseRateIncludingJobTransferWorkDayCases", type: "number", readonly: true },
  { name: "numberOfRestrictedDutyJobTransferWorkDays", type: "number", readonly: false },
  { name: "numberOfRecordableCasesThatDoesNotHaveRestrictedOrLostWorkDays", type: "number", readonly: false },
  { name: "numberOfFatalities", type: "number", readonly: false },
  { name: "severityRate", type: "number", readonly: true },

  /**
   * Q not-available, Y read-write
   */

  { name: "averageNumberOfEmployeesThirdParty", type: "number", readonly: false },
  /**
   * Q not-available, Y read-only
   */

  { name: "numberOfExpossureOrEmployeeHoursPerEmployee", type: "number", readonly: false },
  /**
   * Q not-available, Y read-write
   */

  { name: "numberOfFatalitiesThirdParty", type: "number", readonly: false },

  /**
   * Q not-available, Y read-write
   */

  { name: "totalNumberOfNonFatalRecordableCases", type: "number", readonly: false },

  /**
   * Q not-available, Y read-only
   */

  { name: "nonFatalTotalRecordableIncidentRate", type: "number", readonly: true },

  /**
   * Q not-available, Y read-only
   */

  { name: "fatalityRateBasedOn100Employees", type: "number", readonly: true },

  /**
   * Q not-available, Y read-only
   */

  { name: "fatalityRateBasedOn100000Employees", type: "number", readonly: true },

  /**
   * Q not-available, Y read-only
   */

  /**
   * Q not-available, Y read-write
   */
  {
    name: "severityRateIncludesTotalNumberOfRecordableCasesIncludingOtherRecordableCases",
    type: "number",
    readonly: true,
  },

  {
    name: "severityRateIncludesTotalNumberOfRecordableCasesIncludingOtherRecordableCasesThirdParty",
    type: "number",
    readonly: false,
  },

  /**
   * Q not-available, Y read-only
   */

  { name: "severityRatePer200000HoursWorked", type: "number", readonly: true },

  /**
   * Q not-available, Y read-write
   */

  { name: "totalNumberOfVehicleAccidents", type: "number", readonly: false },

  /**
   * Q not-available, Y read-write
   */

  { name: "totalMilesDriven", type: "number", readonly: false },

  /**
   * Q not-available, Y read-only
   */

  { name: "vehicleAccidentRate", type: "number", readonly: true },

  /**
   * Q not-available, Y read-only
   */

  { name: "otherRecordableCasesRate", type: "number", readonly: true },

  /**
   * Q not-available, Y read-write
   */

  { name: "totalIncidentsResultingInAmputation", type: "number", readonly: false },

  /**
   * Q not-available, Y read-write
   */

  { name: "totalIncidentsResultingInInPatientHospitalization", type: "number", readonly: false },

  /**
   * Q not-available, Y read-write
   */

  { name: "totalIncidentsResultingInEyeLoss", type: "number", readonly: false },

  /**
   * Q not-available, Y read-write
   */

  { name: "totalIncidentsResultingInAmputationInPatientHospitalizationOrEyeLoss", type: "number", readonly: false },

  /**
   * Q not-available, Y read-only
   */

  { name: "seriousInjuryCaseRate", type: "number", readonly: true },
];

export default questions;
