import { ValidationControlBase } from "@frui.ts/bootstrap";
import { Uploader } from "@emanprague/veralinkkit";
import React from "react";
import FileUploadProgress from "views/worker/credentials/components/FileUploadProgress";
import { UploaderProps } from "@emanprague/veralinkkit/dist/Uploader";
import { bound } from "@frui.ts/helpers";
import { observable } from "mobx";

export class FileBox<T> {
  @observable file?: T;
  @observable error?: string;
  @observable progress: number = 0;
}

type Props = UploaderProps & {
  files?: FileBox<any>[];
  onRemove: (file: FileBox<any>) => void;
};

export default class FileUpload<TTarget> extends ValidationControlBase<TTarget, Props> {
  static defaultProps = {
    accept: [".png", ".jpg", ".jpeg"],
  };

  @bound protected renderInner() {
    const { localization, files, onRemove, accept, onDrop } = this.props;
    return (
      <>
        <Uploader error={this.props.error} accept={accept} onDrop={onDrop} localization={localization} />

        {files?.map((file, idx) => (
          <FileUploadProgress
            key={`${file.file?.name}-${idx}`}
            progressBar={file?.progress}
            error={file?.error}
            file={file?.file}
            onRemove={() => onRemove(file)}
          />
        ))}
      </>
    );
  }
}
