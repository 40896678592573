import { IEventBus } from "@emanprague/shared-services";
import AuthenticationCredentials from "entities/authenticationCredentials";
import NewAccount from "entities/newAccount";
import VerifyUser from "entities/verifyUser";
import BackendConnector from "./backendConnector";
import RepositoryBase from "./repositoryBase";
import { NewWorker } from "entities/newWorker";

export default class SignupRepository extends RepositoryBase {
  constructor(connector: BackendConnector, protected eventBus: IEventBus) {
    super(connector, eventBus);
    this.apiFactory = connector.getRequestBuilder;
  }

  async verifyUserName(username: string) {
    const result = await this.callApi(api => api.all("auth/check_username").getRaw({ username }), false);
    const payload = result.payload as Response;

    return payload.json();
  }

  resendEmail(email: string) {
    return this.callApi(api => api.all("auth/users/resend_verification_email").postOnly({ email }));
  }

  createAccount(account: NewAccount) {
    return this.callApi(api => api.all("auth/accounts").postEntity(account));
  }

  reclaimPendingProfile(username: string, account: NewAccount) {
    return this.callApi(api => api.all(`auth/worker_import/pending/${username}/claim`).postEntity(account));
  }

  completeWorkerImportRegistration(importToken: string, account: NewAccount) {
    return this.callApi(api =>
      api.all(`auth/worker_import/complete_registration?importToken=${importToken}`).postEntity(account)
    );
  }

  getPendingProfile(username: string) {
    return this.callApi(api => api.all(`auth/worker_import/pending/${username}`).getEntity(NewWorker));
  }

  verifyUserToken(verifyDto: VerifyUser) {
    return this.callApi(api => api.all("auth/users/verify").postEntity(verifyDto, AuthenticationCredentials));
  }
}
