import { observable } from "mobx";

// This entity has been generated, do not change its content, your changes might get lost. You CAN modify the rest of the file.
export default class SafetyMetricPointsRatio {
  /**
   * @example
   * 40
   */
  @observable
  questionnaire!: number;

  /**
   * @example
   * 60
   */
  @observable
  indicators!: number;

  static ValidationRules = {
    questionnaire: { required: true, number: true },
    indicators: { required: true, number: true },
  };
}
