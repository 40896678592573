import { observable } from "mobx";

// This entity has been generated, do not change its content, your changes might get lost. You CAN modify the rest of the file.
export default class QuestionnaireQuestionShowOnlyIf {
  @observable
  questionId!: string;

  @observable
  answerId!: string;

  @observable
  answerBoolean?: boolean;

  static ValidationRules = {
    questionId: { required: true },
    answerId: { required: true },
  };
}
