import { Alert } from "@emanprague/veralinkkit";
import { registerView, ViewComponent } from "@frui.ts/views";
import { observer } from "mobx-react-lite";
import React from "react";
import ToastViewModel from "viewModels/toastViewModel";

const toastView: ViewComponent<ToastViewModel> = observer(({ vm }) => {
  return (
    <Alert variant={vm.variant} dismissible onClose={vm.onClose}>
      {JSON.stringify(vm.message)}
    </Alert>
  );
});

export default registerView(toastView, ToastViewModel);
