import { ILocalizationService, IEventBus } from "@emanprague/shared-services";
import { bound } from "@frui.ts/helpers";
import { BusyWatcher, ScreenBase, watchBusy } from "@frui.ts/screens";
import { interfaces } from "inversify";
import { observable } from "mobx";
import { attachAutomaticValidator, validate } from "@frui.ts/validation";
import ComplianceRepository from "repositories/complianceRepository";
import ExperienceModifierDefinition, { ExperienceModifierDefinitionValidator } from "entities/experienceModifierDefinition";
import { ComplianceDefinitionEvents } from "services/events";

export default class ExperienceModifierViewModel extends ScreenBase {
  navigationName = "experienceModifier";
  busyWatcher = new BusyWatcher();

  @observable experienceModifier: ExperienceModifierDefinition;

  constructor(
    private companyId: string,
    experienceModifier: ExperienceModifierDefinition,
    public localization: ILocalizationService,
    private repository: ComplianceRepository,
    private eventBus: IEventBus
  ) {
    super();

    this.name = this.translate("title");
    this.experienceModifier = experienceModifier;
    attachAutomaticValidator(this.experienceModifier, ExperienceModifierDefinitionValidator.ValidationRules);
  }

  @bound translate(key: string) {
    return this.localization.translateGeneral(`compliance.experienceModifier.${key}`);
  }

  @bound
  @watchBusy
  async save() {
    if (validate(this.experienceModifier)) {
      const res = await this.repository.saveExperienceModifier(this.companyId, this.experienceModifier);

      if (res.success) {
        this.eventBus.publish(ComplianceDefinitionEvents.experienceModifierUpdated(res.payload));
        this.requestClose();
      }
    }
  }

  @bound cancel() {
    this.requestClose();
  }

  static Factory({ container }: interfaces.Context) {
    return (companyId: string, experienceModifier: ExperienceModifierDefinition) =>
      new ExperienceModifierViewModel(
        companyId,
        experienceModifier,
        container.get("ILocalizationService"),
        container.get(ComplianceRepository),
        container.get("IEventBus")
      );
  }
}
