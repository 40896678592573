import React from "react";
import FormTeamViewModel from "viewModels/company/projects/teams/formTeamViewModel";
import { Button, Col, Form, Row } from "@emanprague/veralinkkit";
import { Input, Select } from "@frui.ts/bootstrap";
import Delete from "@emanprague/veralinkkit/dist/assets/icons/Delete.svg";
import { Plus } from "@emanprague/veralinkkit/dist/Icons";
import { observer } from "mobx-react-lite";
import FormCheck from "controls/formCheck";

interface TeamFormViewProps {
  vm: FormTeamViewModel;
}

const teamFormView = observer(({ vm }: TeamFormViewProps) => {
  const ta = (key: string) => vm.localization.translateAttribute("companyTeam", key);

  const renderAssign = () => {
    if (!vm.team.assignType) {
      return null;
    }

    if (vm.team.assignType === "assignToContractor") {
      return (
        <Form.Group>
          <Select
            mode="key"
            value={vm.team.assignedContractorId}
            target={vm.team}
            property="assignedContractorId"
            items={vm.contractors}
            keyProperty="accountId"
            textProperty="companyName"
          />
        </Form.Group>
      );
    }

    if (vm.team.assignType === "assignToSubcontractor") {
      return (
        <Form.Group>
          <Form.Label>
            <b>{ta("assignedSubcontractorName")}</b>
          </Form.Label>
          <Input target={vm.team} property="assignedSubcontractorName" />
        </Form.Group>
      );
    }
  };

  return (
    <>
      <Row>
        <Col md={12}>
          <Form.Group>
            <Form.Label>
              <b>{ta("industry")}</b>
            </Form.Label>
            <Select mode="item" target={vm.team} property="industry" items={vm.industries} textProperty="name" />
          </Form.Group>
        </Col>
        <Col lg={6} md={12}>
          <Form.Group>
            <Form.Label>
              <b>{ta("profession")}</b>
            </Form.Label>
            <Select
              mode="item"
              target={vm.team}
              property="profession"
              items={vm.professions}
              textProperty="name"
              disabled={!vm.team.industry}
            />
          </Form.Group>
        </Col>
        <Col lg={6} md={12}>
          <Form.Group>
            <Form.Label>
              <b>{ta("nWorkersWanted")}</b>
            </Form.Label>
            <Input type="number" target={vm.team} property="nWorkersWanted" disabled={!vm.team.profession} />
          </Form.Group>
        </Col>
      </Row>
      <h6>{ta("requiredCredentials")}</h6>
      <Row>
        <Col>
          {vm.projectRequirements.map((cred, index) => (
            <div key={index} className="d-flex mt-2 mb-2">
              <Input className="font-bold" target={vm.projectRequirements[index]} property="name" />

              <Button variant="link" onClick={() => vm.removeRequirement(index)}>
                <img src={Delete} alt="delete" className="ml-4" />
              </Button>
            </div>
          ))}
        </Col>
      </Row>
      <Form.Group>
        <Button variant="outline-primary" onClick={vm.addRequirement}>
          <Plus className="mr-2" />
          {ta("addRequirement")}
        </Button>
      </Form.Group>
      {vm.assignOptions.map(name => (
        <Form.Group key={name}>
          <FormCheck id={name} label={ta(name)} type="radio" target={vm.team} property="assignType" />
        </Form.Group>
      ))}
      {renderAssign()}
    </>
  );
});

export default teamFormView;
