import { computed, observable } from "mobx";
import { Type } from "class-transformer";
import ConnectionRequesterDetail from "./connectionRequesterDetail";

// This entity has been generated, do not change its content, your changes might get lost. You CAN modify the rest of the file.
class Connection {
  id?: string;

  @observable
  requesterId!: string;

  @observable
  requesteeId!: string;

  @observable
  accepted!: boolean;

  @observable
  @Type(() => ConnectionRequesterDetail)
  requesterDetail!: ConnectionRequesterDetail;

  static ValidationRules = {
    requesterId: { required: true },
    requesteeId: { required: true },
    accepted: { required: true },
    requesterDetail: { required: true },
  };
}

export default class EConnection extends Connection {
  @computed
  get name() {
    return this.requesterDetail.companyName || "";
  }
}
