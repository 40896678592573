import { bound } from "@frui.ts/helpers";
import { BindingComponent, IBindingProps } from "@frui.ts/views";
import React from "react";
import { Form, FormCheckProps } from "@emanprague/veralinkkit";
import { Observer } from "mobx-react-lite";

export default class FormCheck<TTarget> extends BindingComponent<
  TTarget,
  FormCheckProps & IBindingProps<TTarget> & { value?: any }
> {
  render() {
    return <Observer render={this.renderInner} />;
  }

  @bound protected renderInner() {
    return <Form.Check {...this.inheritedProps} custom checked={this.isChecked} onChange={this.handleValueChanged} />;
  }

  @bound protected handleValueChanged(e: React.FormEvent<any>) {
    const { id, value, type } = this.props;
    if (type === "radio") {
      this.setValue(value ?? id);
    } else {
      this.setValue(!this.value);
    }
  }

  get isChecked() {
    const { id, type, value } = this.props;
    if (type === "radio") {
      return this.value === (value ?? id);
    } else {
      return this.value === true;
    }
  }
}
