import { IInitializationService, ILocalizationService } from "@emanprague/shared-services";
import { validatorsRepository } from "@frui.ts/validation";
import { observable, reaction, runInAction } from "mobx";
import EnumsService from "./enumsService";
import SecurityService from "./securityService";
import UserContext from "./userContext";
import ValidationService from "./validation/validationService";

export default class InitializationService implements IInitializationService {
  @observable isPreLoginInitialized = false;
  @observable isPostLoginInitialized = false;

  constructor(
    userContext: UserContext,
    private localization: ILocalizationService,
    private validation: ValidationService,
    private security: SecurityService,
    private enums: EnumsService
  ) {
    reaction(
      () => userContext.isLogged,
      isLogged => {
        if (!isLogged && this.isPostLoginInitialized) {
          runInAction(() => (this.isPostLoginInitialized = false));
        }
      }
    );
  }

  async initIdentity() {
    const asyncCalls = Promise.all([
      this.security.checkStoredIdentity(),
      this.localization.initialize("en"),
      this.enums.initialize(),
    ]);
    this.validation.initialize(validatorsRepository);

    const [identity] = await asyncCalls;
    runInAction(() => {
      identity.applyIdentity();
    });
  }

  async preLoginInitialize() {
    await this.initIdentity();
    runInAction(() => {
      this.isPreLoginInitialized = true;
    });
  }

  async postLoginInitialize() {
    runInAction(() => (this.isPostLoginInitialized = true));
  }

  async postLogoutCleanup() {
    runInAction(() => (this.isPostLoginInitialized = false));
  }
}
