import { registerView, View, ViewComponent } from "@frui.ts/views";
import { observer } from "mobx-react-lite";
import React from "react";
import SignupRootViewModel from "viewModels/signup/signupRootViewModel";

const signupRootView: ViewComponent<SignupRootViewModel> = observer(({ vm }) => {
  return <View vm={vm.activeChild} />;
});

registerView(signupRootView, SignupRootViewModel);
