import { Exclude } from "class-transformer";
import merge from "lodash/merge";
import { observable } from "mobx";
import { EntityValidationRules } from "services/validation/entityValidationRules";

// This entity has been generated, do not change its content, your changes might get lost. You CAN modify the rest of the file.
export class NewContractor {
  /**
   * Company name
   */
  @observable
  companyName!: string;

  /**
   * Tax Identification Number
   */
  @observable
  taxId!: string;

  /**
   * User’s email address
   */
  @observable
  email!: string;

  /**
   * User name
   */
  @observable
  username!: string;

  /**
   * User password
   */
  @observable
  password!: string;

  static ValidationRules = {
    companyName: { required: true },
    taxId: { required: true },
    email: { format: "email", required: true },
    username: { required: true },
    password: { required: true },
  };
}

export default class NewContractorPassword extends NewContractor {
  @Exclude()
  @observable
  repeatPassword!: string;

  @observable consent: boolean;

  static ValidationRules = merge(
    {
      email: { isEmail: true },
      repeatPassword: { required: true, equalWith: "password" },
      consent: { isChecked: true },
    } as EntityValidationRules<NewContractor>,
    NewContractor.ValidationRules
  );
}
