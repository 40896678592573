import { ILocalizationService } from "@emanprague/shared-services";
import { bound } from "@frui.ts/helpers";
import { BusyWatcher, ScreenBase, watchBusy } from "@frui.ts/screens";
import { classToClass } from "class-transformer";
import CompanyProject from "entities/companyProject";
import LocationSimple from "entities/locationSimple";
import { interfaces } from "inversify";
import { observable, runInAction } from "mobx";
import ProjectRepository from "repositories/projectRepository";
import EnumsService from "services/enumsService";
import { attachAutomaticValidator, validate } from "@frui.ts/validation";

export default class EditProjectViewModel extends ScreenBase {
  navigationName = "edit";
  busyWatcher = new BusyWatcher();
  @observable item: CompanyProject;

  constructor(
    private companyId: string,
    item: CompanyProject,
    public localization: ILocalizationService,
    private repository: ProjectRepository,
    public enums: EnumsService
  ) {
    super();

    this.name = this.translate("title");
    runInAction(() => {
      this.item = classToClass(item);
    });
    attachAutomaticValidator(this.item, CompanyProject.ValidationRules);
    attachAutomaticValidator(this.item.location, LocationSimple.ValidationRules);
  }

  get states() {
    return this.enums.states;
  }

  @bound
  @watchBusy
  async updateProject() {
    if (validate(this.item)) {
      const result = await this.repository.updateProject(this.companyId, this.item.id!, this.item);
      if (result.success) {
        this.requestClose();
      }
    }
  }

  @bound translate(key: string) {
    return this.localization.translateGeneral(`projects.edit.${key}`);
  }

  static Factory({ container }: interfaces.Context) {
    return (companyId: string, item: CompanyProject) =>
      new EditProjectViewModel(
        companyId,
        item,
        container.get("ILocalizationService"),
        container.get(ProjectRepository),
        container.get(EnumsService)
      );
  }
}
