import { Type } from "class-transformer";
import { observable } from "mobx";
import Message from "./message";

// This entity has been generated, do not change its content, your changes might get lost. You CAN modify the rest of the file.
export default class ListOfMessages {
  @observable
  total!: number;

  @observable
  @Type(() => Message)
  messages!: Message[];

  static ValidationRules = {
    total: { required: true, number: true },
    messages: { required: true },
  };
}
