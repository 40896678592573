import React from "react";
import { registerView, View, ViewComponent } from "@frui.ts/views";
import { observer } from "mobx-react-lite";
import CompanyInfoSection from "./sections/companyInfoSection";
import CompanyServicesSection from "./sections/companyServicesSection";
import CompanyContactsSection from "./sections/companyContactsSection";
import CompanyLocationsSection from "./sections/companyLocationsSection";
import CompanyWorkTypesSection from "./sections/companyWorkTypesSection";
import CompanyProfileViewModel from "viewModels/company/companyInfo/companyProfileViewModel";
import "./companyInfo.scss";

const companyProfileView: ViewComponent<CompanyProfileViewModel> = observer(({ vm }) => {
  if (!vm.companyProfile) {
    return <></>;
  }

  return (
    <>
      <h4 className="company__header">{vm.companyProfile.companyName}</h4>

      <CompanyInfoSection vm={vm} />

      <CompanyContactsSection vm={vm} />

      <CompanyWorkTypesSection vm={vm} />

      <CompanyServicesSection vm={vm} />

      <CompanyLocationsSection vm={vm} />

      <View vm={vm.activeChild} />
    </>
  );
});

registerView(companyProfileView, CompanyProfileViewModel);
