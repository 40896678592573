import { expiredState } from "../utils";
import CredentialOverviewViewModel from "viewModels/worker/credentials/credentialOverviewViewModel";
import classnames from "classnames";
import differenceInDays from "date-fns/differenceInDays";
import React from "react";
import { fmtDate } from "utils";
import { CredentialsOk } from "@emanprague/veralinkkit/dist/Icons";

const CredentialStatus: React.FC<{ vm: CredentialOverviewViewModel }> = ({ vm }) => {
  const daysCount = Math.abs(differenceInDays(new Date(), vm.credential.validityTo));
  const currentState = expiredState(vm.credential.validityFrom, vm.credential.validityTo);
  const classNames = classnames("credential-status", {
    "is-expired": currentState === "isExpired",
    "will-expire": currentState === "willExpire",
  });

  return (
    <p className={classNames}>
      {currentState !== "isGood" &&
        vm.translate(currentState, { expiresIn: daysCount, validityTo: fmtDate(vm.credential.validityTo) })}
      &nbsp;
      {vm.credential.verified && <CredentialsOk width={32} height={32} />}
    </p>
  );
};

export default CredentialStatus;
