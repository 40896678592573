import React from "react";
import UserAvatar from "views/common/userAvatar";

type EmployeeInfoProps = {
  tg: any;
  employee: any;
  enums: any;
  hideLocation?: boolean;
};

const EmployeeInfo = ({ tg, employee, enums, hideLocation }: EmployeeInfoProps) => {
  const isPending = employee.pendingRegistration;
  const showLocation = !isPending && (hideLocation === false || hideLocation === undefined);

  return (
    <div className="worker-search-card__header">
      <UserAvatar url={employee.profilePhotoUrl} />
      <div>
        <h5>{[employee.firstName, employee.middleName, employee.lastName].filter(x => x !== undefined).join(" ")}</h5>
        {isPending && <span>{tg("pending")}</span>}
        {showLocation && (
          <span>
            {[employee.location?.city, enums.getStateById(employee.location?.state)?.name]
              .filter(x => x !== undefined)
              .join(", ") || tg("unknownLocation")}
          </span>
        )}
      </div>
    </div>
  );
};
export default EmployeeInfo;
