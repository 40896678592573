import { ILocalizationService } from "@emanprague/shared-services";
import { bound } from "@frui.ts/helpers";
import { BusyWatcher, ScreenBase, watchBusy } from "@frui.ts/screens";
import { observable } from "mobx";
import { interfaces } from "inversify";

export default class ThresholdEditModalViewModel extends ScreenBase {
  navigationName = "thresholdEditModal";
  busyWatcher = new BusyWatcher();

  @observable thresholds: any[] = [];

  constructor(private companyId: string, thresholds: any[], public localization: ILocalizationService) {
    super();
    this.thresholds = thresholds;

    this.name = this.translate("title");
  }

  @bound translate(key: string) {
    return this.localization.translateGeneral(`compliance.thresholdEditModal.${key}`);
  }

  @bound
  @watchBusy
  async save() {}

  @bound cancel() {
    this.requestClose();
  }

  static Factory({ container }: interfaces.Context) {
    return (companyId: string, thresholds: any[]) =>
      new ThresholdEditModalViewModel(
        companyId,
        thresholds,
        container.get("ILocalizationService")
        // container.get(CompanyCredentialsRepository)
      );
  }
}
