import { ILocalizationService } from "@emanprague/shared-services";
import { bound } from "@frui.ts/helpers";
import { BusyWatcher, ConductorOneChildActive, Router, watchBusy } from "@frui.ts/screens";
import AccountType from "entities/accountType";
import AuthenticationIdentity from "entities/authenticationIdentity";
import CompanyProfile from "entities/companyProfile";
import { action, computed, runInAction } from "mobx";
import CompanyProfileRepository from "repositories/companyProfileRepository";
import UserContext from "services/userContext";
import { IModule, ISubModule } from "viewModels/types";
import CompleteCompanyProfileViewModel from "./companyInfo/modals/completeCompanyProfileViewModel";

export default class ModulePageViewModel extends ConductorOneChildActive<ISubModule> implements IModule {
  orderIndex = 1;
  navigationName = "dashboard";
  busyWatcher = new BusyWatcher();

  constructor(
    private subModules: ISubModule[],
    private companyProfileRepository: CompanyProfileRepository,
    private userContext: UserContext,
    public localization: ILocalizationService,
    private router: Router
  ) {
    super();

    this.name = this.translate("title");

    const currentUser = this.userContext.identity;
    const allowedModules = currentUser ? subModules.filter(x => x.isVisible(currentUser)) : [];
    this.setSubModules(allowedModules);
  }

  get profile() {
    return this.userContext.profile as CompanyProfile;
  }

  protected onInitialize() {
    const mustCompleteProfile = !this.profile.services?.length;
    if (mustCompleteProfile) {
      this.router.navigate(CompleteCompanyProfileViewModel);
    }

    if (!this.activeChild && this.children.length) {
      return this.tryActivateChild(this.children[0]);
    }
  }

  @action
  public setSubModules(subModules: ISubModule[]) {
    subModules.sort((a, b) => a.orderIndex - b.orderIndex);
    this.children.push(...subModules);
  }

  @bound
  @watchBusy
  async changeCompanyPhoto(file: File) {
    const result = await this.companyProfileRepository.updatePhoto(this.profile.id, file);

    if (result.success) {
      // todo: fix this in base
      const a = await result.payload.json();
      runInAction(() => {
        this.profile.profilePhotoUrl = a.profilePhotoUrl + `?${new Date().getTime()}`;
      });
    }
  }

  isVisible(identity: AuthenticationIdentity) {
    return identity.accountType === AccountType.Contractor || identity.accountType === AccountType.Operator;
  }

  @computed get isContractorPortalLinkVisible() {
    return this.userContext?.identity?.accountType === AccountType.Operator;
  }

  @bound translate(key: string) {
    return this.localization.translateGeneral(`dashboard.modulePage.${key}`);
  }
}
