import { observable } from "mobx";

export default class ForgotPassword {
  @observable
  email!: string;

  @observable
  username!: string;

  static ValidationRules = {
    username: { requiredIf: { condition: (ent: { email: any }) => !ent.email } },
    email: { requiredIf: { condition: (ent: { username: any }) => !ent.username } },
  };
}
