import { ILocalizationService, IEventBus } from "@emanprague/shared-services";
import { bound, IDisposable } from "@frui.ts/helpers";
import { BusyWatcher, ScreenBase, watchBusy, ConductorOneChildActive } from "@frui.ts/screens";
import { interfaces } from "inversify";
import { computed, observable, runInAction } from "mobx";
import ConnectionsRepository from "repositories/connectionsRepository";
import ContractorConnection from "entities/contractorConnection";
import SafetyCultureQuestionaireViewModel from "../compliance/questionaire/SafetyCultureQuestionaireViewModel";
import SafetyMetricsQuestionnaireViewModel from "../compliance/questionaire/safetyMetricsQuestionnaireViewModel";
import WorkHoursQuestionnaireViewModel from "../compliance/questionaire/workHoursQuestionnaireViewModel";
import ExperienceModifierViewModel from "../compliance/questionaire/experienceModifierViewModel";
import SafetyProgramsViewModel from "../compliance/questionaire/safetyProgramsViewModel";
import InsuranceUploadModalViewModel from "../compliance/questionaire/insuranceUploadModalViewModel";
import UserContext from "services/userContext";
import AccountType from "entities/accountType";
import { CompanyEvents } from "services/events";
import SendMessageViewModel from "viewModels/messages/sendMessageViewModel";

export default class ConnectionDetailViewModel extends ConductorOneChildActive<ScreenBase> {
  busyWatcher = new BusyWatcher();
  @observable model: ContractorConnection;
  selectedElement: any;
  private eventHandlers: IDisposable[];

  constructor(
    private connectionId: string,
    public localization: ILocalizationService,
    private repository: ConnectionsRepository,
    private questionnaireFactory: ReturnType<typeof SafetyCultureQuestionaireViewModel.Factory>,
    private safetyMetricsFactory: ReturnType<typeof SafetyMetricsQuestionnaireViewModel.Factory>,
    private workHoursFactory: ReturnType<typeof WorkHoursQuestionnaireViewModel.Factory>,
    private experienceFactory: ReturnType<typeof ExperienceModifierViewModel.Factory>,
    private safetyPrograms: ReturnType<typeof SafetyProgramsViewModel.Factory>,
    private insuranceFactory: ReturnType<typeof InsuranceUploadModalViewModel.Factory>,
    private sendMessageFactory: ReturnType<typeof SendMessageViewModel.Factory>,
    private userContext: UserContext,
    private eventBus: IEventBus
  ) {
    super();

    this.navigationName = connectionId;
    this.name = this.translate("title");

    this.eventHandlers = [
      this.eventBus.subscribe(CompanyEvents.connectionPointsUpdated, this.loadData),
      this.eventBus.subscribe(CompanyEvents.safetyPoliciesApproved, this.loadData),
    ];
  }

  async initialize() {
    await this.loadData();
  }

  protected async onDeactivate(isClosing: boolean) {
    await super.onDeactivate(isClosing);

    if (isClosing) {
      this.eventHandlers?.forEach(x => x.dispose());
    }
  }

  @computed
  get isOperator() {
    return this.userContext.identity?.accountType === AccountType.Operator;
  }

  makeStatus = (data: any, name: string) => {
    if (data.filled || this.isOperator) {
      return `${name}.filled`;
    }
    return `${name}.required`;
  };

  get points(): any[] {
    const points = this.model?.points ?? {};
    const checks = Object.keys(points) as Array<keyof ContractorConnection>;

    return checks.map(name => {
      const data = (points as any)[name] ?? {};
      return { ...data, name, actionStatus: this.makeStatus(data, name) };
    });
  }

  get total() {
    return this.points
      .reduce((acc, nxt) => {
        return acc + (nxt.givenPoints ? parseFloat(nxt.givenPoints.toFixed(1)) : 0);
      }, 0)
      .toFixed(1);
  }

  @bound
  @watchBusy
  async loadData() {
    const res = await this.repository.loadConnection(this.connectionId);
    runInAction(() => {
      if (res.success) {
        this.model = res.payload;
      }
    });
  }

  @bound openQuestionaire(el: any) {
    this.selectedElement = el;
    this.navigate(el.name, undefined);
  }

  @bound sendMessage() {
    this.navigate("message", undefined);
  }

  findNavigationChild(name: string) {
    switch (name) {
      case "message":
        return this.sendMessageFactory(this.model.companyId!, this.model.companyName!);
      case "generalInformation":
        return this.questionnaireFactory(this.connectionId, "general_information");
      case "contractorSafetyCulture":
        return this.questionnaireFactory(this.connectionId, "safety_culture");
      case "safetyMetrics":
        return this.safetyMetricsFactory(this.connectionId);
      case "workHours":
        return this.workHoursFactory(this.connectionId);
      case "experienceModifier":
        return this.experienceFactory(this.connectionId);
      case "healthAndSafetyPrograms":
        const companyId = this.isOperator ? this.model.companyId! : this.userContext.identity?.accountId!;
        return this.safetyPrograms(companyId, this.connectionId, this.model.points!.healthAndSafetyPrograms!);
      case "insurance":
        return this.insuranceFactory(this.connectionId, this.model.companyId!);
      default:
        return undefined;
    }
  }

  @bound translate(key: string) {
    return this.localization.translateGeneral(`myConnections.detail.${key}`);
  }

  static Factory({ container }: interfaces.Context) {
    return (connectionId: string) =>
      new ConnectionDetailViewModel(
        connectionId,
        container.get("ILocalizationService"),
        container.get(ConnectionsRepository),
        container.get(SafetyCultureQuestionaireViewModel.Factory),
        container.get(SafetyMetricsQuestionnaireViewModel.Factory),
        container.get(WorkHoursQuestionnaireViewModel.Factory),
        container.get(ExperienceModifierViewModel.Factory),
        container.get(SafetyProgramsViewModel.Factory),
        container.get(InsuranceUploadModalViewModel.Factory),
        container.get(SendMessageViewModel.Factory),
        container.get(UserContext),
        container.get("IEventBus")
      );
  }
}
