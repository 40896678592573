import { IEventBus, ILocalizationService } from "@emanprague/shared-services";
import { bound, IDisposable } from "@frui.ts/helpers";
import { BusyWatcher, ScreenBase, watchBusy } from "@frui.ts/screens";
import ProjectRepository from "repositories/projectRepository";
import { FilteredListViewModel } from "@frui.ts/datascreens";
import CompanyProjectTeam from "entities/companyProjectTeam";
import { interfaces } from "inversify";
import EditTeamViewModel from "viewModels/company/projects/teams/editTeamViewModel";
import NewTeamViewModel from "viewModels/company/projects/teams/newTeamViewModel";
import AddWorkersViewModel from "viewModels/company/projects/teams/addWorkersViewModel";
import { classToClass } from "class-transformer";
import UserContext from "services/userContext";
import { computed } from "mobx";
import { ProjectsEvents } from "services/events";
import DialogService from "services/dialogService";
import EnumsService from "services/enumsService";

export default class TeamsListViewModel extends FilteredListViewModel<CompanyProjectTeam, any, ScreenBase> {
  navigationName = "teams";
  busyWatcher = new BusyWatcher();
  private eventHandlers: IDisposable[];

  constructor(
    private companyId: string,
    private projectId: string,
    public projectOwner: boolean,
    public localization: ILocalizationService,
    private repository: ProjectRepository,
    private editTeamFactory: ReturnType<typeof EditTeamViewModel.Factory>,
    private newTeamFactory: ReturnType<typeof NewTeamViewModel.Factory>,
    private addWorkersFactory: ReturnType<typeof AddWorkersViewModel.Factory>,
    private userContext: UserContext,
    private eventBus: IEventBus,
    private dialog: DialogService,
    public enums: EnumsService
  ) {
    super();

    this.name = this.translate("title");

    this.eventHandlers = [this.eventBus.subscribe(ProjectsEvents.teamsUpdated, this.loadData)];
  }

  onInitialize() {
    this.loadData();
  }

  protected async onDeactivate(isClosing: boolean) {
    await super.onDeactivate(isClosing);

    if (isClosing) {
      this.eventHandlers?.forEach(x => x.dispose());
    }
  }

  @computed
  get userId() {
    return this.userContext.identity?.accountId;
  }

  @bound
  @watchBusy
  async loadData() {
    const result = await this.repository.getProjectTeams(this.companyId, this.projectId);
    if (result.success) {
      this.setData([result.payload, this.currentPaging]);
    }
  }

  @bound
  openNewTeam() {
    const vm = this.newTeamFactory(this.companyId, this.projectId);
    this.tryActivateChild(vm);
  }

  @bound
  openEditTeam(item: CompanyProjectTeam) {
    const vm = this.editTeamFactory(this.companyId, this.projectId, item);
    this.tryActivateChild(vm);
  }

  @bound
  openAddWorkers(item: CompanyProjectTeam) {
    const vm = this.addWorkersFactory(this.companyId, this.projectId, item);
    this.tryActivateChild(vm);
  }

  @bound
  @watchBusy
  async removeWorker(team: CompanyProjectTeam, workerId: string) {
    const confirmationResult = await this.dialog.showConfirmation(
      this.translate("removeMessage"),
      this.translate("removeTitle"),
      this.translate("removeOk"),
      this.translate("removeCancel")
    );
    if (confirmationResult) {
      const updatedTeam = classToClass(team);
      updatedTeam.workersAssigned = updatedTeam.workersAssigned?.filter(w => w.id !== workerId);

      return this.repository.updateProjectTeamWorkers(this.companyId, this.projectId, team.id!, updatedTeam);
    }
  }

  @bound translate(key: string) {
    return this.localization.translateGeneral(`projects.teams.${key}`);
  }

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  protected resetFilterValues(filter: any) {}

  static Factory({ container }: interfaces.Context) {
    return (companyId: string, projectId: string, projectOwner: boolean) =>
      new TeamsListViewModel(
        companyId,
        projectId,
        projectOwner,
        container.get("ILocalizationService"),
        container.get(ProjectRepository),
        container.get(EditTeamViewModel.Factory),
        container.get(NewTeamViewModel.Factory),
        container.get(AddWorkersViewModel.Factory),
        container.get(UserContext),
        container.get("IEventBus"),
        container.get(DialogService),
        container.get(EnumsService)
      );
  }
}
