import { observable } from "mobx";
import { Type, Transform } from "class-transformer";
import ComplianceRequirementGrades from "./complianceRequirementGrades";
import ContractorConnectionPoints from "./contractorConnectionPoints";
import AccountType from "./accountType";
import { formatISO } from "date-fns";
import parseISO from "date-fns/parseISO";

// This entity has been generated, do not change its content, your changes might get lost. You CAN modify the rest of the file.
export default class ContractorConnection {
  /**
   * Connection ID
   */
  @observable
  connectionId?: string;

  /**
   * The counterparty’s company ID
   */
  @observable
  companyId?: string;

  /**
   * The counterparty’s company name
   */
  @observable
  companyName?: string;

  /**
   * Is connection accepted by contractor?
   */
  @observable
  accepted?: boolean;

  /**
   * Linked since date
   */
  @observable
  @Transform(value => (value ? (typeof value === "string" ? parseISO(value) : value) : undefined), { toClassOnly: true })
  @Transform(value => (value ? formatISO(value, { representation: "date" }) : undefined), { toPlainOnly: true })
  acceptedAt?: Date;

  @observable
  accountType?: AccountType;

  @observable
  grade?: ComplianceRequirementGrades;

  @observable
  @Type(() => ContractorConnectionPoints)
  points?: ContractorConnectionPoints;

  static ValidationRules = {
    acceptedAt: { format: "date" },
  };
}
