import { ILocalizationService } from "@emanprague/shared-services";
import { bound } from "@frui.ts/helpers";
import { BusyWatcher, Router, watchBusy } from "@frui.ts/screens";
import AccountType from "entities/accountType";
import AuthenticationIdentity from "entities/authenticationIdentity";
import ContractorProfileSearchResult from "entities/contractorProfileSearchResult";
import { computed, extendObservable, reaction, runInAction } from "mobx";
import { ContractorPortalFilter } from "models/contractorPortalFilter";
import ContractorPortalRepository from "repositories/contractorPortalRepository";
import EnumsRepository from "repositories/enumsRepository";
import EnumsService from "services/enumsService";
import ContinuousListViewModelBase from "viewModels/continuousListViewModelBase";
import { IModule } from "viewModels/types";

export default class ModulePageViewModel
  extends ContinuousListViewModelBase<ContractorProfileSearchResult, ContractorPortalFilter>
  implements IModule {
  orderIndex = 4;
  navigationName = "contractorPortal";
  busyWatcher = new BusyWatcher();

  constructor(
    private contractorPortalRepository: ContractorPortalRepository,
    private enumsRepository: EnumsRepository,
    public localization: ILocalizationService,
    private router: Router,
    public enums: EnumsService
  ) {
    super();

    this.name = this.translate("title");
    this.pagingFilter.sortColumn = this.sortOptions[0]?.[0];
    this.pagingFilter.limit = 20;
  }

  get sortOptions() {
    return this.enumsRepository.getContractorPortalSortOptions();
  }

  @computed get states() {
    return this.enums.states;
  }

  onInitialize() {
    this.loadData();

    reaction(
      () => [
        this.pagingFilter.sortColumn,
        this.pagingFilter.sortDirection,
        this.filter.search,
        this.filter.service,
        this.filter["location.city"],
        this.filter["location.state"],
      ],
      this.applyFilterAndLoadDebounced
    );
  }

  @bound
  @watchBusy
  async loadData() {
    const result = await this.contractorPortalRepository.searchContractors(this.appliedFilter, this.pagingFilter);
    if (result.success) {
      this.setData(result.payload);
      // assure optional observable property 'connectionAccepted'
      this.items.forEach(item => {
        if (item.connectionAccepted === undefined) extendObservable(item, { connectionAccepted: undefined });
      });
    }
  }

  @bound
  openContractorProfile(contractor: ContractorProfileSearchResult) {
    const { accountId } = contractor;
    this.router.navigatePath(`publicProfile/company/${accountId}`);
  }

  @bound
  async sendConnection(contractor: ContractorProfileSearchResult) {
    const { accountId } = contractor;
    const result = await this.contractorPortalRepository.sendConnection(accountId);

    if (result.success) {
      runInAction(() => (contractor.connectionAccepted = false));
    }
  }

  protected resetFilterValues(filter: ContractorPortalFilter) {
    filter.search = undefined;
    filter.service = undefined;
    filter["location.city"] = undefined;
    filter["location.state"] = undefined;
  }

  isVisible(user: AuthenticationIdentity) {
    return user.accountType === AccountType.Operator;
  }

  @bound translate(key: string) {
    return this.localization.translateGeneral(`contractorPortal.modulePage.${key}`);
  }
}
