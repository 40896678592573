import { ILocalizationService } from "@emanprague/shared-services";
import { bound } from "@frui.ts/helpers";
import { BusyWatcher, ScreenBase, watchBusy } from "@frui.ts/screens";
import Message from "entities/message";
import { interfaces } from "inversify";
import { action, observable, runInAction } from "mobx";
import MessagesRepository from "repositories/messagesRepository";
import SendMessageViewModel from "viewModels/messages/sendMessageViewModel";

export default class DetailMessageViewModel extends ScreenBase {
  navigationName = "detail";
  busyWatcher = new BusyWatcher();

  @observable message: Message;

  constructor(
    private messageId: string,
    public localization: ILocalizationService,
    private repository: MessagesRepository,
    private sendMessageFactory: ReturnType<typeof SendMessageViewModel.Factory>
  ) {
    super();

    this.name = this.translate("title");
  }

  async onInitialize() {
    await this.loadMessage();
    if (this.message.read === false) {
      this.readMessage();
    }
  }

  @bound
  @watchBusy
  async loadMessage() {
    const result = await this.repository.getMessage(this.messageId);
    if (result.success) {
      runInAction(() => {
        this.message = result.payload;
      });
    }
  }

  @bound
  readMessage() {
    this.repository.readMessage(this.messageId);
  }

  @action.bound
  @watchBusy
  async replyMessage() {
    if (this.parent) {
      const vm = this.sendMessageFactory(this.message.senderAccountId ?? "", this.message.senderAccountName ?? "");
      this.parent.tryActivateChild(vm);
    }
  }

  @bound translate(key: string) {
    return this.localization.translateGeneral(`messages.detail.${key}`);
  }

  static Factory({ container }: interfaces.Context) {
    return (messageId: string) =>
      new DetailMessageViewModel(
        messageId,
        container.get("ILocalizationService"),
        container.get(MessagesRepository),
        container.get(SendMessageViewModel.Factory)
      );
  }
}
