import React from "react";
import classnames from "classnames";
import ComplianceRequirementGrades from "entities/complianceRequirementGrades";
import "./grade.scss";

export type GradeData = {
  grade: ComplianceRequirementGrades;
  points: number;
  maxPoints: number;
};

const warnColor = ["D", "F"];
const dangerColor = ["B", "C"];
const goodColor = ["A"];

const Grade: React.FC<GradeData> = ({ grade, points, maxPoints }) => {
  const pointsGroup = `(${Math.floor(points)}/${Math.floor(maxPoints)})`;
  const classname = classnames("grade", {
    "is-success": goodColor.includes(grade),
    "is-danger": warnColor.includes(grade),
    "is-warning": dangerColor.includes(grade),
  });
  return (
    <p>
      <span className={classname}>{grade}</span>
      &nbsp;
      {grade && pointsGroup}
    </p>
  );
};

export default Grade;
