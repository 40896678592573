import ListOfMessages from "entities/listOfMessages";
import RepositoryBase from "./repositoryBase";
import { IPagingFilter } from "@frui.ts/data";
import Message from "entities/message";
import MessageReadStatus from "entities/messageReadStatus";
import MessageFilter from "models/messageFilter";
import { MessagesEvents } from "services/events";

export default class MessagesRepository extends RepositoryBase {
  getMessages(filter: MessageFilter, paging: IPagingFilter) {
    return this.callApi(api => api.all("messaging/messages").getPagedEntities(ListOfMessages, x => x.messages, paging, filter));
  }

  getMessage(messageId: string) {
    return this.callApi(api => api.one("messaging/messages", messageId).getEntity(Message));
  }

  async readMessage(messageId: string) {
    const messageStatus = new MessageReadStatus();
    messageStatus.read = true;
    const result = await this.callApi(api => api.one("messaging/messages", messageId).all("read").putOnly(messageStatus));

    if (result.success) {
      this.eventBus.publish(MessagesEvents.messageRead(undefined));
    }

    return result;
  }

  sendMessage(recipientAccountId: string, content: string) {
    const newMessage = new Message();
    newMessage.recipientAccountId = recipientAccountId;
    newMessage.content = content;
    return this.callApi(api => api.all("messaging/messages").postEntity(newMessage, Message));
  }
}
