import { Button, Col, Row } from "@emanprague/veralinkkit";
import { registerView, ViewComponent } from "@frui.ts/views";
import Input from "controls/input";
import PagingInfo from "controls/pagingInfo";
import SortSelect from "controls/sortSelect";
import { observer } from "mobx-react-lite";
import React from "react";
import ModulePageViewModel from "viewModels/contractorPortal/modulePageViewModel";
import LoadingContainer from "views/common/loadingContainer";
import "./contractorPortal.scss";
import ContractorSearchCard from "./contractorSearchCard";
import ContractorPortalFilter from "./filter/contractorPortalFilter";

const modulePageListView: ViewComponent<ModulePageViewModel> = observer(({ vm }) => {
  const { translateGeneral, translateAttribute } = vm.localization;
  const ta = (code: string) => translateAttribute("contractorPortalFilter", code);
  const tg = (code: string) => translateGeneral(`contractorPortal.modulePage.${code}`);

  return (
    <>
      <h4>{tg("title")}</h4>

      <Row className="contractor-portal__header">
        <PagingInfo paging={vm.currentPaging} localization={vm.localization} />

        <p>{ta("sortBy")}:</p>
        <SortSelect
          paging={vm.pagingFilter}
          items={vm.sortOptions}
          model="contractorPortalSortOption"
          localization={vm.localization}
        />

        <Input placeholder={ta("search")} target={vm.filter} property="search" />
      </Row>

      <Row>
        <Col md={4}>
          <ContractorPortalFilter vm={vm} />
        </Col>

        <Col md={8} className="contractor-portal__list">
          <LoadingContainer loading={vm.busyWatcher.isBusy}>
            {vm.items?.map(contractor => (
              <ContractorSearchCard
                key={contractor.accountId}
                contractor={contractor}
                localization={vm.localization}
                onViewProfile={() => vm.openContractorProfile(contractor)}
                onRequestLink={() => vm.sendConnection(contractor)}
                enums={vm.enums}
              />
            ))}
          </LoadingContainer>

          {vm.canLoadData && (
            <Button block variant="outline-primary" onClick={vm.loadData}>
              {translateGeneral("paging.loadMore")}
            </Button>
          )}
        </Col>
      </Row>
    </>
  );
});

registerView(modulePageListView, ModulePageViewModel);
