import { ILocalizationService } from "@emanprague/shared-services";
import { bound } from "@frui.ts/helpers";
import { BusyWatcher, Router, ScreenBase, watchBusy } from "@frui.ts/screens";
import { validate } from "@frui.ts/validation";
import AccountType from "entities/accountType";
import NewWorkerPassword from "entities/newWorker";
import { action, observable, runInAction } from "mobx";
import SignupRepository from "repositories/signupRepository";
import AsyncValidator from "services/validation/asyncValidator";
import ValidationService from "services/validation/validationService";
import SuccessEmailSentViewModel from "./successEmailSentViewModel";
import { API_URI, IMPORT_TOKEN } from "config";
import WorkerImportService from "services/workerImportService";
import { ApiResult } from "repositories/apiModels";
import StorageProvider from "services/storageProvider";
import LoginViewModel from "viewModels/profile/loginViewModel";

export const navigationName = "worker";

@Router.registerRoute({ name: Router.Self, route: navigationName })
export default class WorkerFormViewModel extends ScreenBase {
  navigationName = navigationName;
  busyWatcher = new BusyWatcher();
  @observable item: NewWorkerPassword;
  asyncValidator: AsyncValidator<NewWorkerPassword>;
  @observable errorMessage?: string;

  constructor(
    public localization: ILocalizationService,
    private repository: SignupRepository,
    private router: Router,
    validationService: ValidationService,
    private workerImportService: WorkerImportService,
    private storageProvider: StorageProvider
  ) {
    super();

    this.name = this.translate("title");
    this.item = new NewWorkerPassword();
    this.asyncValidator = validationService.attachUserNameValidation(this.item, NewWorkerPassword.ValidationRules);
  }

  onActivate() {
    if (this.workerImportService.data) {
      runInAction(() => {
        this.item.firstName = this.workerImportService.data?.firstName!;
        this.item.lastName = this.workerImportService.data?.lastName!;
        this.item.email = this.workerImportService.data?.email!;
        this.item.mobile = this.workerImportService.data?.mobile!;
        this.item.socialSecurityNumber = this.workerImportService.data?.socialSecurityNumber!;
      });
    }
  }

  onDeactivate(isClosing: boolean) {
    if (isClosing) {
      this.asyncValidator.dispose();
    }
    return super.onDeactivate(isClosing);
  }

  @bound
  @watchBusy
  async signup() {
    this.setErrorMessage();
    if (validate(this.item)) {
      let result: ApiResult<Response>;
      const importToken = this.storageProvider.get(IMPORT_TOKEN) as string;

      if (this.navigationParamsValue && this.navigationParamsValue.workerImport && importToken) {
        result = await this.repository.completeWorkerImportRegistration(importToken, {
          type: AccountType.Worker,
          details: this.item,
        });

        if (result.success) {
          runInAction(() => {
            this.storageProvider.remove(IMPORT_TOKEN);
            this.workerImportService.data = undefined;
          });
          this.router.navigate(LoginViewModel);
        }
      } else {
        result = await this.repository.createAccount({ type: AccountType.Worker, details: this.item });
      }

      if (result.success) {
        this.router.navigate(SuccessEmailSentViewModel, undefined, { email: this.item.email });
      } else {
        this.setErrorMessage(result.payload.errorDescription);
      }
    }
  }

  get termsUrl() {
    return `${API_URI}/auth/terms_and_conditions`;
  }

  @action private setErrorMessage(message?: string) {
    this.errorMessage = message;
  }

  @bound translate(key: string) {
    return this.localization.translateGeneral(`signup.signupForm.${key}`);
  }

  navigate(_subPath: string | undefined, params: any) {
    const { workerImport } = params;
    if (workerImport) {
      runInAction(() => {
        this.navigationParamsValue = { workerImport };
      });
    }
  }
}
