import { Button, Form, LoaderOverlay, Row } from "@emanprague/veralinkkit";
import { Input } from "@frui.ts/bootstrap";
import { registerView } from "@frui.ts/views";
import React from "react";
import Plus from "@emanprague/veralinkkit/dist/Icons/Plus";
import ModalView from "views/common/modalView";
import Delete from "@emanprague/veralinkkit/dist/Icons/Delete";
import CompanyServicesViewModel from "viewModels/company/companyInfo/modals/companyServicesViewModel";
import "./modals.scss";

class CompanyServicesModalView extends ModalView<CompanyServicesViewModel> {
  renderBody(vm: CompanyServicesViewModel) {
    return (
      <LoaderOverlay className="company-info__modal" loading={vm.busyWatcher.isBusy} variant="light">
        {vm.model.map((service, index) => (
          <Row key={index} className="company-info__modal-service">
            <Form.Group>
              <Input type="text" target={service} property="name" />
            </Form.Group>
            <Delete fontSize={24} color="#888F91" onClick={() => vm.removeService(index)} />
          </Row>
        ))}

        <Row>
          <Button variant="outline-primary" onClick={vm.addService}>
            <Plus fontSize={20} />
            {vm.translate("addService")}
          </Button>
        </Row>
      </LoaderOverlay>
    );
  }

  renderFooter(vm: CompanyServicesViewModel) {
    return (
      <Button variant="primary" disabled={vm.busyWatcher.isBusy} onClick={vm.save}>
        {vm.translate("save")}
      </Button>
    );
  }
}

registerView(CompanyServicesModalView as any, CompanyServicesViewModel);
