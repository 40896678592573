import { Button, Form, Uploader } from "@emanprague/veralinkkit";
import { registerView } from "@frui.ts/views";
import Input from "controls/input";
import React from "react";
import UploadDocumentViewModel from "viewModels/company/projects/documents/uploadDocumentViewModel";
import ModalView from "views/common/modalView";
import FileUploadProgress from "views/worker/credentials/components/FileUploadProgress";

class UploadDocumentModalView extends ModalView<UploadDocumentViewModel> {
  renderBody(vm: UploadDocumentViewModel) {
    const ta = vm.localization.translateAttribute;
    return vm.busyWatcher.isBusy ? (
      <span>{vm.translate("uploading")}...</span>
    ) : (
      <>
        <Form.Group>
          <Form.Label>
            <b>{ta("projectDocument", "name")}</b>
            <span className="optional-text"> ({ta("projectDocument", "optional")}) </span>
          </Form.Label>
          <Input target={vm.projectDocument} property="name" />
        </Form.Group>
        {!vm.content && (
          <Uploader
            onDrop={(files: File[]) => vm.selectContent(files[0])}
            localization={{
              button: vm.translate("uploadFile"),
              or: vm.translate("dragFile"),
              types: `(${vm.translate("fileValidation")})`,
            }}
          />
        )}
        <FileUploadProgress progressBar={0} file={vm.content} onRemove={vm.removeContent} />
      </>
    );
  }

  renderFooter(vm: UploadDocumentViewModel) {
    return (
      <>
        <Button variant="outline-primary" onClick={vm.requestClose}>
          {vm.translate("cancel")}
        </Button>
        <Button variant="primary" onClick={vm.uploadFile} disabled={!vm.content || vm.busyWatcher.isBusy}>
          {vm.translate("save")}
        </Button>
      </>
    );
  }
}

registerView(UploadDocumentModalView as any, UploadDocumentViewModel);
