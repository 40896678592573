import React from "react";
import { Button, Card } from "@emanprague/veralinkkit";
import Right from "@emanprague/veralinkkit/dist/Icons/Arrow Simple/Right";

import "../index.scss";
import Tooltip from "@emanprague/basekit/dist/Tooltip";
import InfoCircleIcon from "@emanprague/veralinkkit/dist/Icons/InfoCircle";

const statCard: React.FC<{
  children?: React.ReactNode;
  title: string;
  subtitle: string;
  info?: string;
  link: string;
  path: string;
}> = ({ children, info, title, subtitle, link, path }) => {
  return (
    <Card className="statCard">
      <Card.Header>
        <div className="statCard__titles">
          <h6 className="text-uppercase">
            {title}
            {info && (
              <Tooltip id="info-tooltip" tooltip={info}>
                <InfoCircleIcon className="infoTooltipIcon" />
              </Tooltip>
            )}
          </h6>
          <h6 className="text-uppercase">{subtitle}</h6>
        </div>
        <Button variant="link" onClick={() => (window.location.href = path)}>
          {link} <Right fontSize={16} />
        </Button>
      </Card.Header>
      <Card.Body>{children}</Card.Body>
    </Card>
  );
};

export default statCard;
