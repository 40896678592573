import { Alert, Button, Card, Col, Form, Row } from "@emanprague/veralinkkit";
import { Check, Input } from "@frui.ts/bootstrap";
import { preventDefault, registerView, ViewComponent } from "@frui.ts/views";
import FormField from "controls/formField";
import NewContractorPassword from "entities/newContractor";
import NewOperatorPassword from "entities/newOperator";
import { observer } from "mobx-react-lite";
import React from "react";
import CompanyFormViewModel from "viewModels/signup/companyFormViewModel";
import { PasswordInput } from "controls/passwordInput";

const formFields: FormField<NewOperatorPassword | NewContractorPassword>[] = [
  {
    type: "input",
    property: "companyName",
  },
  {
    type: "input",
    property: "taxId",
  },
  {
    type: "input",
    property: "email",
  },
  {
    type: "input",
    property: "username",
  },

  {
    type: "passwordInput",
    textType: "password",
    property: "password",
  },

  {
    type: "passwordInput",
    textType: "password",
    property: "repeatPassword",
  },
];

const companyFormListView: ViewComponent<CompanyFormViewModel> = observer(({ vm }) => {
  const ta = (attribute: string) => vm.localization.translateAttribute("user", attribute);

  return (
    <Row className="justify-content-center mt-4 signup-form">
      <Col md={6}>
        <Card className="card-big">
          <Card.Body>
            <h1 className="text-center">{vm.name}</h1>

            <Form onSubmit={preventDefault(vm.signup)}>
              {vm.item &&
                formFields.map(field => (
                  <Form.Group key={`${field.property}-${field.type}`}>
                    {field.type === "input" && (
                      <>
                        <Form.Label>
                          {ta(field.property)}
                          {field.optional && <span className="text-capitalize optional-text"> {vm.translate("optional")} </span>}
                        </Form.Label>
                        <Input
                          type={field.textType ?? "text"}
                          target={vm.item}
                          property={field.property}
                          errorMessage={vm.asyncValidator.errors[field.property]}
                        />
                      </>
                    )}
                    {field.type === "passwordInput" && (
                      <PasswordInput
                        label={ta(field.property)}
                        target={vm.item}
                        property={field.property}
                        errorMessage={vm.asyncValidator.errors[field.property]}
                      />
                    )}
                  </Form.Group>
                ))}

              {vm.errorMessage && <Alert variant="danger">{vm.errorMessage}</Alert>}

              <Button disabled={vm.busyWatcher.isBusy} type="submit" block>
                {vm.translate("submit")}
              </Button>
            </Form>

            <Check
              className="signCheck"
              label={
                <p className="terms">
                  {vm.translate("terms")}{" "}
                  <a href={`#/signup/company?role=${vm.role}`} onClick={() => window.open(vm.termsUrl, "_blank")}>
                    {vm.translate("termsLink")}
                  </a>
                </p>
              }
              data-testid="consent-check"
              property="consent"
              target={vm.item}
            />
          </Card.Body>
        </Card>
      </Col>
    </Row>
  );
});

registerView(companyFormListView, CompanyFormViewModel);
