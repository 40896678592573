import WorkerProfileSearchResults from "entities/workerProfileSearchResults";
import { WorkerPortalFilter } from "models/workerPortalFilter";
import RepositoryBase from "./repositoryBase";
import { IPagingFilter } from "@frui.ts/data";
import WorkforceStatProfession from "entities/workforceStatProfession";

export default class WorkerPortalRepository extends RepositoryBase {
  searchWorkers(filter: WorkerPortalFilter, paging: IPagingFilter) {
    return this.callApi(api =>
      api
        .ignoreEmptyStrings()
        .all("userprofile/worker_portal")
        .all("search")
        .getPagedEntities(WorkerProfileSearchResults, x => x.workers, paging, filter)
    );
  }

  sendConnection(workerId: string) {
    return this.callApi(api => api.all("userprofile/worker_portal").one("worker", workerId).all("connections").postOnly({}));
  }

  acceptConnection(workerId: string, connectionId: string) {
    return this.callApi(api =>
      api.all("userprofile/worker_portal").one("worker", workerId).one("connections", connectionId).all("accept").postOnly({})
    );
  }

  getStats() {
    return this.callApi(api => api.all("userprofile/worker_portal/stats").getEntities(WorkforceStatProfession));
  }
}
