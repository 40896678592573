import React from "react";
import { Row, Col, Button } from "@emanprague/veralinkkit";
import MailBig from "@emanprague/veralinkkit/dist/Icons/MailBig";
import "./linkSent.scss";

interface LinkSentProps {
  title: string;
  message: string;
  email: string;
  help: string;
  resend: string;
  onResend: () => void;
  isDisabled?: boolean;
}

const LinkSent: React.FC<LinkSentProps> = props => {
  return (
    <Row className="text-center">
      <Col className="success-link-sent">
        <Row className="justify-content-center align-items-center mb-4">
          <div className="card__hero ">
            <MailBig className="card__hero-icon" />
          </div>
        </Row>
        <h1>{props.title}</h1>
        <p className="description mt-4">
          {props.message}
          <b className="description__identity">{props.email}</b>
        </p>
        <p className="optional-text mt-1">{props.help}</p>

        <Button disabled={props.isDisabled} className="card__btn mt-4" variant="outline-primary" onClick={props.onResend}>
          {props.resend}
        </Button>
      </Col>
    </Row>
  );
};

export default LinkSent;
