import { Button } from "@emanprague/veralinkkit";
import { registerView, View, ViewComponent } from "@frui.ts/views";
import { observer } from "mobx-react-lite";
import React from "react";
import SubModuleViewModel from "viewModels/company/projects/subModuleViewModel";
import { Plus } from "@emanprague/veralinkkit/dist/Icons";

import ProjectCard from "./components/projectCard";

import "./index.scss";
import LoadingContainer from "views/common/loadingContainer";

const subModuleView: ViewComponent<SubModuleViewModel> = observer(({ vm }) => {
  return (
    <>
      {!vm.activeChild || vm.activeChild.navigationName !== "detail" ? (
        <>
          <div className="projectsList__header">
            <h4>{vm.name}</h4>
            <Button variant="primary" onClick={vm.openNewProjectModal}>
              <Plus fontSize={24} />
              {vm.translate("newProject")}
            </Button>
          </div>
          <LoadingContainer loading={vm.busyWatcher.isBusy}>
            <div className="projectsList__container">
              {vm.items?.map((project, index) => (
                <ProjectCard
                  key={index}
                  project={project}
                  localization={vm.localization}
                  onProjectNameClick={vm.openProjectDetail}
                  onEditProject={vm.openEditProjectModal}
                  onRemoveProject={vm.removeProject}
                  projectOwner={vm.isProjectOwner(project.operatorId)}
                  enums={vm.enums}
                />
              ))}
            </div>
          </LoadingContainer>
          <View vm={vm.activeChild} />
        </>
      ) : (
        <View vm={vm.activeChild} />
      )}
    </>
  );
});

registerView(subModuleView, SubModuleViewModel);
