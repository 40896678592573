import { IEventBus, ILocalizationService } from "@emanprague/shared-services";
import { bound, IDisposable } from "@frui.ts/helpers";
import { BusyWatcher, ConductorAllChildrenActive, ScreenBase, watchBusy } from "@frui.ts/screens";
import { interfaces } from "inversify";
import { action, computed, observable, runInAction } from "mobx";
import ProjectRepository from "repositories/projectRepository";
import EditProjectViewModel from "viewModels/company/projects/editProjectViewModel";
import TeamsListViewModel from "viewModels/company/projects/teams/teamsListViewModel";
import DocumentsListViewModel from "viewModels/company/projects/documents/documentsListViewModel";
import CompanyProject from "entities/companyProject";
import { ProjectsEvents } from "services/events";
import EnumsService from "services/enumsService";

export default class ProjectDetailViewModel extends ConductorAllChildrenActive<ScreenBase> {
  navigationName = "detail";
  busyWatcher = new BusyWatcher();

  @observable item?: CompanyProject;
  private eventHandlers: IDisposable[];

  constructor(
    private companyId: string,
    private projectId: string,
    projectName: string,
    public projectOwner: boolean,
    public localization: ILocalizationService,
    private repository: ProjectRepository,
    private editProjectFactory: ReturnType<typeof EditProjectViewModel.Factory>,
    private teamsListFactory: ReturnType<typeof TeamsListViewModel.Factory>,
    private documentsListFactory: ReturnType<typeof DocumentsListViewModel.Factory>,
    private eventBus: IEventBus,
    public enums: EnumsService
  ) {
    super();

    this.name = projectName;

    this.eventHandlers = [
      this.eventBus.subscribe(ProjectsEvents.listUpdated, this.loadDetail),
      this.eventBus.subscribe(ProjectsEvents.teamsUpdated, this.loadDetail),
    ];
  }

  onInitialize() {
    this.loadDetail();
    this.preparedNestedVewModels();
  }

  protected async onDeactivate(isClosing: boolean) {
    await super.onDeactivate(isClosing);

    if (isClosing) {
      this.eventHandlers?.forEach(x => x.dispose());
    }
  }

  @bound
  @watchBusy
  async loadDetail() {
    const result = await this.repository.getProject(this.companyId, this.projectId);
    if (result.success) {
      runInAction(() => {
        this.item = result.payload;
        this.name = result.payload.name!;
      });
    }
  }

  @action
  private preparedNestedVewModels() {
    this.tryActivateChild(this.teamsListFactory(this.companyId, this.projectId, this.projectOwner));
    this.tryActivateChild(this.documentsListFactory(this.companyId, this.projectId, this.projectOwner));
  }

  @computed
  get editProjectViewModel() {
    return this.children.find((c: ScreenBase) => c.navigationName === "edit");
  }

  @computed
  get nestedSectionsViewModels() {
    return this.children.filter((c: ScreenBase) => c.navigationName !== "edit");
  }

  @action.bound
  openEditProjectModal() {
    if (this.item) {
      const vm = this.editProjectFactory(this.companyId, this.item);
      this.tryActivateChild(vm);
    }
  }

  @bound translate(key: string) {
    return this.localization.translateGeneral(`projects.detail.${key}`);
  }

  static Factory({ container }: interfaces.Context) {
    return (companyId: string, projectId: string, projectName: string, projectOwner: boolean) =>
      new ProjectDetailViewModel(
        companyId,
        projectId,
        projectName,
        projectOwner,
        container.get("ILocalizationService"),
        container.get(ProjectRepository),
        container.get(EditProjectViewModel.Factory),
        container.get(TeamsListViewModel.Factory),
        container.get(DocumentsListViewModel.Factory),
        container.get("IEventBus"),
        container.get(EnumsService)
      );
  }
}
