import { IEventBus, ILocalizationService } from "@emanprague/shared-services";
import { bound } from "@frui.ts/helpers";
import { ScreenBase } from "@frui.ts/screens";
import AuthenticationIdentity from "entities/authenticationIdentity";
import Notification from "entities/notification";
import NotificationProjectContent from "entities/notificationProjectContent";
import { interfaces } from "inversify";
import { runInAction } from "mobx";
import NotificationsRepository from "repositories/notificationsRepository";
import { StatusbarEvents } from "services/events";
import UserContext from "services/userContext";

export default class ProjectViewModel extends ScreenBase {
  identity: AuthenticationIdentity;

  constructor(
    public notification: Notification,
    private notificationsRepository: NotificationsRepository,
    public localization: ILocalizationService,
    private eventBus: IEventBus,
    userContext: UserContext
  ) {
    super();
    if (!userContext.identity) return;
    this.identity = userContext.identity;
  }

  // todo: can be inherited
  get profileUrl() {
    return `#/publicProfile/company/${(this.notification.content as NotificationProjectContent).operatorAccountId}`;
  }

  get projectUrl() {
    return `#/dashboard/projects/detail/${(this.notification.content as NotificationProjectContent).projectId}`;
  }

  // todo: can be inherited
  @bound
  async markAsRead() {
    const result = await this.notificationsRepository.markOneRead(this.notification.id!);

    if (result.success) {
      runInAction(() => (this.notification.read = true));
      this.eventBus.publish(StatusbarEvents.notificationRead(undefined));
    }
  }

  @bound translate(key: string, placeholders?: Record<string, string>) {
    return this.localization.translateGeneral(`notifications.project.${key}`, placeholders);
  }

  static Factory({ container }: interfaces.Context) {
    return (notification: Notification) =>
      new ProjectViewModel(
        notification,
        container.get(NotificationsRepository),
        container.get("ILocalizationService"),
        container.get("IEventBus"),
        container.get(UserContext)
      );
  }
}
