import { Button, Card } from "@emanprague/veralinkkit";
import { registerView, View, ViewComponent } from "@frui.ts/views";
import { observer } from "mobx-react-lite";
import React from "react";
import Pager from "controls/pager";
import LoadingContainer from "views/common/loadingContainer";
import ModulePageViewModel from "viewModels/notifications/notificationsViewModel";
import "./notifications.scss";

const modulePageView: ViewComponent<ModulePageViewModel> = observer(({ vm }) => {
  return (
    <>
      <div className="notifications">
        <div className="notifications__header">
          <h4>{vm.name}</h4>
          {!!vm.items?.length && (
            <Button variant="outline-primary" onClick={vm.markAllRead}>
              {vm.translate("markAllRead")}
            </Button>
          )}
        </div>

        <LoadingContainer loading={vm.busyWatcher.isBusy}>
          {!!vm.items?.length && (
            <Card className="notifications__list">
              {vm.list?.map((item, index) => (
                <View vm={item} key={index} />
              ))}
            </Card>
          )}
        </LoadingContainer>

        {!!vm.items?.length && <Pager paging={vm.currentPaging} filter={vm.pagingFilter} onPageChanged={vm.changePage} />}
      </div>
    </>
  );
});

registerView(modulePageView, ModulePageViewModel);
