import { ILocalizationService } from "@emanprague/shared-services";
import { bound } from "@frui.ts/helpers";
import { BusyWatcher, ScreenBase, watchBusy } from "@frui.ts/screens";
import { attachAutomaticValidator, hasVisibleErrors, validate } from "@frui.ts/validation";
import { classToClass } from "class-transformer";
import CompanyProfile from "entities/companyProfile";
import { interfaces } from "inversify";
import { computed } from "mobx";
import CompanyProfileRepository from "repositories/companyProfileRepository";

export default class CompanyInfoViewModel extends ScreenBase {
  navigationName = "info";
  busyWatcher = new BusyWatcher();

  model: CompanyProfile;

  constructor(
    originalProfile: CompanyProfile,
    private onSuccess: () => void,
    private companyProfileRepository: CompanyProfileRepository,
    public localization: ILocalizationService
  ) {
    super();
    this.name = this.translate("title");

    this.model = classToClass(originalProfile) ?? new CompanyProfile();
    attachAutomaticValidator(this.model, CompanyProfile.ValidationRules);
  }

  @bound
  @watchBusy
  async save() {
    if (validate(this.model)) {
      const result = await this.companyProfileRepository.updateCompanyProfile(this.model.id, this.model);
      if (result.success) {
        this.onSuccess();
        this.requestClose();
      }
    }
  }

  @computed get canSave() {
    return !hasVisibleErrors(this.model);
  }

  @bound translate(key: string) {
    return this.localization.translateGeneral(`companyInfo.companyInfoModal.${key}`);
  }

  static Factory({ container }: interfaces.Context) {
    return (originalProfile: CompanyProfile, onSuccess: () => void) =>
      new CompanyInfoViewModel(
        originalProfile,
        onSuccess,
        container.get(CompanyProfileRepository),
        container.get("ILocalizationService")
      );
  }
}
