import { observable } from "mobx";
import { Type } from "class-transformer";
import CompanyEmployeeCurrentProject from "./companyEmployeeCurrentProject";
import LocationSimple from "./locationSimple";

// This entity has been generated, do not change its content, your changes might get lost. You CAN modify the rest of the file.
export default class CompanyEmployee {
  @observable
  accountId?: string;

  @observable
  professionTitle: string;

  @observable
  pendingRegistration?: boolean;

  @observable
  profilePhotoUrl!: string;

  @observable
  firstName!: string;

  @observable
  middleName?: string;

  @observable
  lastName!: string;

  @observable
  @Type(() => LocationSimple)
  location?: LocationSimple;

  @observable
  primaryProfessionName!: string;

  @observable
  primaryProfessionYears!: number;

  @observable
  @Type(() => CompanyEmployeeCurrentProject)
  currentProjects!: CompanyEmployeeCurrentProject[];

  static ValidationRules = {
    profilePhotoUrl: { format: "url", required: true },
    firstName: { required: true },
    lastName: { required: true },
    primaryProfessionName: { required: true },
    primaryProfessionYears: { required: true, number: true },
    currentProjects: { required: true },
  };
}
