import { ILocalizationService } from "@emanprague/shared-services";
import { bound } from "@frui.ts/helpers";
import { BusyWatcher, Router, ScreenBase, watchBusy } from "@frui.ts/screens";
import { attachAutomaticValidator, hasVisibleErrors, validate } from "@frui.ts/validation";
import { IMPORT_TOKEN } from "config";
import AuthenticateUser from "entities/authenticateUser";
import { action, observable, runInAction } from "mobx";
import SecurityService from "services/securityService";
import StorageProvider from "services/storageProvider";

export default class LoginViewModel extends ScreenBase {
  navigationName = "login";
  busyWatcher = new BusyWatcher();

  @observable credentials: AuthenticateUser;
  @observable errorMessage?: string;

  constructor(
    public localization: ILocalizationService,
    private securityService: SecurityService,
    public router: Router,
    private storageProvider: StorageProvider
  ) {
    super();

    this.name = this.translate("title");

    this.credentials = new AuthenticateUser();
    attachAutomaticValidator(this.credentials, AuthenticateUser.ValidationRules);
  }

  get canLogin() {
    return !hasVisibleErrors(this.credentials);
  }

  @bound
  @watchBusy
  async login() {
    this.setErrorMessage();
    if (validate(this.credentials)) {
      const result = await this.securityService.logIn(this.credentials.username.trim(), this.credentials.password);
      const importToken = this.storageProvider.get(IMPORT_TOKEN) as string;

      if (this.navigationParamsValue && this.navigationParamsValue.connectionRequest && importToken) {
        await this.securityService.completeConnectionRequest(importToken);
        this.storageProvider.remove(IMPORT_TOKEN);
      }

      if (typeof result === "string") {
        this.setErrorMessage(result);
      }
    }
  }

  @action
  setErrorMessage(message?: string) {
    this.errorMessage = message;
  }

  @bound translate(key: string) {
    return this.localization.translateGeneral(`profile.login.${key}`);
  }

  navigate(_subPath: string | undefined, params: any) {
    const { connectionRequest } = params;
    if (connectionRequest) {
      runInAction(() => {
        this.navigationParamsValue = { connectionRequest };
      });
    }
  }
}
