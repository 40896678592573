import React from "react";

import "../index.scss";

const noDataPlaceholder: React.FC<{
  text: string;
  hidden?: boolean;
}> = ({ text, hidden }) => {
  if (hidden) {
    return null;
  }

  return (
    <div className="noDataPlaceholder">
      <span>{text}</span>
    </div>
  );
};

export default noDataPlaceholder;
