import { ILocalizationService } from "@emanprague/shared-services";
import { bound } from "@frui.ts/helpers";
import { BusyWatcher, ScreenBase, watchBusy } from "@frui.ts/screens";
import AccountType from "entities/accountType";
import AuthenticationIdentity from "entities/authenticationIdentity";
import CompanyProjectStats from "entities/companyProjectStats";
import { computed, observable, runInAction } from "mobx";
import { ISubModule } from "viewModels/types";
import ProjectRepository from "repositories/projectRepository";
import UserContext from "services/userContext";
import CompanyProfile from "entities/companyProfile";
import WorkerPortalRepository from "repositories/workerPortalRepository";
import CompanyCredentialsRepository from "repositories/companyCredentialsRepository";
import ConnectionsRepository from "repositories/connectionsRepository";
import WorkforceStatProfession from "entities/workforceStatProfession";
import { getColorByText } from "utils/viewUtils";
import CompanyCredentialsStats from "entities/companyCredentialsStats";
import ContractorConnection from "entities/contractorConnection";
import EnumsService from "services/enumsService";

const setAsInitial = (name: string) => (name === "null" ? "Initial" : name);

export default class SubModuleViewModel extends ScreenBase implements ISubModule {
  orderIndex = 1;
  navigationName = "companyDashboard";
  busyWatcher = new BusyWatcher();

  @observable ongoingProjects: CompanyProjectStats[] = [];
  @observable availableWorkforce: WorkforceStatProfession[] = [];
  @observable workerCredentials: CompanyCredentialsStats = new CompanyCredentialsStats();
  @observable connections: ContractorConnection[] = [];
  @observable selectedIndustry?: string;

  get industries() {
    return this.enums.industries;
  }

  constructor(
    private userContext: UserContext,
    public localization: ILocalizationService,
    private projectRepository: ProjectRepository,
    private workerPortalRepository: WorkerPortalRepository,
    private companyCredentialsRepository: CompanyCredentialsRepository,
    private connectionsRepository: ConnectionsRepository,
    private enums: EnumsService
  ) {
    super();

    this.name = this.translate("title");
  }

  onInitialize() {
    this.loadProjects();
    this.loadAvailableWorkforce();
    this.loadWorkerCredentials();
    this.loadConnections();
  }

  @computed get companyProfileName() {
    return (this.userContext.profile as CompanyProfile)?.companyName ?? "";
  }

  @computed get companyProfileId() {
    return (this.userContext.profile as CompanyProfile)?.id ?? "";
  }

  @watchBusy
  async loadProjects() {
    const result = await this.projectRepository.getStats(this.companyProfileId);
    if (result.success) {
      runInAction(() => {
        this.ongoingProjects = (result.payload || []).slice(0, 4);
      });
    }
  }

  @watchBusy
  async loadAvailableWorkforce() {
    const result = await this.workerPortalRepository.getStats();
    if (result.success) {
      runInAction(() => {
        this.availableWorkforce = result.payload || [];
      });
    }
  }

  @watchBusy
  async loadWorkerCredentials() {
    const result = await this.companyCredentialsRepository.getStats(this.companyProfileId);
    if (result.success) {
      runInAction(() => {
        this.workerCredentials = result.payload;
      });
    }
  }

  @watchBusy
  async loadConnections() {
    const result = await this.connectionsRepository.loadConnections({ offset: 0, limit: 4 });
    if (result.success) {
      runInAction(() => {
        this.connections = (result.payload[0] ?? []) as ContractorConnection[];
      });
    }
  }

  @computed
  get availableWorkforceData() {
    const labels: string[] = [];
    const values: number[] = [];
    const tooltips: string[] = [];

    let unassigned = 0;
    let total = 0;

    const filteredAvailableWorkforce = this.selectedIndustry
      ? this.availableWorkforce.filter(item => item.industryId === this.selectedIndustry)
      : this.availableWorkforce;

    filteredAvailableWorkforce.forEach(item => {
      tooltips.push(`${setAsInitial(item.name)}: ${item.unassigned ?? 0}`);
      labels.push(`${setAsInitial(item.name)} ${item.unassigned ?? 0}/${item.total}`);
      values.push(item.total || 0);

      unassigned += item.unassigned ?? 0;
      total += item.total;
    });

    const colors: string[] = labels.map(l => getColorByText(l));

    return { labels, values, tooltips, colors, unassigned, total };
  }

  @computed
  get workerCredentialsData() {
    const items: { label: string; value: number }[] = [];
    const totalEmployees = this.workerCredentials.totalEmployees;

    items.push({
      label: this.translate("expired"),
      value: this.workerCredentials.expired ?? 0,
    });

    items.push({
      label: this.translate("days30"),
      value: this.workerCredentials.days30 ?? 0,
    });

    items.push({
      label: this.translate("days60"),
      value: this.workerCredentials.days60 ?? 0,
    });

    items.push({
      label: this.translate("days90"),
      value: this.workerCredentials.days90 ?? 0,
    });

    return { items, totalEmployees };
  }

  isVisible(identity: AuthenticationIdentity) {
    return identity.accountType === AccountType.Contractor || identity.accountType === AccountType.Operator;
  }

  @bound translate(key: string, values?: any) {
    return this.localization.translateGeneral(`companyDashboard.subModule.${key}`, values);
  }
}
