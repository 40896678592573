import AuthenticateUser from "entities/authenticateUser";
import AuthenticationCredentials from "entities/authenticationCredentials";
import ForgotPasswordEmail from "entities/forgotPasswordEmail";
import ForgotPasswordUsername from "entities/forgotPasswordUsername";
import ConfirmResetPassword from "entities/resetPassword";
import UserSettingsPassword from "entities/userSettingsPassword";
import RepositoryBase from "./repositoryBase";
import UserSettings from "entities/userSettings";
import WorkerImportPending from "entities/workerImportPending";

export default class LoginRepository extends RepositoryBase {
  login(credentials: AuthenticateUser) {
    return this.callApi(api => api.all("auth/authenticate").postEntity(credentials, AuthenticationCredentials), false);
  }

  requestPasswordReset(forgotPassword: ForgotPasswordUsername | ForgotPasswordEmail) {
    return this.callApi(api => api.all("auth/forgot_password").postOnly(forgotPassword));
  }

  confirmPasswordReset(resetPassword: ConfirmResetPassword) {
    return this.callApi(api => api.all("auth/reset_password").postOnly(resetPassword));
  }

  loadSettings() {
    return this.callApi(api => api.all("userprofile/user_settings").getEntity(UserSettings));
  }

  changeSettings(settings: UserSettings) {
    return this.callApi(api => api.all("userprofile/user_settings/email").postEntity(settings));
  }

  async changePassword(userSettingsPassword: UserSettingsPassword) {
    const result = await this.callApi(api => api.all("userprofile/change_password").putOnly(userSettingsPassword));
    if (result.success && result.payload.status === 204) {
      return {
        success: true,
      };
    }

    return { success: false };
  }

  checkWorkerImport(importToken: string) {
    return this.callApi(api => api.all("auth/worker_import").getEntity(WorkerImportPending, { importToken }));
  }

  completeConnectionRequest(importToken: string) {
    return this.callApi(api => api.all(`auth/worker_import/complete_connection_request?importToken=${importToken}`).postOnly({}));
  }
}
