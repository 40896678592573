import { registerView } from "@frui.ts/views";
import React from "react";
import SafetyCultureQuestionaireViewModel from "viewModels/company/compliance/questionaire/SafetyCultureQuestionaireViewModel";
import ModalView, { ModalSize } from "views/common/modalView";
import { Button } from "@emanprague/veralinkkit";
import "./questionaire.scss";
import Questionnaire from "../components/questionnaire";

class SafetyCultureQuestionaireView extends ModalView<SafetyCultureQuestionaireViewModel> {
  size: ModalSize = "lg";
  overrides = { footer: "questionaire__footer" };
  renderFooter(vm: SafetyCultureQuestionaireViewModel) {
    if (vm.isOperator) {
      return <div />;
    }

    return (
      <>
        <div className="questionaire__hint">
          <b>{vm.translate("autofillQuestionaire")}</b>
          <Button variant="outline-primary" disabled={vm.busyWatcher.isBusy} onClick={vm.autofill}>
            {vm.translate("autofill")}
          </Button>
        </div>

        <div>
          <Button variant="outline-primary" disabled={vm.busyWatcher.isBusy} onClick={vm.cancel}>
            {vm.translate("cancel")}
          </Button>
          <Button disabled={vm.busyWatcher.isBusy} onClick={vm.save}>
            {vm.translate("save")}
          </Button>
        </div>
      </>
    );
  }

  renderBody(vm: SafetyCultureQuestionaireViewModel) {
    return (
      <Questionnaire
        {...{
          model: vm.model,
          sectionsVisible: vm.model && vm.model.length > 1,
          selectSection: vm.selectSection,
          selectedSection: vm.selectedSection,
          tg: vm.translate,
          isBusy: vm.busyWatcher.isBusy,
          readOnly: vm.isOperator,
        }}
      />
    );
  }
}

registerView(SafetyCultureQuestionaireView, SafetyCultureQuestionaireViewModel);
