import { ILocalizationService } from "@emanprague/shared-services";
import { DetailViewModel } from "@frui.ts/datascreens";
import { bound } from "@frui.ts/helpers";
import { BusyWatcher, watchBusy } from "@frui.ts/screens";
import { attachAutomaticValidator, validate } from "@frui.ts/validation";
import { classToClass } from "class-transformer";
import WorkerProfileEducation from "entities/workerProfileEducation";
import { interfaces } from "inversify";
import { IReactionDisposer } from "mobx";
import WorkerProfileRepository from "repositories/workerProfileRepository";
import EnumsService from "services/enumsService";

export default class EducationDetailViewModel extends DetailViewModel<WorkerProfileEducation> {
  busyWatcher = new BusyWatcher();
  mode: "create" | "edit";
  reactions: IReactionDisposer[] = [];

  get professions() {
    return this.enums.professions;
  }

  constructor(
    private workerProfileId: string,
    item: WorkerProfileEducation | undefined,
    public localization: ILocalizationService,
    private repository: WorkerProfileRepository,
    private enums: EnumsService
  ) {
    super();
    if (item) {
      this.mode = "edit";
      this.navigationName = "edit-education";
      this.setItem(classToClass(item));
    } else {
      this.mode = "create";
      this.navigationName = "education";
      this.setItem(new WorkerProfileEducation());
    }
    this.name = this.translate(`${this.mode}Title`);
    attachAutomaticValidator(this.item, WorkerProfileEducation.ValidationRules);
  }

  async onInitialize() {
    super.onInitialize();
  }

  protected loadDetail() {
    return this.item;
  }

  @watchBusy
  async add() {
    const result = await this.repository.addEducation(this.workerProfileId, this.item);
    if (result.success) {
      this.requestClose();
    }
  }

  @watchBusy
  async edit() {
    // TODO
    // const result = await this.repository.editEducation(this.workerProfileId, this.item);
    // if (result.success) {
    //   this.requestClose();
    // }
  }

  @bound
  save() {
    if (validate(this.item)) {
      if (this.mode === "edit") {
        this.edit();
      } else {
        this.add();
      }
    }
  }

  @bound translate(key: string) {
    return this.localization.translateGeneral(`worker.educationDetail.${key}`);
  }

  static Factory({ container }: interfaces.Context) {
    return (workerProfileId: string, item?: WorkerProfileEducation) =>
      new EducationDetailViewModel(
        workerProfileId,
        item,
        container.get("ILocalizationService"),
        container.get(WorkerProfileRepository),
        container.get(EnumsService)
      );
  }
}
