import { observable } from "mobx";

// This entity has been generated, do not change its content, your changes might get lost. You CAN modify the rest of the file.
class CompanyContact {
  /**
   * Location ID (read-only)
   */
  id!: string;

  /**
   * Division
   * @example
   * Management
   */
  @observable
  division!: string;

  /**
   * Full Name
   * @example
   * John Doe
   */
  @observable
  fullName!: string;

  /**
   * Role
   * @example
   * CEO
   */
  @observable
  role!: string;

  /**
   * Phone number
   * @example
   * 124-865-1234
   */
  @observable
  phoneNumber?: string;

  /**
   * Email
   * @example
   * joe.doe@example.com
   */
  @observable
  email?: string;

  static ValidationRules = {
    id: { required: true },
    division: { required: true },
    fullName: { required: true },
    role: { required: true },
  };
}

export default class CompanyContactExtended extends CompanyContact {
  static ValidationRules = {
    id: { required: false },

    division: { required: true },
    fullName: { required: true },
    role: { required: true },

    email: { isEmail: true },
    phoneNumber: { isPhone: true },
  };
}
