import { ILocalizationService } from "@emanprague/shared-services";
import { bound } from "@frui.ts/helpers";
import { BusyWatcher, Router, ScreenBase, watchBusy } from "@frui.ts/screens";
import AccountType from "entities/accountType";
import { observable, runInAction } from "mobx";
import SecurityService from "services/securityService";

export const navigationName = "confirm";
@Router.registerRoute({ name: Router.Self, route: navigationName })
export default class ConfirmViewModel extends ScreenBase {
  navigationName = navigationName;
  busyWatcher = new BusyWatcher();
  @observable errorMessage: string;
  @observable awaiting = false;

  constructor(public localization: ILocalizationService, private securityService: SecurityService) {
    super();

    this.name = this.translate("title");
  }

  @bound translate(key: string) {
    return this.localization.translateGeneral(`signup.confirm.${key}`);
  }

  @watchBusy
  private async processToken(token: string) {
    const error = await this.securityService.validateConfirmToken(token);
    if (error) {
      runInAction(() => (this.errorMessage = error));
    }
  }

  @watchBusy
  private async validateEmail(token: string) {
    await this.securityService.validateUserEmail(token);
  }

  async navigate(_subpath: any, params: any) {
    if (params?.token && params?.accountType && params?.accountType === AccountType.Worker) {
      this.processToken(params.token);
    } else if (params?.token && params?.accountType && params?.accountType !== AccountType.Worker) {
      this.validateEmail(params?.token);
      runInAction(() => {
        this.awaiting = true;
      });
    }
  }
}
