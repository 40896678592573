import { observable } from "mobx";
import { Type } from "class-transformer";
import QuestionnaireQuestion from "./questionnaireQuestion";

// This entity has been generated, do not change its content, your changes might get lost. You CAN modify the rest of the file.
export default class QuestionnaireSection {
  @observable
  name!: string;

  @observable
  @Type(() => QuestionnaireQuestion)
  questions!: QuestionnaireQuestion[];

  static ValidationRules = {
    name: { required: true },
    questions: { required: true },
  };
}
