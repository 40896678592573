import { registerView } from "@frui.ts/views";
import React from "react";
import SafetyMetricsQuestionnaireViewModel from "viewModels/company/compliance/questionaire/safetyMetricsQuestionnaireViewModel";
import ModalView, { ModalSize } from "views/common/modalView";
import { Button, Row, Col, Tab, Tabs } from "@emanprague/veralinkkit";
import "./metrics.scss";
import LoadingContainer from "views/common/loadingContainer";
import Questionnaire from "../components/questionnaire";
import OshaTable from "../components/oshaTable";

class SafetyMetricsQuestionnaireView extends ModalView<SafetyMetricsQuestionnaireViewModel> {
  protected size: ModalSize = "xl";

  overrides = { footer: "metrics__footer" };
  renderFooter(vm: SafetyMetricsQuestionnaireViewModel) {
    const selectedVariantOsha = vm.selectedForm === "oshaForm";
    const selectedVariantQuestionnaire = vm.selectedForm === "questionnaire";

    if (vm.isOperator) {
      return <div />;
    }

    return (
      <>
        {selectedVariantOsha && (
          <div className="metrics__footer-wide">
            <div>
              <Button
                variant="outline-primary"
                disabled={vm.busyWatcher.isBusy || !vm.isPrevYearVisible}
                onClick={() => vm.previousYear()}>
                {vm.translate("previousYear")}
              </Button>
              {!vm.isLastYear && (
                <Button variant="outline-primary" disabled={vm.busyWatcher.isBusy} onClick={() => vm.nextYear()}>
                  {vm.translate("nextYear")}
                </Button>
              )}
              {vm.isLastYear && (
                <Button variant="outline-primary" disabled={vm.busyWatcher.isBusy} onClick={() => vm.switch("questionnaire")}>
                  {vm.translate("visitQuestionnaire")}
                </Button>
              )}
            </div>

            <div>
              <Button variant="outline-primary" disabled={vm.busyWatcher.isBusy} onClick={vm.cancel}>
                {vm.translate("cancel")}
              </Button>
              <Button disabled={vm.busyWatcher.isBusy} onClick={vm.saveOsha}>
                {vm.translate("save")}
              </Button>
            </div>
          </div>
        )}
        {selectedVariantQuestionnaire && (
          <div className="metrics__footer-wide">
            <div>
              <Button variant="outline-primary" disabled={vm.busyWatcher.isBusy} onClick={() => vm.switch("oshaForm")}>
                {vm.translate("backToOsha")}
              </Button>
            </div>

            <div>
              <Button variant="outline-primary" disabled={vm.busyWatcher.isBusy} onClick={vm.cancel}>
                {vm.translate("cancel")}
              </Button>
              <Button disabled={vm.busyWatcher.isBusy} onClick={vm.saveQuestionnaire}>
                {vm.translate("save")}
              </Button>
            </div>
          </div>
        )}
      </>
    );
  }

  renderHeader(vm: SafetyMetricsQuestionnaireViewModel) {
    const selectedVariantOsha = vm.selectedForm === "oshaForm" ? "primary" : "dark";
    const selectedVariantQuestionnaire = vm.selectedForm === "questionnaire" ? "primary" : "dark";

    return (
      <Row className="metrics__header">
        <Col>
          <h4 className="display-6">{vm.name}</h4>
        </Col>
        {!vm.isOperator && (
          <Col className="metrics__switch">
            <Button onClick={() => vm.switch("oshaForm")} variant={selectedVariantOsha}>
              {vm.translate("oshaForm")}
            </Button>
            <Button onClick={() => vm.switch("questionnaire")} variant={selectedVariantQuestionnaire}>
              {vm.translate("questionnaire")}
            </Button>
          </Col>
        )}
      </Row>
    );
  }
  renderBody(vm: SafetyMetricsQuestionnaireViewModel) {
    return (
      <LoadingContainer loading={vm.busyWatcher.isBusy}>
        {vm.selectedForm === "oshaForm" && (
          <Tabs onSelect={year => vm.selectCurrentYear(year!)} activeKey={vm.selectedYear} className="metrics__tabs">
            {vm.model.oshaForm.map(year => (
              <Tab key={year.year!.toString()} eventKey={year.year!.toString()} title={year.year!.toString()}>
                <OshaTable questions={vm.oshaQuestions} ta={vm.localization.translateAttribute} tg={vm.translate} data={year} />
              </Tab>
            ))}
          </Tabs>
        )}
        {vm.selectedForm === "questionnaire" && (
          <Questionnaire
            {...{
              model: vm.model.questionnaire,
              selectSection: vm.selectSection,
              selectedSection: vm.selectedSection,
              tg: vm.translate,
              isBusy: vm.busyWatcher.isBusy,
              readOnly: vm.isOperator,
            }}
          />
        )}
      </LoadingContainer>
    );
  }
}

registerView(SafetyMetricsQuestionnaireView, SafetyMetricsQuestionnaireViewModel);
