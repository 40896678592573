import { registerView, View, ViewComponent } from "@frui.ts/views";
import { observer } from "mobx-react-lite";
import React from "react";
import SubModuleViewModel from "viewModels/company/employees/subModuleViewModel";
import { Button, Col, Row } from "@emanprague/veralinkkit";

import "./index.scss";
import { Import, Export } from "@emanprague/veralinkkit/dist/Icons";
import EVerifyIcon from "./everify-logo.svg";

const subModuleView: ViewComponent<SubModuleViewModel> = observer(({ vm }) => {
  const tg = (code: string) => vm.translate(code);
  return (
    <div className="employees">
      <Row className="company__header">
        <Col lg={3} md={12}>
          <h4>{vm.name}</h4>
        </Col>
        <Col lg={9} md={12} className="dheader__actions">
          <Button variant="outline-primary" onClick={vm.openImportModal}>
            <Import fontSize={24} color="#00949E" />
            &nbsp;
            {tg("importWorkers")}
          </Button>
          <Button variant="outline-primary" onClick={vm.openExportModal}>
            <Export fontSize={24} color="#00949E" />
            &nbsp;
            {tg("exportWorkers")}
          </Button>
        </Col>
      </Row>
      <View vm={vm.activeTabVM} context="header" fallbackMode="children" />
      <div className="everify">
        <a rel="noopener noreferrer" href="https://www.e-verify.gov" target="_blank">
          {vm.translate("verifyWorker")} &nbsp;
          <img src={EVerifyIcon} alt="e-verify logo" />
        </a>
      </div>

      <br />
      <View vm={vm.activeTabVM} />
      <View vm={vm.exportVM || vm.importVM} />
    </div>
  );
});

registerView(subModuleView, SubModuleViewModel);
