import { observable } from "mobx";
import { Type, Transform } from "class-transformer";
import LocationSimple from "./locationSimple";
import { formatISO } from "date-fns";
import parseISO from "date-fns/parseISO";

// This entity has been generated, do not change its content, your changes might get lost. You CAN modify the rest of the file.
class CompanyProject {
  /**
   * read-only
   */
  id?: string;

  @observable
  name?: string;

  @observable
  description?: string;

  /**
   * client / assigned by
   */
  @observable
  operatorId?: string;

  /**
   * client / assigned by (read-only)
   */
  @observable
  operatorName?: string;

  @observable
  @Transform(value => (value ? (typeof value === "string" ? parseISO(value) : value) : undefined), { toClassOnly: true })
  @Transform(value => (value ? formatISO(value, { representation: "date" }) : undefined), { toPlainOnly: true })
  startDate?: Date;

  @observable
  @Transform(value => (value ? (typeof value === "string" ? parseISO(value) : value) : undefined), { toClassOnly: true })
  @Transform(value => (value ? formatISO(value, { representation: "date" }) : undefined), { toPlainOnly: true })
  endDate?: Date;

  @observable
  @Type(() => LocationSimple)
  location?: LocationSimple;

  /**
   * Number of workers already assigned to project. (read-only)
   */
  @observable
  nWorkersAssigned!: number;

  /**
   * Total number of worker wanted. (read-only)
   */
  @observable
  nWorkersWanted!: number;

  static ValidationRules = {
    startDate: { format: "date" },
    endDate: { format: "date" },
    nWorkersAssigned: { number: true },
    nWorkersWanted: { number: true },
  };
}

export default class CompanyProjectExtended extends CompanyProject {
  static ValidationRules = Object.assign(CompanyProject.ValidationRules, {
    name: { required: true },
  });
}
