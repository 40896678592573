import { Button, Col, Row } from "@emanprague/veralinkkit";
import { registerView, View, ViewComponent } from "@frui.ts/views";
import Input from "controls/input";
import PagingInfo from "controls/pagingInfo";
import SortSelect from "controls/sortSelect";
import { observer } from "mobx-react-lite";
import React from "react";
import ModulePageViewModel from "viewModels/workerPortal/modulePageViewModel";
import WorkerSearchCard from "./workerSearchCard";
import WorkerPortalFilter from "./filter/workerPortalFilter";
import "./workerPortal.scss";
import LoadingContainer from "views/common/loadingContainer";

const modulePageListView: ViewComponent<ModulePageViewModel> = observer(({ vm }) => {
  const { translateGeneral, translateAttribute } = vm.localization;
  const ta = (code: string) => translateAttribute("workerPortalFilter", code);
  const tg = (code: string) => translateGeneral(`workerPortal.modulePage.${code}`);

  return (
    <>
      <h4>{tg("title")}</h4>

      <Row className="worker-portal__header">
        <PagingInfo paging={vm.currentPaging} localization={vm.localization} />

        <p>{ta("sortBy")}:</p>
        <SortSelect
          paging={vm.pagingFilter}
          items={vm.sortOptions}
          model="workerPortalSortOption"
          localization={vm.localization}
        />

        <Input placeholder={ta("search")} target={vm.filter} property="search" />
      </Row>

      <Row>
        <Col md={4}>
          <WorkerPortalFilter vm={vm} />
        </Col>

        <Col md={8} className="worker-portal__list">
          <LoadingContainer loading={vm.busyWatcher.isBusy}>
            {vm.items?.map(worker => (
              <WorkerSearchCard
                key={worker.accountId}
                worker={worker}
                localization={vm.localization}
                onViewProfile={() => vm.openWorkerProfile(worker)}
                onSendMessage={() => vm.openSendMessageModal(worker)}
                onRequestLink={() => vm.sendConnection(worker)}
                enums={vm.enums}
              />
            ))}
          </LoadingContainer>

          {vm.canLoadData && (
            <Button block variant="outline-primary" onClick={vm.loadData}>
              {translateGeneral("paging.loadMore")}
            </Button>
          )}
        </Col>
      </Row>
      <View vm={vm.activeChild} />
    </>
  );
});

registerView(modulePageListView, ModulePageViewModel);
