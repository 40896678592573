import React, { FunctionComponent, ReactNode } from "react";
import { Form } from "@emanprague/veralinkkit";
import Tooltip from "@emanprague/basekit/dist/Tooltip";
import InfoCircleIcon from "@emanprague/veralinkkit/dist/Icons/InfoCircle";
import { ILocalizationService } from "@emanprague/shared-services";

import "./field.scss";

interface FieldProps {
  label?: string;
  info?: string;
  optional?: boolean;
  children: ReactNode;
  localization: ILocalizationService;
}

const Field: FunctionComponent<FieldProps> = ({ label, info, optional, children, localization }) => {
  return (
    <Form.Group>
      <Form.Label>{label ? label : "  "}</Form.Label>
      {info && (
        <Tooltip id="info-tooltip" tooltip={info}>
          <InfoCircleIcon className="infoTooltipIcon" />
        </Tooltip>
      )}
      {optional && <span className="optionalText">{localization.translateGeneral("optional")}</span>}
      {children}
    </Form.Group>
  );
};

export default Field;
