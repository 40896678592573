import { Button, Form, LoaderOverlay, Media } from "@emanprague/veralinkkit";
import { registerView } from "@frui.ts/views";
import Input from "controls/input";
import React from "react";
import ExportWorkersViewModel from "viewModels/company/employees/exportWorkersViewModel";
import ModalView from "views/common/modalView";
import InfiniteScroll from "react-infinite-scroller";
import UserAvatarPlaceholder from "@emanprague/veralinkkit/dist/Icons/User";

class ExportWorkersModalView extends ModalView<ExportWorkersViewModel> {
  renderBody(vm: ExportWorkersViewModel) {
    return (
      <>
        <Input placeholder={vm.translate("search")} target={vm.filter} property="search" />
        <LoaderOverlay loading={vm.busyWatcher.isBusy} variant="light">
          <ul className="workersList">
            <label>{vm.translate("selected")}</label>
            <div className="workersList__content">
              {vm.selectedWorkers.map(worker => (
                <Media key={worker.accountId} as="li">
                  <Form.Check
                    id={worker.accountId ?? ""}
                    custom
                    checked={true}
                    onChange={() => vm.unselectWorker(worker)}
                    label=""
                  />
                  {worker.profilePhotoUrl ? (
                    <img width={24} height={24} src={worker.profilePhotoUrl} alt="avatar" />
                  ) : (
                    <UserAvatarPlaceholder fontSize={24} />
                  )}
                  <Media.Body>
                    <b>
                      {worker.firstName} {worker.middleName} {worker.lastName}
                    </b>
                    <span>{worker.primaryProfessionName || worker.professionTitle}</span>
                  </Media.Body>
                </Media>
              ))}
            </div>
          </ul>
          <ul className="workersList">
            <label>{vm.translate("available")}</label>
            <div className="workersList__content">
              <InfiniteScroll pageStart={0} loadMore={() => vm.loadData()} hasMore={vm.canLoadData} useWindow={false}>
                {vm.availableWorkers?.map((worker, index) => (
                  <Media key={`${index}-${worker.accountId}`} as="li">
                    <Form.Check id={worker.accountId ?? ""} custom onChange={() => vm.selectWorker(worker)} label="" />
                    {worker.profilePhotoUrl ? (
                      <img width={24} height={24} src={worker.profilePhotoUrl} alt="avatar" />
                    ) : (
                      <UserAvatarPlaceholder fontSize={24} />
                    )}
                    <Media.Body>
                      <b>
                        {worker.firstName} {worker.middleName} {worker.lastName}
                      </b>
                      <span>{worker.primaryProfessionName || worker.professionTitle}</span>
                    </Media.Body>
                  </Media>
                )) ?? <div />}
              </InfiniteScroll>
            </div>
          </ul>
        </LoaderOverlay>
      </>
    );
  }

  renderFooter(vm: ExportWorkersViewModel) {
    return (
      <Button variant="primary" disabled={!vm.canExport} onClick={vm.exportWorkers}>
        {vm.translate("export")}
      </Button>
    );
  }
}

registerView(ExportWorkersModalView as any, ExportWorkersViewModel);
