import { Card, Col, RoundedButton, Row } from "@emanprague/veralinkkit";
import EditIcon from "@emanprague/veralinkkit/dist/Icons/Edit";
import { ViewComponent } from "@frui.ts/views";
import { observer } from "mobx-react-lite";
import React from "react";
import WorkerProfileViewModel from "viewModels/worker/workerProfileViewModel";

const aboutMeSectionView: ViewComponent<WorkerProfileViewModel> = observer(({ vm }) => {
  const { mode, item } = vm;
  const isEdit = mode === "edit";

  const handleOpen = () => vm.openScreen("about");
  const ta = vm.localization.translateAttribute;

  return (
    <Card>
      <Card.Header>
        <Card.Title className="text-uppercase">{vm.translate("aboutMe")}</Card.Title>
        {isEdit && (
          <RoundedButton onClick={handleOpen}>
            <EditIcon fontSize={20} />
          </RoundedButton>
        )}
      </Card.Header>
      <Card.Body>
        <div className="skills">
          <Row className="skills__content">
            <Col md={12} className="bio">
              <h6>{vm.translate("aboutMe")}</h6>
              <p>{item.skills.bio}</p>
            </Col>
            <Col md={12}>
              <h6>{ta("workerProfile", "affiliations")}</h6>
              <ul>
                {item.USMilitaryVeteran && <li>{ta("workerProfile", "USMilitaryVeteran")}</li>}
                {item.unionMember && <li>{ta("workerProfile", "unionMember")}</li>}
              </ul>
            </Col>
          </Row>
        </div>
      </Card.Body>
    </Card>
  );
});

export default aboutMeSectionView;
