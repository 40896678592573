import { IEventBus, ILocalizationService, INotificationService, SeverityLevel } from "@emanprague/shared-services";
import { bound } from "@frui.ts/helpers";
import { BusyWatcher, Router, ScreenBase, watchBusy } from "@frui.ts/screens";
import AccountType from "entities/accountType";
import AuthenticationIdentity from "entities/authenticationIdentity";
import EmployeeRepository from "repositories/employeeRepository";
import EmployeeFilter from "models/employeeFilter";
import CompanyEmployee from "entities/companyEmployee";
import { action, reaction } from "mobx";
import RemoveWorkerViewModel from "./removeWorkerViewModel";
import { FilteredListViewModel } from "@frui.ts/datascreens";
import { EmployeesEvents } from "services/events";
import EnumsRepository from "repositories/enumsRepository";
import SendMessageViewModel from "viewModels/messages/sendMessageViewModel";
import EnumsService from "services/enumsService";
import { interfaces } from "inversify";

export default class ActiveEmployeesViewModel extends FilteredListViewModel<CompanyEmployee, EmployeeFilter, ScreenBase> {
  navigationName = "active";
  busyWatcher = new BusyWatcher();

  constructor(
    private companyId: string,
    private enumsRepository: EnumsRepository,
    private notificationService: INotificationService,
    private repository: EmployeeRepository,
    public localization: ILocalizationService,
    private eventBus: IEventBus,
    private router: Router,
    private removeWorkersFactory: ReturnType<typeof RemoveWorkerViewModel.Factory>,
    private sendMessageFactory: ReturnType<typeof SendMessageViewModel.Factory>,
    public enums: EnumsService
  ) {
    super();

    this.name = this.translate("title");
    this.pagingFilter.sortColumn = this.sortOptions[0]?.[0];
    this.pagingFilter.limit = 20;
  }

  onInitialize() {
    this.loadData();
    this.eventBus.subscribe(EmployeesEvents.workerRemoved, this.loadData);
    this.eventBus.subscribe(EmployeesEvents.workersImported, this.loadData);
    reaction(() => [this.pagingFilter.sortColumn, this.pagingFilter.sortDirection, this.filter.search], this.applyFilterAndLoad);
  }

  createFilter(): EmployeeFilter {
    return new EmployeeFilter();
  }

  get sortOptions() {
    return this.enumsRepository.getCompanyEmployeesSortOptions();
  }

  @bound
  @watchBusy
  async loadData() {
    const result = await this.repository.searchEmployees(this.companyId, this.appliedFilter, this.pagingFilter);
    if (result.success) {
      this.setData(result.payload);
    }
  }

  @bound
  viewProfile(employee: CompanyEmployee) {
    const { accountId } = employee;
    this.router.navigatePath(`publicProfile/worker/${accountId}`);
  }

  @action.bound
  @watchBusy
  async openSendMessageModal(employee: CompanyEmployee) {
    const { accountId, firstName, middleName, lastName } = employee;
    const fullName = [firstName, middleName, lastName].filter(w => w !== undefined).join(" ");

    const vm = this.sendMessageFactory(accountId!, fullName);
    this.tryActivateChild(vm);
  }

  @bound
  changePage(offset: number, _limit: number) {
    this.pagingFilter.offset = offset;
    this.loadData();
  }

  isVisible(identity: AuthenticationIdentity) {
    return identity.accountType === AccountType.Contractor || identity.accountType === AccountType.Operator;
  }

  @bound translate(key: string) {
    return this.localization.translateGeneral(`employees.subModule.${key}`);
  }

  @bound openRemoveModal(worker: CompanyEmployee) {
    const vm = this.removeWorkersFactory(this.companyId, worker);
    return this.tryActivateChild(vm);
  }

  @bound async sendResendMessage(worker: CompanyEmployee) {
    const result = await this.repository.sendResendMessage(this.companyId, worker.accountId!);
    if (result.success) {
      this.notificationService.addNotification(this.translate("inviteDone"), SeverityLevel.success, "inviteDone");
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  protected resetFilterValues(filter: EmployeeFilter) {}

  static Factory({ container }: interfaces.Context) {
    return (companyId: string) =>
      new ActiveEmployeesViewModel(
        companyId,
        container.get(EnumsRepository),
        container.get("INotificationService"),
        container.get(EmployeeRepository),
        container.get("ILocalizationService"),
        container.get("IEventBus"),
        container.get(Router),
        container.get(RemoveWorkerViewModel.Factory),
        container.get(SendMessageViewModel.Factory),
        container.get(EnumsService)
      );
  }
}
