import React from "react";
import "./fullHeight.scss";
import { Container } from "@emanprague/veralinkkit";

const FullHeightContainer = (props: any) => {
  return (
    <div className="full-white">
      <Container>{props.children}</Container>
    </div>
  );
};

export default FullHeightContainer;
