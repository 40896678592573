import React from "react";
import { StateNotOk, Check } from "@emanprague/veralinkkit/dist/Icons";
import ComplianceRequirementApprovalResult from "entities/complianceRequirementApprovalResult";
import ComplianceRequirementApprovalResultStatus from "entities/complianceRequirementApprovalResultStatus";

type Props = {
  connection: ComplianceRequirementApprovalResult & { name: string; filled: boolean };
  tg: (a: string) => string;
};

const PENDING_STATES = [ComplianceRequirementApprovalResultStatus.Pending];

const PointStatus: React.FC<Props> = ({ connection, tg }) => {
  if (PENDING_STATES.includes(connection.status)) {
    return <b>{tg(`${connection.name}.pending`)}</b>;
  }

  if (connection.status === ComplianceRequirementApprovalResultStatus.Rejected) {
    return (
      <>
        <p className="my-connections__state is-danger">
          <StateNotOk />
          {tg(`${connection.name}.rejected`)}
        </p>
        {connection.rejectedMessage && (
          <div>
            <b>{tg("note")}</b>
            <p>{connection.rejectedMessage}</p>
          </div>
        )}
      </>
    );
  }

  if (connection.filled || connection.status === ComplianceRequirementApprovalResultStatus.Approved) {
    return (
      <p className="my-connections__state is-good">
        <Check />
        {tg(`${connection.name}.filled`)}
      </p>
    );
  }
  return <p className="text-muted">{tg(`${connection.name}.required`)}</p>;
};

export default PointStatus;
