import { observable } from "mobx";
import { Type } from "class-transformer";
import CompanyWorkTypes from "./companyWorkTypes";

// This entity has been generated, do not change its content, your changes might get lost. You CAN modify the rest of the file.
export default class CompanySafetyPolicies {
  @observable
  @Type(() => CompanyWorkTypes)
  workTypes?: CompanyWorkTypes[];
}
