import { observable } from "mobx";
import AccountType from "./accountType";
import NotificationContent from "./notificationContent";
import NotificationType from "./notificationType";
import { Transform } from "class-transformer";
import { formatISO } from "date-fns";
import parseISO from "date-fns/parseISO";

// This entity has been generated, do not change its content, your changes might get lost. You CAN modify the rest of the file.
export default class Notification {
  readonly id?: string;

  @observable
  type!: NotificationType;

  /**
   * Related account ID
   */
  readonly accountId?: string;

  /**
   * Related account full name
   */
  readonly accountFullName?: string;

  @observable
  accountType?: AccountType;

  /**
   * Worker's or company photo URL
   */
  readonly accountPhotoUrl?: string;

  /**
   * Indicates whether notification has been read
   */
  @observable read!: boolean;

  @observable
  content!: NotificationContent;

  @Transform(value => (value ? (typeof value === "string" ? parseISO(value) : value) : undefined), { toClassOnly: true })
  @Transform(value => (value ? formatISO(value) : undefined), { toPlainOnly: true })
  readonly dateReceived!: Date;

  static ValidationRules = {
    type: { required: true },
    read: { required: true },
    content: { required: true },
    dateReceived: { format: "date-time", required: true },
  };
}
