import { registerView } from "@frui.ts/views";
import React from "react";
import AddSafetyPolicyViewModel from "viewModels/company/companyInfo/modals/addSafetyPolicyViewModel";
import ModalView from "views/common/modalView";
import { Row, Button, Col, Form } from "@emanprague/veralinkkit";
import { Delete, Plus } from "@emanprague/veralinkkit/dist/Icons";
import { Select } from "@frui.ts/bootstrap";
import FileUpload from "controls/fileUpload";
import { getValidationMessage } from "@frui.ts/validation";

class AddSafetyPolicyView extends ModalView<AddSafetyPolicyViewModel> {
  renderFooter(vm: AddSafetyPolicyViewModel) {
    return (
      <>
        <Button variant="outline-primary" disabled={vm.busyWatcher.isBusy} onClick={vm.cancel}>
          {vm.translate("cancel")}
        </Button>
        <Button variant="primary" disabled={vm.busyWatcher.isBusy} onClick={vm.save}>
          {vm.translate("save")}
        </Button>
      </>
    );
  }

  renderHeader(vm: AddSafetyPolicyViewModel) {
    const selectedVariantWorkTypes = vm.selectedForm === "worktypes" ? "primary" : "dark";
    const selectedVariantSafety = vm.selectedForm === "safety" ? "primary" : "dark";
    return (
      <Row className="company-info__policies-header">
        <Col>
          <h4 className="display-6">{vm.name}</h4>
        </Col>
        <Col className="company-info__policies-header__actions">
          <Button onClick={() => vm.switch("worktypes")} variant={selectedVariantWorkTypes}>
            {vm.translate("workTypes")}
          </Button>
          <Button onClick={() => vm.switch("safety")} variant={selectedVariantSafety}>
            {vm.translate("safety")}
          </Button>
        </Col>
      </Row>
    );
  }

  renderBody(vm: AddSafetyPolicyViewModel) {
    const isWorkTypeActive = vm.selectedForm === "worktypes";
    const isSafetyActive = vm.selectedForm === "safety";
    return (
      <>
        {isSafetyActive && (
          <div>
            {vm.model.safetyPolicies.length === 0 && <p>{vm.translate("emptyWorkTypes")}</p>}
            {vm.model.safetyPolicies.length > 0 && (
              <>
                <p>{vm.translate("addMoreWorkTypes")}</p>

                <div className="company-info__policy">
                  {vm.model.safetyPolicies.map((safety, index) => {
                    if (!safety.workTypeId) {
                      return null;
                    }
                    return (
                      <div className="company-info__policy-item">
                        <h4>{safety.worktype.name}</h4>
                        {safety.safetyPolicies.map(policy => (
                          <>
                            <h6>{policy.safetyPolicy?.name}</h6>
                            <Row key={index} className="company-info__worktype-item">
                              <Form.Group>
                                <Select
                                  mode="key"
                                  value={policy?.safetyPolicyId}
                                  target={policy}
                                  placeholder={vm.translate("selectSafety")}
                                  property="safetyPolicyId"
                                  items={vm.codeListSafetyPolicies}
                                  keyProperty="id"
                                  textProperty="name"
                                />
                              </Form.Group>
                              <Delete
                                className="cursor-pointer"
                                fontSize={24}
                                color="#888F91"
                                onClick={() => vm.removePolicy(safety, policy, index)}
                              />
                            </Row>

                            <Row>
                              <Col className="company-info__policy-upload">
                                <FileUpload
                                  accept={[".pdf"]}
                                  onDrop={(files: File[]) => {
                                    vm.replaceFile(policy, safety.workTypeId, files[0]);
                                  }}
                                  error={getValidationMessage(policy, "attachmentUrl")}
                                  localization={{
                                    button: vm.translate("upload"),
                                    or: vm.translate("dragFile"),
                                    types: vm.translate("fileValidation"),
                                  }}
                                  files={policy.file && [policy.file]}
                                  onRemove={() => vm.removeFile(policy, safety.workTypeId)}
                                />
                              </Col>
                            </Row>
                          </>
                        ))}

                        <Row className="company-info__policy-action">
                          <Button variant="outline-primary" onClick={() => vm.addSafety(safety)}>
                            <Plus fontSize={20} />
                            {vm.translate("addSafetyPolicy")}
                          </Button>
                        </Row>
                      </div>
                    );
                  })}
                </div>
              </>
            )}
          </div>
        )}
        {isWorkTypeActive && (
          <div>
            {vm.model.safetyPolicies.map((workType, index) => (
              <Row key={index} className="company-info__worktype-item">
                <Form.Group>
                  <Select
                    mode="key"
                    value={workType?.workTypeId}
                    target={workType}
                    placeholder={vm.translate("selectWorktype")}
                    property="workTypeId"
                    items={vm.codeListWorkTypes}
                    errorMessage={getValidationMessage(workType, "workTypeId")}
                    keyProperty="id"
                    textProperty="name"
                  />
                </Form.Group>
                <Delete className="cursor-pointer" fontSize={24} color="#888F91" onClick={() => vm.removeWorktype(index)} />
              </Row>
            ))}

            <Row>
              <Button variant="outline-primary" onClick={vm.addWorkType}>
                <Plus fontSize={20} />
                {vm.translate("addWorktype")}
              </Button>
            </Row>
          </div>
        )}
      </>
    );
  }
}

registerView(AddSafetyPolicyView, AddSafetyPolicyViewModel);
