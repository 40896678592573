import { ILocalizationService } from "@emanprague/shared-services";
import { bound } from "@frui.ts/helpers";
import { ConductorOneChildActive, Router, ScreenBase } from "@frui.ts/screens";
import CompanyFormViewModel from "./companyFormViewModel";
import ConfirmViewModel from "./confirmViewModel";
import SelectRoleViewModel from "./selectRoleViewModel";
import SuccessEmailSentViewModel, { navigationName as SuccessNavigationName } from "./successEmailSentViewModel";
import WorkerFormViewModel from "./workerFormViewModel";

const navigationName = "signup";

@Router.registerRoute({ route: navigationName, children: [WorkerFormViewModel, CompanyFormViewModel, ConfirmViewModel] })
@Router.registerRoute({ route: `${navigationName}/${SuccessNavigationName}`, name: SuccessEmailSentViewModel })
export default class SignupRootViewModel extends ConductorOneChildActive<ScreenBase> {
  navigationName = navigationName;
  constructor(
    public localization: ILocalizationService,
    private roleSelect: SelectRoleViewModel,
    workerForm: WorkerFormViewModel,
    companyForm: CompanyFormViewModel,
    successSent: SuccessEmailSentViewModel,
    confirmation: ConfirmViewModel
  ) {
    super();

    this.name = this.translate("title");
    this.children.push(this.roleSelect, workerForm, companyForm, successSent, confirmation);
  }

  closeChild() {
    // instead of closing the child, restart the wizard
    return this.tryActivateChild(this.roleSelect);
  }

  navigate(subPath: string | undefined, params: any) {
    // if no child is specified, start the wizard from the beginning
    if (!subPath) {
      return this.tryActivateChild(this.roleSelect);
    } else {
      return super.navigate(subPath, params);
    }
  }

  @bound translate(key: string) {
    return this.localization.translateGeneral(`signup.${key}`);
  }
}
