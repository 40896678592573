import RepositoryBase from "./repositoryBase";
import { IPagingFilter } from "@frui.ts/data";
import NotificationsFilter from "models/notificationsFilter";
import Notification from "entities/notification";
import ListOfNotifications from "entities/listOfNotifications";
import CountOfNewNotifications from "entities/countOfNewNotifications";

export default class NotificationsRepository extends RepositoryBase {
  getNotifications(filter: NotificationsFilter, paging: IPagingFilter) {
    return this.callApi(api =>
      api.all("notifications/notifications").getPagedEntities(ListOfNotifications, x => x.notifications, paging, filter)
    );
  }

  getNotification(notificationId: string) {
    return this.callApi(api => api.one("notifications/notifications", notificationId).getEntity(Notification));
  }

  markAllRead() {
    return this.callApi(api => api.all("notifications/notifications").all("read").putOnly({}));
  }

  markOneRead(notificationId: string) {
    return this.callApi(api => api.one("notifications/notifications", notificationId).all("read").putOnly({ read: true }));
  }

  countUnread() {
    return this.callApi(api => api.all("notifications/notifications/count_unread").getEntity(CountOfNewNotifications));
  }
}
