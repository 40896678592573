import { observable } from "mobx";

export default class CompanyService {
  @observable
  name: string;

  static ValidationRules = {
    name: { required: true },
  };
}
