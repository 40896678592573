import { ILocalizationService, IEventBus } from "@emanprague/shared-services";
import { bound } from "@frui.ts/helpers";
import { BusyWatcher, watchBusy, ScreenBase, Router } from "@frui.ts/screens";
import AccountType from "entities/accountType";
import AuthenticationIdentity from "entities/authenticationIdentity";
import { ISubModule } from "viewModels/types";
import ContinuousListViewModelBase from "viewModels/continuousListViewModelBase";
import { action, observable, reaction } from "mobx";
import { SortingDirection } from "@frui.ts/data";
import SortOption from "models/sortOption";
import CompanyCredentialsRepository from "repositories/companyCredentialsRepository";
import EnumsService from "services/enumsService";
import { makeCredentialsSections } from "utils";
import CompanyEmployeeByCredential from "entities/companyEmployeeByCredential";
import { CredentialPhoto } from "entities/credential";
import WorkerProfileViewModel from "viewModels/worker/workerProfileViewModel";
import CredentialsOverviewViewModel from "viewModels/worker/credentials/credentialsOverviewViewModel";
import UserContext from "services/userContext";
import ImportCredentialsViewModel from "./importCredentialsViewModel";
import ExportCredentialsViewModel from "./exportCredentialsViewModel";
import { WorkerEvents } from "services/events";
import SendMessageViewModel from "viewModels/messages/sendMessageViewModel";

export class CredentialsFilter {
  @observable search?: string;
}

export default class SubModuleViewModel
  extends ContinuousListViewModelBase<CompanyEmployeeByCredential, CredentialsFilter>
  implements ISubModule {
  orderIndex = 5;
  navigationName = "credentials";
  busyWatcher = new BusyWatcher();
  profileId: string;
  @observable.ref credential: CredentialPhoto;

  constructor(
    private repository: CompanyCredentialsRepository,
    public localization: ILocalizationService,
    public enums: EnumsService,
    private userContext: UserContext,
    private eventBus: IEventBus,
    private router: Router,
    private credentialsFactory: ReturnType<typeof CredentialsOverviewViewModel.Factory>,
    private workerProfileFactory: ReturnType<typeof WorkerProfileViewModel.Factory>,
    private exportCredentialsFactory: ReturnType<typeof ExportCredentialsViewModel.Factory>,
    private importCredentialsFactory: ReturnType<typeof ImportCredentialsViewModel.Factory>,
    private sendMessageFactory: ReturnType<typeof SendMessageViewModel.Factory>
  ) {
    super();
    this.name = this.translate("title");
    this.pagingFilter.sortColumn = this.sortOptions[0]?.[0];
    this.pagingFilter.limit = 20;
  }

  get trainingEntities() {
    return this.enums.trainingEntities;
  }

  onInitialize() {
    this.eventBus.subscribe(WorkerEvents.credentialCreated, this.loadData);
    this.eventBus.subscribe(WorkerEvents.credentialDeleted, this.loadData);
    if (!this.items) {
      this.loadData();

      reaction(
        () => [this.pagingFilter.sortColumn, this.pagingFilter.sortDirection, this.filter.search],
        this.applyFilterAndLoadDebounced
      );
    }
  }

  get sortOptions() {
    return [
      ["lastName", SortingDirection.Ascending],
      ["lastName", SortingDirection.Descending],

      ["credentials.validityTo", SortingDirection.Ascending],
      ["credentials.validityTo", SortingDirection.Descending],
    ] as SortOption<any>[];
  }

  isVisible(identity: AuthenticationIdentity) {
    return identity.accountType === AccountType.Contractor || identity.accountType === AccountType.Operator;
  }

  @bound translate(key: string) {
    return this.localization.translateGeneral(`credentials.subModule.${key}`);
  }

  @bound openProfile(accountId: string) {
    this.router.navigatePath(`publicProfile/worker/${accountId}`);
  }

  @bound openOverview(profileId: string, credential: CredentialPhoto) {
    this.credential = credential;
    this.profileId = profileId;
    return this.navigate("credentialOverview", undefined);
  }

  @bound uploadCredentials(profileId: string) {
    this.profileId = profileId;
    return this.navigate("credentialsAdd", undefined);
  }

  @bound
  @watchBusy
  async loadData() {
    if (!this.userContext.identity) {
      return;
    }

    const result = await this.repository.loadCredentials(
      this.userContext.identity.accountId,
      this.pagingFilter,
      this.appliedFilter
    );

    if (result.success) {
      const credentialsResults = result.payload[0].map(employee => {
        return {
          ...employee,
          credentialsSection: makeCredentialsSections(employee.credentials as any, this.trainingEntities),
        };
      });
      this.setData([credentialsResults, result.payload[1]]);
    }
  }

  @action.bound
  @watchBusy
  async openSendMessageModal(accountId: string, accountName: string) {
    const vm = this.sendMessageFactory(accountId, accountName);
    this.tryActivateChild(vm);
  }

  @bound
  changePage(offset: number, _limit: number) {
    this.pagingFilter.offset = offset;
    this.loadData();
  }

  protected resetFilterValues(filter: CredentialsFilter) {
    filter.search = undefined;
  }

  @bound openExportModal() {
    return this.navigate("export", undefined);
  }

  @bound openImportModal() {
    return this.navigate("import", undefined);
  }

  protected findNavigationChild(navigationName: string | undefined): ScreenBase | undefined {
    switch (navigationName) {
      case "workerProfile":
        return this.workerProfileFactory(this.profileId, "read");
      case "credentialsAdd":
        return this.credentialsFactory(this.profileId, this.credential, { mode: "edit", variant: "worker" });
      case "credentialOverview":
        return this.credentialsFactory(this.profileId, this.credential, { mode: "readonly", variant: "worker" });
      case "export":
        return this.exportCredentialsFactory(this.userContext.identity?.accountId as string);
      case "import":
        return this.importCredentialsFactory(this.userContext.identity?.accountId as string);

      default:
        return undefined;
    }
  }
}
