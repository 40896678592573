import { registerView, ViewComponent, View } from "@frui.ts/views";
import { observer } from "mobx-react-lite";
import React from "react";
import StatusViewModel from "viewModels/profile/statusViewModel";
import { Dropdown } from "@emanprague/veralinkkit";
import UserAvatar from "views/common/userAvatar";
import "./statusView.scss";

const statusView: ViewComponent<StatusViewModel> = observer(({ vm }) => (
  <>
    <Dropdown
      id="profile"
      variant="outlined"
      className="status-profile"
      title={
        <>
          <UserAvatar url={vm.photoUrl} />
          <p>{vm.userName}</p>
        </>
      }>
      <Dropdown.Item id="accountSettings" onClick={vm.openSettings}>
        {vm.translate("accountSettings")}
      </Dropdown.Item>

      <Dropdown.Item id="changePassword" onClick={vm.changePassword}>
        {vm.translate("changePassword")}
      </Dropdown.Item>
      <Dropdown.Item id="logout" onClick={vm.logout}>
        {vm.translate("logout")}
      </Dropdown.Item>
    </Dropdown>
    <View vm={vm.activeChild} />
  </>
));

registerView(statusView, StatusViewModel);
