import { ILocalizationService, IEventBus } from "@emanprague/shared-services";
import { bound } from "@frui.ts/helpers";
import { BusyWatcher, ScreenBase, ConductorOneChildActive, watchBusy } from "@frui.ts/screens";
import AccountType from "entities/accountType";
import AuthenticationIdentity from "entities/authenticationIdentity";
import { ISubModule } from "viewModels/types";
import { observable, runInAction, action } from "mobx";
import ThresholdEditModalViewModel from "./thresholdEditModalViewModel";
import PointsAllocationEditViewModel from "./pointsAllocationEditViewModel";
import ExperienceModifierViewModel from "./experienceModifierViewModel";
import InsuranceEditViewModel from "./insuranceEditViewModel";
import SafetyProgramsEditViewModel from "./safetyProgramsEditViewModel";
import SafetyIndicatorsViewModel from "./safetyIndicatorsViewModel";
import ComplianceRepository from "repositories/complianceRepository";
import UserContext from "services/userContext";
import ComplianceRequirementsGradeThreshold from "entities/complianceRequirementsGradeThreshold";
import ComplianceRequirementsPointsAllocation from "entities/complianceRequirementsPointsAllocation";
import InsuranceDocument from "entities/insuranceDocument";
import { ComplianceDefinitionEvents } from "services/events";
import ExperienceModifierDefinition from "entities/experienceModifierDefinition";

export default class SubModuleViewModel extends ConductorOneChildActive<ScreenBase> implements ISubModule {
  orderIndex = 3;
  navigationName = "compliance";
  busyWatcher = new BusyWatcher();
  @observable thresholds: ComplianceRequirementsGradeThreshold[];
  @observable pointsAllocation: ComplianceRequirementsPointsAllocation;
  @observable experienceModifier?: number;
  @observable insuranceDocuments: InsuranceDocument[];
  private eventHandlers: any[];

  get pointsList() {
    return Object.keys(this.pointsAllocation ?? {}).map((el: string) => {
      return {
        property: el,
        value: this.pointsAllocation[el as keyof ComplianceRequirementsPointsAllocation],
      };
    });
  }

  get insurance() {
    return this.insuranceDocuments?.length;
  }

  get thresholdPoints() {
    return this.thresholds ?? [];
  }

  constructor(
    public localization: ILocalizationService,
    private insuranceEditFactory: ReturnType<typeof InsuranceEditViewModel.Factory>,
    private thresholdsEditFactory: ReturnType<typeof ThresholdEditModalViewModel.Factory>,
    private experienceFactory: ReturnType<typeof ExperienceModifierViewModel.Factory>,
    private safetyProgramFactory: ReturnType<typeof SafetyProgramsEditViewModel.Factory>,
    private pointsAllocationsFactory: ReturnType<typeof PointsAllocationEditViewModel.Factory>,
    private safetyIndicatorsFactory: ReturnType<typeof SafetyIndicatorsViewModel.Factory>,
    private repository: ComplianceRepository,
    private context: UserContext,
    private eventBus: IEventBus
  ) {
    super();

    this.name = this.translate("title");

    this.eventHandlers = [
      this.eventBus.subscribe(ComplianceDefinitionEvents.pointsAllocationUpdated, this.updatePoints),
      this.eventBus.subscribe(ComplianceDefinitionEvents.experienceModifierUpdated, this.updateExperience),
      this.eventBus.subscribe(ComplianceDefinitionEvents.insuranceUploaded, this.updateInsurance),
      this.eventBus.subscribe(ComplianceDefinitionEvents.insuranceRemoved, this.removeInsurance),
    ];
  }

  async onInitialize() {
    await Promise.all([this.loadThresholds(), this.loadPoints(), this.loadExperienceModifier(), this.loadInsurance()]);
  }

  @action.bound
  removeInsurance(event: any) {
    if (event.payload) {
      this.insuranceDocuments = this.insuranceDocuments.filter(({ id }: InsuranceDocument) => id !== event.payload.id);
    }
  }

  @action.bound
  updateInsurance(event: any) {
    if (event.payload) {
      this.insuranceDocuments = this.insuranceDocuments.concat([event.payload]);
    }
  }

  @action.bound
  updateExperience(event: any) {
    if (event.payload) {
      this.experienceModifier = event.payload.threshold;
    }
  }

  @action.bound
  updatePoints(event: any) {
    if (event.payload) {
      this.pointsAllocation = event.payload;
    }
  }

  async onDeactivate(isClosing: boolean) {
    super.onDeactivate(isClosing);

    if (isClosing) {
      this.eventHandlers?.forEach(x => x.dispose());
    }
  }

  @watchBusy
  async loadInsurance() {
    const result = await this.repository.loadInsuranceDocuments(this.context.identity?.accountId as string);

    if (result.success) {
      runInAction(() => {
        this.insuranceDocuments = result.payload;
      });
    }
  }

  @watchBusy
  async loadPoints() {
    const result = await this.repository.loadPointsAllocations(this.context.identity?.accountId as string);

    if (result.success) {
      runInAction(() => {
        this.pointsAllocation = result.payload;
      });
    }
  }

  @watchBusy
  async loadExperienceModifier() {
    const result = await this.repository.loadExperienceModifier(this.context.identity?.accountId as string);

    if (result.success) {
      runInAction(() => {
        this.experienceModifier = result.payload.threshold;
      });
    }
  }

  @watchBusy
  async loadThresholds() {
    const result = await this.repository.loadThresholds(this.context.identity?.accountId as string);

    if (result.success) {
      runInAction(() => {
        this.thresholds = result.payload.thresholds;
      });
    }
  }

  @bound
  openAllocationThresholds() {
    this.navigate("pointAllocations", undefined);
  }

  @bound
  openEditThresholds() {
    this.navigate("threshold", undefined);
  }

  @bound isEditable(elem: any) {
    return ["experienceModifier", "insurance", "safetyMetrics"].includes(elem.type);
  }

  @bound editCallback(elem: any) {
    if (this.isEditable(elem)) {
      return () => this.navigate(elem.type, undefined);
    }
    return undefined;
  }

  isVisible(identity: AuthenticationIdentity) {
    return identity.accountType === AccountType.Operator;
  }

  @bound translate(key: string) {
    return this.localization.translateGeneral(`compliance.subModule.${key}`);
  }

  findNavigationChild(name: string | undefined): ScreenBase | undefined {
    switch (name) {
      case "pointAllocations":
        return this.pointsAllocationsFactory(this.context.identity?.accountId as string, this.pointsAllocation);
      case "experienceModifier":
        const modifier = new ExperienceModifierDefinition();
        modifier.threshold = this.experienceModifier;
        return this.experienceFactory(this.context.identity?.accountId as string, modifier);
      case "insurance":
        return this.insuranceEditFactory(this.context.identity?.accountId as string, this.insuranceDocuments);
      case "safetyMetrics":
        return this.safetyIndicatorsFactory(this.context.identity?.accountId as string);
      default:
        return undefined;
    }
  }
}
