import { ILocalizationService } from "@emanprague/shared-services";
import { bound } from "@frui.ts/helpers";
import { BusyWatcher, ScreenBase } from "@frui.ts/screens";
import { action, observable } from "mobx";
import SignupRepository from "repositories/signupRepository";

export const navigationName = "success";

export default class SuccessEmailSentViewModel extends ScreenBase {
  navigationName = navigationName;
  busyWatcher = new BusyWatcher();

  @observable email: string;

  constructor(public localization: ILocalizationService, private repository: SignupRepository) {
    super();
    this.name = this.translate("title");
  }

  @bound translate(key: string) {
    return this.localization.translateGeneral(`signup.successEmailSent.${key}`);
  }

  @bound
  async resend() {
    await this.repository.resendEmail(this.email);
  }

  @action navigate(_subpath: any, params: any) {
    if (params?.email) {
      this.email = params.email;
    } else {
      this.requestClose();
    }
  }
}
