import { observable } from "mobx";
import { Type } from "class-transformer";
import OshaFormQuarter from "./oshaFormQuarter";
import OshaFormQuestion from "./oshaFormQuestion";

// This entity has been generated, do not change its content, your changes might get lost. You CAN modify the rest of the file.
export default class OshaFormYear {
  /**
   * @example
   * 2020
   */
  @observable
  year?: number;

  @observable
  @Type(() => OshaFormQuarter)
  quarters?: OshaFormQuarter[];

  @observable
  @Type(() => OshaFormQuestion)
  total?: OshaFormQuestion;

  static ValidationRules = {
    year: { number: true },
  };
}
