import { Button, LoaderOverlay, Form, Row } from "@emanprague/veralinkkit";
import { Input } from "@frui.ts/bootstrap";
import { registerView } from "@frui.ts/views";
import React from "react";
import CompanyInfoViewModel from "viewModels/company/companyInfo/modals/companyInfoViewModel";
import ModalView from "views/common/modalView";
import "./modals.scss";

class CompanyInfoModalView extends ModalView<CompanyInfoViewModel> {
  renderBody(vm: CompanyInfoViewModel) {
    const ta = (code: string) => vm.localization.translateAttribute("companyInfo", code);

    return (
      <LoaderOverlay className="company-info__modal" loading={vm.busyWatcher.isBusy} variant="light">
        <Row>
          <Form.Group>
            <Form.Label>{ta("companyName")}</Form.Label>
            <Input type="text" target={vm.model} property="companyName" />
          </Form.Group>
        </Row>

        <Row>
          <Form.Group>
            <Form.Label>{ta("taxId")}</Form.Label>
            <Input type="text" target={vm.model} property="taxId" />
          </Form.Group>
        </Row>

        <Row>
          <Form.Group>
            <Form.Label>
              {ta("yearFounded")}&nbsp;
              <span>({vm.translate("optional")})</span>
            </Form.Label>
            <Input type="number" target={vm.model} property="yearFounded" />
          </Form.Group>
        </Row>

        <Row>
          <Form.Group>
            <Form.Label>
              {ta("website")}&nbsp;
              <span>({vm.translate("optional")})</span>
            </Form.Label>
            <Input type="text" target={vm.model} property="website" />
          </Form.Group>
        </Row>

        <Row>
          <Form.Group>
            <Form.Label>{ta("contactPhone")}</Form.Label>
            <Input type="text" target={vm.model} property="phoneNumber" />
          </Form.Group>
        </Row>

        <Row>
          <Form.Group>
            <Form.Label>
              {ta("aboutUs")}&nbsp;
              <span>({vm.translate("optional")})</span>
            </Form.Label>
            <Input as="textarea" type="text" placeholder={ta("aboutUsHint")} target={vm.model} property="bio" />
          </Form.Group>
        </Row>
      </LoaderOverlay>
    );
  }

  renderFooter(vm: CompanyInfoViewModel) {
    return (
      <Button variant="primary" disabled={vm.busyWatcher.isBusy || !vm.canSave} onClick={vm.save}>
        {vm.translate("save")}
      </Button>
    );
  }
}

registerView(CompanyInfoModalView as any, CompanyInfoViewModel);
