import { observer } from "mobx-react-lite";
import React from "react";
import StatCard from "../components/statCard";
import { formatDateRange, projectProgress } from "utils/viewUtils";
import SubModuleViewModel from "viewModels/company/companyDashboard/subModuleViewModel";
import ProjectRow from "../components/projectRow";
import NoDataPlaceholder from "../components/noDataPlaceholder";
import LoadingContainer from "views/common/loadingContainer";
import "../index.scss";

const projects: React.FC<{
  vm: SubModuleViewModel;
}> = observer(({ vm }) => {
  return (
    <LoadingContainer loading={vm.busyWatcher.isBusy}>
      <StatCard
        title={vm.translate("ongoing")}
        subtitle={vm.translate("projects")}
        link={vm.translate("viewAll")}
        path="/#/dashboard/projects">
        {vm.ongoingProjects.length > 0 ? (
          vm.ongoingProjects.map((item, index) => (
            <ProjectRow
              key={index}
              name={item.name!}
              interval={formatDateRange({ startDate: item.startDate, endDate: item.endDate }, vm.localization)}
              complete={projectProgress(item.startDate!, item.endDate!)}
            />
          ))
        ) : (
          <NoDataPlaceholder text={vm.translate("noProjectsFound")} hidden={vm.busyWatcher.isBusy} />
        )}
      </StatCard>
    </LoadingContainer>
  );
});

export default projects;
