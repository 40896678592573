import { ILocalizationService } from "@emanprague/shared-services";
import { bound } from "@frui.ts/helpers";
import { BusyWatcher, ScreenBase, watchBusy } from "@frui.ts/screens";
import { observable, action, runInAction, computed } from "mobx";
import ComplianceRepository from "repositories/complianceRepository";
import { interfaces } from "inversify";
import ExperienceModifier, { ExperinceModifierValidated } from "entities/experienceModifier";
import { FileBox } from "controls/fileUpload";
import UserContext from "services/userContext";
import AccountType from "entities/accountType";
import { attachAutomaticValidator, validate } from "@frui.ts/validation";

type Model = {
  experience: ExperienceModifier;
  fileBox: FileBox<File>;
};

export default class ExperienceModifierViewModel extends ScreenBase {
  navigationName = "experienceModifier";
  busyWatcher = new BusyWatcher();
  @observable model: Model = { fileBox: new FileBox(), experience: new ExperienceModifier() };

  @observable isInitDataLoaded = false;
  @observable isDocumentUploaded = false;

  constructor(
    private connectionId: string,
    public localization: ILocalizationService,
    private repository: ComplianceRepository,
    private userContext: UserContext
  ) {
    super();

    this.name = this.translate("title");
  }

  @computed
  get isOperator() {
    return this.userContext.identity?.accountType === AccountType.Operator;
  }

  @bound translate(key: string) {
    return this.localization.translateGeneral(`compliance.experienceModifier.${key}`);
  }

  onInitialize() {
    this.loadData();
  }

  @bound
  @watchBusy
  async loadData() {
    const result = await this.repository.loadComplianceExperience(this.connectionId);

    if (result.success) {
      runInAction(() => {
        this.model.experience = result.payload;
        if (this.model.experience.fileUrl) {
          this.model.fileBox.file = new File([], result.payload.fileName!);
          this.isDocumentUploaded = true;
        }
        this.model.experience = attachAutomaticValidator(this.model.experience, ExperinceModifierValidated.ValidationRules);

        this.isInitDataLoaded = true;
      });
    }
  }

  @bound
  @watchBusy
  async save() {
    const valid = validate(this.model.experience);
    if (!valid) {
      return;
    }
    const saveResult = await this.repository.saveEMRModifier(this.connectionId, this.model.experience);

    if (!saveResult.success) {
      return;
    }

    if (this.model.experience.fileName) {
      await this.repository.deleteEMRAttachment(this.connectionId);
    }

    if (this.model.fileBox.file) {
      const attachResult = await this.repository.saveEMRAttachment(this.connectionId, this.model.fileBox!);
      if (attachResult.success) {
        await this.loadData();
        runInAction(() => {
          this.isDocumentUploaded = true;
        });
      }
    }
  }

  @bound cancel() {
    this.requestClose();
  }

  @action.bound
  async removeFile() {
    this.model.fileBox.file = undefined;
    this.model.experience.rating = 0;
    this.isDocumentUploaded = false;
  }

  @action.bound
  replaceFile(file: File) {
    if (file) {
      this.model.fileBox.file = file;
    }
  }

  static Factory({ container }: interfaces.Context) {
    return (connectionId: string) =>
      new ExperienceModifierViewModel(
        connectionId,
        container.get("ILocalizationService"),
        container.get(ComplianceRepository),
        container.get(UserContext)
      );
  }
}
