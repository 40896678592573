import { Type } from "class-transformer";
import { observable } from "mobx";
import LocationSimple from "./locationSimple";
import WorkerProfileContact from "./workerProfileContact";
import WorkerProfileEducation from "./workerProfileEducation";
import WorkerProfileExperience from "./workerProfileExperience";
import { Transform } from "class-transformer";
import { formatISO } from "date-fns";
import WorkerProfileSkills from "./workerProfileSkills";
import parseISO from "date-fns/parseISO";

// This entity has been generated, do not change its content, your changes might get lost. You CAN modify the rest of the file.
export default class WorkerProfile {
  /**
   * Worker ID (read-only)
   */
  id!: string;

  @observable
  pendingRegistration?: boolean;

  /**
   * Worker profile photo URL (read-only)
   */
  @observable
  profilePhotoUrl?: string;

  /**
   * First name
   */
  @observable
  firstName?: string;

  /**
   * Middle name
   */
  @observable
  middleName?: string;

  /**
   * Last name
   */
  @observable
  lastName?: string;

  @observable
  @Type(() => LocationSimple)
  location?: LocationSimple;

  @observable
  @Type(() => WorkerProfileContact)
  contact?: WorkerProfileContact;

  @observable
  @Type(() => WorkerProfileSkills)
  skills: WorkerProfileSkills;

  /**
   * Available for hire from date or null if not available
   */
  @observable
  @Transform(value => (value ? (typeof value === "string" ? parseISO(value) : value) : undefined), { toClassOnly: true })
  @Transform(value => (value ? formatISO(value, { representation: "date" }) : undefined), { toPlainOnly: true })
  availableFrom?: Date;

  @observable
  @Type(() => WorkerProfileExperience)
  workExperience: WorkerProfileExperience[] = [];

  @observable
  @Type(() => WorkerProfileEducation)
  education: WorkerProfileEducation[] = [];

  @observable
  USMilitaryVeteran!: boolean;

  @observable
  unionMember!: boolean;

  static ValidationRules = {
    id: { required: true },
    profilePhotoUrl: { format: "uri" },
    availableFrom: { format: "date" },
    USMilitaryVeteran: { required: true },
    unionMember: { required: true },
  };
}
