import OshaFormYear from "entities/oshaFormYear";
import OshaFormQuarter from "entities/oshaFormQuarter";
import OshaFormQuestion, { WithWorkOshaQuestion } from "entities/oshaFormQuestion";
import OshaFormAnswers from "entities/oshaFormAnswers";
import { format, subYears } from "date-fns";
import { extendObservable } from "mobx";
import Calculator from "./calculator";

const checkWorkPerformed = (questions: OshaFormQuestion) => Object.values(questions).some(el => !!el);

export const makeOshaQuestion = ({ year, answers }: { year: number; answers?: OshaFormYear }) => {
  let yearForm = new OshaFormYear();
  yearForm.year = year;
  yearForm.quarters = Array.from({ length: 4 }).map((_, idx) => {
    const quarterAnswer = answers?.quarters?.find(({ quarter }) => quarter === idx + 1);

    const quarter = new OshaFormQuarter();
    quarter.quarter = idx + 1;
    if (quarterAnswer?.questions) {
      (quarterAnswer.questions as WithWorkOshaQuestion).workPerformed = checkWorkPerformed(quarterAnswer!.questions);
    }

    quarter.questions = quarterAnswer?.questions ?? new WithWorkOshaQuestion();

    return quarter;
  });

  yearForm.total = answers?.total ?? new WithWorkOshaQuestion();

  yearForm = extendObservable(yearForm, {
    get calculator() {
      const calculator = new Calculator(yearForm.quarters);

      return {
        isInput: calculator.isInput,
        total: calculator.allTotal,
        readOnly: calculator.readOnly,
      };
    },
  });

  return yearForm;
};

export const makeOshaForm = (startYear: number, payload?: OshaFormAnswers) => {
  return Array.from({ length: 3 })
    .map((_, idx) => {
      const year = subYears(new Date(startYear, 0, 1), idx);
      const yearNum = parseInt(format(year, "yyyy"));

      const yearAnswers = payload?.years?.find(({ year }) => year === yearNum);

      return makeOshaQuestion({ year: yearNum, answers: yearAnswers });
    })
    .reverse();
};
