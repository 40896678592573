import { ILocalizationService, IEventBus } from "@emanprague/shared-services";
import { bound } from "@frui.ts/helpers";
import { BusyWatcher, ConductorSingleChild, ScreenBase, watchBusy } from "@frui.ts/screens";
import AccountType from "entities/accountType";
import AuthenticationIdentity from "entities/authenticationIdentity";
import CompanyContact from "entities/companyContact";
import CompanyLocation from "entities/companyLocation";
import CompanyProfile from "entities/companyProfile";
import { interfaces } from "inversify";
import { groupBy } from "lodash";
import { computed, observable, runInAction, action } from "mobx";
import CompanyProfileRepository from "repositories/companyProfileRepository";
import EnumsService from "services/enumsService";
import CompanyContactsViewModel from "./modals/companyContactsViewModel";
import CompanyInfoViewModel from "./modals/companyInfoViewModel";
import CompanyLocationsViewModel from "./modals/companyLocationsViewModel";
import CompanyServicesViewModel from "./modals/companyServicesViewModel";
import CompanyWorkTypes from "entities/companyWorkTypes";
import AddSafetyPolicyViewModel from "./modals/addSafetyPolicyViewModel";
import { CompanyEvents } from "services/events";
import CompanySafetyPolicies from "entities/companySafetyPolicies";
import UserContext from "services/userContext";

export default class CompanyProfileViewModel extends ConductorSingleChild<ScreenBase> {
  navigationName = "profile";
  busyWatcher = new BusyWatcher();

  private companyId: string;
  @observable companyProfile: CompanyProfile;

  @observable.ref companyContacts: CompanyContact[];
  @observable.ref companyWorktypes: CompanyWorkTypes[];
  @observable.shallow companyLocations: CompanyLocation[];
  eventHandlers: any;

  get isEdit() {
    return this.mode === "edit";
  }

  get codeListWorkTypes() {
    return this.enums.workTypes;
  }

  get workTypesModel() {
    return (
      this.companyWorktypes?.map(el => {
        const workType = this.codeListWorkTypes.find(({ id }) => id === el.workTypeId);

        const count = el.safetyPolicies.length;

        return {
          workType,
          count,
        };
      }) ?? []
    );
  }

  get safetyPoliciesCount() {
    return this.workTypesModel.reduce((acc, nxt) => acc + nxt.count, 0);
  }

  get isContractor() {
    return this.ctx?.identity?.accountType === AccountType.Contractor;
  }

  @computed get companyContactsGrouped() {
    return groupBy(this.companyContacts, "division");
  }

  constructor(
    source: string | CompanyProfile,
    public mode: "edit" | "read",
    private companyProfileRepository: CompanyProfileRepository,
    public localization: ILocalizationService,
    private companyInfoVMFactory: ReturnType<typeof CompanyInfoViewModel.Factory>,
    private companyContactsVMFactory: ReturnType<typeof CompanyContactsViewModel.Factory>,
    private companyServicesVMFactory: ReturnType<typeof CompanyServicesViewModel.Factory>,
    private companyLocationsVMFactory: ReturnType<typeof CompanyLocationsViewModel.Factory>,
    private companyWorkTypesFactory: ReturnType<typeof AddSafetyPolicyViewModel.Factory>,
    public enums: EnumsService,
    private eventBus: IEventBus,
    private ctx: UserContext
  ) {
    super();

    this.name = this.translate("title");

    if (source instanceof CompanyProfile) {
      this.companyId = source.id;
      this.companyProfile = source;
    } else {
      this.companyId = source;
    }

    this.navigationName = this.companyId;

    this.eventHandlers = [this.eventBus.subscribe(CompanyEvents.safetyPoliciesUpdated, this.handlePolicyUpdate)];
  }

  async onInitialize() {
    if (!this.companyProfile) {
      await this.loadCompanyProfile();
    }

    this.loadContacts();
    this.loadLocations();
    this.loadWorktypes();
  }

  protected findNavigationChild(navigationName: string | undefined) {
    return undefined;
  }

  @bound
  @watchBusy
  async loadCompanyProfile() {
    const response = await this.companyProfileRepository.getProfile(this.companyId);
    if (response.success) {
      runInAction(() => (this.companyProfile = response.payload));
    }
  }

  @bound
  @action
  handlePolicyUpdate(event: { payload: CompanySafetyPolicies }) {
    this.companyWorktypes = event.payload.workTypes ?? [];
  }

  @bound
  @watchBusy
  async loadWorktypes() {
    // Check if current user is operator and tryin to request the data for external profile
    if (!this.isContractor) {
      return;
    }
    const response = await this.companyProfileRepository.getSafetyPolicies(this.companyProfile.id);
    if (response.success) {
      runInAction(() => (this.companyWorktypes = response.payload.workTypes ?? []));
    }
  }

  @bound
  @watchBusy
  async loadLocations() {
    const response = await this.companyProfileRepository.getLocations(this.companyProfile.id);
    if (response.success) {
      runInAction(() => (this.companyLocations = response.payload));
    }
  }

  @bound
  @watchBusy
  async loadContacts() {
    const response = await this.companyProfileRepository.getContacts(this.companyProfile.id);
    if (response.success) {
      runInAction(() => (this.companyContacts = response.payload));
    }
  }

  @bound openEditCompanyInfo() {
    const modal = this.companyInfoVMFactory(this.companyProfile, this.loadCompanyProfile);
    this.tryActivateChild(modal);
  }

  @bound openEditCompanyContacts() {
    const modal = this.companyContactsVMFactory(this.companyProfile, this.companyContacts, this.loadContacts);
    this.tryActivateChild(modal);
  }

  @bound openEditCompanyServices() {
    const modal = this.companyServicesVMFactory(this.companyProfile, this.loadCompanyProfile);
    this.tryActivateChild(modal);
  }

  @bound openEditCompanyLocations() {
    const modal = this.companyLocationsVMFactory(this.companyProfile, this.companyLocations, this.loadLocations);
    this.tryActivateChild(modal);
  }

  @bound openWorkTypesModal() {
    const modal = this.companyWorkTypesFactory(this.companyId, this.companyWorktypes);
    this.tryActivateChild(modal);
  }

  isVisible(identity: AuthenticationIdentity) {
    return identity.accountType === AccountType.Contractor || identity.accountType === AccountType.Operator;
  }

  @bound translate(key: string, args?: any) {
    return this.localization.translateGeneral(`companyInfo.subModule.${key}`, args);
  }

  static Factory({ container }: interfaces.Context) {
    return (companyId: string | CompanyProfile, mode: "edit" | "read") =>
      new CompanyProfileViewModel(
        companyId,
        mode,
        container.get(CompanyProfileRepository),
        container.get("ILocalizationService"),
        container.get(CompanyInfoViewModel.Factory),
        container.get(CompanyContactsViewModel.Factory),
        container.get(CompanyServicesViewModel.Factory),
        container.get(CompanyLocationsViewModel.Factory),
        container.get(AddSafetyPolicyViewModel.Factory),
        container.get(EnumsService),
        container.get("IEventBus"),
        container.get(UserContext)
      );
  }
}
