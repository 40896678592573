import React from "react";
import { isFilled } from "utils/questionaire";
import QuestionnaireAnswer from "entities/questionnaireAnswer";
import { QuestionaireWithAnswer } from "viewModels/company/compliance/questionaire/SafetyCultureQuestionaireViewModel";
import { observer } from "mobx-react-lite";

const countAnswers = (questions: QuestionaireWithAnswer[]) => {
  const allQuestions = questions.length;
  const answersCount = questions.filter(question => {
    return isFilled(question.answer as QuestionnaireAnswer);
  }).length;
  return `${answersCount}/${allQuestions}`;
};

type Props = {
  name: string;
  className?: string;
  questions: any;
  onSelectSection: () => void;
};

const QuestionSection: React.FC<Props> = observer(({ onSelectSection, questions, name, className }) => {
  const count = countAnswers(questions);
  return (
    <div onClick={onSelectSection} className={className}>
      <p>{name}</p>

      <p>{count}</p>
    </div>
  );
});

export default QuestionSection;
