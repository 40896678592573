import { registerView } from "@frui.ts/views";
import React from "react";
import ExperienceModifierViewModel from "viewModels/company/compliance/experienceModifierViewModel";
import ModalView from "views/common/modalView";
import { Row, Button, Form, Col } from "@emanprague/veralinkkit";
import Input from "controls/input";

class ExperienceModifierView extends ModalView<ExperienceModifierViewModel> {
  renderFooter(vm: ExperienceModifierViewModel) {
    return (
      <Row className="justify-content-end">
        <Button variant="outline-primary" disabled={vm.busyWatcher.isBusy} onClick={vm.cancel}>
          {vm.translate("cancel")}
        </Button>
        <Button variant="primary" disabled={vm.busyWatcher.isBusy} onClick={vm.save}>
          {vm.translate("save")}
        </Button>
      </Row>
    );
  }

  renderBody(vm: ExperienceModifierViewModel) {
    const data = vm.experienceModifier;
    return (
      <Row>
        <Col>
          <Row className="edit-row experience">
            <Form.Group>
              <Form.Label>{vm.translate("experienceLabel")}</Form.Label>
              <Input type="number" step=".01" placeholder="0" target={data} property="threshold" />
            </Form.Group>
          </Row>
        </Col>
      </Row>
    );
  }
}
registerView(ExperienceModifierView, ExperienceModifierViewModel);
