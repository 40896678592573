import { observable } from "mobx";

// This entity has been generated, do not change its content, your changes might get lost. You CAN modify the rest of the file.
class CompanyLocation {
  /**
   * Location ID (read-only)
   */
  id!: string;

  /**
   * Full Name
   * @example
   * John Doe
   */
  @observable
  name!: string;

  /**
   * Street
   * @example
   * 5200 North Sam Houston Pkwy East, Suite 500
   */
  @observable
  street!: string;

  /**
   * @example
   * Houston
   */
  @observable
  city!: string;

  /**
   * @example
   * Texas
   */
  @observable
  state!: string;

  /**
   * @example
   * 77086
   */
  @observable
  zip!: string;

  static ValidationRules = {
    id: { required: true },
    name: { required: true },
    street: { required: true },
    city: { required: true },
    state: { required: true },
    zip: { required: true },
  };
}

export default class CompanyLocationExtended extends CompanyLocation {
  static ValidationRules = {
    id: { required: false },
    name: { required: true },
    street: { required: true },
    city: { required: true },
    state: { required: true },
    zip: {
      required: true,
      number: true,
    },
  };
}
