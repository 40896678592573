import { registerView } from "@frui.ts/views";
import React from "react";
import SafetyIndicatorsViewModel from "viewModels/company/compliance/safetyIndicatorsViewModel";
import ModalView, { ModalSize } from "views/common/modalView";
import { Row, Col, Button } from "@emanprague/veralinkkit";
import Input from "controls/input";
import "./compliance.scss";

export class SafetyIndicatorsView extends ModalView<SafetyIndicatorsViewModel> {
  size: ModalSize = "lg";

  renderFooter(vm: SafetyIndicatorsViewModel) {
    return (
      <Row className="safety-metrics__footer">
        <Col md={6}>
          <Button data-testid="reset" variant="outline-primary" disabled={vm.busyWatcher.isBusy} onClick={vm.reset}>
            {vm.translate("reset")}
          </Button>
        </Col>

        <Col md={6} className="d-flex justify-content-end">
          <Button variant="outline-primary" disabled={vm.busyWatcher.isBusy} onClick={vm.cancel}>
            {vm.translate("cancel")}
          </Button>
          <Button data-testid="save" variant="primary" disabled={vm.busyWatcher.isBusy} onClick={vm.save}>
            {vm.translate("save")}
          </Button>
        </Col>
      </Row>
    );
  }

  renderBody(vm: SafetyIndicatorsViewModel) {
    const { translateAttribute } = vm.localization;
    const ta = (code: string, entity: string) => translateAttribute(entity, code);

    return (
      <>
        <h5>{vm.translate("metrics")}</h5>
        <Row className="safety-metrics__section">
          <Col>
            {vm.indicators?.indicatorThresholds?.map(indicator => {
              return (
                <Row key={indicator.indicator} className="edit-row indicators">
                  <b>{ta((indicator.indicator as string).toLowerCase(), "safetySettings")}</b>

                  <Input type="number" placeholder="0" target={indicator} property="threshold" />
                </Row>
              );
            })}
          </Col>
        </Row>

        <h5 className="safety-metrics__title">{vm.translate("ratio")}</h5>
        <Row>
          <Col>
            {vm.ratio.map(name => {
              return (
                <Row key={name} className="edit-row indicators">
                  <b>{vm.translate(name)}</b>

                  <Input
                    append={<span className="indicators__append">%</span>}
                    data-testid={name}
                    type="number"
                    step=".1"
                    placeholder="0"
                    target={vm.indicators?.pointsRatio}
                    property={name}
                  />
                </Row>
              );
            })}
          </Col>
        </Row>
      </>
    );
  }
}

registerView(SafetyIndicatorsView, SafetyIndicatorsViewModel);
