import { ILocalizationService } from "@emanprague/shared-services";
import { bound } from "@frui.ts/helpers";
import { BusyWatcher, ConductorOneChildActive, ScreenBase } from "@frui.ts/screens";
import AccountType from "entities/accountType";
import AuthenticationIdentity from "entities/authenticationIdentity";
import CompanyProfile from "entities/companyProfile";
import UserContext from "services/userContext";
import { ISubModule } from "viewModels/types";
import CompanyProfileViewModel from "./companyProfileViewModel";

export default class SubModuleViewModel extends ConductorOneChildActive<ScreenBase> implements ISubModule {
  orderIndex = 2;
  navigationName = "companyInfo";
  busyWatcher = new BusyWatcher();

  constructor(
    private userContext: UserContext,
    public localization: ILocalizationService,
    private companyProfileFactory: ReturnType<typeof CompanyProfileViewModel.Factory>
  ) {
    super();
    this.name = this.translate("title");
  }

  async onInitialize() {
    const profile = this.userContext.profile as CompanyProfile;
    const profileViewModel = this.companyProfileFactory(profile, "edit");

    profileViewModel.navigationName = "profile"; // TODO: needed?
    await this.tryActivateChild(profileViewModel);
  }

  protected findNavigationChild(navigationName: string | undefined) {
    return this.activeChild;
  }

  isVisible(identity: AuthenticationIdentity) {
    return identity.accountType === AccountType.Contractor || identity.accountType === AccountType.Operator;
  }

  @bound translate(key: string) {
    return this.localization.translateGeneral(`companyInfo.subModule.${key}`);
  }
}
