import { Button, LoaderOverlay } from "@emanprague/veralinkkit";
import { registerView } from "@frui.ts/views";
import React from "react";
import NewTeamViewModel from "viewModels/company/projects/teams/newTeamViewModel";
import ModalView, { ModalSize } from "views/common/modalView";
import TeamFormView from "./teamFormView";

class NewTeamModalView extends ModalView<NewTeamViewModel> {
  size: ModalSize = "lg";

  renderBody(vm: NewTeamViewModel) {
    return (
      <>
        <LoaderOverlay loading={vm.busyWatcher.isBusy} variant="light">
          <TeamFormView vm={vm.formVM} />
        </LoaderOverlay>
      </>
    );
  }

  renderFooter(vm: NewTeamViewModel) {
    return (
      <>
        <Button variant={"outline-primary"} disabled={vm.busyWatcher.isBusy} onClick={() => vm.requestClose()}>
          {vm.translate("cancel")}
        </Button>
        <Button variant="primary" disabled={vm.busyWatcher.isBusy} onClick={vm.createTeam}>
          {vm.translate("save")}
        </Button>
      </>
    );
  }
}

registerView(NewTeamModalView as any, NewTeamViewModel);
