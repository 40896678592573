import React from "react";
import { registerView, ViewComponent } from "@frui.ts/views";
import { observer } from "mobx-react-lite";
import WorkerJoinedViewModel from "viewModels/notifications/notificationItems/workerJoinedViewModel";

const workerJoinedView: ViewComponent<WorkerJoinedViewModel> = observer(({ vm }) => {
  const { content, translate } = vm;

  return (
    <header onClick={vm.markAsRead}>
      <b>{content.accountFullName} </b>
      {translate("invited")}
    </header>
  );
});

registerView(workerJoinedView, WorkerJoinedViewModel);
