import { observable } from "mobx";

// This entity has been generated, do not change its content, your changes might get lost. You CAN modify the rest of the file.
export default class ExperienceModifier {
  /**
   * Experience Modifier value
   * @example
   * 2.34
   */
  @observable
  rating?: number;

  /**
   * Display name of the file
   * @example
   * experience.xslx
   */
  readonly fileName?: string;

  /**
   * URL to download the file
   */
  readonly fileUrl?: string;

  static ValidationRules = {
    rating: { number: true },
  };
}

export class ExperinceModifierValidated extends ExperienceModifier {
  static ValidationRules = Object.assign(ExperienceModifier.ValidationRules, {
    rating: { isAboveZero: true, maxDecimals: { size: 2 }, required: true },
  });
}
