import { observable } from "mobx";

// This entity has been generated, do not change its content, your changes might get lost. You CAN modify the rest of the file.
class ResetPassword {
  /**
   * Token sent in the email to verify request
   */
  @observable
  resetPasswordToken!: string;

  /**
   * New password
   */
  @observable
  newPassword!: string;

  static ValidationRules = {
    resetPasswordToken: { required: true },
    newPassword: { required: true },
  };
}

export default class ConfirmResetPassword extends ResetPassword {
  @observable
  confirmNewPassword: string;

  static ValidationRules = {
    ...ResetPassword.ValidationRules,
    confirmNewPassword: { required: true, equalWith: "newPassword" },
  };
}
