import { ILocalizationService } from "@emanprague/shared-services";
import { IPagingInfo } from "@frui.ts/data";
import { observer } from "mobx-react-lite";
import React from "react";
import { block } from "bem-cn";

export interface PagingInfoProps {
  paging: IPagingInfo;
  localization: ILocalizationService;
  className?: string;
}

const style = block("paging-info");

const PagingInfo: React.FunctionComponent<PagingInfoProps> = observer(({ paging, localization, className }) => {
  const classNames = style.mix(className);

  if (!paging) {
    return <div className={classNames}>{localization.translateGeneral("paging.noData")}</div>;
  }

  const displayed = Math.min(paging.limit, paging.totalItems - paging.offset);

  return (
    <div className={classNames}>
      {localization.translateGeneral("paging.showing")} {displayed} {localization.translateGeneral("paging.of")}{" "}
      {paging.totalItems}
    </div>
  );
});

export default PagingInfo;
