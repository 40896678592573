import { registerView, ViewComponent, View } from "@frui.ts/views";
import { observer } from "mobx-react-lite";
import React from "react";
import SubModuleViewModel from "viewModels/company/compliance/subModuleViewModel";
import RequirementCard from "./components/requirementsCards";
import RequirementCheck from "./components/requirementsCheck";
import { Row, Col } from "@emanprague/veralinkkit";
import { isNull, isUndefined } from "lodash";
import "./compliance.scss";

const makeValue = (threshold: { min?: number; max?: number; value?: number }) => {
  if (threshold.min && threshold.max) {
    return `${threshold.min ?? 0} - ${threshold.max ?? 0}`;
  }

  return threshold.value ?? 0;
};

export const subModuleView: ViewComponent<SubModuleViewModel> = observer(({ vm }) => {
  const { translateGeneral, translateAttribute } = vm.localization;
  const tg = (code: string, opts = {}) => translateGeneral(`compliance.subModule.${code}`, opts);
  const ta = (code: string, entity: string) => translateAttribute(entity, code);
  const thresholds = vm.thresholdPoints.map(threshold => ({
    name: threshold.name,
    required: true,
    value: tg("points", { value: makeValue(threshold) }),
  }));

  const points = vm.pointsList.map(point => ({
    name: ta(point.property, "compliancePoints"),
    required: !(isNull(point.value) || isUndefined(point.value)),
    value: tg("points", { value: point.value ?? 0 }),
  }));

  const checkList = vm.pointsList.map(point => {
    const value = vm[point.property as keyof SubModuleViewModel];
    return {
      type: point.property,
      name: ta(point.property, "compliancePoints"),
      required: !(isNull(point.value) || isUndefined(point.value)),
      value: value ? tg(`checkList.${point.property}`, { value }) : undefined,
    };
  });

  return (
    <>
      <h4 className="company__header">{vm.name}</h4>
      <Row>
        <Col lg={6} xs={12} className="compliance-section">
          <RequirementCard testId="test-thresholds-list" title={tg("thresholds")} data={thresholds} />
        </Col>
        <Col lg={6} xs={12} className="compliance-section">
          <RequirementCard
            testId="test-allocations-list"
            title={tg("allocations")}
            onEdit={vm.openAllocationThresholds}
            data={points}
            classNames={{
              childRow: "is-allocation",
            }}
          />
        </Col>
      </Row>
      {checkList.map((elem: any, idx) => (
        <RequirementCheck
          key={`${elem.title}-${idx}`}
          onEdit={vm.editCallback(elem)}
          isEditable={vm.isEditable(elem)}
          title={elem.name}
          requirement={elem.value}
        />
      ))}
      <View vm={vm.activeChild} />
    </>
  );
});

registerView(subModuleView, SubModuleViewModel);
