import { observable } from "mobx";
import WorkerImportPendingAction from "./workerImportPendingAction";

// This entity has been generated, do not change its content, your changes might get lost. You CAN modify the rest of the file.
export default class WorkerImportPending {
  @observable
  firstName!: string;

  @observable
  middleName!: string;

  @observable
  lastName!: string;

  @observable
  professionTitle?: string;

  @observable
  mobile!: string;

  @observable
  socialSecurityNumber!: string;

  @observable
  email!: string;

  @observable
  username!: string;

  @observable
  action?: WorkerImportPendingAction;

  static ValidationRules = {
    firstName: { required: true },
    middleName: { required: true },
    lastName: { required: true },
    mobile: { required: true },
    socialSecurityNumber: { required: true },
    email: { format: "email", required: true },
    username: { required: true },
  };
}
