import { FetchApiConnector } from "@frui.ts/apiclient";
import { bound } from "@frui.ts/helpers";
import { ClassTransformOptions, serialize } from "class-transformer";
import DeserializingRequestBuilder from "./deserializingRequestBuilder";
import UserContext from "services/userContext";

const serializeOptions: ClassTransformOptions = {
  excludePrefixes: ["_"],
};

function serializeEntity(value: any): string {
  return serialize(value, serializeOptions);
}

export default class BackendConnector {
  constructor(private baseUrl: string, private context: UserContext) {}

  @bound
  getAuthorizedRequestBuilder() {
    const apiConnector = new FetchApiConnector({ jsonSerializer: serializeEntity });
    const params: RequestInit = {
      headers: { Authorization: `Bearer ${this.context.token}` },
    };
    return new DeserializingRequestBuilder(apiConnector, this.baseUrl, params);
  }

  @bound
  getRequestBuilder() {
    const apiConnector = new FetchApiConnector({ jsonSerializer: serializeEntity });

    return new DeserializingRequestBuilder(apiConnector, this.baseUrl);
  }
}
