import React from "react";
import { observer } from "mobx-react-lite";
import { Button, Card, Dropdown } from "@emanprague/veralinkkit";
import Menu from "@emanprague/veralinkkit/dist/Icons/Menu";
import Comment from "@emanprague/veralinkkit/dist/Icons/Comment";
import CompanyEmployee from "entities/companyEmployee";
import { ILocalizationService } from "@emanprague/shared-services";
import EmployeeInfo from "views/common/employeeInfo";
import EnumsService from "services/enumsService";

interface EmployeeSearchCardProps {
  localization: ILocalizationService;
  employee: CompanyEmployee;
  onRemove: (employee: CompanyEmployee) => void;
  onResend: (employee: CompanyEmployee) => void;
  onViewProfile: (employee: CompanyEmployee) => void;
  onSendMessage: (employee: CompanyEmployee) => void;
  enums: EnumsService;
}

const EmployeeSearchCard: React.FunctionComponent<EmployeeSearchCardProps> = observer(
  ({ localization, employee, onRemove, onViewProfile, onSendMessage, enums, onResend }) => {
    const tg = (code: string) => localization.translateGeneral(`employees.card.${code}`);
    const tm = localization.translateModel;
    const isPending = employee.pendingRegistration;

    return (
      <Card className="mb-4">
        <Card.Header>
          <EmployeeInfo tg={tg} employee={employee} enums={enums} />
          <Dropdown
            className="employeeCard__options"
            title={<Menu className={"menuIcon"} fontSize={24} color="#888F91" />}
            variant="link"
            id="options">
            <Dropdown.Item onClick={() => onRemove(employee)}>{tg("remove")}</Dropdown.Item>
            {isPending && <Dropdown.Item onClick={() => onResend(employee)}>{tg("resend")}</Dropdown.Item>}
          </Dropdown>
        </Card.Header>
        <Card.Body>
          {!isPending && (
            <>
              <p>
                {employee.primaryProfessionName ?? tg("unknownProfession")}
                &nbsp;
                <span>{`(${employee.primaryProfessionYears} ${tm("year", employee.primaryProfessionYears)} ${tg(
                  "experience"
                )})`}</span>
              </p>
              <span>
                {tg("currentlyOnProjects")}: {employee.currentProjects?.map(p => p.name).join(", ") || " - "}
              </span>
            </>
          )}
          {isPending && <p>{employee.professionTitle}</p>}
        </Card.Body>
        <Card.Footer>
          <Button onClick={() => onViewProfile(employee)}>{tg("viewProfile")}</Button>
          <Button variant="outline-primary" disabled={isPending} onClick={() => onSendMessage(employee)}>
            <Comment fontSize={24} {...(isPending ? { opacity: 0.4 } : {})} color="#00949E" />
            &nbsp;
            {tg("sendMessage")}
          </Button>
        </Card.Footer>
      </Card>
    );
  }
);

export default EmployeeSearchCard;
