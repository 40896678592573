import { ILocalizationService } from "@emanprague/shared-services";
import { bound } from "@frui.ts/helpers";
import { BusyWatcher, ScreenBase, Router } from "@frui.ts/screens";
import SecurityService from "services/securityService";

export default class EmailConfirmedViewModel extends ScreenBase {
  navigationName = "emailConfirmed";
  busyWatcher = new BusyWatcher();
  token: string;

  constructor(public localization: ILocalizationService, private router: Router, private securityService: SecurityService) {
    super();

    this.name = this.translate("title");
  }

  async validateToken() {
    await this.securityService.validateConfirmToken(this.token);

    this.router.navigatePath("");
  }

  navigate(_subpath: any, params: any) {
    if (params?.token) {
      this.token = params.token;
      this.validateToken();
    } else {
      this.requestClose();
    }
  }

  @bound translate(key: string) {
    return this.localization.translateGeneral(`profile.emailConfirmed.${key}`);
  }
}
