import React from "react";
import { registerView } from "@frui.ts/views";

import PersonalQrViewModel from "viewModels/worker/modals/personalQrViewModel";
import ModalView from "views/common/modalView";
import QRCode from "qrcode.react";
import { Col, Row, Card } from "@emanprague/veralinkkit";
import "./modals.scss";

class PersonalQrModalView extends ModalView<PersonalQrViewModel> {
  renderBody(vm: PersonalQrViewModel) {
    return (
      <div className="qr-view">
        <Row>
          <Col>
            <h5 className="qr-view__name text-center">{vm.workerName}</h5>
          </Col>
        </Row>
        <Row className="justify-content-center ">
          <Col xs={5}>
            <Card>
              <Card.Body className="justify-content-center d-flex">
                <QRCode size={253} value={`${window.location.origin}/#/worker/${vm.workerId}`} />
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </div>
    );
  }
}

registerView(PersonalQrModalView as any, PersonalQrViewModel);
