import React from "react";
import { registerView, View, ViewComponent } from "@frui.ts/views";
import { observer } from "mobx-react-lite";
import ProjectDetailViewModel from "viewModels/company/projects/projectDetailViewModel";
import { Breadcrumb, Tab, Tabs } from "@emanprague/veralinkkit";
import ProjectCardDetail from "./components/projectCardDetail";

export enum PROJECT_DETAIL_SECTIONS {
  PROJECT_OVERVIEW = "projectOverview",
  TEAM = "team",
  PROJECT_DOCUMENTS = "projectDocuments",
}

const projectDetailView: ViewComponent<ProjectDetailViewModel> = observer(({ vm }) => {
  const { item } = vm;

  return (
    <>
      <div className="spacedHederRow">
        <Breadcrumb>
          <Breadcrumb.Item onClick={() => vm.requestClose()}>{vm.translate("projects")}</Breadcrumb.Item>
          <Breadcrumb.Item active={true}>{vm.name}</Breadcrumb.Item>
        </Breadcrumb>
      </div>
      <div className="spacedHederRow">
        <Tabs
          defaultActiveKey={PROJECT_DETAIL_SECTIONS.PROJECT_OVERVIEW}
          onSelect={eventKey => {
            if (eventKey !== null) {
              document.getElementById(eventKey)?.scrollIntoView({ behavior: "smooth", block: "nearest", inline: "nearest" });
            }
          }}>
          {Object.values(PROJECT_DETAIL_SECTIONS).map(key => (
            <Tab key={key} eventKey={key} title={vm.translate(key)} />
          ))}
        </Tabs>
      </div>
      <div>
        <section id={PROJECT_DETAIL_SECTIONS.PROJECT_OVERVIEW} className="cardSection">
          {item && (
            <ProjectCardDetail
              project={item}
              localization={vm.localization}
              onEditClick={vm.openEditProjectModal}
              projectOwner={vm.projectOwner}
              enums={vm.enums}
            />
          )}
          <View vm={vm.editProjectViewModel} />
        </section>
        {/* Nested Sections part */}
        {vm.nestedSectionsViewModels.map(nVm => (
          <View key={nVm.navigationName} vm={nVm} />
        ))}
      </div>
    </>
  );
});

registerView(projectDetailView, ProjectDetailViewModel);
