import { ILocalizationService } from "@emanprague/shared-services";
import { bound } from "@frui.ts/helpers";
import { BusyWatcher, ConductorAllChildrenActive, ScreenBase } from "@frui.ts/screens";
import AccountType from "entities/accountType";
import AuthenticationIdentity from "entities/authenticationIdentity";
import { ISubModule } from "viewModels/types";
import UserContext from "services/userContext";
import { computed, observable, runInAction } from "mobx";
import CompanyProfile from "entities/companyProfile";
import ExportWorkersViewModel from "./exportWorkersViewModel";
import ImportWorkersViewModel from "./importWorkersViewModel";
import EnumsService from "services/enumsService";
import ActiveEmployeesViewModel from "./activeEmployeesViewModel";

export default class SubModuleViewModel extends ConductorAllChildrenActive<ScreenBase> implements ISubModule {
  orderIndex = 4;
  navigationName = "employees";
  busyWatcher = new BusyWatcher();
  @observable activeTab = "active";

  tabs = ["active"];

  constructor(
    private userContext: UserContext,
    public localization: ILocalizationService,
    private exportWorkersFactory: ReturnType<typeof ExportWorkersViewModel.Factory>,
    private importWorkersFactory: ReturnType<typeof ImportWorkersViewModel.Factory>,
    private activeEmployeesFactory: ReturnType<typeof ActiveEmployeesViewModel.Factory>,
    public enums: EnumsService
  ) {
    super();
    this.name = this.translate("title");
  }

  onInitialize() {
    this.tryActivateChild(this.activeEmployeesFactory(this.companyProfileId));
  }

  @computed get companyProfileId() {
    return (this.userContext.profile as CompanyProfile)?.id ?? "";
  }

  isVisible(identity: AuthenticationIdentity) {
    return identity.accountType === AccountType.Contractor || identity.accountType === AccountType.Operator;
  }

  @bound translate(key: string) {
    return this.localization.translateGeneral(`employees.subModule.${key}`);
  }

  @bound openExportModal() {
    const vm = this.exportWorkersFactory(this.companyProfileId);
    this.tryActivateChild(vm);
  }

  @bound openImportModal() {
    const vm = this.importWorkersFactory(this.companyProfileId);
    this.tryActivateChild(vm);
  }

  @bound
  onTabChange(key: string | null) {
    if (key) {
      runInAction(() => {
        this.activeTab = key;
      });
    }
  }

  @computed
  get exportVM() {
    return this.children.find(vm => vm.navigationName === "export");
  }

  @computed
  get importVM() {
    return this.children.find(vm => vm.navigationName === "import");
  }

  @computed
  get activeTabVM() {
    return this.children.find(vm => vm.navigationName === this.activeTab);
  }
}
