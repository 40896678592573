import { IEventBus } from "@emanprague/shared-services";
import { GeneralEvents } from "services/events";
import ApiErrorResponse, { ApiResult } from "./apiModels";
import BackendConnector from "./backendConnector";
import DeserializingRequestBuilder from "./deserializingRequestBuilder";
import { unwrapErrorMessage } from "./helpers";

export default abstract class RepositoryBase {
  protected apiFactory: () => DeserializingRequestBuilder;
  constructor(connector: BackendConnector, protected eventBus: IEventBus) {
    this.apiFactory = connector.getAuthorizedRequestBuilder;
  }

  protected callApi<T>(action: (api: DeserializingRequestBuilder) => Promise<T>, notifyError = true): Promise<ApiResult<T>> {
    return action(this.apiFactory()).then(
      payload => ({ success: true, payload }),
      (error: ApiErrorResponse) => {
        if (notifyError) {
          const errorMessage = this.unwrapErrorMessage(error);
          this.eventBus.publish(GeneralEvents.apiError({ response: error, errorMessage }));
        }

        if (!error.content) {
          // console.warn("Unexpected API error response structure.", error);
        } else if (!error.content.errorDescription) {
          // fix server error message
          error.content.errorDescription = this.unwrapErrorMessage(error);
        }

        return { success: false, payload: error.content };
      }
    );
  }

  protected unwrapErrorMessage(error: any | ApiErrorResponse) {
    if (error.content) {
      return unwrapErrorMessage(error.content);
    }

    return error as string;
  }
}
