import { Card, RoundedButton } from "@emanprague/veralinkkit";
import DeleteIcon from "@emanprague/veralinkkit/dist/Icons/Delete";
import PlusIcon from "@emanprague/veralinkkit/dist/Icons/Plus";
import { ViewComponent } from "@frui.ts/views";
import WorkerProfileEducation from "entities/workerProfileEducation";
import React from "react";
import WorkerProfileViewModel from "viewModels/worker/workerProfileViewModel";

const educationSectionView: ViewComponent<WorkerProfileViewModel> = ({ vm }) => {
  const { mode, item } = vm;
  const isEdit = mode === "edit";

  const handleOpen = () => vm.openScreen("education");

  const renderTitle = (ed: WorkerProfileEducation) => {
    if (!!ed.degree) {
      return (
        <>
          {ed.degree}&nbsp;<span>{vm.translate("at")}</span>
          {` ${ed.institution}`}
        </>
      );
    } else {
      return ed.institution;
    }
  };

  return (
    <Card>
      <Card.Header>
        <Card.Title className="text-uppercase">{vm.translate("education")}</Card.Title>
        {isEdit && (
          <RoundedButton onClick={handleOpen}>
            <PlusIcon fontSize={20} />
          </RoundedButton>
        )}
      </Card.Header>
      <Card.Body>
        <div className="history-list">
          {item.education.map(ed => (
            <div className="history-list__item" key={ed.id}>
              <h6
                className={`item__title ${isEdit ? "edit" : ""}`}
                onClick={() => {
                  if (isEdit) {
                    vm.openEditEducationModal(ed);
                  }
                }}>
                {renderTitle(ed)}
              </h6>
              <p className="item__date">{ed.endYear}</p>
              <div className="item__description">{ed.fieldOfStudy}</div>
              {isEdit && (
                <DeleteIcon className="item__action" color="#888F91" onClick={() => vm.deleteEducationItem(ed.id ?? "")} />
              )}
            </div>
          ))}
        </div>
      </Card.Body>
    </Card>
  );
};

export default educationSectionView;
