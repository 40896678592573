import { ILocalizationService } from "@emanprague/shared-services";
import { bound } from "@frui.ts/helpers";
import { BusyWatcher, ScreenBase, watchBusy } from "@frui.ts/screens";
import ProjectRepository from "repositories/projectRepository";
import { interfaces } from "inversify";
import { action, observable, runInAction } from "mobx";
import CompanyProjectDocument from "entities/companyProjectDocument";

export default class UploadDocumentViewModel extends ScreenBase {
  navigationName = "upload";
  busyWatcher = new BusyWatcher();
  @observable content?: File;
  @observable projectDocument = new CompanyProjectDocument();

  constructor(
    private companyId: string,
    private projectId: string,
    public localization: ILocalizationService,
    private repository: ProjectRepository
  ) {
    super();

    this.name = this.translate("title");
  }

  @bound
  @watchBusy
  async uploadFile() {
    if (this.content) {
      const result = await this.repository.createProjectDocument(this.companyId, this.projectId, this.projectDocument);
      if (result.success) {
        const cRequest = await this.repository.uploadProjectDocument(
          this.companyId,
          this.projectId,
          result.payload.id!,
          this.content!
        );
        if (cRequest.success) {
          this.requestClose();
        }
      }
    }
  }

  selectContent(content: File) {
    runInAction(() => {
      this.content = content;
      this.projectDocument.fileName = content.name;
    });
  }

  @action.bound
  removeContent() {
    this.content = undefined;
  }

  @bound translate(key: string) {
    return this.localization.translateGeneral(`projects.documents.upload.${key}`);
  }

  static Factory({ container }: interfaces.Context) {
    return (companyId: string, projectId: string) =>
      new UploadDocumentViewModel(companyId, projectId, container.get("ILocalizationService"), container.get(ProjectRepository));
  }
}
