import { observable } from "mobx";

// This entity has been generated, do not change its content, your changes might get lost. You CAN modify the rest of the file.
export default class CompanySafetyPolicy {
  /**
   * Safety Policy ID (from code list)
   */
  @observable
  safetyPolicyId!: string;

  /**
   * Attachment display name
   */
  @observable
  attachmentName?: string;

  /**
   * Attachment URL
   */
  @observable
  attachmentUrl?: string;

  static ValidationRules = {
    safetyPolicyId: { required: true },
  };
}
