import { ScreenBase, ConductorOneChildActive } from "@frui.ts/screens";
import { CredentialPhoto } from "entities/credential";
import { interfaces } from "inversify";
import CredentialOverviewViewModel from "./credentialOverviewViewModel";
import CredentialsAddViewModel from "./credentialsAddViewModel";
import { ILocalizationService } from "@emanprague/shared-services";
import { bound } from "@frui.ts/helpers";
import { action } from "mobx";
import { Mode, Variant } from "viewModels/types";

const readModes = ["overview", "readonly"];

export default class CredentialsOverviewViewModel extends ConductorOneChildActive<ScreenBase> {
  navigationName = "credentialDetail";

  private extraData: any;

  constructor(
    private workerId: string,
    private credential: CredentialPhoto | undefined,
    private mode: { mode: Mode; variant: Variant },
    public localization: ILocalizationService,
    private addcredentialsFactory: ReturnType<typeof CredentialsAddViewModel.Factory>,
    private credentialOverviewFactory: ReturnType<typeof CredentialOverviewViewModel.Factory>
  ) {
    super();

    this.name = this.translate("title");
  }

  @bound translate(key: string) {
    return this.localization.translateGeneral(`worker.modulePage.${key}`);
  }

  @action setExtra(extra: any) {
    this.extraData = extra;
  }

  onInitialize() {
    if (readModes.includes(this.mode.mode)) {
      return this.navigate("credentialOverview", undefined);
    } else {
      this.setExtra("full");
      return this.navigate("credentialAdd", undefined);
    }
  }

  protected findNavigationChild(navigationName: string | undefined): ScreenBase | undefined {
    if (!this.workerId) {
      return undefined;
    }

    switch (navigationName) {
      case "credentialAdd":
        return this.addcredentialsFactory(this.workerId, this.credential, { ...this.mode, extra: this.extraData });
      case "credentialOverview":
        return this.credentialOverviewFactory(this.workerId, this.credential as CredentialPhoto, this.mode);
      default:
        return undefined;
    }
  }

  static Factory({ container }: interfaces.Context) {
    return (profileId: string, credential: CredentialPhoto | undefined, mode: { mode: Mode; variant: Variant }) =>
      new CredentialsOverviewViewModel(
        profileId,
        credential,
        mode,
        container.get("ILocalizationService"),
        container.get(CredentialsAddViewModel.Factory),
        container.get(CredentialOverviewViewModel.Factory)
      );
  }
}
