import React from "react";
import "../index.scss";

const statChartItem: React.FC<{
  label: string;
  count: number;
  total: number;
  text: string;
}> = ({ label, count, total, text }) => {
  return (
    <div className="statChart__item">
      <b>{label}</b>
      <div className="item__bar">
        <div className="item__line" style={{ width: `${(count / total) * 100}%` }}>
          &nbsp;
        </div>
        <span className="item__value">{text}</span>
      </div>
    </div>
  );
};

export default statChartItem;
