import { Spinner } from "@emanprague/veralinkkit";
import { observer } from "mobx-react-lite";
import React from "react";
import "./loadingContainer.scss";

interface ILoadingContainerProps {
  loading?: boolean;
  className?: string;
}

const LoadingContainer: React.FunctionComponent<ILoadingContainerProps> = observer(({ children, loading, className }) => {
  return (
    <div className={`loading-container ${className}`}>
      {children}

      <div className="loading-container__spinner" hidden={!loading}>
        <Spinner animation="border" variant="primary" />
        <span className="sr-only">Loading...</span>
      </div>
    </div>
  );
});
export default LoadingContainer;
