import isBefore from "date-fns/isBefore";
import differenceInDays from "date-fns/differenceInDays";

const MAX_EXPIRATIO_DAYS = 90;

export const expiredState = (_validFrom: Date, validTo: Date): string => {
  const daysCount = Math.abs(differenceInDays(new Date(), validTo));

  if (isBefore(validTo, new Date())) {
    return "isExpired";
  }

  if (daysCount < MAX_EXPIRATIO_DAYS) {
    return "willExpire";
  }

  return "isGood";
};
