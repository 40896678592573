import { registerView } from "@frui.ts/views";
import React from "react";
import WorkHoursQuestionnaireViewModel from "viewModels/company/compliance/questionaire/workHoursQuestionnaireViewModel";
import ModalView from "views/common/modalView";
import { Button } from "@emanprague/veralinkkit";
import Questionnaire from "../components/questionnaire";

class WorkHoursQuestionnaireView extends ModalView<WorkHoursQuestionnaireViewModel> {
  renderFooter(vm: WorkHoursQuestionnaireViewModel) {
    if (vm.isOperator) {
      return <div />;
    }

    return (
      <>
        <Button variant="outline-primary" disabled={vm.busyWatcher.isBusy} onClick={vm.cancel}>
          {vm.translate("cancel")}
        </Button>
        <Button disabled={vm.busyWatcher.isBusy} onClick={vm.save}>
          {vm.translate("save")}
        </Button>
      </>
    );
  }

  renderBody(vm: WorkHoursQuestionnaireViewModel) {
    return (
      <Questionnaire
        {...{
          sectionsVisible: false,
          selectedSection: vm.model.section,
          tg: vm.translate,
          isBusy: vm.busyWatcher.isBusy,
          readOnly: vm.isOperator,
        }}
      />
    );
  }
}

registerView(WorkHoursQuestionnaireView, WorkHoursQuestionnaireViewModel);
