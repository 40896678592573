import { registerView } from "@frui.ts/views";
import React from "react";
import ExperienceModifierViewModel from "viewModels/company/compliance/questionaire/experienceModifierViewModel";
import ModalView, { ModalSize } from "views/common/modalView";
import Input from "controls/input";
import { Row, Col, Button } from "@emanprague/veralinkkit";
import { Table } from "react-bootstrap";
import FileUpload from "controls/fileUpload";

import "./experienceModifier.scss";

class ExperienceModifierView extends ModalView<ExperienceModifierViewModel> {
  size: ModalSize = "lg";
  renderFooter(vm: ExperienceModifierViewModel) {
    if (vm.isOperator) {
      return <div />;
    }

    return (
      <>
        <Button variant="outline-primary" disabled={vm.busyWatcher.isBusy} onClick={vm.cancel}>
          {vm.translate("cancel")}
        </Button>
        <Button variant="primary" disabled={vm.busyWatcher.isBusy || vm.isDocumentUploaded} onClick={vm.save}>
          {vm.translate("save")}
        </Button>
      </>
    );
  }

  renderBody(vm: ExperienceModifierViewModel) {
    if (!vm.isInitDataLoaded) {
      return <div />;
    }

    return (
      <div className="experience-modifier">
        {((!vm.model.fileBox.file && !vm.isDocumentUploaded) || (vm.model.fileBox.file && !vm.isDocumentUploaded)) &&
          !vm.isOperator && (
            <>
              <Row>
                <Col>
                  <Input
                    label={vm.translate("yourRating")}
                    type="number"
                    step=".01"
                    placeholder="0"
                    target={vm.model.experience}
                    property="rating"
                  />
                </Col>
              </Row>
              <Row className="experience-modifier__upload">
                <Col md={12}>
                  <p className="font-weight-bold">{vm.translate("uploadEmr")}</p>
                </Col>

                <Col md={12}>
                  <FileUpload
                    accept={[".pdf"]}
                    onDrop={(files: File[]) => {
                      vm.replaceFile(files[0]);
                    }}
                    localization={{
                      button: vm.translate("upload"),
                      or: vm.translate("dragFile"),
                      types: vm.translate("fileValidation"),
                    }}
                    files={vm.model.fileBox && [vm.model.fileBox]}
                    onRemove={vm.removeFile}
                  />
                </Col>
              </Row>
            </>
          )}

        {((vm.model.fileBox.file && vm.isDocumentUploaded) || vm.isOperator) && (
          <>
            <Row>
              <Col>
                <h6>{vm.translate("rating")}</h6>
                {vm.model.experience.rating !== 0 ? (
                  <b>{vm.model.experience.rating}</b>
                ) : (
                  <span>{vm.translate("notFilled")}</span>
                )}
              </Col>
            </Row>
            <br />
            <Row>
              <Col md={12}>
                <h6>{vm.translate("documentHistory")}</h6>
                <Table borderless responsive className="experience-modifier__table">
                  <thead>
                    <tr>
                      <th>File</th>
                      <th />
                    </tr>
                  </thead>
                  <tbody>
                    {vm.model.experience.fileUrl ? (
                      <tr>
                        <td>
                          <b>{vm.model.experience.fileName}</b>
                        </td>
                        <td className="table__actions">
                          <a rel="noopener noreferrer" href={vm.model.experience.fileUrl} target="_blank">
                            {vm.translate("download")}
                          </a>
                          {!vm.isOperator && (
                            <>
                              &nbsp;
                              <span onClick={vm.removeFile}>{vm.translate("delete")}</span>
                            </>
                          )}
                        </td>
                      </tr>
                    ) : (
                      <tr>
                        <td colSpan={2}>{vm.translate("noDocumentUploaded")}</td>
                      </tr>
                    )}
                  </tbody>
                </Table>
              </Col>
            </Row>
          </>
        )}
      </div>
    );
  }
}

registerView(ExperienceModifierView, ExperienceModifierViewModel);
