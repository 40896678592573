import { Alert, Button, Card, Col, Form, Row } from "@emanprague/veralinkkit";
import { Check, Input } from "@frui.ts/bootstrap";
import { preventDefault, registerView, ViewComponent } from "@frui.ts/views";
import FormField from "controls/formField";
import NewWorkerPassword from "entities/newWorker";
import { observer } from "mobx-react-lite";
import React from "react";
import { PasswordInput } from "controls/passwordInput";
import Field from "controls/field";
import JoinViewModel from "viewModels/join/joinViewModel";

const formFields: FormField<NewWorkerPassword>[] = [
  {
    type: "input",
    property: "firstName",
  },
  {
    type: "input",
    property: "middleName",
    optional: true,
  },
  {
    type: "input",
    property: "lastName",
  },
  {
    type: "input",
    property: "email",
  },
  {
    type: "input",
    property: "mobile",
  },
  {
    type: "input",
    property: "socialSecurityNumber",
    info: true,
  },
  {
    type: "input",
    property: "username",
    info: true,
  },
  {
    type: "passwordInput",
    textType: "password",
    property: "password",
  },
  {
    type: "passwordInput",
    textType: "password",
    property: "repeatPassword",
  },
];

const joinWorkerFormListView: ViewComponent<JoinViewModel> = observer(({ vm }) => {
  const ta = (attribute: string) => vm.localization.translateAttribute("user", attribute);

  return (
    <Row className="justify-content-center mt-4 signup-form">
      <Col md={6}>
        <Card className="card-big">
          <Card.Body>
            <h1 className="text-center">{vm.name}</h1>

            <Form onSubmit={preventDefault(vm.join)}>
              {formFields.map(field => {
                if (field.type === "input") {
                  return (
                    <Field
                      key={`${field.property}-${field.type}`}
                      label={ta(field.property)}
                      optional={field.optional}
                      info={field.info ? ta(`${field.property}Info`) : undefined}
                      localization={vm.localization}>
                      <Input
                        type={field.textType ?? "text"}
                        target={vm.item}
                        property={field.property}
                        errorMessage={vm.asyncValidator.errors[field.property]}
                      />
                    </Field>
                  );
                } else {
                  return (
                    <Form.Group key={`${field.property}-${field.type}`}>
                      <PasswordInput
                        label={ta(field.property)}
                        target={vm.item}
                        property={field.property}
                        errorMessage={vm.asyncValidator.errors[field.property]}
                      />
                    </Form.Group>
                  );
                }
              })}
              {vm.errorMessage && <Alert variant="danger">{vm.errorMessage}</Alert>}

              <Button disabled={vm.busyWatcher.isBusy || !vm.item.consent} type="submit" block>
                {vm.translate("submit")}
              </Button>
            </Form>

            <Check
              className="signCheck"
              label={
                <p className="terms">
                  {vm.translate("terms")}{" "}
                  <a href="#/signup/worker" onClick={() => window.open(vm.termsUrl, "_blank")}>
                    {vm.translate("termsLink")}
                  </a>
                </p>
              }
              data-testid="consent-check"
              property="consent"
              target={vm.item}
            />
          </Card.Body>
        </Card>
      </Col>
    </Row>
  );
});

registerView(joinWorkerFormListView, JoinViewModel);
