import React from "react";
import { registerView, ViewComponent } from "@frui.ts/views";
import { observer } from "mobx-react-lite";
import CredentialExpireViewModel from "viewModels/notifications/notificationItems/credentialExpireViewModel";

const credentialExpireView: ViewComponent<CredentialExpireViewModel> = observer(({ vm }) => {
  const { content, translate, localization } = vm;
  const taExpire = (code: string) => localization.translateAttribute("credentialExpiration", code);

  // is about me
  if (vm.isAboutMe) {
    return (
      <header>
        {translate("your")} {content.credentialName} {taExpire(content.type)}
      </header>
    );
  }

  // is about others
  if (!vm.isAboutMe) {
    return (
      <header>
        {content.credentialName} {translate("whichBelongsTo")} <a href={vm.profileUrl}>{content.accountFullName}</a>{" "}
        {taExpire(content.type)}
      </header>
    );
  }

  return <></>;
});

registerView(credentialExpireView, CredentialExpireViewModel);

// "credentialExpire": {
//   "your": "Your",
//   "willExpire": "will expire in",
//   "whichBelongsTo": "which belongs to"
// },

// "credentialExpiration": {
//   "DAYS_90": "will expire in 90 days",
//   "DAYS_60": "will expire in 60 days",
//   "DAYS_30": "will expire in 30 days",
//   "EXPIRED": "has expired"
// }
