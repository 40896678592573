import { ILocalizationService } from "@emanprague/shared-services";
import { bound } from "@frui.ts/helpers";
import { BusyWatcher, ScreenBase, watchBusy } from "@frui.ts/screens";
import { interfaces } from "inversify";
import { action, observable } from "mobx";
import MessagesRepository from "repositories/messagesRepository";

export default class SendMessageViewModel extends ScreenBase {
  navigationName = "send";
  busyWatcher = new BusyWatcher();

  @observable content: string;

  constructor(
    private recipientAccountId: string,
    private recipientName: string,
    public localization: ILocalizationService,
    private repository: MessagesRepository
  ) {
    super();

    this.name = `${this.translate("title")} ${recipientName}`;
  }

  @action.bound
  @watchBusy
  async sendMessage() {
    if (!!this.content) {
      const result = await this.repository.sendMessage(this.recipientAccountId, this.content);
      if (result.success) {
        this.requestClose();
      }
    }
  }

  @bound translate(key: string) {
    return this.localization.translateGeneral(`messages.send.${key}`);
  }

  static Factory({ container }: interfaces.Context) {
    return (recipientAccountId: string, recipientName: string) =>
      new SendMessageViewModel(
        recipientAccountId,
        recipientName,
        container.get("ILocalizationService"),
        container.get(MessagesRepository)
      );
  }
}
