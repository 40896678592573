import { registerView } from "@frui.ts/views";
import React from "react";
import SafetyProgramsEditViewModel from "viewModels/company/compliance/safetyProgramsEditViewModel";
import ModalView, { ModalSize } from "views/common/modalView";
import { Row, Form, Button } from "@emanprague/veralinkkit";
import Input from "controls/input";
import { Delete, Plus } from "@emanprague/veralinkkit/dist/Icons";
import "./compliance.scss";

class SafetyProgramsEditView extends ModalView<SafetyProgramsEditViewModel> {
  size: ModalSize = "lg";

  renderFooter(vm: SafetyProgramsEditViewModel) {
    return (
      <Row className="justify-content-end">
        <Button variant="outline-primary" disabled={vm.busyWatcher.isBusy} onClick={vm.cancel}>
          {vm.translate("cancel")}
        </Button>
        <Button variant="primary" disabled={vm.busyWatcher.isBusy} onClick={vm.save}>
          {vm.translate("save")}
        </Button>
      </Row>
    );
  }

  renderBody(vm: SafetyProgramsEditViewModel) {
    return (
      <>
        <p className="display-5">{vm.translate("subtitle")}</p>
        {vm.model.safetyPrograms.map((service: any, index: number) => (
          <Row key={index} className="compliance__modal-service">
            <Form.Group>
              <Input type="text" target={service} property="name" />
            </Form.Group>
            <Delete fontSize={24} color="#888F91" onClick={() => vm.removeService(index)} />
          </Row>
        ))}

        <Row>
          <Button variant="outline-primary" onClick={vm.addService}>
            <Plus fontSize={20} />
            {vm.translate("addService")}
          </Button>
        </Row>
      </>
    );
  }
}

registerView(SafetyProgramsEditView, SafetyProgramsEditViewModel);
