import RepositoryBase from "./repositoryBase";
import ComplianceRequirementsGradeThresholds from "entities/complianceRequirementsGradeThresholds";
import ComplianceRequirementsPointsAllocation from "entities/complianceRequirementsPointsAllocation";
import InsuranceDocument from "entities/insuranceDocument";
import SafetyMetricSettings from "entities/safetyMetricSettings";
import { action, observable } from "mobx";
import Questionnaire from "entities/questionnaire";
import QuestionnaireAnswers from "entities/questionnaireAnswers";
import OshaFormAnswers from "entities/oshaFormAnswers";
import ExperienceModifierDefinition from "entities/experienceModifierDefinition";
import { FileBox } from "controls/fileUpload";
import ExperienceModifier from "entities/experienceModifier";

export default class ComplianceRepository extends RepositoryBase {
  @observable progress: Record<string, number> = {};

  savePointsAllocations(operatorId: string, points: ComplianceRequirementsPointsAllocation) {
    return this.callApi(api =>
      api
        .one("userprofile/operator", operatorId)
        .all("compliance_requirements/points_allocation")
        .putEntity(points, ComplianceRequirementsPointsAllocation)
    );
  }

  saveSafetyMetrics(operatorId: string, metrics: SafetyMetricSettings) {
    return this.callApi(api =>
      api
        .one("userprofile/operator", operatorId)
        .all("compliance_requirements/safety_metrics")
        .putEntity(metrics, SafetyMetricSettings)
    );
  }

  loadSafetyMetrics(operatorId: string) {
    return this.callApi(api =>
      api.one("userprofile/operator", operatorId).all("compliance_requirements/safety_metrics").getEntity(SafetyMetricSettings)
    );
  }

  loadExperienceModifier(operatorId: string) {
    return this.callApi(api =>
      api
        .one("userprofile/operator", operatorId)
        .all("compliance_requirements/experience_modifier")
        .getEntity(ExperienceModifierDefinition)
    );
  }

  saveEMRModifier(connectionId: string, modifier: ExperienceModifier) {
    return this.callApi(api =>
      api
        .one("userprofile/contractor_connection", connectionId)
        .all("compliance_requirements/experience_modifier")
        .putOnly(modifier)
    );
  }

  saveExperienceModifier(operatorId: string, modifier: ExperienceModifierDefinition) {
    return this.callApi(api =>
      api
        .one("userprofile/operator", operatorId)
        .all("compliance_requirements/experience_modifier")
        .putEntity(modifier, ExperienceModifierDefinition)
    );
  }

  // submitCertificate(connectionId: string) {
  //   return this.callApi(api =>
  //     api.one("userprofile/contractor_connection", connectionId).all("compliance_requirements/insurance")
  //   );
  // }

  saveInsuranceCertificate(connectionId: string, attachmentName: string, file: FileBox<File>) {
    return this.callApi(api =>
      api
        .one("userprofile/contractor_connection", connectionId)
        .all("compliance_requirements/insurance/attachment")
        .postBlob(
          file.file!,
          action(a => (file.progress = a)),
          { attachmentName }
        )
    );
  }

  loadInsuranceCertificates(connectionId: string) {
    return this.callApi(api =>
      api
        .one("userprofile/contractor_connection", connectionId)
        .all("compliance_requirements/insurance")
        .getEntities(InsuranceDocument)
    );
  }

  loadInsuranceDocuments(operatorId: string) {
    return this.callApi(api =>
      api
        .one("userprofile/operator", operatorId)
        .all("compliance_requirements/insurance_requirements_documents")
        .getEntities(InsuranceDocument)
    );
  }

  deleteInsuranceDocuments(operatorId: string, documentId: string) {
    return this.callApi(api =>
      api
        .one("userprofile/operator", operatorId)
        .one("compliance_requirements/insurance_requirements_documents", documentId)
        .delete()
    );
  }

  saveInsuranceDocuments(operatorId: string, file: FileBox<any>) {
    return this.callApi(api =>
      api
        .one("userprofile/operator", operatorId)
        .all("compliance_requirements/insurance_requirements_documents")
        .postBlob(
          file.file,
          action(a => {
            file.progress = a;
          }),
          { attachmentName: file.file.name }
        )
    );
  }

  submitInsuranceReview(connectionId: string, documentId: string, approved: boolean, rejectedMessage: string) {
    return this.callApi(api =>
      api
        .one("userprofile/contractor_connection", connectionId)
        .one("compliance_requirements/insurance", documentId)
        .all("submit_review")
        .postOnly({ approved, rejectedMessage })
    );
  }

  loadPointsAllocations(operatorId: string) {
    return this.callApi(api =>
      api
        .one("userprofile/operator", operatorId)
        .all("compliance_requirements/points_allocation")
        .getEntity(ComplianceRequirementsPointsAllocation)
    );
  }

  loadThresholds(operatorId: string) {
    return this.callApi(api =>
      api
        .one("userprofile/operator", operatorId)
        .all("compliance_requirements/grade_thresholds")
        .getEntity(ComplianceRequirementsGradeThresholds)
    );
  }

  // Compliance questions

  saveAnswers(questionType: string, connectionId: string, answers: QuestionnaireAnswers) {
    return this.callApi(api =>
      api
        .one("userprofile/contractor_connection", connectionId)
        .one("compliance_requirements/questionnaire", questionType)
        .all("answers")
        .putOnly(answers)
    );
  }

  loadLatestAnswers(questionType: string) {
    return this.callApi(api =>
      api
        .one("userprofile/compliance_requirements/questionnaire", questionType)
        .all("latest_answers")
        .getEntity(QuestionnaireAnswers)
    );
  }

  loadAnswers(questionType: string, connectionId: string) {
    return this.callApi(api =>
      api
        .one("userprofile/contractor_connection", connectionId)
        .one("compliance_requirements/questionnaire", questionType)
        .all("answers")
        .getEntity(QuestionnaireAnswers)
    );
  }

  loadQuestions(connectionId: string, questionType: string) {
    return this.callApi(api =>
      api.one("userprofile/contractor_connection", connectionId).one("questionnaire", questionType).getEntity(Questionnaire)
    );
  }

  // OSHA form
  loadOshaAnswers(connectionId: string) {
    return this.callApi(api =>
      api
        .one("userprofile/contractor_connection", connectionId)
        .all("compliance_requirements/osha_form_answers")
        .getEntity(OshaFormAnswers)
    );
  }

  saveOshaForm(connectionId: string, answers: OshaFormAnswers) {
    return this.callApi(api =>
      api.one("userprofile/contractor_connection", connectionId).all("compliance_requirements/osha_form_answers").putOnly(answers)
    );
  }

  // Experience

  loadComplianceExperience(connectionId: string) {
    return this.callApi(api =>
      api
        .one("userprofile/contractor_connection", connectionId)
        .all("compliance_requirements/experience_modifier")
        .getEntity(ExperienceModifier)
    );
  }

  saveComplianceExperience(connectionId: string, experience: ExperienceModifier) {
    return this.callApi(api =>
      api
        .one("userprofile/contractor_connection", connectionId)
        .all("compliance_requirements/experience_modifier")
        .putEntity(experience, ExperienceModifier)
    );
  }

  deleteEMRAttachment(connectionId: string) {
    return this.callApi(api =>
      api
        .one("userprofile/contractor_connection", connectionId)
        .all("compliance_requirements/experience_modifier/attachment")
        .delete()
    );
  }

  async saveEMRAttachment(connectionId: string, fileBox: FileBox<File>) {
    return this.callApi(api =>
      api
        .one("userprofile/contractor_connection", connectionId)
        .all("compliance_requirements/experience_modifier/attachment")
        .postBlob(
          fileBox.file!,
          action(a => (fileBox.progress = a)),
          { attachmentName: fileBox.file?.name }
        )
    );
  }
  // Safety policy

  requestSafetyPolicyReview(connectionId: string) {
    return this.callApi(api =>
      api
        .one("userprofile/contractor_connection", connectionId)
        .all("compliance_requirements/health_and_safety_programs/request_review")
        .postOnly({})
    );
  }

  submitSafetyPolicyReview(connectionId: string, approved: boolean, rejectedMessage?: string) {
    return this.callApi(api =>
      api
        .one("userprofile/contractor_connection", connectionId)
        .all("compliance_requirements/health_and_safety_programs/submit_review")
        .postOnly({
          approved,
          rejectedMessage,
        })
    );
  }
}
