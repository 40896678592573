import { observable } from "mobx";
import { Exclude, Type } from "class-transformer";
import CompanyProjectTeamWorkerAssigned from "./companyProjectTeamWorkerAssigned";
import CodeListIndustry from "entities/codeListIndustry";
import CodeListProfession from "entities/codeListProfession";

// This entity has been generated, do not change its content, your changes might get lost. You CAN modify the rest of the file.
class CompanyProjectTeam {
  /**
   * read-only
   */
  id?: string;

  @observable
  name?: string;

  /**
   * Array of credential name IDs.
   */
  @observable
  requiredCredentials?: string[];

  @observable
  @Type(() => CompanyProjectTeamWorkerAssigned)
  workersAssigned?: CompanyProjectTeamWorkerAssigned[];

  @observable
  nWorkersWanted?: number;

  /**
   * Void if assigned to subcontractor outside of Veralink.
   */
  @observable
  assignedContractorId?: string;

  /**
   * Void if assigned to subcontractor outside of Veralink.
   */
  @observable
  assignedContractorName?: string;

  /**
   * Name of subcontractor. Void if assigned to contractor registered on Veralink.
   */
  @observable
  assignedSubcontractorName?: string;

  static ValidationRules = {
    nWorkersWanted: { number: true },
  };
}

export default class CompanyProjectTeamExtended extends CompanyProjectTeam {
  @Exclude()
  @observable
  assignType: "assignToMe" | "assignToContractor" | "assignToSubcontractor" = "assignToMe";
  @Exclude()
  @observable
  industry: CodeListIndustry;
  @Exclude()
  @observable
  profession?: CodeListProfession;

  static ValidationRules = Object.assign(CompanyProjectTeam.ValidationRules, {
    industry: { required: true },
    profession: { required: true },
    nWorkersWanted: { required: true },
    assignedContractorId: {
      requiredIf: { condition: (entity: CompanyProjectTeamExtended) => entity.assignType === "assignToContractor" },
    },
    assignedSubcontractorName: {
      requiredIf: { condition: (entity: CompanyProjectTeamExtended) => entity.assignType === "assignToSubcontractor" },
    },
  });
}
