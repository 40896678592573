import merge from "lodash/merge";
import { observable } from "mobx";
import { EntityValidationRules } from "services/validation/entityValidationRules";

// This entity has been generated, do not change its content, your changes might get lost. You CAN modify the rest of the file.
export default class WorkerProfileSkills {
  /**
   * Profession title
   */
  @observable
  professionTitle!: string;

  /**
   * Profession ID
   */
  @observable
  professionId?: string;

  /**
   * Number of year experience of profession
   */
  @observable
  professionYears!: number;

  /**
   * Industry ID. Based on professionId.
   */
  @observable
  industryId!: string;

  /**
   * Other skills, worker can input
   * @example
   * Drilling holes,Hammering stuff
   */
  @observable
  otherSkills?: string[];

  /**
   * Short info about worker
   */
  @observable
  bio?: string;

  static ValidationRules = {
    professionTitle: { required: true },
    professionId: { required: true },
    professionYears: { required: true, number: true },
    industryId: { required: true },
  };
}

merge(WorkerProfileSkills.ValidationRules, {
  professionYears: { required: true, number: true, isPositiveNumber: true },
} as EntityValidationRules<WorkerProfileSkills>);
