import { ILocalizationService } from "@emanprague/shared-services";
import AccountType from "entities/accountType";
import { differenceInDays } from "date-fns";
import React from "react";

export const renderLink = (text: string, link: string) => {
  return (
    <a rel="noopener noreferrer" target="_blank" href={link}>
      {text}
    </a>
  );
};

export const getClickableMail = (mail?: string) => {
  if (mail) {
    return renderLink(mail, `mailto:${mail}`);
  } else {
    return "-";
  }
};

export const getClickableLink = (link?: string) => {
  if (!!link) {
    return renderLink(link, link.startsWith("http://") || link.startsWith("https://") ? link : `//${link}`);
  } else {
    return "-";
  }
};

export const getClickableLinkedIn = (link?: string) => {
  let username: string | undefined = undefined;
  if (!!link) {
    if (link.includes("www.linkedin.com/in/")) {
      username = link.replace("http://www.linkedin.com/in/", "");
      username = link.replace("https://www.linkedin.com/in/", "");
      username = username.replace("/", "");
    }

    let url = link.startsWith("http://") || link.startsWith("https://") ? link : `//${link}`;
    if (username) {
      url = `https://www.linkedin.com/in/${username}`;
    } else {
      username = "Profile";
    }

    return renderLink(username!, url);
  } else {
    return "-";
  }
};

export const formatDateRange = (dates: { startDate: Date | undefined; endDate: Date | undefined }, loc: ILocalizationService) => {
  return [loc.formatDate(dates.startDate), loc.formatDate(dates.endDate)].filter(d => !!d).join(" - ");
};

export const getRandomColor = () => {
  const letters = "0123456789ABCDEF";
  let color = "#";
  for (let i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }

  return color;
};

const hashCode = (str: string) => {
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }
  return hash;
};

const intToRGB = (i: number) => {
  const c = (i & 0x00ffffff).toString(16).toUpperCase();

  return "00000".substring(0, 6 - c.length) + c;
};

export const getColorByText = (str: string) => {
  return `#${intToRGB(hashCode(str))}`;
};

export const getProfileUrl = (id: string, type: AccountType) => {
  if (type === AccountType.Worker) {
    return `#/publicProfile/worker/${id}`;
  } else {
    return `#/publicProfile/company/${id}`;
  }
};

export const projectProgress = (start: Date, end: Date) => {
  const today = new Date();

  let pastDays = differenceInDays(today, start);
  const totalDays = differenceInDays(end, start);

  if (pastDays < 0) {
    pastDays = totalDays;
  }

  return (pastDays / totalDays) * 100;
};

export const copyToClipboard = (value: string) => {
  const tempInput = document.createElement("input");
  document.body.appendChild(tempInput);
  tempInput.setAttribute("value", value);
  tempInput.select();
  document.execCommand("copy");
  document.body.removeChild(tempInput);
};
