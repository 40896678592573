import { registerView, ViewComponent, View } from "@frui.ts/views";
import { observer } from "mobx-react-lite";
import React from "react";
import ConnectionDetailViewModel from "viewModels/company/myConnections/connectionDetailViewModel";
import { Card, Breadcrumb, Button, Row } from "@emanprague/veralinkkit";
import { Table } from "react-bootstrap";
import "./index.scss";
import classnames from "classnames";
import Comment from "@emanprague/veralinkkit/dist/Icons/Comment";
import LoadingContainer from "views/common/loadingContainer";
import PointStatus from "./components/PointStatus";

const warnColor = ["D", "F"];
const dangerColor = ["B", "C"];
const goodColor = ["A"];

const connectionDetailView: ViewComponent<ConnectionDetailViewModel> = observer(({ vm }) => {
  const tg = (code: string) => vm.localization.translateGeneral(`myConnections.detail.${code}`);
  const tp = (code: string) => vm.localization.translateGeneral(`myConnections.subModule.${code}`);
  const ta = vm.localization.translateAttribute;

  const classname = classnames("my-connections-detail__grade", {
    "is-success": goodColor.includes(vm.model?.grade as string),
    "is-danger": warnColor.includes(vm.model?.grade as string),
    "is-warning": dangerColor.includes(vm.model?.grade as string),
  });

  return (
    <>
      <LoadingContainer loading={vm.busyWatcher.isBusy}>
        <div className="my-connections-detail">
          <Row className="my-connections-detail__header">
            <Breadcrumb>
              <Breadcrumb.Item href="#/dashboard/myConnections">{tp("title")}</Breadcrumb.Item>
              <Breadcrumb.Item active>{vm.model?.companyName}</Breadcrumb.Item>
            </Breadcrumb>

            <Button onClick={vm.sendMessage}>
              <Comment fontSize={24} />
              {tg("sendMessage")}
            </Button>
          </Row>
          <Card>
            <Card.Header>
              <div>
                <h4 className="company__header">{vm.name}</h4>

                <p className="my-connections-detail__subtitle">
                  {tg("yourGrade")}

                  <span className={classname}>{vm.model?.grade}</span>

                  {tg("enterDetails")}
                </p>
              </div>
            </Card.Header>

            <Card.Body>
              <Table responsive>
                <thead>
                  <tr>
                    <th>{tg("category")}</th>
                    <th>{tg("status")}</th>
                    <th>{tg("points")}</th>
                    <th />
                  </tr>
                </thead>

                <tbody>
                  {vm.points.map((el, index) => (
                    <tr key={index}>
                      <td className="align-middle">
                        <b>{ta("compliancePoints", el.name)}</b>
                      </td>

                      <td className="align-middle">
                        <PointStatus tg={tg} connection={el} />
                      </td>

                      <td className="align-middle">
                        <b>{`${(el.givenPoints ?? 0).toFixed(1)}/${(el.totalPoints ?? 0).toFixed(1)}`}</b>
                      </td>

                      <td>
                        <Button
                          className="my-connections-detail__action-point"
                          variant="link"
                          onClick={() => vm.openQuestionaire(el)}>
                          {tg(`action.${el.actionStatus}`)}
                        </Button>
                      </td>
                    </tr>
                  ))}

                  <tr>
                    <td>{tg("total")}</td>
                    <td />
                    <td>
                      <b>{vm.total}</b>
                    </td>
                    <td />
                  </tr>
                </tbody>
              </Table>
            </Card.Body>
          </Card>
        </div>
      </LoadingContainer>
      <View vm={vm.activeChild} />
    </>
  );
});

registerView(connectionDetailView, ConnectionDetailViewModel);
