import { ILocalizationService, IEventBus } from "@emanprague/shared-services";
import { bound } from "@frui.ts/helpers";
import { BusyWatcher, ScreenBase, watchBusy } from "@frui.ts/screens";
import { interfaces } from "inversify";
import WorkerProfileRepository from "repositories/workerProfileRepository";
import EnumsService from "services/enumsService";
import Credential from "entities/credential";
import { action, observable, runInAction } from "mobx";
import DialogService from "services/dialogService";
import { WorkerEvents } from "services/events";
import { Mode, Variant } from "viewModels/types";

export default class CredentialOverviewViewModel extends ScreenBase {
  navigationName = "credentialOverview";
  busyWatcher = new BusyWatcher();

  @observable photoUrl?: string;

  get trainingEntity() {
    const trainingEntityId = this.credential.trainingEntityId;
    return this.enums.trainingEntities.find(({ id }) => id === trainingEntityId);
  }

  get isDisabled() {
    return this.credential.verified || this.mode.mode === "readonly";
  }

  constructor(
    private workerId: string,
    private mode: { mode: Mode; variant: Variant },
    public localization: ILocalizationService,
    private repository: WorkerProfileRepository,
    private enums: EnumsService,
    private dialog: DialogService,
    public credential: Credential,
    private eventBus: IEventBus
  ) {
    super();

    this.name = credential.credentialName;
    this.eventBus.subscribe(WorkerEvents.credentialPhotoUploaded, this.reload);
  }

  @bound translate(key: string, params?: Record<string, any>) {
    return this.localization.translateGeneral(`worker.credentialOverview.${key}`, params);
  }

  @watchBusy
  async loadPhoto() {
    if (!this.credential?.photoUrl) {
      return;
    }
    const photoUrl = await this.repository.getPhotoObjectUrl(this.credential.id as string, this.workerId);

    runInAction(() => {
      this.photoUrl = photoUrl;
    });
  }

  onInitialize() {
    this.reload();
  }

  onActivate() {
    this.loadPhoto();
  }

  @bound
  async reload() {
    const result = await this.repository.loadCredential(this.workerId, this.credential.id as string);
    if (result.success) {
      runInAction(() => {
        this.credential = result.payload;
      });

      await this.loadPhoto();
    }
  }

  @bound editCredential() {
    (this.parent as any).setExtra("photo");
    (this.parent as any).navigate("credentialAdd", undefined);
  }

  @action.bound
  async deleteCredential() {
    const confirmationResult = await this.dialog.showConfirmation(
      this.translate("confirmBody"),
      this.translate("confirmTitle"),
      this.translate("confirmSuccess"),
      this.translate("confirmCancel")
    );
    if (confirmationResult) {
      const result = await this.repository.deleteCredential(this.credential.id as string, this.workerId);

      if (result.success) {
        this.requestClose();
      }
    }
  }

  static Factory({ container }: interfaces.Context) {
    return (workerId: string, credential: Credential, mode: { variant: Variant; mode: Mode }) =>
      new CredentialOverviewViewModel(
        workerId,
        mode,
        container.get("ILocalizationService"),
        container.get(WorkerProfileRepository),
        container.get(EnumsService),
        container.get(DialogService),
        credential,
        container.get("IEventBus")
      );
  }
}
