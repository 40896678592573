import { observable } from "mobx";
import { Type } from "class-transformer";
import CompanyEmployeesPendingRecord from "./companyEmployeesPendingRecord";

// This entity has been generated, do not change its content, your changes might get lost. You CAN modify the rest of the file.
export default class CompanyEmployeesPendingList {
  @observable
  @Type(() => CompanyEmployeesPendingRecord)
  pending!: CompanyEmployeesPendingRecord[];

  @observable
  total!: number;

  static ValidationRules = {
    pending: { required: true },
    total: { required: true, number: true },
  };
}
