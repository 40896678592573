import { IEventBus, ILocalizationService } from "@emanprague/shared-services";
import { bound, IDisposable } from "@frui.ts/helpers";
import { BusyWatcher, ScreenBase, watchBusy } from "@frui.ts/screens";
import { FilteredListViewModel } from "@frui.ts/datascreens";
import AuthenticationIdentity from "entities/authenticationIdentity";
import NotificationsFilter from "models/notificationsFilter";
import Notification from "entities/notification";
import NotificationsRepository from "repositories/notificationsRepository";
import { computed, observable, runInAction } from "mobx";
import NotificationItemViewModel from "./notificationItems/notificationItemViewModel";
import { NOTIFICATIONS_REFRESH_TIME } from "config";
import { IPagingFilter } from "@frui.ts/data";
import { StatusbarEvents } from "services/events";

export default class NotificationsViewModel extends FilteredListViewModel<Notification, NotificationsFilter, ScreenBase> {
  navigationName = "notifications";
  busyWatcher = new BusyWatcher();
  hideFromMenu = true;

  @observable statusbarCount: number;
  @observable.shallow statusbarItems: Notification[];

  backgroundTimeout: NodeJS.Timeout;
  private eventHandlers: IDisposable[];

  constructor(
    private repository: NotificationsRepository,
    public localization: ILocalizationService,
    private eventBus: IEventBus,
    private notificationItemFactory: ReturnType<typeof NotificationItemViewModel.Factory>
  ) {
    super();

    this.name = this.translate("title");
    this.pagingFilter.limit = 20;

    this.initBackgroundTimeout();
    this.eventHandlers = [this.eventBus.subscribe(StatusbarEvents.notificationRead, this.loadBackground)];
  }

  @computed get list() {
    return this.items?.map(item => this.notificationItemFactory(item));
  }

  @computed get statusbarList() {
    return this.statusbarItems?.map(item => this.notificationItemFactory(item));
  }

  onActivate() {
    this.applyFilterAndLoad();
    return super.onActivate();
  }

  protected async onDeactivate(isClosing: boolean) {
    await super.onDeactivate(isClosing);

    if (isClosing) {
      this.eventHandlers?.forEach(x => x.dispose());
    }
  }

  @bound
  initBackgroundTimeout() {
    this.backgroundTimeout = setInterval(() => this.loadBackground(), NOTIFICATIONS_REFRESH_TIME);
    this.loadBackground();
  }

  @bound
  @watchBusy
  async loadData() {
    const result = await this.repository.getNotifications(this.appliedFilter, this.pagingFilter);
    if (result.success) {
      this.setData(result.payload);
    }
  }

  @bound
  async loadBackground() {
    const [data, unreadCount] = await Promise.all([
      this.repository.getNotifications({} as any, { offset: 0, limit: 4 } as IPagingFilter),
      this.repository.countUnread(),
    ]);

    if (data.success && unreadCount.success) {
      runInAction(() => {
        this.statusbarItems = data.payload[0];
        this.statusbarCount = unreadCount.payload.total;
      });
    }
  }

  @bound
  @watchBusy
  async markAllRead() {
    const result = await this.repository.markAllRead();
    if (result.success) {
      this.loadData();
      this.loadBackground();
    }
  }

  @bound translate(key: string) {
    return this.localization.translateGeneral(`notifications.modulePage.${key}`);
  }

  isVisible(user: AuthenticationIdentity) {
    return !!user;
  }

  @bound
  changePage(offset: number, _limit: number) {
    this.pagingFilter.offset = offset;
    this.loadData();
  }

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  protected resetFilterValues(filter: any) {}
}
