import { observer } from "mobx-react-lite";
import React from "react";
import StatCard from "../components/statCard";
// import { Select } from "@frui.ts/bootstrap";
import SubModuleViewModel from "viewModels/company/companyDashboard/subModuleViewModel";
import { Doughnut } from "react-chartjs-2";
import { ChartData, ChartOptions } from "chart.js";
import NoDataPlaceholder from "../components/noDataPlaceholder";
import LoadingContainer from "views/common/loadingContainer";
import "../index.scss";

const getDoughnutData = (vm: SubModuleViewModel): ChartData => ({
  labels: vm.availableWorkforceData.labels,
  datasets: [
    {
      data: vm.availableWorkforceData.values,
      backgroundColor: vm.availableWorkforceData.colors,
    },
  ],
});

const getChartOptions = (vm: SubModuleViewModel): ChartOptions => ({
  maintainAspectRatio: false,
  cutoutPercentage: 65,
  legend: { position: "right", fullWidth: false },
  tooltips: {
    callbacks: {
      label: item => vm.availableWorkforceData.tooltips[item.index!],
    },
  },
});

const workforce: React.FC<{
  vm: SubModuleViewModel;
}> = observer(({ vm }) => {
  const chartData = getDoughnutData(vm);
  const chartOptions = getChartOptions(vm);
  return (
    <LoadingContainer loading={vm.busyWatcher.isBusy}>
      <StatCard
        title={vm.companyProfileName}
        subtitle={vm.translate("availableWorkforce")}
        link={vm.translate("viewAll")}
        info={vm.translate("availableWorkforceDisclaimer")}
        path="/#/dashboard/employees">
        {/* TODO: for future  */}
        {/* <Select
          mode="key"
          value={vm.selectedIndustry}
          target={vm}
          property="selectedIndustry"
          items={vm.industries}
          keyProperty="id"
          textProperty="name"
          allowEmpty
        />
        <br /> */}
        {vm.availableWorkforceData.labels.length > 0 ? (
          <>
            <b>
              {vm.translate("availableWorkforceXY", {
                Y: vm.availableWorkforceData.total - vm.availableWorkforceData.unassigned,
                X: vm.availableWorkforceData.total,
              })}
            </b>
            <div>
              <Doughnut height={300} data={chartData} options={chartOptions} />
            </div>
          </>
        ) : (
          <NoDataPlaceholder text={vm.translate("noWorkersFound")} hidden={vm.busyWatcher.isBusy} />
        )}
      </StatCard>
    </LoadingContainer>
  );
});

export default workforce;
