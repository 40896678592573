import { observable } from "mobx";

// This entity has been generated, do not change its content, your changes might get lost. You CAN modify the rest of the file.
export default class WorkforceStatProfession {
  /**
   * Profession name
   */
  @observable
  name!: string;

  /**
   * Number of employees of this profession not assigned to any project
   */
  @observable
  unassigned?: number;

  /**
   * Number of employees with this profession
   */
  @observable
  total!: number;

  /**
   * TODO: doesn't exist on BE side
   */
  readonly industryId!: string;

  static ValidationRules = {
    name: { required: true },
    unassigned: { number: true },
    total: { required: true, number: true },
  };
}
