import { Alert, Button, Card, Col, Container, Form, Row } from "@emanprague/veralinkkit";
import { Input } from "@frui.ts/bootstrap";
import { preventDefault, registerView, ViewComponent } from "@frui.ts/views";
import { observer } from "mobx-react-lite";
import React from "react";
import ForgotPasswordViewModel from "viewModels/profile/forgotPasswordViewModel";
import LoginViewModel from "viewModels/profile/loginViewModel";
import "./login.scss";
import { PasswordInput } from "controls/passwordInput";

const loginListView: ViewComponent<LoginViewModel> = observer(({ vm }) => {
  const ta = vm.localization.translateAttribute;
  return (
    <Container className="login">
      <Row className="justify-content-center">
        <Col md={6}>
          <Card className="card-big">
            <Card.Body>
              <h1 className="text-center">{vm.name}</h1>

              <Form onSubmit={preventDefault(vm.login)}>
                <div className="form-group">
                  <label>{ta("user", "username")}</label>
                  <Input target={vm.credentials} property="username" />
                </div>
                <PasswordInput label={vm.translate("password")} target={vm.credentials} property="password" />

                {vm.errorMessage && <Alert variant="danger">{vm.errorMessage}</Alert>}

                <Button type="submit" disabled={vm.busyWatcher.isBusy || !vm.canLogin} block>
                  {vm.translate("login_button")}
                </Button>
              </Form>

              <p className="instructions text-center mb-4">
                {vm.translate("signupInstruction")}
                <a href="#/signup">{vm.translate("signup")}</a>
              </p>

              <div className="d-flex justify-content-center mb-4">
                <a href={vm.router.getUrl(ForgotPasswordViewModel)}>{vm.translate("forgotPassword")}</a>
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
});

registerView(loginListView, LoginViewModel);
