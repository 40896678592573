import { Col, Row } from "@emanprague/veralinkkit";
import { registerView, ViewComponent } from "@frui.ts/views";
import PagingInfo from "controls/pagingInfo";
import SortSelect from "controls/sortSelect";
import { observer } from "mobx-react-lite";
import React from "react";
import ActiveEmployeesViewModel from "viewModels/company/employees/activeEmployeesViewModel";
import Input from "controls/input";

const activeHeaderView: ViewComponent<ActiveEmployeesViewModel> = observer(({ vm }) => {
  const tg = (code: string) => vm.translate(code);

  return (
    <>
      <Row className="dheader__subheader">
        <Col lg={4} md={12} className="dheader__pagination">
          <PagingInfo paging={vm.currentPaging} localization={vm.localization} />
        </Col>
        <Col lg={8} md={12} className="dheader__filter">
          <div>
            <span>{tg("sortBy")}:</span>
            <SortSelect
              items={vm.sortOptions}
              paging={vm.pagingFilter}
              model="workerPortalSortOption"
              localization={vm.localization}
            />
          </div>
          <Input placeholder={tg("search")} target={vm.filter} property="search" />
        </Col>
      </Row>
    </>
  );
});

registerView(activeHeaderView, ActiveEmployeesViewModel, "header");
