import { ILocalizationService } from "@emanprague/shared-services";
import { bound } from "@frui.ts/helpers";
import { BusyWatcher, ScreenBase, watchBusy } from "@frui.ts/screens";
import { attachAutomaticValidator, validate } from "@frui.ts/validation";
import { classToClass } from "class-transformer";
import LocationSimple from "entities/locationSimple";
import WorkerProfile from "entities/workerProfile";
import WorkerProfileContact from "entities/workerProfileContact";
import { interfaces } from "inversify";
import { action, observable, runInAction } from "mobx";
import WorkerProfileRepository from "repositories/workerProfileRepository";
import EnumsService from "services/enumsService";

export default class ContactInformationViewModel extends ScreenBase {
  navigationName = "contact";
  busyWatcher = new BusyWatcher();

  @observable contact: WorkerProfileContact;
  @observable location?: LocationSimple;

  constructor(
    private workerId: string,
    originalWorkerProfile: WorkerProfile,
    public localization: ILocalizationService,
    private repository: WorkerProfileRepository,
    private enums: EnumsService
  ) {
    super();
    this.name = this.translate("title");

    runInAction(() => {
      this.location = classToClass(originalWorkerProfile.location);
      this.contact = classToClass(originalWorkerProfile.contact) ?? new WorkerProfileContact();
      attachAutomaticValidator(this.contact, WorkerProfileContact.ValidationRules);
    });
  }

  get states() {
    return this.enums.states;
  }

  @action.bound
  @watchBusy
  async updateContact() {
    if (validate(this.contact)) {
      const result = await this.repository.updateWorkerProfile(this.workerId, { location: this.location, contact: this.contact });
      if (result.success) {
        this.requestClose();
      }
    }
  }

  @bound translate(key: string) {
    return this.localization.translateGeneral(`worker.contact.${key}`);
  }

  static Factory({ container }: interfaces.Context) {
    return (workerId: string, originalWorkerProfile: WorkerProfile) =>
      new ContactInformationViewModel(
        workerId,
        originalWorkerProfile,
        container.get("ILocalizationService"),
        container.get(WorkerProfileRepository),
        container.get(EnumsService)
      );
  }
}
