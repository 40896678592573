import OshaFormQuarter from "entities/oshaFormQuarter";
import OshaFormQuestion from "entities/oshaFormQuestion";
import questions from "./questions";

export const inputFields: string[] = [];

class Calculator {
  constructor(private quarters: OshaFormQuarter[] | undefined) {}

  isInput(quesiton: string) {
    return inputFields.includes(quesiton);
  }

  extractWorkingQuarters() {
    return this.quarters!.map(quarter => quarter.questions!);
  }

  extractData(question: string) {
    return this.extractWorkingQuarters()
      .map(answer => {
        const readOnlyData = this.readOnly[question as keyof OshaFormQuestion];
        if (readOnlyData) {
          return readOnlyData;
        }
        return answer![question as keyof OshaFormQuestion];
      })
      .filter(answer => !!answer)
      .flat()
      .map(answer => this.parseString(answer as any) || 0);
  }

  parseString(num: any | undefined) {
    if (!!num) {
      return parseFloat(num);
    }

    return 0;
  }

  floor(num: number) {
    return this.parseString(num.toFixed(4));
  }

  makeReadOnly(question: string) {
    switch (question) {
      case "restrictedWorkDayCaseRateIncludingJobTransferWorkDayCases":
        return this.extractWorkingQuarters().map(answer => {
          let exposure = this.parseString(answer.numberOfExposureOrEmployeeHours);
          exposure = exposure > 0 ? exposure : 1;
          const daysAway = this.parseString(answer.numberOfCasesWithRestrictedDutyJobTransferWorkDays);
          // dpendency detection - if deps exists - than calculate this one, otherwise undefined
          return this.floor((daysAway * 200000) / exposure);
        });

      case "daysAwayRestrictedOrTransferRate":
        return this.extractWorkingQuarters().map(answer => {
          let exposure = this.parseString(answer.numberOfExposureOrEmployeeHours);
          exposure = exposure > 0 ? exposure : 1;
          const daysAway = this.parseString(answer.numberOfCasesWithDaysAwayFromWork);
          return this.floor((daysAway * 200000) / exposure);
        });

      case "severityRate": {
        return this.extractWorkingQuarters().map(answer => {
          const fatalities = this.parseString(answer.numberOfDaysAwayFromWork);

          let recordableCases =
            this.parseString(answer.totalNumberOfRecordableCases) + this.parseString(answer.totalNumberOfOtherRecordableCases);

          recordableCases = recordableCases > 0 ? recordableCases : 1;

          return this.floor(fatalities / recordableCases);
        });
      }

      case "totalNumberOfRecordableCasesIncludingNumberOfOtherRecordableCases": {
        return this.extractWorkingQuarters().map(answer => {
          let recordableCases =
            this.parseString(answer.totalNumberOfRecordableCases) + this.parseString(answer.totalNumberOfOtherRecordableCases);

          return this.floor(recordableCases);
        });
      }

      case "severityRateIncludesTotalNumberOfRecordableCasesIncludingOtherRecordableCases": {
        return this.extractWorkingQuarters().map(answer => {
          const fatalities =
            this.parseString(answer.numberOfFatalities) +
            this.parseString(answer.numberOfCasesWithDaysAwayFromWork) +
            this.parseString(answer.numberOfCasesWithRestrictedDutyJobTransferWorkDays) +
            this.parseString(answer.numberOfRecordableCasesThatDoesNotHaveRestrictedOrLostWorkDays);

          let recordableCases =
            this.parseString(answer.totalNumberOfRecordableCases) + this.parseString(answer.totalNumberOfOtherRecordableCases);

          recordableCases = recordableCases > 0 ? recordableCases : 1;

          return this.floor(fatalities / recordableCases);
        });
      }

      case "totalRecordableIncidentRate":
        return this.extractWorkingQuarters().map(answer => {
          let exposure = this.parseString(answer.numberOfExposureOrEmployeeHours);
          exposure = exposure > 0 ? exposure : 1;

          const result = this.floor(
            ((this.parseString(answer.totalNumberOfRecordableCases) +
              this.parseString(answer.totalNumberOfOtherRecordableCases)) *
              200000) /
              exposure
          );
          return result;
        });

      case "otherRecordableCasesRate":
        return this.extractWorkingQuarters().map(answer => {
          let exposure = this.parseString(answer.numberOfExposureOrEmployeeHours);
          exposure = exposure > 0 ? exposure : 1;
          return this.floor((this.parseString(answer.totalNumberOfOtherRecordableCases) * 200000) / exposure);
        });

      case "lostWorkDayRate": {
        return this.extractWorkingQuarters().map(answer => {
          let exposure = this.parseString(answer.numberOfExposureOrEmployeeHours);
          exposure = exposure > 0 ? exposure : 1;
          const daysAway = this.parseString(answer.numberOfDaysAwayFromWork);
          return this.floor((daysAway * 200000) / exposure);
        });
      }
      case "lostTimeCasesRate": {
        return this.extractWorkingQuarters().map(answer => {
          let exposure = this.parseString(answer.numberOfExposureOrEmployeeHours);
          exposure = exposure > 0 ? exposure : 1;
          const daysAway = this.parseString(answer.numberOfCasesWithDaysAwayFromWork);
          return this.floor((daysAway * 200000) / exposure);
        });
      }
      case "vehicleAccidentRate": {
        return this.extractWorkingQuarters().map(answer => {
          const accidents = this.parseString(answer.totalNumberOfVehicleAccidents);
          let miles = this.parseString(answer.totalMilesDriven);
          miles = miles > 0 ? miles : 1;

          return this.floor((accidents * 1000000) / miles);
        });
      }
      case "nonFatalTotalRecordableIncidentRate":
        return this.extractWorkingQuarters().map(answer => {
          let exposure = this.parseString(answer.numberOfExposureOrEmployeeHours);
          exposure = exposure > 0 ? exposure : 1;

          let cases = this.parseString(answer.totalNumberOfNonFatalRecordableCases);

          return this.floor((cases * 200000) / exposure);
        });

      case "fatalityRateBasedOn100Employees":
        return this.extractWorkingQuarters().map(answer => {
          let exposure = this.parseString(answer.numberOfExposureOrEmployeeHours);
          exposure = exposure > 0 ? exposure : 1;

          let fatalities = this.parseString(answer.numberOfFatalities);

          return this.floor((fatalities * 100) / exposure);
        });

      case "fatalityRateBasedOn100000Employees":
        return this.extractWorkingQuarters().map(answer => {
          let exposure = this.parseString(answer.numberOfExposureOrEmployeeHours);
          exposure = exposure > 0 ? exposure : 1;

          let fatalities = this.parseString(answer.numberOfFatalities);

          return this.floor((fatalities * 100000) / exposure);
        });
      case "severityRatePer200000HoursWorked":
        return this.extractWorkingQuarters().map(answer => {
          let cases = this.parseString(answer.totalNumberOfRecordableCases);
          cases = cases > 0 ? cases : 1;

          let fatalities = this.parseString(answer.numberOfDaysAwayFromWork);

          return this.floor((fatalities * 200000) / cases);
        });

      case "seriousInjuryCaseRate": {
        return this.extractWorkingQuarters().map(answer => {
          let exposure = this.parseString(answer.numberOfExposureOrEmployeeHours);
          exposure = exposure > 0 ? exposure : 1;

          let fatalities =
            this.parseString(answer.totalIncidentsResultingInAmputation) +
            this.parseString(answer.totalIncidentsResultingInEyeLoss) +
            this.parseString(answer.totalIncidentsResultingInInPatientHospitalization);

          return this.floor((fatalities * 200000) / exposure);
        });
      }

      default:
        return null;
    }
  }

  makeTotal(question: string) {
    switch (question) {
      case "workPerformed":
        return null;

      case "averageNumberOfEmployees":
      case "averageNumberOfEmployeesThirdParty":
      case "severityRateIncludesTotalNumberOfRecordableCasesIncludingOtherRecordableCasesThirdParty":
      case "totalRecordableIncidentRate":
      case "lostTimeCasesRate":
      case "daysAwayRestrictedOrTransferRate":
      case "lostWorkDayRate":
      case "restrictedWorkDayCaseRateIncludingJobTransferWorkDayCases":
      case "severityRate":
      case "nonFatalTotalRecordableIncidentRate":
      case "fatalityRateBasedOn100Employees":
      case "fatalityRateBasedOn100000Employees":
      case "severityRateIncludesTotalNumberOfRecordableCasesIncludingOtherRecordableCases":
      case "severityRatePer200000HoursWorked":
      case "otherRecordableCasesRate":
      case "vehicleAccidentRate":
      case "seriousInjuryCaseRate":
        const data = this.extractData(question);
        return data.slice(0, 4).reduce((acc, nxt) => acc + (Number.isFinite(nxt) ? nxt : 0), 0) / 4;
      default:
        if (!inputFields.includes(question)) {
          const data = this.extractData(question);
          return data.slice(0, 4).reduce((acc, nxt) => acc + (Number.isFinite(nxt) ? nxt : 0), 0);
        } else {
          return undefined;
        }
    }
  }

  get allTotal() {
    return questions.reduce((acc, question) => {
      const total = this.makeTotal(question.name);
      return {
        ...acc,
        [question.name]: total === 0 ? undefined : total,
      };
    }, {});
  }

  get readOnly(): OshaFormQuestion {
    return questions
      .filter(({ readonly }) => readonly)
      .reduce((acc, question) => {
        const total = this.makeReadOnly(question.name);
        return {
          ...acc,
          [question.name]: total,
        };
      }, {});
  }
}
export default Calculator;
