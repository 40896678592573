import { registerView } from "@frui.ts/views";
import React from "react";
import PointsAllocationEditViewModel from "viewModels/company/compliance/pointsAllocationEditViewModel";
import ModalView, { ModalSize } from "views/common/modalView";
import { Row, Col, Form, Button } from "@emanprague/veralinkkit";
import Input from "controls/input";
import "./compliance.scss";
import { Check } from "@frui.ts/bootstrap";
import classnames from "classnames";

export class PointsAllocationEditView extends ModalView<PointsAllocationEditViewModel> {
  size: ModalSize = "lg";

  renderFooter(vm: PointsAllocationEditViewModel) {
    return (
      <Row className="justify-content-end">
        <Button variant="outline-primary" disabled={vm.busyWatcher.isBusy} onClick={vm.cancel}>
          {vm.translate("cancel")}
        </Button>
        <Button data-testid="save" variant="primary" disabled={vm.busyWatcher.isBusy || !vm.isValid} onClick={vm.save}>
          {vm.translate("save")}
        </Button>
      </Row>
    );
  }

  renderBody(vm: PointsAllocationEditViewModel) {
    const tg = (code: string, opts = {}) => vm.localization.translateGeneral(`compliance.subModule.${code}`, opts);
    const ta = (code: string, entity: string) => vm.localization.translateAttribute(entity, code);
    const totalClasses = classnames("allocation-summary__total", {
      "is-good": vm.total === 400,
    });

    return (
      <>
        <Row>
          <Col>
            {vm.inputNames.map(name => {
              return (
                <Row key={name} data-testid={`${name}`} className="edit-row allocations">
                  <Col>
                    <b>{ta(name, "compliancePoints")}</b>
                    <Check
                      data-testid={`${name}-check`}
                      label={tg("requiredContractors")}
                      property={name as any}
                      target={vm.requiredFields}
                    />
                  </Col>
                  <Form.Group>
                    <Input
                      disabled={!vm.requiredFields[name]}
                      data-testid={`${name}-input`}
                      type="number"
                      placeholder="0"
                      target={vm.allocations}
                      property={name}
                    />
                  </Form.Group>
                </Row>
              );
            })}
          </Col>
        </Row>
        <Row className="allocation-summary">
          <Col className="text-center">
            <p>
              {vm.translate("total")}{" "}
              <span data-testid="total-count" className={totalClasses}>
                {vm.total}
              </span>
            </p>

            <p className="text-muted">{vm.translate("max")}</p>
          </Col>
        </Row>
      </>
    );
  }
}

registerView(PointsAllocationEditView, PointsAllocationEditViewModel);
