import { observable } from "mobx";
import SafetyMetricIndicators from "./safetyMetricIndicators";

// This entity has been generated, do not change its content, your changes might get lost. You CAN modify the rest of the file.
export default class SafetyMetricIndicatorThreshold {
  @observable
  indicator?: SafetyMetricIndicators;

  @observable
  threshold?: number;

  static ValidationRules = {
    threshold: { number: true },
  };
}

export const ValidationRules = {
  threshold: { isPositiveNumber: true, maxDecimals: { size: 2 }, required: true },
};
