import { Button, Col, Form, LoaderOverlay, Row } from "@emanprague/veralinkkit";
import { Check, Input } from "@frui.ts/bootstrap";
import { registerView } from "@frui.ts/views";
import Field from "controls/field";
import React from "react";
import AboutDetailViewModel from "viewModels/worker/modals/aboutDetailViewModel";
import ModalView, { ModalSize } from "views/common/modalView";

class AboutDetailModalView extends ModalView<AboutDetailViewModel> {
  size: ModalSize = "lg";

  renderBody(vm: AboutDetailViewModel) {
    const ta = vm.localization.translateAttribute;

    return (
      <LoaderOverlay loading={vm.busyWatcher.isBusy} variant="light">
        <Row>
          <Col md={12}>
            <Field localization={vm.localization} label={ta("workerProfile", "bio")} optional>
              <Input as="textarea" type="text" target={vm.item.skills} property="bio" />
            </Field>
          </Col>
          <Col md={12}>
            <Form.Group>
              <Check label={ta("workerProfile", "USMilitaryVeteran")} target={vm.item} property="USMilitaryVeteran" />
            </Form.Group>
          </Col>
          <Col xs={12} md={6}>
            <Form.Group>
              <Check label={ta("workerProfile", "unionMember")} target={vm.item} property="unionMember" />
            </Form.Group>
          </Col>
        </Row>
      </LoaderOverlay>
    );
  }

  renderFooter(vm: AboutDetailViewModel) {
    return (
      <Button variant="primary" disabled={vm.busyWatcher.isBusy} onClick={vm.updateAbout}>
        {vm.translate("save")}
      </Button>
    );
  }
}

registerView(AboutDetailModalView as any, AboutDetailViewModel);
