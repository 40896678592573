import { observable } from "mobx";

// This entity has been generated, do not change its content, your changes might get lost. You CAN modify the rest of the file.
export default class AuthenticateUser {
  /**
   * User name
   */
  @observable
  username!: string;

  /**
   * User password
   */
  @observable
  password!: string;

  static ValidationRules = {
    username: { required: true },
    password: { required: true },
  };
}
