import { ILocalizationService, INotificationService } from "@emanprague/shared-services";
import { bound } from "@frui.ts/helpers";
import { ConductorOneChildActive, Router, ScreenBase } from "@frui.ts/screens";
import ForgotPasswordViewModel, { navigationName as forgotPasswordNavigationName } from "./profile/forgotPasswordViewModel";
import LoginViewModel from "./profile/loginViewModel";
import SetupPasswordViewModel from "./profile/setupPasswordViewModel";
import SignupRootViewModel from "./signup/signupRootViewModel";
import WorkerImportViewModel, { navigationName as workerImportNavigationName } from "./workerImportViewModel";
import PublicProfileViewModel from "./public/profileViewModel";
import ToastViewModel from "./toastViewModel";
import JoinViewModel from "./join/joinViewModel";

@Router.registerRoute({ route: workerImportNavigationName, name: WorkerImportViewModel })
@Router.registerRoute({ route: "", children: [SignupRootViewModel] })
@Router.registerRoute({ route: "login", name: LoginViewModel })
@Router.registerRoute({ route: forgotPasswordNavigationName, name: ForgotPasswordViewModel })
export default class AnonymousRootViewModel extends ConductorOneChildActive<ScreenBase> {
  constructor(
    public localization: ILocalizationService,
    private login: LoginViewModel,
    private forgotPassword: ForgotPasswordViewModel,
    private setupPassword: SetupPasswordViewModel,
    private signup: SignupRootViewModel,
    private join: JoinViewModel,
    private workerImport: WorkerImportViewModel,
    private publicProfile: PublicProfileViewModel,
    private notificationService: INotificationService
  ) {
    super();
    this.children.push(
      this.login,
      this.forgotPassword,
      this.setupPassword,
      this.signup,
      this.join,
      this.workerImport,
      this.publicProfile
    );
  }

  get toasts() {
    const results: ToastViewModel[] = [];

    const service = this.notificationService;
    service.notifications.forEach((notification, key) =>
      results.push(new ToastViewModel(key, notification.message, notification.severity, () => service.removeNotification(key)))
    );

    return results;
  }

  @bound translate(key: string) {
    return this.localization.translateGeneral(`help.${key}`);
  }

  protected onInitialize() {
    if (!this.activeChild) {
      return this.tryActivateChild(this.login);
    }
  }
}
