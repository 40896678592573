import { ILocalizationService } from "@emanprague/shared-services";
import { bound } from "@frui.ts/helpers";
import { BusyWatcher, ScreenBase, watchBusy } from "@frui.ts/screens";
import ProjectRepository from "repositories/projectRepository";
import { interfaces } from "inversify";
import { observable, runInAction } from "mobx";
import CompanyProjectTeam from "entities/companyProjectTeam";
import { classToClass } from "class-transformer";
import FormTeamViewModel from "viewModels/company/projects/teams/formTeamViewModel";
import { attachAutomaticValidator, validate } from "@frui.ts/validation";

export default class EditTeamViewModel extends ScreenBase {
  navigationName = "edit";
  busyWatcher = new BusyWatcher();

  @observable item: CompanyProjectTeam = new CompanyProjectTeam();
  @observable formVM: FormTeamViewModel;

  constructor(
    private companyId: string,
    private projectId: string,
    team: CompanyProjectTeam,
    public localization: ILocalizationService,
    private repository: ProjectRepository,
    formTeamFactory: ReturnType<typeof FormTeamViewModel.Factory>
  ) {
    super();

    this.name = this.translate("title");
    runInAction(() => {
      this.item = classToClass(team);
      this.formVM = formTeamFactory(this.item);
      this.formVM.prepareEdit();
    });

    attachAutomaticValidator(this.formVM.team, CompanyProjectTeam.ValidationRules);
  }

  @bound
  @watchBusy
  async editTeam() {
    if (validate(this.formVM.team)) {
      this.formVM.prepareTeam();
      const result = await this.repository.updateProjectTeam(this.companyId, this.projectId, this.item.id!, this.formVM.team);
      if (result.success) {
        this.requestClose();
      }
    }
  }

  @bound
  @watchBusy
  async deleteTeam() {
    const result = await this.repository.deleteProjectTeam(this.companyId, this.projectId, this.item.id!);
    if (result.success) {
      this.requestClose();
    }
  }

  @bound translate(key: string) {
    return this.localization.translateGeneral(`projects.teams.edit.${key}`);
  }

  static Factory({ container }: interfaces.Context) {
    return (companyId: string, projectId: string, team: CompanyProjectTeam) =>
      new EditTeamViewModel(
        companyId,
        projectId,
        team,
        container.get("ILocalizationService"),
        container.get(ProjectRepository),
        container.get(FormTeamViewModel.Factory)
      );
  }
}
