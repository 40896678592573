import { ApiError } from "./apiModels";

export function unwrapErrorMessage(error: ApiError) {
  for (const key in error.errors) {
    const message = error.errors[key];
    if (message && message.length) {
      return message[0];
    }
  }

  return error.errorDescription ?? "Unknown error";
}
