import { ILocalizationService } from "@emanprague/shared-services";
import { bound } from "@frui.ts/helpers";
import { BusyWatcher, ScreenBase, watchBusy } from "@frui.ts/screens";
import isFuture from "date-fns/isFuture";
import startOfToday from "date-fns/startOfToday";
import WorkerProfile from "entities/workerProfile";
import { interfaces } from "inversify";
import { action, observable, runInAction } from "mobx";
import WorkerProfileRepository from "repositories/workerProfileRepository";

export type AvailableType = "availableNow" | "availableFrom";

export default class AvailabilityViewModel extends ScreenBase {
  navigationName = "availability";
  busyWatcher = new BusyWatcher();

  @observable isAvailable: boolean;
  @observable availableType: AvailableType;
  @observable availableFrom?: Date;

  constructor(
    private workerId: string,
    originalWorkerProfile: WorkerProfile,
    public localization: ILocalizationService,
    private repository: WorkerProfileRepository
  ) {
    super();
    this.name = this.translate("title");

    runInAction(() => {
      this.isAvailable = !!originalWorkerProfile.availableFrom;
      this.availableFrom = originalWorkerProfile.availableFrom;
      this.availableType =
        originalWorkerProfile.availableFrom && isFuture(originalWorkerProfile.availableFrom) ? "availableFrom" : "availableNow";
    });
  }

  @action.bound
  @watchBusy
  async updateAvailability() {
    let date: Date | undefined;

    if (this.isAvailable) {
      if (this.availableType === "availableFrom" && this.availableFrom) {
        date = this.availableFrom;
      } else {
        date = startOfToday();
      }
    }

    const result = await this.repository.updateAvailability(this.workerId, date);
    if (result.success) {
      this.requestClose();
    }
  }

  @bound translate(key: string) {
    return this.localization.translateGeneral(`worker.availability.${key}`);
  }

  static Factory({ container }: interfaces.Context) {
    return (workerId: string, originalWorkerProfile: WorkerProfile) =>
      new AvailabilityViewModel(
        workerId,
        originalWorkerProfile,
        container.get("ILocalizationService"),
        container.get(WorkerProfileRepository)
      );
  }
}
