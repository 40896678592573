import { ILocalizationService } from "@emanprague/shared-services";
import { bound } from "@frui.ts/helpers";
import { BusyWatcher, ScreenBase, Router, watchBusy } from "@frui.ts/screens";
import { observable, runInAction, computed } from "mobx";
import { UserSettingsUserName } from "entities/userSettings";
import { attachAutomaticValidator, validate } from "@frui.ts/validation";
import { attachAutomaticDirtyWatcher, resetDirty, isDirty } from "@frui.ts/dirtycheck";
import LoginRepository from "repositories/loginRepository";
import { omit } from "lodash";

export default class AccountSettingsViewModel extends ScreenBase {
  navigationName = "settings";
  busyWatcher = new BusyWatcher();

  oldEmail: string;
  @observable settings = new UserSettingsUserName();

  constructor(public localization: ILocalizationService, private repository: LoginRepository, private router: Router) {
    super();
    this.name = this.translate("title");
    attachAutomaticValidator(this.settings, UserSettingsUserName.ValidationRules);
    attachAutomaticDirtyWatcher(this.settings);
  }

  @bound translate(key: string) {
    return this.localization.translateGeneral(`profile.accountSettings.${key}`);
  }

  onInitialize() {
    this.loadSettings();
  }

  @bound
  @watchBusy
  async loadSettings() {
    const result = await this.repository.loadSettings();

    if (result.success) {
      runInAction(() => {
        this.settings = Object.assign(this.settings, result.payload);
        this.oldEmail = result.payload.email;
      });

      resetDirty(this.settings);
    }
  }

  @bound async save() {
    if (validate(this.settings)) {
      const result = await this.repository.changeSettings(omit(this.settings, ["username"]));
      if (result.success) {
        (this.parent as any).navigate("emailSent", { email: this.settings.email, oldEmail: this.oldEmail }, {});
      }
    }
  }

  @computed get canSubmit() {
    const isDirtyEmail = isDirty(this.settings, "email");
    return !isDirtyEmail;
  }
}
