import { registerView, ViewComponent } from "@frui.ts/views";
import { observer } from "mobx-react-lite";
import React from "react";
import SuccessEmailSentViewModel from "viewModels/signup/successEmailSentViewModel";
import LinkSent from "views/common/linkSent";
import FullHeightContainer from "views/common/fullHeightContainer";

const successEmailSentListView: ViewComponent<SuccessEmailSentViewModel> = observer(({ vm }) => (
  <FullHeightContainer>
    <LinkSent
      email={vm.email}
      title={vm.translate("title")}
      message={vm.translate("description")}
      help={vm.translate("help")}
      resend={vm.translate("sendAgain")}
      onResend={vm.resend}
    />
  </FullHeightContainer>
));

registerView(successEmailSentListView, SuccessEmailSentViewModel);
