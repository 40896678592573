import { observable } from "mobx";

// This entity has been generated, do not change its content, your changes might get lost. You CAN modify the rest of the file.
export default class OshaFormQuestion {
  /**
   * Q read-write, Y readwrite
   */
  @observable
  averageNumberOfEmployees?: number;

  /**
   * Q read-write, Y read-only
   */
  @observable
  numberOfExposureOrEmployeeHours?: number;

  /**
   * Q read-write, Y read-only
   */
  @observable
  totalNumberOfRecordableCasesIncludingNumberOfOtherRecordableCases?: number;

  /**
   * Q read-only, Y read-only
   */
  readonly totalRecordableIncidentRate?: number;

  /**
   * Q read-write, Y read-only
   */
  @observable
  numberOfCasesWithDaysAwayFromWork?: number;

  /**
   * Q read-only, Y read-only
   */
  readonly lostTimeCasesRate?: number;

  /**
   * Q read-write, Y read-only
   */
  @observable
  numberOfDaysAwayFromWork?: number;

  /**
   * Q read-only, Y read-only
   */
  readonly lostWorkDayRate?: number;

  /**
   * Q read-write, Y read-only
   */
  @observable
  numberOfCasesWithRestrictedDutyJobTransferWorkDays?: number;

  /**
   * Q read-only, Y read-only
   */
  readonly restrictedWorkDayCaseRateIncludingJobTransferWorkDayCases?: number;

  /**
   * Q read-write, Y read-only
   */
  @observable
  numberOfRestrictedDutyJobTransferWorkDays?: number;

  /**
   * Q read-write, Y read-only
   */
  @observable
  numberOfFatalities?: number;

  /**
   * Q read-only, Y read-only
   */
  readonly severityRate?: number;

  /**
   * Q read-only, Y read-only
   */
  readonly daysAwayRestrictedOrTransferRate?: number;

  /**
   * Q not-available, Y read-write
   */
  @observable
  averageNumberOfEmployeesThirdParty?: number;

  /**
   * Q not-available, Y read-only
   */
  readonly numberOfExposureOrEmployeeHoursPerEmployee?: number;

  /**
   * Q not-available, Y read-write
   */
  @observable
  numberOfFatalitiesThirdParty?: number;

  /**
   * Q not-available, Y read-write
   */
  @observable
  totalNumberOfOtherRecordableCases?: number;

  /**
   * Q not-available, Y read-write
   */
  @observable
  totalNumberOfRecordableCases?: number;

  /**
   * Q not-available, Y read-only
   */
  readonly totalNumberOfNonFatalRecordableCases?: number;

  /**
   * Q read-write, Y read-only
   */
  @observable
  numberOfRecordableCasesThatDoesNotHaveRestrictedOrLostWorkDays?: number;

  /**
   * Q not-available, Y read-only
   */
  readonly nonFatalTotalRecordableIncidentRate?: number;

  /**
   * Q not-available, Y read-only
   */
  readonly fatalityRateBasedOn100Employees?: number;

  /**
   * Q not-available, Y read-only
   */
  readonly fatalityRateBasedOn100000Employees?: number;

  /**
   * Q not-available, Y read-only
   */
  @observable
  severityRateIncludesTotalNumberOfRecordableCasesIncludingOtherRecordableCases?: number;

  /**
   * Q not-available, Y read-write
   */
  @observable
  severityRateIncludesTotalNumberOfRecordableCasesIncludingOtherRecordableCasesThirdParty?: number;

  /**
   * Q not-available, Y read-only
   */
  readonly severityRatePer200000HoursWorked?: number;

  /**
   * Q not-available, Y read-write
   */
  @observable
  totalNumberOfVehicleAccidents?: number;

  /**
   * Q not-available, Y read-write
   */
  @observable
  totalMilesDriven?: number;

  /**
   * Q not-available, Y read-only
   */
  readonly vehicleAccidentRate?: number;

  /**
   * Q not-available, Y read-only
   */
  readonly otherRecordableCasesRate?: number;

  /**
   * Q not-available, Y read-write
   */
  @observable
  totalIncidentsResultingInAmputation?: number;

  /**
   * Q not-available, Y read-write
   */
  @observable
  totalIncidentsResultingInInPatientHospitalization?: number;

  /**
   * Q not-available, Y read-write
   */
  @observable
  totalIncidentsResultingInEyeLoss?: number;

  /**
   * Q not-available, Y read-write
   */
  @observable
  totalIncidentsResultingInAmputationInPatientHospitalizationOrEyeLoss?: number;

  /**
   * Q not-available, Y read-only
   */
  readonly seriousInjuryCaseRate?: number;

  static ValidationRules = {
    averageNumberOfEmployees: { number: true },
    numberOfExposureOrEmployeeHours: { number: true },
    totalNumberOfRecordableCasesIncludingNumberOfOtherRecordableCases: { number: true },
    totalRecordableIncidentRate: { number: true },
    numberOfCasesWithDaysAwayFromWork: { number: true },
    lostTimeCasesRate: { number: true },
    numberOfDaysAwayFromWork: { number: true },
    lostWorkDayRate: { number: true },
    numberOfCasesWithRestrictedDutyJobTransferWorkDays: { number: true },
    restrictedWorkDayCaseRateIncludingJobTransferWorkDayCases: { number: true },
    numberOfRestrictedDutyJobTransferWorkDays: { number: true },
    numberOfFatalities: { number: true },
    severityRate: { number: true },
    daysAwayRestrictedOrTransferRate: { number: true },
    averageNumberOfEmployeesThirdParty: { number: true },
    numberOfExposureOrEmployeeHoursPerEmployee: { number: true },
    numberOfFatalitiesThirdParty: { number: true },
    totalNumberOfOtherRecordableCases: { number: true },
    totalNumberOfRecordableCases: { number: true },
    totalNumberOfNonFatalRecordableCases: { number: true },
    numberOfRecordableCasesThatDoesNotHaveRestrictedOrLostWorkDays: { number: true },
    nonFatalTotalRecordableIncidentRate: { number: true },
    fatalityRateBasedOn100Employees: { number: true },
    fatalityRateBasedOn100000Employees: { number: true },
    severityRateIncludesTotalNumberOfRecordableCasesIncludingOtherRecordableCases: { number: true },
    severityRateIncludesTotalNumberOfRecordableCasesIncludingOtherRecordableCasesThirdParty: { number: true },
    severityRatePer200000HoursWorked: { number: true },
    totalNumberOfVehicleAccidents: { number: true },
    totalMilesDriven: { number: true },
    vehicleAccidentRate: { number: true },
    otherRecordableCasesRate: { number: true },
    totalIncidentsResultingInAmputation: { number: true },
    totalIncidentsResultingInInPatientHospitalization: { number: true },
    totalIncidentsResultingInEyeLoss: { number: true },
    totalIncidentsResultingInAmputationInPatientHospitalizationOrEyeLoss: { number: true },
    seriousInjuryCaseRate: { number: true },
  };
}

export class WithWorkOshaQuestion extends OshaFormQuestion {
  @observable workPerformed: boolean;
}
