import CodeListProfession from "entities/codeListProfession";
import { observable, runInAction } from "mobx";
import EnumsRepository from "repositories/enumsRepository";
import TrainingEntity from "entities/trainingEntity";
import CredentialType from "entities/credentialType";
import CodeListState from "entities/codeListState";
import CodeListSafetyPolicy from "entities/codeListSafetyPolicy";
import CodeListWorkType from "entities/codeListWorkType";
import CodeListIndustry from "entities/codeListIndustry";
// import CodeListUnion from "entities/codeListUnion";

export default class EnumsService {
  @observable.ref states: CodeListState[];
  @observable.ref professions: CodeListProfession[];
  @observable.ref trainingEntities: TrainingEntity[];
  @observable.ref credentialTypes: CredentialType[];
  @observable.ref workTypes: CodeListWorkType[];
  @observable.ref safetyPolicies: CodeListSafetyPolicy[];
  @observable.ref workerCredentialTypes: CredentialType[];
  @observable.ref companyCredentialTypes: CredentialType[];
  @observable.ref industries: CodeListIndustry[];
  // @observable.ref unions: CodeListUnion[];

  constructor(private repository: EnumsRepository) {}

  async initialize() {
    const [
      states,
      professions,
      trainingEntities,
      credentialTypes,
      workTypes,
      safetyPolicies,
      workerCredentialTypes,
      companyCredentialTypes,
      industries,
    ] = await Promise.all([
      this.repository.getStates(),
      this.repository.getProfessions(),
      this.repository.getTrainingEntities(),
      this.repository.getCredentialTypes(),
      this.repository.getWorkTypes(),
      this.repository.getSafetyPolicies(),
      this.repository.getWorkerCredentialTypes(),
      this.repository.getCompanyCredentialTypes(),
      this.repository.getIndustries(),
    ]);

    runInAction(() => {
      this.states = states;
      this.professions = professions;
      this.trainingEntities = trainingEntities;
      this.credentialTypes = credentialTypes;
      this.safetyPolicies = safetyPolicies;
      this.workTypes = workTypes;
      this.workerCredentialTypes = workerCredentialTypes;
      this.companyCredentialTypes = companyCredentialTypes;
      this.industries = industries;
    });
  }

  getStateById(id?: string) {
    return id ? this.states.find(state => state.id === id) : undefined;
  }

  getProfessionById(id?: string) {
    return id ? this.professions.find(profession => profession.id === id) : undefined;
  }

  getIndustryById(id?: string) {
    return id ? this.industries.find(industry => industry.id === id) : undefined;
  }

  // getUnionById(id?: string) {
  //   return id ? this.unions.find(union => union.id === id) : undefined;
  // }
}
