import { observable } from "mobx";
import merge from "lodash/merge";
import { EntityValidationRules } from "services/validation/entityValidationRules";

// This entity has been generated, do not change its content, your changes might get lost. You CAN modify the rest of the file.
export class WorkerProfileContact {
  /**
   * Contact email
   */
  @observable
  email!: string;

  /**
   * Contact phone number
   */
  @observable
  phoneNumber?: string;

  /**
   * Contact website
   */
  @observable
  website?: string;

  /**
   * LinkedIn profile name
   */
  @observable
  linkedin?: string;

  static ValidationRules = {
    email: { format: "email", required: true },
    website: { format: "uri" },
  };
}

export default class WorkerProfileContactValidation extends WorkerProfileContact {
  static ValidationRules = merge(
    {
      email: { isEmail: true },
      phoneNumber: { isPhone: true },
    } as EntityValidationRules<WorkerProfileContact>,
    WorkerProfileContact.ValidationRules
  );
}
