import { observable } from "mobx";

// This entity has been generated, do not change its content, your changes might get lost. You CAN modify the rest of the file.
export default class ExperienceModifierDefinition {
  /**
   * Experience Modifier threshold
   * @example
   * 2.34
   */
  @observable
  threshold?: number;

  static ValidationRules = {
    threshold: { number: true },
  };
}

export class ExperienceModifierDefinitionValidator extends ExperienceModifierDefinition {
  static ValidationRules = Object.assign(ExperienceModifierDefinition.ValidationRules, {
    threshold: { required: true, isPositiveNumber: true, maxDecimals: { size: 2 } },
  });
}
