import { ILocalizationService, IEventBus } from "@emanprague/shared-services";
import { bound, IDisposable } from "@frui.ts/helpers";
import { BusyWatcher, ScreenBase, watchBusy, ConductorOneChildActive } from "@frui.ts/screens";
import { ISubModule, ICredentialsViewModel } from "viewModels/types";
import AccountType from "entities/accountType";
import AuthenticationIdentity from "entities/authenticationIdentity";
import { CredentialPhoto } from "entities/credential";
import { observable, runInAction, computed } from "mobx";
import { makeCredentialsSections } from "utils";
import EnumsService from "services/enumsService";
import CredentialsOverviewViewModel from "viewModels/worker/credentials/credentialsOverviewViewModel";
import UserContext from "services/userContext";
import { WorkerEvents } from "services/events";
import WorkerProfileRepository from "repositories/workerProfileRepository";

export default class SubModuleViewModel extends ConductorOneChildActive<ScreenBase> implements ISubModule, ICredentialsViewModel {
  navigationName = "companyCredentials";
  orderIndex = 3;
  busyWatcher = new BusyWatcher();
  @observable companyCredentials: CredentialPhoto[];
  @observable.ref credential: CredentialPhoto;
  private companyId?: string;
  mode = "edit";

  private eventHandlers: IDisposable[];
  constructor(
    private context: UserContext,
    private enums: EnumsService,
    private credentialOverviewFactory: ReturnType<typeof CredentialsOverviewViewModel.Factory>,
    public localization: ILocalizationService,
    private repository: WorkerProfileRepository,
    private eventBus: IEventBus,
    private userContext: UserContext
  ) {
    super();

    this.companyId = this.context?.identity?.accountId;
    this.name = this.translate("title");
    if (userContext?.identity?.accountType === AccountType.Contractor) {
      this.eventHandlers = [
        this.eventBus.subscribe(WorkerEvents.credentialCreated, this.loadCredentials),
        this.eventBus.subscribe(WorkerEvents.credentialDeleted, this.loadCredentials),
      ];
    }
  }

  protected async onDeactivate(isClosing: boolean) {
    await super.onDeactivate(isClosing);

    if (isClosing) {
      this.eventHandlers?.forEach(x => x.dispose());
    }
  }

  async onInitialize() {
    if (!this.companyCredentials) {
      await this.loadCredentials();
    }
  }

  @bound
  @watchBusy
  async loadCredentials() {
    const result = await this.repository.loadCredentials(this.companyId as string);

    if (result.success) {
      runInAction(() => {
        this.companyCredentials = (result.payload as unknown) as CredentialPhoto[];
      });
    }
  }

  @bound openCredentialsModal() {
    return this.navigate("credentialsListAdd", undefined);
  }

  @bound
  async openCredentialsOverviewModal(credential: CredentialPhoto) {
    this.credential = credential;
    return this.navigate("credentialListOverview", undefined);
  }

  get trainingEntities() {
    return this.enums.trainingEntities;
  }

  @computed get credentials() {
    return makeCredentialsSections(this.companyCredentials ?? [], this.trainingEntities);
  }

  isVisible(identity: AuthenticationIdentity) {
    return identity.accountType === AccountType.Contractor;
  }

  @bound translate(key: string) {
    return this.localization.translateGeneral(`companyCredentials.subModule.${key}`);
  }

  protected findNavigationChild(navigationName: string | undefined): ScreenBase | undefined {
    if (!this.companyId) {
      return undefined;
    }

    switch (navigationName) {
      case "credentialsListAdd":
        return this.credentialOverviewFactory(this.companyId, undefined, { variant: "company", mode: "edit" });
      case "credentialListOverview":
        return this.credentialOverviewFactory(this.companyId, this.credential, { variant: "company", mode: "overview" });
      default:
        return undefined;
    }
  }
}
