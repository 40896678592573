import { registerView, ViewComponent, preventDefault } from "@frui.ts/views";
import { observer } from "mobx-react-lite";
import React from "react";
import { Container, Row, Col, Card, Form, Button, LoaderOverlay } from "@emanprague/veralinkkit";
import Input from "controls/input";
import "./accountSettings.scss";
import AccountSettingsViewModel from "viewModels/profile/accountSettings/accountSettingsViewModel";

const accountSettingsView: ViewComponent<AccountSettingsViewModel> = observer(({ vm }) => {
  const ta = vm.localization.translateAttribute;
  return (
    <LoaderOverlay loading={vm.busyWatcher.isBusy} variant="light">
      <Container>
        <Row className="justify-content-center">
          <Col md={8}>
            <h1 className="account-settings__title">{vm.name}</h1>
            <Card className="account-settings">
              <Card.Body>
                <Row className="justify-content-center">
                  <Col md={10}>
                    <Form onSubmit={preventDefault(vm.save)}>
                      <Form.Group>
                        <Form.Label>{ta("userSettings", "email")}</Form.Label>
                        <Input type="text" target={vm.settings} property="email" />
                      </Form.Group>
                      <Form.Group>
                        <Form.Label>{ta("userSettings", "username")}</Form.Label>
                        <Input type="text" disabled target={vm.settings} property="username" />
                      </Form.Group>

                      <Button block type="submit" disabled={vm.canSubmit}>
                        {vm.translate("submit")}
                      </Button>
                    </Form>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </LoaderOverlay>
  );
});

registerView(accountSettingsView, AccountSettingsViewModel);
