import { observable } from "mobx";
import WorkerProfileSearchResult from "./workerProfileSearchResult";

export default class WorkerProfileSearchResults {
  @observable
  workers!: WorkerProfileSearchResult[];

  @observable
  total!: number;

  static ValidationRules = {
    workers: { required: true },
    total: { required: true },
  };
}
