import { IEventBus, ILocalizationService } from "@emanprague/shared-services";
import { bound } from "@frui.ts/helpers";
import { BusyWatcher, ScreenBase, watchBusy } from "@frui.ts/screens";
import AccountType from "entities/accountType";
import AuthenticationIdentity from "entities/authenticationIdentity";
import EmployeeRepository from "repositories/employeeRepository";
import EmployeeFilter from "models/employeeFilter";
import { reaction, runInAction } from "mobx";
import { EmployeesEvents } from "services/events";
import EnumsRepository from "repositories/enumsRepository";
import EnumsService from "services/enumsService";
import { interfaces } from "inversify";
import CompanyEmployeesPendingRecord from "entities/companyEmployeesPendingRecord";
import ContinuousListViewModelBase from "viewModels/continuousListViewModelBase";

export default class PendingEmployeesViewModel extends ContinuousListViewModelBase<
  CompanyEmployeesPendingRecord,
  EmployeeFilter,
  ScreenBase
> {
  navigationName = "pending";
  busyWatcher = new BusyWatcher();
  alwaysDisplayLoadMore = true;

  constructor(
    private companyId: string,
    private enumsRepository: EnumsRepository,
    private repository: EmployeeRepository,
    public localization: ILocalizationService,
    private eventBus: IEventBus,
    public enums: EnumsService
  ) {
    super();

    this.name = this.translate("title");
    this.pagingFilter.sortColumn = this.sortOptions[0]?.[0];
    this.pagingFilter.limit = 20;
  }

  onInitialize() {
    this.loadData();
    this.eventBus.subscribe(EmployeesEvents.workerRemoved, this.loadData);
    this.eventBus.subscribe(EmployeesEvents.workersImported, this.loadData);
    reaction(() => [this.pagingFilter.sortColumn, this.pagingFilter.sortDirection, this.filter.search], this.applyFilterAndLoad);
  }

  createFilter(): EmployeeFilter {
    return new EmployeeFilter();
  }

  get sortOptions() {
    return this.enumsRepository.getCompanyPendingEmployeesSortOptions();
  }

  @bound
  @watchBusy
  async loadData() {
    const result = await this.repository.searchEmployeesPending(this.companyId, this.appliedFilter, this.pagingFilter);
    if (result.success) {
      const [res, paging] = result.payload;
      if (paging) {
        paging.totalItems = (this.currentPaging?.totalItems ?? 0) + paging.totalItems;
      }

      if (res.length === 0) {
        runInAction(() => {
          this.alwaysDisplayLoadMore = false;
        });
      }

      this.setData([res, paging]);
    }
  }

  isVisible(identity: AuthenticationIdentity) {
    return identity.accountType === AccountType.Contractor || identity.accountType === AccountType.Operator;
  }

  @bound translate(key: string) {
    return this.localization.translateGeneral(`employees.subModule.${key}`);
  }

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  protected resetFilterValues(filter: EmployeeFilter) {}

  static Factory({ container }: interfaces.Context) {
    return (companyId: string) =>
      new PendingEmployeesViewModel(
        companyId,
        container.get(EnumsRepository),
        container.get(EmployeeRepository),
        container.get("ILocalizationService"),
        container.get("IEventBus"),
        container.get(EnumsService)
      );
  }
}
