import { ConductorOneChildActive, IScreen, IChild, ScreenBase } from "@frui.ts/screens";
import { observable, IObservableArray, action } from "mobx";

export default abstract class RootViewModelBase<
  TChild extends IScreen & IChild,
  TAppended extends ScreenBase
> extends ConductorOneChildActive<TChild> {
  appendedViewModels: IObservableArray<TAppended> = observable.array([], { deep: false });

  @action
  appendViewModel(child: TAppended) {
    child.parent = this as any;
    this.appendedViewModels.push(child);
  }

  protected async onActivate() {
    await super.onActivate();
    for (const child of this.appendedViewModels) {
      await child.activate();
    }
  }

  protected async onDeactivate(isClosing: boolean) {
    await super.onDeactivate(isClosing);
    for (const child of this.appendedViewModels) {
      await child.deactivate(isClosing);
    }
  }

  @action
  closeChild(child: TChild | TAppended, forceClose = false): Promise<boolean> | boolean {
    const wasAppended = this.appendedViewModels.remove(child as any);
    if (wasAppended) {
      return true;
    } else {
      // modules cannot be closed
      return false;
    }
  }

  async navigate(subPath: string | undefined, params: any) {
    if (this.appendedViewModels.length) {
      // navigating should close all active appended view models (except the one that should be navigated to)
      for (const child of this.appendedViewModels.filter(x => !subPath || !subPath.startsWith(x.navigationName))) {
        await this.closeChild(child);
      }
    }

    if (!subPath) {
      await this.tryActivateChild(this.children[0]);
      return;
    }

    await super.navigate(subPath, params);
  }

  async navigateToAppendedViewModel(viewModel: TAppended, subPath?: string, params?: any) {
    this.appendViewModel(viewModel);
    await viewModel.navigate(subPath, params);
    await viewModel.activate();
  }
}
