import { registerView, View, ViewComponent } from "@frui.ts/views";
import { observer } from "mobx-react-lite";
import React from "react";
import NotifyMessagesViewModel from "viewModels/messages/notifyMessagesViewModel";
import Messages from "@emanprague/veralinkkit/dist/Icons/Comment";
import { Dropdown } from "@emanprague/veralinkkit";
import "./index.scss";
import UnreadBadge from "views/common/unreadBadge";

import MessageItem from "./components/messageItem";

const notifyMessagesView: ViewComponent<NotifyMessagesViewModel> = observer(({ vm }) => (
  <>
    <Dropdown
      id="notificationMessages"
      variant="outlined"
      className="notification__menu"
      title={
        <>
          <UnreadBadge count={vm.unreadCount} maxCount={99} />
          <Messages fontSize={20} />
        </>
      }>
      <h5>
        <span>{vm.translate("title")}</span>
        <Dropdown.Item href="#/messages">{vm.translate("showAll")}</Dropdown.Item>
      </h5>
      {vm.notificationMessages?.length > 0 ? (
        <div className="messages">
          <div className="messages__container">
            <ul className="messages__list">
              {vm.notificationMessages?.map((message, index) => {
                return (
                  <MessageItem
                    key={`message-${index}`}
                    message={message}
                    localization={vm.localization}
                    onUserClick={vm.requestClose}
                    openDetail={vm.openDetailMessage}
                  />
                );
              })}
            </ul>
          </div>
        </div>
      ) : (
        <p>{vm.translate("noNewMessages")}</p>
      )}
    </Dropdown>
    <View vm={vm.activeChild} />
  </>
));

registerView(notifyMessagesView, NotifyMessagesViewModel);
