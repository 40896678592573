import { IEventBus, ILocalizationService } from "@emanprague/shared-services";
import { bound, IDisposable } from "@frui.ts/helpers";
import { BusyWatcher, ConductorSingleChild, ScreenBase, watchBusy } from "@frui.ts/screens";
import AvatarPlaceholder from "assets/avatar.png";
import Credential, { CredentialPhoto } from "entities/credential";
import WorkerProfile from "entities/workerProfile";
import { interfaces } from "inversify";
import { action, computed, observable, runInAction } from "mobx";
import WorkerProfileRepository from "repositories/workerProfileRepository";
import EnumsService from "services/enumsService";
import { GeneralEvents, WorkerEvents } from "services/events";
import CredentialOverviewViewModel from "./credentials/credentialOverviewViewModel";
import CredentialsAddViewModel from "./credentials/credentialsAddViewModel";
import AvailabilityViewModel from "./modals/availabilityViewModel";
import ContactInformationViewModel from "./modals/contactInformationViewModel";
import SkillsDetailViewModel from "./modals/skillsDetailViewModel";
import AboutDetailViewModel from "./modals/aboutDetailViewModel";
import PersonalQrViewModel from "./modals/personalQrViewModel";
import { makeCredentialsSections } from "utils";
import CredentialsOverviewViewModel from "./credentials/credentialsOverviewViewModel";
import { ICredentialsViewModel } from "viewModels/types";
import WorkerProfileExperience from "entities/workerProfileExperience";
import DialogService from "services/dialogService";
import { copyToClipboard } from "utils/viewUtils";
import { Router } from "@frui.ts/screens";

import ExperienceDetailViewModel from "./modals/experienceDetailViewModel";
import EducationDetailViewModel from "./modals/educationDetailViewModel";
import WorkerProfileEducation from "entities/workerProfileEducation";

export default class WorkerProfileViewModel extends ConductorSingleChild<ScreenBase> implements ICredentialsViewModel {
  navigationName = "profile";
  busyWatcher = new BusyWatcher();

  private workerId: string;
  @observable item: WorkerProfile;
  @observable workerCredentials: Credential[] = [];
  @observable.ref credential: Credential;
  @observable workerImage: string = AvatarPlaceholder;

  private eventHandlers: IDisposable[];

  get professions() {
    return this.enums.professions;
  }

  get trainingEntities() {
    return this.enums.trainingEntities;
  }

  get credentialTypes() {
    return this.enums.credentialTypes;
  }

  get credentials() {
    return makeCredentialsSections(this.workerCredentials, this.trainingEntities);
  }

  get fullName() {
    return `${this.item.firstName ?? ""} ${this.item.middleName ?? ""} ${this.item.lastName ?? ""}`;
  }

  get printLocation() {
    if (this.item.location) {
      const { city, state } = this.item?.location;
      return [city, this.enums.getStateById(state)?.name].filter(value => value !== undefined).join(", ");
    }
    return "";
  }

  get isAvailable() {
    return !!this.item.availableFrom;
  }

  @computed
  get score() {
    const result = { text: this.translate("low"), value: 0 };

    let actual = 0;
    const total = 4;

    if (!!this.item.skills) {
      actual += 1;
    }

    if (this.item.workExperience.length > 0) {
      actual += 1;
    }

    if (this.item.education.length > 0) {
      actual += 1;
    }

    if (this.workerCredentials.length > 0) {
      actual += 1;
    }

    result.value = (actual / total) * 100;

    if (result.value > 66) {
      result.text = this.translate("strong");
    } else if (result.value > 33 && result.value <= 66) {
      result.text = this.translate("medium");
    }

    return result;
  }

  constructor(
    source: string | WorkerProfile,
    public mode: "edit" | "read",
    public localization: ILocalizationService,
    public enums: EnumsService,
    private repository: WorkerProfileRepository,
    private skillsDetailFactory: ReturnType<typeof SkillsDetailViewModel.Factory>,
    private aboutDetailFactory: ReturnType<typeof AboutDetailViewModel.Factory>,
    private contactInformationFactory: ReturnType<typeof ContactInformationViewModel.Factory>,
    private personalQrFactory: ReturnType<typeof PersonalQrViewModel.Factory>,
    private availabilityFactory: ReturnType<typeof AvailabilityViewModel.Factory>,
    private credentialOverviewFactory: ReturnType<typeof CredentialsOverviewViewModel.Factory>,
    private eventBus: IEventBus,
    private dialog: DialogService,
    private experienceDetailFactory: ReturnType<typeof ExperienceDetailViewModel.Factory>,
    private educationDetailFactory: ReturnType<typeof EducationDetailViewModel.Factory>
  ) {
    super();

    this.name = this.translate("title");

    if (source instanceof WorkerProfile) {
      this.workerId = source.id;
      this.item = source;
    } else {
      this.workerId = source;
    }

    this.navigationName = this.workerId;

    this.eventHandlers = [
      this.eventBus.subscribe(WorkerEvents.profileUpdated, this.handleProfileUpdated),
      this.eventBus.subscribe(WorkerEvents.educationUpdated, this.loadWorkerProfile),
      this.eventBus.subscribe(WorkerEvents.experienceUpdated, this.loadWorkerProfile),
      this.eventBus.subscribe(WorkerEvents.userImageUpdated, this.loadWorkerPhoto),
      this.eventBus.subscribe(WorkerEvents.credentialCreated, this.loadCredentials),
      this.eventBus.subscribe(WorkerEvents.credentialDeleted, this.loadCredentials),
    ];
  }

  async onInitialize() {
    if (!this.item) {
      await this.loadWorkerProfile();
    }

    await this.loadCredentials();

    await this.loadWorkerPhoto();
  }

  protected async onDeactivate(isClosing: boolean) {
    await super.onDeactivate(isClosing);

    if (isClosing) {
      this.eventHandlers?.forEach(x => x.dispose());
    }
  }

  @bound
  copyLinkToClipboard() {
    const link = `${window.location.origin}/#/publicProfile/worker/${this.workerId}`;
    copyToClipboard(link);
    this.eventBus.publish(GeneralEvents.info(this.translate("linkCopied")));
  }

  @action.bound private handleProfileUpdated(event: { payload: WorkerProfile }) {
    if (event.payload && event.payload.id === this.workerId) {
      this.item = event.payload;
    }
  }

  @bound
  @watchBusy
  private async loadCredentials() {
    const result = await this.repository.loadCredentials(this.workerId);
    if (result.success) {
      runInAction(() => (this.workerCredentials = result.payload));
    }
  }

  @bound
  @watchBusy
  private async loadWorkerProfile() {
    const result = await this.repository.getProfile(this.workerId);
    if (result.success) {
      this.eventBus.publish(WorkerEvents.profileUpdated(result.payload));
    }
  }

  @bound
  @watchBusy
  async loadWorkerPhoto() {
    if (this.item.profilePhotoUrl) {
      const photoUrl = await this.repository.getWorkerPhotoObjectUrl(this.item.profilePhotoUrl);
      if (photoUrl) {
        runInAction(() => {
          this.workerImage = photoUrl;
        });
      }
    }
  }

  @bound
  @watchBusy
  async uploadWorkerPhoto(file: File) {
    const result = await this.repository.uploadWorkerPhoto(file, this.workerId);
    if (result.success) {
      await this.loadWorkerProfile();
      this.eventBus.publish(WorkerEvents.userImageUpdated(undefined));
    }
  }

  @watchBusy
  async deleteEducationItem(educationId: string) {
    const confirmationResult = await this.dialog.showConfirmation(
      this.translate("confirmBody"),
      this.translate("confirmTitleEducation"),
      this.translate("confirmOk"),
      this.translate("confirmCancel")
    );
    if (confirmationResult) {
      await this.repository.deleteEducation(this.workerId, educationId);
    }
  }

  @watchBusy
  async deleteExperienceItem(experienceId: string) {
    const confirmationResult = await this.dialog.showConfirmation(
      this.translate("confirmBody"),
      this.translate("confirmTitleExperience"),
      this.translate("confirmOk"),
      this.translate("confirmCancel")
    );
    if (confirmationResult) {
      await this.repository.deleteExperience(this.workerId, experienceId);
    }
  }

  @bound
  openScreen(navigationName: string) {
    const buildUrl = Router.getChildUrlFactory(this);
    window.location.href = buildUrl(navigationName);
  }

  @bound openEditExperienceModal(experience: WorkerProfileExperience) {
    const vm = this.experienceDetailFactory(this.item.id, experience);
    this.tryActivateChild(vm);
  }

  @bound openEditEducationModal(education: WorkerProfileEducation) {
    const vm = this.educationDetailFactory(this.item.id, education);
    this.tryActivateChild(vm);
  }

  @bound openCredentialsModal() {
    return this.navigate("credentialsListAdd", undefined);
  }

  @bound openCredentialsOverviewModal(credential: CredentialPhoto) {
    this.credential = credential;
    return this.navigate("credentialListOverview", undefined);
  }

  @bound translate(key: string) {
    return this.localization.translateGeneral(`worker.modulePage.${key}`);
  }

  @bound tm(name: string, size?: number) {
    return this.localization.translateModel(name, size);
  }

  deactivateChild(child: ScreenBase | undefined, isClosing: boolean) {
    if (isClosing) {
      if (child instanceof CredentialOverviewViewModel) {
        this.loadCredentials();
      }

      if (child instanceof CredentialsAddViewModel) {
        this.loadCredentials();
      }
    }

    return super.deactivateChild(child, isClosing);
  }

  protected findNavigationChild(navigationName: string | undefined): ScreenBase | undefined {
    if (!this.workerId || !this.item) {
      return undefined;
    }

    switch (navigationName) {
      case "qr":
        return this.personalQrFactory(this.workerId, this.fullName);
      case "availability":
        return this.availabilityFactory(this.workerId, this.item);
      case "skills":
        return this.skillsDetailFactory(this.item);
      case "about":
        return this.aboutDetailFactory(this.item);
      case "contact":
        return this.contactInformationFactory(this.workerId, this.item);
      case "addExperience":
        return this.experienceDetailFactory(this.item.id);
      case "education":
        return this.educationDetailFactory(this.item.id);
      case "credentialsListAdd":
        return this.credentialOverviewFactory(this.workerId, undefined, { mode: "edit", variant: "worker" });
      case "credentialListOverview":
        return this.credentialOverviewFactory(this.workerId, this.credential, {
          mode: this.mode === "edit" ? "overview" : "readonly",
          variant: "worker",
        });
      default:
        return undefined;
    }
  }

  static Factory({ container }: interfaces.Context) {
    return (profileId: string | WorkerProfile, mode: "edit" | "read") =>
      new WorkerProfileViewModel(
        profileId,
        mode,
        container.get("ILocalizationService"),
        container.get(EnumsService),
        container.get(WorkerProfileRepository),
        container.get(SkillsDetailViewModel.Factory),
        container.get(AboutDetailViewModel.Factory),
        container.get(ContactInformationViewModel.Factory),
        container.get(PersonalQrViewModel.Factory),
        container.get(AvailabilityViewModel.Factory),
        container.get(CredentialsOverviewViewModel.Factory),
        container.get("IEventBus"),
        container.get(DialogService),
        container.get(ExperienceDetailViewModel.Factory),
        container.get(EducationDetailViewModel.Factory)
      );
  }
}
