import { observable } from "mobx";

// This entity has been generated, do not change its content, your changes might get lost. You CAN modify the rest of the file.
export default class VerifyUser {
  /**
   * Verification token (URL link with this parameter to FE is sent over email).

   */
  @observable
  verificationToken!: string;

  static ValidationRules = {
    verificationToken: { required: true },
  };
}
