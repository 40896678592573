import { Button } from "@emanprague/veralinkkit";
import { Input } from "@frui.ts/bootstrap";
import { registerView } from "@frui.ts/views";
import React from "react";
import SendMessageViewModel from "viewModels/messages/sendMessageViewModel";
import ModalView, { ModalSize } from "views/common/modalView";

import "./sendMessage.scss";

class SendMessageModalView extends ModalView<SendMessageViewModel> {
  size: ModalSize = "lg";
  renderBody(vm: SendMessageViewModel) {
    return (
      <>
        <Input className="sendMessageTextArea" as="textarea" type="text" target={vm} property="content" />
      </>
    );
  }

  renderFooter(vm: SendMessageViewModel) {
    return (
      <Button variant="primary" disabled={!vm.content} onClick={vm.sendMessage}>
        {vm.translate("send")}
      </Button>
    );
  }
}

registerView(SendMessageModalView as any, SendMessageViewModel);
