import { registerView, ViewComponent, View } from "@frui.ts/views";
import { observer } from "mobx-react-lite";
import React from "react";
import SubModuleViewModel from "viewModels/company/companyCredentials/subModuleViewModel";
import CredentialsSectionView from "views/worker/credentials/credentialsSectionView";
import { LoaderOverlay } from "@emanprague/veralinkkit";

const subModuleView: ViewComponent<SubModuleViewModel> = observer(({ vm }) => (
  <>
    <h4 className="company__header">{vm.translate("title")}</h4>
    <LoaderOverlay variant="light" loading={vm.busyWatcher.isBusy}>
      <CredentialsSectionView scrollable={false} vm={vm} />
    </LoaderOverlay>
    <View vm={vm.activeChild} />
  </>
));

registerView(subModuleView, SubModuleViewModel);
