import React from "react";
import NewProjectViewModel from "viewModels/company/projects/newProjectViewModel";
import EditProjectViewModel from "viewModels/company/projects/editProjectViewModel";
import { Col, Form, Row } from "@emanprague/veralinkkit";
import { Input, Select } from "@frui.ts/bootstrap";
import CustomInput from "controls/input";

interface ProjectFormViewProps {
  vm: NewProjectViewModel | EditProjectViewModel;
}

const projectFormView = ({ vm }: ProjectFormViewProps) => {
  const ta = (key: string) => vm.localization.translateAttribute("companyProject", key);
  // const tg = (key: string) => vm.localization.translateGeneral(`projects.form.${key}`);

  return (
    <>
      <Form.Group>
        <Form.Label>
          <b>{ta("name")}</b>
        </Form.Label>
        <Input target={vm.item} property="name" />
      </Form.Group>
      <Form.Group>
        <Form.Label>
          <b>{ta("description")}</b>
          <span className="optional-text"> ({ta("optional")}) </span>
        </Form.Label>
        <Input as="textarea" type="text" target={vm.item} property="description" />
      </Form.Group>
      <h6>{ta("projectDuration")}</h6>
      <Row>
        <Col lg={6} md={12}>
          <Form.Group>
            <Form.Label>{ta("startDate")}</Form.Label>
            <CustomInput type="date" target={vm.item} property="startDate" />
          </Form.Group>
        </Col>
        <Col lg={6} md={12}>
          <Form.Group>
            <Form.Label>{ta("endDate")}</Form.Label>
            <CustomInput type="date" target={vm.item} property="endDate" />
          </Form.Group>
        </Col>
      </Row>
      <h6>{ta("projectLocation")}</h6>
      <Form.Group>
        <Form.Label>
          <b>{ta("city")}</b>
        </Form.Label>
        {/* FIXME after BE will be ready */}
        <Input target={vm.item.location} property="city" />
      </Form.Group>
      <Form.Group>
        <Form.Label>{ta("state")}</Form.Label>
        <Select
          mode="key"
          value={vm.item.location?.state}
          target={vm.item.location}
          property="state"
          items={vm.states}
          keyProperty="id"
          textProperty="name"
        />
      </Form.Group>
    </>
  );
};

export default projectFormView;
