import { ILocalizationService } from "@emanprague/shared-services";
import { bound } from "@frui.ts/helpers";
import { BusyWatcher, Router, ScreenBase } from "@frui.ts/screens";
import { attachAutomaticValidator, validate } from "@frui.ts/validation";
import ResetPassword from "entities/resetPassword";
import { action, observable, runInAction } from "mobx";
import LoginRepository from "repositories/loginRepository";
import LoginViewModel from "./loginViewModel";

export default class SetupPasswordViewModel extends ScreenBase {
  navigationName = "setup-password";
  busyWatcher = new BusyWatcher();
  @observable model: ResetPassword;
  @observable token: string;
  @observable errorMessage?: string;

  constructor(public localization: ILocalizationService, private repository: LoginRepository, private router: Router) {
    super();

    this.name = this.translate("title");
    this.reset();
  }

  protected onActivate() {
    this.reset();
  }

  @action
  private reset() {
    this.model = new ResetPassword();
    attachAutomaticValidator(this.model, ResetPassword.ValidationRules);
  }

  @bound
  async sendReset() {
    this.setErrorMessage();
    this.model.resetPasswordToken = this.token;
    if (validate(this.model)) {
      const result = await this.repository.confirmPasswordReset(this.model);

      if (result.success) {
        await this.router.navigate(LoginViewModel, undefined, {});
      } else {
        this.setErrorMessage(result.payload.errorDescription);
      }
    }
  }

  navigate(_subpath: any, params: any) {
    const token = params?.token;
    if (token) {
      runInAction(() => {
        this.navigationParamsValue = { token };
        this.token = token;
      });
    } else {
      this.router.navigate(LoginViewModel);
    }
  }

  @action
  setErrorMessage(message?: string) {
    this.errorMessage = message;
  }

  @bound translate(key: string) {
    return this.localization.translateGeneral(`profile.setupPassword.${key}`);
  }
}
