import { ILocalizationService } from "@emanprague/shared-services";
import { bound } from "@frui.ts/helpers";
import { BusyWatcher, ScreenBase, watchBusy } from "@frui.ts/screens";
import { attachAutomaticValidator, validate } from "@frui.ts/validation";
import WorkerProfileEducation from "entities/workerProfileEducation";
import { interfaces } from "inversify";
import { action, observable } from "mobx";
import WorkerProfileRepository from "repositories/workerProfileRepository";

export default class AddEducationViewModel extends ScreenBase {
  navigationName = "education";
  busyWatcher = new BusyWatcher();

  @observable education: WorkerProfileEducation = new WorkerProfileEducation();

  constructor(private workerId: string, public localization: ILocalizationService, private repository: WorkerProfileRepository) {
    super();

    this.name = this.translate("title");
    attachAutomaticValidator(this.education, WorkerProfileEducation.ValidationRules);
  }

  @action.bound
  @watchBusy
  async addEducationItem() {
    if (validate(this.education)) {
      const result = await this.repository.addEducation(this.workerId, this.education);
      if (result.success) {
        this.requestClose();
      }
    }
  }

  @bound translate(key: string) {
    return this.localization.translateGeneral(`worker.education.${key}`);
  }

  static Factory({ container }: interfaces.Context) {
    return (workerId: string) =>
      new AddEducationViewModel(workerId, container.get("ILocalizationService"), container.get(WorkerProfileRepository));
  }
}
