import { Button } from "@emanprague/veralinkkit";
import React from "react";
import ContractorConnectionPoints from "entities/contractorConnectionPoints";
import ContractorConnection from "entities/contractorConnection";
import ComplianceRequirementGrades from "entities/complianceRequirementGrades";
import Grade, { GradeData } from "./grade";

const makeGrade = (connection: ContractorConnection): GradeData => {
  const grade = connection.grade as ComplianceRequirementGrades;
  const allPoints = connection.points;

  if (!allPoints) {
    return {
      grade,
      maxPoints: 0,
      points: 0,
    };
  }

  const points: number[] = Object.keys(allPoints).reduce(
    (acc, key) => {
      const total = acc[0];
      const currentPoints = acc[1];
      const nxt = allPoints[key as keyof ContractorConnectionPoints] as Record<string, any>;
      if (!nxt) {
        return acc;
      }

      return [total + (nxt.totalPoints ?? 0), currentPoints + (nxt.givenPoints ?? 0)];
    },
    [0, 0]
  );

  return {
    grade,
    points: points[1],
    maxPoints: points[0],
  };
};

const mapStatus = (connection: ContractorConnection, isOperator?: boolean) => {
  if (!connection.accepted) {
    return ["new", undefined];
  } else {
    const points = connection.points ?? {};
    const pendingChecks = Object.keys(points)
      .map(key => (points as any)[key])
      .filter(elem => !!elem)
      .map(elem => {
        if (!elem.filled && !isOperator) {
          return elem;
        }

        if (elem.status === "PENDING") {
          return elem;
        }

        return undefined;
      })
      .filter(elem => !!elem);

    if (pendingChecks.length > 0) {
      return ["unfinished", pendingChecks];
    }

    return ["linked", undefined];
  }
};

type Props = {
  isOperator: boolean;
  tg: (a: string, b?: any) => string;
  connection: ContractorConnection;
  visitDetail: () => void;
};

export const ConnectionStatus: React.FC<Props> = ({ isOperator, tg, connection, visitDetail }) => {
  const gradeData = makeGrade(connection);
  const [status, checks] = mapStatus(connection, isOperator);

  if (!connection) {
    return null;
  }

  switch (status) {
    case "linked": {
      return <Grade grade={gradeData.grade} points={gradeData.points} maxPoints={gradeData.maxPoints} />;
    }
    case "new": {
      return <p>{tg("pending")}</p>;
    }
    case "unfinished": {
      return (
        <>
          <Button onClick={visitDetail}>{tg("reviewCategories", { value: checks?.length })}</Button>
          <Grade grade={gradeData.grade} points={gradeData.points} maxPoints={gradeData.maxPoints} />
        </>
      );
    }
    default:
      return null;
  }
};
