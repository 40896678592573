import React from "react";
import Input from "controls/input";
import FormCheck from "controls/formCheck";
import { observer } from "mobx-react-lite";

type Props = {
  name: string;
  id: string;
  type: string;
  readonly?: boolean;
  answer: any;
  disabled?: boolean;
  tg: (a: string) => string;
};

const OSHAFormQuestion: React.FC<Props> = observer(({ disabled, name, id, type, readonly, answer, tg }) => {
  if (readonly) {
    const response = (answer ?? {})[name];
    return <p className="auto-answer">{Number.isFinite(response) ? response : <span className="text-muted">0</span>}</p>;
  }
  switch (type) {
    case "number": {
      return (
        <Input disabled={disabled} className="metrics__input" type="number" step=".01" id={id} target={answer} property={name} />
      );
    }
    case "select": {
      return <FormCheck disabled={disabled} id={id} type="checkbox" label="Yes" target={answer} property={name} />;
    }
    default: {
      return <span>{answer[name]}</span>;
    }
  }
});

export default OSHAFormQuestion;
