import Input from "controls/input";
import React from "react";
import classnames from "classnames";
import {
  SectionWithAnswer,
  QuestionaireWithAnswer,
} from "viewModels/company/compliance/questionaire/SafetyCultureQuestionaireViewModel";
import QuestionnaireQuestionShowOnlyIf from "entities/questionnaireQuestionShowOnlyIf";
import { observer } from "mobx-react-lite";
import QuestionnaireAnswer from "entities/questionnaireAnswer";
import { CollectionCheck, Check } from "@frui.ts/bootstrap";

type Props = {
  className?: string;
  questionText: string;
  type: string;
  index: number;
  showOnlyIf?: QuestionnaireQuestionShowOnlyIf;
  tg: (a: string, b?: any) => string;
  options?: any;
  answer?: QuestionnaireAnswer;
  context: SectionWithAnswer;
  readOnly?: boolean;
};

const booleanOptions = [
  {
    id: "yes",
    text: "Yes",
  },
  { id: "no", text: "No" },
];

const checkVisibility = (context: QuestionaireWithAnswer[], conditions?: QuestionnaireQuestionShowOnlyIf) => {
  if (!conditions) {
    return true;
  }
  const dependency = context.find(({ id }) => conditions.questionId === id);
  if (dependency) {
    switch (dependency.type) {
      case "BOOLEAN":
        return dependency.answer!.booleanAnswer === conditions.answerBoolean;
      case "MULTI":
        if (conditions.answerId) {
          return dependency.answer!.multiAnswer?.includes(conditions.answerId);
        } else {
          return dependency.answer!.multiAnswer!.length > 0;
        }
      default:
        if (conditions.answerId) {
          return dependency.answer!.singleAnswer === conditions.answerId;
        } else {
          return !!dependency.answer!.singleAnswer || !!dependency.answer!.textAnswer;
        }
    }
  }

  return true;
};

const renderFieldByType = (props: {
  type: string;
  readOnly?: boolean;
  options?: any;
  answer?: QuestionnaireAnswer;
  tg: (a: string, b?: any) => string;
  index: number;
}) => {
  const { type, readOnly, options, answer, tg, index } = props;
  switch (type) {
    case "BOOLEAN": {
      return (
        <>
          {!readOnly &&
            booleanOptions.map((option: { id: string; text: string }, optionIndex: number) => (
              <Check
                key={optionIndex}
                type="radio"
                value={option.id === "yes"}
                property="booleanAnswer"
                id={`${index}-${optionIndex}-${option.id}`}
                label={option.text}
                target={answer}
              />
            ))}
          {readOnly &&
            booleanOptions.find((item: { id: string; text: string }) => item.id === (answer?.booleanAnswer ? "yes" : "no"))?.text}
        </>
      );
    }
    case "SINGLE": {
      return (
        <>
          {!readOnly &&
            options.map((option: { id: string; text: string }, optionIndex: number) => (
              <Check
                key={optionIndex}
                type="radio"
                value={option.id}
                property="singleAnswer"
                id={option.id}
                label={option.text}
                target={answer}
              />
            ))}
          {readOnly && options.find((item: { id: string; text: string }) => item.id === answer?.singleAnswer)?.text}
        </>
      );
    }
    case "MULTI": {
      return (
        <>
          {!readOnly &&
            options.map((option: { id: string; text: string }, optionIndex: number) => (
              <CollectionCheck key={optionIndex} property="multiAnswer" value={option.id} label={option.text} target={answer} />
            ))}
          {readOnly &&
            options
              .filter((item: { id: string; text: string }) => answer?.multiAnswer?.includes(item.id))
              .map((item: { id: string; text: string }) => item.text)
              .join(", ")}
        </>
      );
    }
    case "TEXT": {
      return (
        <>
          {!readOnly && <Input placeholder={tg("typeHere")} target={answer} property="textAnswer" />}
          {readOnly && answer?.textAnswer}
        </>
      );
    }
    case "NUMBER": {
      return (
        <>
          {!readOnly && <Input type="number" placeholder={tg("typeHere")} target={answer} property="numberAnswer" />}
          {readOnly && answer?.numberAnswer}
        </>
      );
    }
    case "CALCULATED": {
      return answer?.textAnswer ?? tg("noDataCalculated");
    }
    default:
      return null;
  }
};

const Question: React.FC<Props> = observer(
  ({ showOnlyIf, context, className, questionText, answer, type, options, index, tg, readOnly }) => {
    const wrapperClasses = classnames("question", {
      [className as string]: !!className,
    });

    const isVisible = checkVisibility(context.questions, showOnlyIf);
    if (!isVisible) {
      return null;
    }

    return (
      <div className={wrapperClasses}>
        <div className="question__header">
          <p className="text-muted">{tg(type === "CALCULATED" ? "questionNumberCalculated" : "questionNumber", { index })}</p>
          <b>{questionText}</b>
        </div>
        {renderFieldByType({
          type,
          readOnly,
          options,
          answer,
          tg,
          index,
        })}
      </div>
    );
  }
);

export default Question;
