import { observable } from "mobx";
import ComplianceRequirementApprovalResultStatus from "./complianceRequirementApprovalResultStatus";

// This entity has been generated, do not change its content, your changes might get lost. You CAN modify the rest of the file.
export default class ComplianceRequirementApprovalResult {
  @observable
  status!: ComplianceRequirementApprovalResultStatus;

  /**
   * Message from the Operator when he rejects the submission
   */
  @observable
  rejectedMessage?: string;

  @observable
  givenPoints?: number;

  @observable
  totalPoints?: number;

  static ValidationRules = {
    status: { required: true },
    givenPoints: { number: true },
    totalPoints: { number: true },
  };
}
