import { registerView } from "@frui.ts/views";
import React from "react";
import SafetyProgramsViewModel from "viewModels/company/compliance/questionaire/safetyProgramsViewModel";
import ModalView, { ModalSize } from "views/common/modalView";
import { Table } from "react-bootstrap";
import { Button } from "@emanprague/veralinkkit";
import "./safetyPrograms.scss";
import EmptySafety from "../components/emptySafety";
import SafetyStatus from "../components/safetyStatus";
import LoadingContainer from "views/common/loadingContainer";
import ComplianceRequirementApprovalResultStatus from "entities/complianceRequirementApprovalResultStatus";

import SafetyProgramsApproveRequest from "../components/safetyProgramsApproveRequest";
import { Input } from "@frui.ts/bootstrap";

const nonSubmittable = ["PENDING"];

class SafetyProgramsView extends ModalView<SafetyProgramsViewModel> {
  size: ModalSize = "lg";
  renderFooter(vm: SafetyProgramsViewModel) {
    if (nonSubmittable.includes(vm.status.status) || vm.safetyPolicies.length === 0) {
      return <></>;
    }
    if (!vm.isOperator) {
      return (
        <Button disabled={vm.busyWatcher.isBusy} onClick={vm.save}>
          {vm.translate("save")}
        </Button>
      );
    } else {
      return <></>;
    }
  }

  renderBody(vm: SafetyProgramsViewModel) {
    return (
      <LoadingContainer loading={vm.busyWatcher.isBusy}>
        {vm.safetyPolicies.length === 0 && !vm.busyWatcher.isBusy && <EmptySafety translate={vm.translate} />}
        {vm.safetyPolicies.length > 0 && (
          <>
            <SafetyProgramsApproveRequest
              tg={vm.translate}
              submitReview={approved => vm.submitReview(approved)}
              showButtons={vm.status.status === ComplianceRequirementApprovalResultStatus.Pending && vm.isOperator}
              textBox={
                <Input
                  as="textarea"
                  type="text"
                  target={vm}
                  property="requestMessage"
                  placeholder={vm.translate("requestMessagePlaceholder")}
                />
              }>
              <>
                <SafetyStatus status={vm.status.status} rejectedMessage={vm.status.rejectedMessage} translate={vm.translate} />
                <p className="safetyPrograms__header">{vm.translate("uploaded")}</p>
                <Table className="safetyPrograms__table" borderless responsive>
                  <thead>
                    <tr>
                      <th>{vm.translate("file")}</th>
                      <th>{vm.translate("worktype")}</th>
                      <th />
                    </tr>
                  </thead>
                  <tbody>
                    {vm.safetyPolicies.map((workType: any, index: number) => {
                      return (
                        <tr key={index}>
                          <td>{workType.attachmentName}</td>
                          <td>{workType.workType.name}</td>
                          <td>
                            <a
                              rel="noopener noreferrer"
                              href={workType.attachmentUrl}
                              download={workType.atatchmentName}
                              target="_blank">
                              {vm.translate("download")}
                            </a>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              </>
            </SafetyProgramsApproveRequest>
            {!vm.isOperator && (
              <p className="safetyPrograms__footer">
                {vm.translate("edit")}
                <a href="#/dashboard/companyInfo/profile">{vm.translate("info")}</a>
                {vm.translate("page")}
              </p>
            )}
          </>
        )}
      </LoadingContainer>
    );
  }
}

registerView(SafetyProgramsView, SafetyProgramsViewModel);
