import { registerView } from "@frui.ts/views";
import React from "react";
import InsuranceEditViewModel from "viewModels/company/compliance/insuranceEditViewModel";
import ModalView from "views/common/modalView";
import { Row, Col, Button } from "@emanprague/veralinkkit";
import FileUpload from "controls/fileUpload";
import "./compliance.scss";

class InsuranceEditView extends ModalView<InsuranceEditViewModel> {
  renderFooter(vm: InsuranceEditViewModel) {
    return (
      <Row className="justify-content-end">
        <Button variant="primary" disabled={vm.busyWatcher.isBusy} onClick={vm.save}>
          {vm.translate("save")}
        </Button>
      </Row>
    );
  }

  renderBody(vm: InsuranceEditViewModel) {
    return (
      <>
        <h5 className="insurance__subtitle">{vm.translate("subtitle")}</h5>
        <Row>
          <Col>
            <FileUpload
              accept={[".pdf"]}
              onDrop={(files: File[]) => {
                vm.replaceFile(files as any[]);
              }}
              localization={{
                button: vm.translate("upload"),
                or: vm.translate("dragFile"),
                types: vm.translate("fileValidation"),
              }}
              files={vm.fileList}
              onRemove={vm.removeFile}
            />
          </Col>
        </Row>
      </>
    );
  }
}

registerView(InsuranceEditView, InsuranceEditViewModel);
