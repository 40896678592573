// WARNING: This file has been generated. Do not edit it manually, your changes might get lost.
import { Container, interfaces } from "inversify";
import entitiesCompanyService from "./entities/companyService";
import repositoriesCompanyCredentialsRepository from "./repositories/companyCredentialsRepository";
import repositoriesCompanyProfileRepository from "./repositories/companyProfileRepository";
import repositoriesComplianceRepository from "./repositories/complianceRepository";
import repositoriesConnectionsRepository from "./repositories/connectionsRepository";
import repositoriesContractorPortalRepository from "./repositories/contractorPortalRepository";
import repositoriesEmployeeRepository from "./repositories/employeeRepository";
import repositoriesEnumsRepository from "./repositories/enumsRepository";
import repositoriesLoginRepository from "./repositories/loginRepository";
import repositoriesMessagesRepository from "./repositories/messagesRepository";
import repositoriesNotificationsRepository from "./repositories/notificationsRepository";
import repositoriesProjectRepository from "./repositories/projectRepository";
import repositoriesSignupRepository from "./repositories/signupRepository";
import repositoriesWorkerPortalRepository from "./repositories/workerPortalRepository";
import repositoriesWorkerProfileRepository from "./repositories/workerProfileRepository";
import servicesDialogService from "./services/dialogService";
import servicesEnumsService from "./services/enumsService";
import { EventsService as servicesEventsEventsService } from "./services/events";
import servicesInitializationService from "./services/initializationService";
import servicesLocaleProvider from "./services/localeProvider";
import servicesNotificationService from "./services/notificationService";
import servicesSecurityService from "./services/securityService";
import servicesStorageProvider from "./services/storageProvider";
import servicesValidationValidationService from "./services/validation/validationService";
import servicesWorkerImportService from "./services/workerImportService";
import viewModelsAnonymousRootViewModel from "./viewModels/anonymousRootViewModel";
import viewModelsCompanyCompanyCredentialsSubModuleViewModel from "./viewModels/company/companyCredentials/subModuleViewModel";
import viewModelsCompanyCompanyDashboardSubModuleViewModel from "./viewModels/company/companyDashboard/subModuleViewModel";
import viewModelsCompanyCompanyInfoCompanyProfileViewModel from "./viewModels/company/companyInfo/companyProfileViewModel";
import viewModelsCompanyCompanyInfoModalsAddSafetyPolicyViewModel from "./viewModels/company/companyInfo/modals/addSafetyPolicyViewModel";
import viewModelsCompanyCompanyInfoModalsCompanyContactsViewModel from "./viewModels/company/companyInfo/modals/companyContactsViewModel";
import viewModelsCompanyCompanyInfoModalsCompanyInfoViewModel from "./viewModels/company/companyInfo/modals/companyInfoViewModel";
import viewModelsCompanyCompanyInfoModalsCompanyLocationsViewModel from "./viewModels/company/companyInfo/modals/companyLocationsViewModel";
import viewModelsCompanyCompanyInfoModalsCompanyServicesViewModel from "./viewModels/company/companyInfo/modals/companyServicesViewModel";
import viewModelsCompanyCompanyInfoModalsCompleteCompanyProfileViewModel from "./viewModels/company/companyInfo/modals/completeCompanyProfileViewModel";
import viewModelsCompanyCompanyInfoSubModuleViewModel from "./viewModels/company/companyInfo/subModuleViewModel";
import viewModelsCompanyComplianceExperienceModifierViewModel from "./viewModels/company/compliance/experienceModifierViewModel";
import viewModelsCompanyComplianceInsuranceEditViewModel from "./viewModels/company/compliance/insuranceEditViewModel";
import viewModelsCompanyCompliancePointsAllocationEditViewModel from "./viewModels/company/compliance/pointsAllocationEditViewModel";
import viewModelsCompanyComplianceQuestionaireExperienceModifierViewModel from "./viewModels/company/compliance/questionaire/experienceModifierViewModel";
import viewModelsCompanyComplianceQuestionaireInsuranceUploadModalViewModel from "./viewModels/company/compliance/questionaire/insuranceUploadModalViewModel";
import viewModelsCompanyComplianceQuestionaireSafetyCultureQuestionaireViewModel from "./viewModels/company/compliance/questionaire/SafetyCultureQuestionaireViewModel";
import viewModelsCompanyComplianceQuestionaireSafetyMetricsQuestionnaireViewModel from "./viewModels/company/compliance/questionaire/safetyMetricsQuestionnaireViewModel";
import viewModelsCompanyComplianceQuestionaireSafetyProgramsViewModel from "./viewModels/company/compliance/questionaire/safetyProgramsViewModel";
import viewModelsCompanyComplianceQuestionaireWorkHoursQuestionnaireViewModel from "./viewModels/company/compliance/questionaire/workHoursQuestionnaireViewModel";
import viewModelsCompanyComplianceSafetyIndicatorsViewModel from "./viewModels/company/compliance/safetyIndicatorsViewModel";
import viewModelsCompanyComplianceSafetyProgramsEditViewModel from "./viewModels/company/compliance/safetyProgramsEditViewModel";
import viewModelsCompanyComplianceSubModuleViewModel from "./viewModels/company/compliance/subModuleViewModel";
import viewModelsCompanyComplianceThresholdEditModalViewModel from "./viewModels/company/compliance/thresholdEditModalViewModel";
import viewModelsCompanyCredentialsExportCredentialsViewModel from "./viewModels/company/credentials/exportCredentialsViewModel";
import viewModelsCompanyCredentialsImportCredentialsViewModel from "./viewModels/company/credentials/importCredentialsViewModel";
import viewModelsCompanyCredentialsSubModuleViewModel from "./viewModels/company/credentials/subModuleViewModel";
import viewModelsCompanyEmployeesActiveEmployeesViewModel from "./viewModels/company/employees/activeEmployeesViewModel";
import viewModelsCompanyEmployeesExportWorkersViewModel from "./viewModels/company/employees/exportWorkersViewModel";
import viewModelsCompanyEmployeesImportWorkersViewModel from "./viewModels/company/employees/importWorkersViewModel";
import viewModelsCompanyEmployeesPendingEmployeesViewModel from "./viewModels/company/employees/pendingEmployeesViewModel";
import viewModelsCompanyEmployeesRemoveWorkerViewModel from "./viewModels/company/employees/removeWorkerViewModel";
import viewModelsCompanyEmployeesSubModuleViewModel from "./viewModels/company/employees/subModuleViewModel";
import viewModelsCompanyModulePageViewModel from "./viewModels/company/modulePageViewModel";
import viewModelsCompanyMyConnectionsConnectionDetailViewModel from "./viewModels/company/myConnections/connectionDetailViewModel";
import viewModelsCompanyMyConnectionsSubModuleViewModel from "./viewModels/company/myConnections/subModuleViewModel";
import viewModelsCompanyProjectsDocumentsDocumentsListViewModel from "./viewModels/company/projects/documents/documentsListViewModel";
import viewModelsCompanyProjectsDocumentsUploadDocumentViewModel from "./viewModels/company/projects/documents/uploadDocumentViewModel";
import viewModelsCompanyProjectsEditProjectViewModel from "./viewModels/company/projects/editProjectViewModel";
import viewModelsCompanyProjectsNewProjectViewModel from "./viewModels/company/projects/newProjectViewModel";
import viewModelsCompanyProjectsProjectDetailViewModel from "./viewModels/company/projects/projectDetailViewModel";
import viewModelsCompanyProjectsSubModuleViewModel from "./viewModels/company/projects/subModuleViewModel";
import viewModelsCompanyProjectsTeamsAddWorkersViewModel from "./viewModels/company/projects/teams/addWorkersViewModel";
import viewModelsCompanyProjectsTeamsEditTeamViewModel from "./viewModels/company/projects/teams/editTeamViewModel";
import viewModelsCompanyProjectsTeamsFormTeamViewModel from "./viewModels/company/projects/teams/formTeamViewModel";
import viewModelsCompanyProjectsTeamsNewTeamViewModel from "./viewModels/company/projects/teams/newTeamViewModel";
import viewModelsCompanyProjectsTeamsTeamsListViewModel from "./viewModels/company/projects/teams/teamsListViewModel";
import viewModelsConfirmDialogViewModel from "./viewModels/confirmDialogViewModel";
import viewModelsContractorPortalModulePageViewModel from "./viewModels/contractorPortal/modulePageViewModel";
import viewModelsJoinJoinViewModel from "./viewModels/join/joinViewModel";
import viewModelsMessagesDetailMessageViewModel from "./viewModels/messages/detailMessageViewModel";
import viewModelsMessagesModulePageViewModel from "./viewModels/messages/modulePageViewModel";
import viewModelsMessagesNotifyMessagesViewModel from "./viewModels/messages/notifyMessagesViewModel";
import viewModelsMessagesSendMessageViewModel from "./viewModels/messages/sendMessageViewModel";
import viewModelsNotificationsNotificationItemsConnectionRequestViewModel from "./viewModels/notifications/notificationItems/connectionRequestViewModel";
import viewModelsNotificationsNotificationItemsCredentialExpireViewModel from "./viewModels/notifications/notificationItems/credentialExpireViewModel";
import viewModelsNotificationsNotificationItemsNotificationItemViewModel from "./viewModels/notifications/notificationItems/notificationItemViewModel";
import viewModelsNotificationsNotificationItemsProjectViewModel from "./viewModels/notifications/notificationItems/projectViewModel";
import viewModelsNotificationsNotificationItemsWorkerJoinedViewModel from "./viewModels/notifications/notificationItems/workerJoinedViewModel";
import viewModelsNotificationsNotificationsViewModel from "./viewModels/notifications/notificationsViewModel";
import viewModelsProfileAccountSettingsAccountSettingsListViewModel from "./viewModels/profile/accountSettings/accountSettingsListViewModel";
import viewModelsProfileAccountSettingsAccountSettingsViewModel from "./viewModels/profile/accountSettings/accountSettingsViewModel";
import viewModelsProfileAccountSettingsEmailConfirmedViewModel from "./viewModels/profile/accountSettings/emailConfirmedViewModel";
import viewModelsProfileAccountSettingsSuccessEmailChangeViewModel from "./viewModels/profile/accountSettings/successEmailChangeViewModel";
import viewModelsProfileChangePasswordViewModel from "./viewModels/profile/changePasswordViewModel";
import viewModelsProfileForgotPasswordViewModel from "./viewModels/profile/forgotPasswordViewModel";
import viewModelsProfileLoginViewModel from "./viewModels/profile/loginViewModel";
import viewModelsProfileSetupPasswordViewModel from "./viewModels/profile/setupPasswordViewModel";
import viewModelsProfileStatusViewModel from "./viewModels/profile/statusViewModel";
import viewModelsPublicProfileViewModel from "./viewModels/public/profileViewModel";
import viewModelsRootViewModel from "./viewModels/rootViewModel";
import viewModelsSignupCompanyFormViewModel from "./viewModels/signup/companyFormViewModel";
import viewModelsSignupConfirmViewModel from "./viewModels/signup/confirmViewModel";
import viewModelsSignupSelectRoleViewModel from "./viewModels/signup/selectRoleViewModel";
import viewModelsSignupSignupRootViewModel from "./viewModels/signup/signupRootViewModel";
import viewModelsSignupSuccessEmailSentViewModel from "./viewModels/signup/successEmailSentViewModel";
import viewModelsSignupWorkerFormViewModel from "./viewModels/signup/workerFormViewModel";
import viewModelsToastViewModel from "./viewModels/toastViewModel";
import viewModelsWorkerCredentialsCredentialOverviewViewModel from "./viewModels/worker/credentials/credentialOverviewViewModel";
import viewModelsWorkerCredentialsCredentialsAddViewModel from "./viewModels/worker/credentials/credentialsAddViewModel";
import viewModelsWorkerCredentialsCredentialsOverviewViewModel from "./viewModels/worker/credentials/credentialsOverviewViewModel";
import viewModelsWorkerModalsAboutDetailViewModel from "./viewModels/worker/modals/aboutDetailViewModel";
import viewModelsWorkerModalsAddEducationViewModel from "./viewModels/worker/modals/addEducationViewModel";
import viewModelsWorkerModalsAvailabilityViewModel from "./viewModels/worker/modals/availabilityViewModel";
import viewModelsWorkerModalsCompleteWorkerProfileViewModel from "./viewModels/worker/modals/completeWorkerProfileViewModel";
import viewModelsWorkerModalsContactInformationViewModel from "./viewModels/worker/modals/contactInformationViewModel";
import viewModelsWorkerModalsEducationDetailViewModel from "./viewModels/worker/modals/educationDetailViewModel";
import viewModelsWorkerModalsExperienceDetailViewModel from "./viewModels/worker/modals/experienceDetailViewModel";
import viewModelsWorkerModalsPersonalQrViewModel from "./viewModels/worker/modals/personalQrViewModel";
import viewModelsWorkerModalsSkillsDetailViewModel from "./viewModels/worker/modals/skillsDetailViewModel";
import viewModelsWorkerModulePageViewModel from "./viewModels/worker/modulePageViewModel";
import viewModelsWorkerWorkerProfileViewModel from "./viewModels/worker/workerProfileViewModel";
import viewModelsWorkerImportViewModel from "./viewModels/workerImportViewModel";
import viewModelsWorkerPortalModulePageViewModel from "./viewModels/workerPortal/modulePageViewModel";

export default function registerServices(container: Container) {
  container.bind<entitiesCompanyService>(entitiesCompanyService).toSelf().inSingletonScope();
  container.bind<repositoriesCompanyCredentialsRepository>(repositoriesCompanyCredentialsRepository).toSelf();
  container.bind<repositoriesCompanyProfileRepository>(repositoriesCompanyProfileRepository).toSelf();
  container.bind<repositoriesComplianceRepository>(repositoriesComplianceRepository).toSelf();
  container.bind<repositoriesConnectionsRepository>(repositoriesConnectionsRepository).toSelf();
  container.bind<repositoriesContractorPortalRepository>(repositoriesContractorPortalRepository).toSelf();
  container.bind<repositoriesEmployeeRepository>(repositoriesEmployeeRepository).toSelf();
  container.bind<repositoriesEnumsRepository>(repositoriesEnumsRepository).toSelf();
  container.bind<repositoriesLoginRepository>(repositoriesLoginRepository).toSelf();
  container.bind<repositoriesMessagesRepository>(repositoriesMessagesRepository).toSelf();
  container.bind<repositoriesNotificationsRepository>(repositoriesNotificationsRepository).toSelf();
  container.bind<repositoriesProjectRepository>(repositoriesProjectRepository).toSelf();
  container.bind<repositoriesSignupRepository>(repositoriesSignupRepository).toSelf();
  container.bind<repositoriesWorkerPortalRepository>(repositoriesWorkerPortalRepository).toSelf();
  container.bind<repositoriesWorkerProfileRepository>(repositoriesWorkerProfileRepository).toSelf();
  container.bind<servicesDialogService>(servicesDialogService).toSelf().inSingletonScope();
  container.bind<servicesEnumsService>(servicesEnumsService).toSelf().inSingletonScope();
  container.bind<servicesEventsEventsService>("IEventBus").to(servicesEventsEventsService).inSingletonScope();
  container.bind<servicesInitializationService>("IInitializationService").to(servicesInitializationService).inSingletonScope();
  container.bind<servicesLocaleProvider>("ILocaleProvider").to(servicesLocaleProvider).inSingletonScope();
  container.bind<servicesNotificationService>("INotificationService").to(servicesNotificationService).inSingletonScope();
  container.bind<servicesSecurityService>(servicesSecurityService).toSelf().inSingletonScope();
  container.bind<servicesStorageProvider>(servicesStorageProvider).toSelf();
  container.bind<servicesWorkerImportService>(servicesWorkerImportService).toSelf().inSingletonScope();
  container.bind<viewModelsAnonymousRootViewModel>(viewModelsAnonymousRootViewModel).toSelf();
  container.bind("Factory<AnonymousRootViewModel>").toAutoFactory(viewModelsAnonymousRootViewModel);
  container.bind<viewModelsConfirmDialogViewModel>(viewModelsConfirmDialogViewModel).toSelf();
  container.bind("Factory<ConfirmDialogViewModel>").toAutoFactory(viewModelsConfirmDialogViewModel);
  container.bind<viewModelsRootViewModel>(viewModelsRootViewModel).toSelf();
  container.bind("Factory<RootViewModel>").toAutoFactory(viewModelsRootViewModel);
  container.bind<viewModelsToastViewModel>(viewModelsToastViewModel).toSelf();
  container.bind("Factory<ToastViewModel>").toAutoFactory(viewModelsToastViewModel);
  container.bind<viewModelsWorkerImportViewModel>(viewModelsWorkerImportViewModel).toSelf();
  container.bind("Factory<WorkerImportViewModel>").toAutoFactory(viewModelsWorkerImportViewModel);
  container.bind<servicesValidationValidationService>(servicesValidationValidationService).toSelf().inSingletonScope();
  container.bind<viewModelsCompanyModulePageViewModel>("IModule").to(viewModelsCompanyModulePageViewModel);
  container.bind<viewModelsContractorPortalModulePageViewModel>("IModule").to(viewModelsContractorPortalModulePageViewModel);
  container.bind<viewModelsJoinJoinViewModel>(viewModelsJoinJoinViewModel).toSelf();
  container.bind("Factory<JoinViewModel>").toAutoFactory(viewModelsJoinJoinViewModel);
  container
    .bind<interfaces.Factory<viewModelsMessagesDetailMessageViewModel>>(viewModelsMessagesDetailMessageViewModel.Factory)
    .toFactory(viewModelsMessagesDetailMessageViewModel.Factory);
  container.bind<viewModelsMessagesModulePageViewModel>("IModule").to(viewModelsMessagesModulePageViewModel);
  container.bind<viewModelsMessagesNotifyMessagesViewModel>(viewModelsMessagesNotifyMessagesViewModel).toSelf();
  container.bind("Factory<NotifyMessagesViewModel>").toAutoFactory(viewModelsMessagesNotifyMessagesViewModel);
  container
    .bind<interfaces.Factory<viewModelsMessagesSendMessageViewModel>>(viewModelsMessagesSendMessageViewModel.Factory)
    .toFactory(viewModelsMessagesSendMessageViewModel.Factory);
  container.bind<viewModelsNotificationsNotificationsViewModel>(viewModelsNotificationsNotificationsViewModel).toSelf();
  container.bind("Factory<NotificationsViewModel>").toAutoFactory(viewModelsNotificationsNotificationsViewModel);
  container.bind<viewModelsProfileChangePasswordViewModel>(viewModelsProfileChangePasswordViewModel).toSelf();
  container.bind("Factory<ChangePasswordViewModel>").toAutoFactory(viewModelsProfileChangePasswordViewModel);
  container.bind<viewModelsProfileForgotPasswordViewModel>(viewModelsProfileForgotPasswordViewModel).toSelf();
  container.bind("Factory<ForgotPasswordViewModel>").toAutoFactory(viewModelsProfileForgotPasswordViewModel);
  container.bind<viewModelsProfileLoginViewModel>(viewModelsProfileLoginViewModel).toSelf();
  container.bind("Factory<LoginViewModel>").toAutoFactory(viewModelsProfileLoginViewModel);
  container.bind<viewModelsProfileSetupPasswordViewModel>(viewModelsProfileSetupPasswordViewModel).toSelf();
  container.bind("Factory<SetupPasswordViewModel>").toAutoFactory(viewModelsProfileSetupPasswordViewModel);
  container.bind<viewModelsProfileStatusViewModel>(viewModelsProfileStatusViewModel).toSelf();
  container.bind("Factory<StatusViewModel>").toAutoFactory(viewModelsProfileStatusViewModel);
  container.bind<viewModelsPublicProfileViewModel>(viewModelsPublicProfileViewModel).toSelf();
  container.bind("Factory<ProfileViewModel>").toAutoFactory(viewModelsPublicProfileViewModel);
  container.bind<viewModelsSignupCompanyFormViewModel>(viewModelsSignupCompanyFormViewModel).toSelf();
  container.bind("Factory<CompanyFormViewModel>").toAutoFactory(viewModelsSignupCompanyFormViewModel);
  container.bind<viewModelsSignupConfirmViewModel>(viewModelsSignupConfirmViewModel).toSelf();
  container.bind("Factory<ConfirmViewModel>").toAutoFactory(viewModelsSignupConfirmViewModel);
  container.bind<viewModelsSignupSelectRoleViewModel>(viewModelsSignupSelectRoleViewModel).toSelf();
  container.bind("Factory<SelectRoleViewModel>").toAutoFactory(viewModelsSignupSelectRoleViewModel);
  container.bind<viewModelsSignupSignupRootViewModel>(viewModelsSignupSignupRootViewModel).toSelf();
  container.bind("Factory<SignupRootViewModel>").toAutoFactory(viewModelsSignupSignupRootViewModel);
  container.bind<viewModelsSignupSuccessEmailSentViewModel>(viewModelsSignupSuccessEmailSentViewModel).toSelf();
  container.bind("Factory<SuccessEmailSentViewModel>").toAutoFactory(viewModelsSignupSuccessEmailSentViewModel);
  container.bind<viewModelsSignupWorkerFormViewModel>(viewModelsSignupWorkerFormViewModel).toSelf();
  container.bind("Factory<WorkerFormViewModel>").toAutoFactory(viewModelsSignupWorkerFormViewModel);
  container.bind<viewModelsWorkerModulePageViewModel>("IModule").to(viewModelsWorkerModulePageViewModel);
  container
    .bind<interfaces.Factory<viewModelsWorkerWorkerProfileViewModel>>(viewModelsWorkerWorkerProfileViewModel.Factory)
    .toFactory(viewModelsWorkerWorkerProfileViewModel.Factory);
  container.bind<viewModelsWorkerPortalModulePageViewModel>("IModule").to(viewModelsWorkerPortalModulePageViewModel);
  container.bind<viewModelsCompanyCompanyCredentialsSubModuleViewModel>("ISubModule").to(viewModelsCompanyCompanyCredentialsSubModuleViewModel);
  container.bind<viewModelsCompanyCompanyDashboardSubModuleViewModel>("ISubModule").to(viewModelsCompanyCompanyDashboardSubModuleViewModel);
  container
    .bind<interfaces.Factory<viewModelsCompanyCompanyInfoCompanyProfileViewModel>>(viewModelsCompanyCompanyInfoCompanyProfileViewModel.Factory)
    .toFactory(viewModelsCompanyCompanyInfoCompanyProfileViewModel.Factory);
  container.bind<viewModelsCompanyCompanyInfoSubModuleViewModel>("ISubModule").to(viewModelsCompanyCompanyInfoSubModuleViewModel);
  container
    .bind<interfaces.Factory<viewModelsCompanyComplianceExperienceModifierViewModel>>(viewModelsCompanyComplianceExperienceModifierViewModel.Factory)
    .toFactory(viewModelsCompanyComplianceExperienceModifierViewModel.Factory);
  container
    .bind<interfaces.Factory<viewModelsCompanyComplianceInsuranceEditViewModel>>(viewModelsCompanyComplianceInsuranceEditViewModel.Factory)
    .toFactory(viewModelsCompanyComplianceInsuranceEditViewModel.Factory);
  container
    .bind<interfaces.Factory<viewModelsCompanyCompliancePointsAllocationEditViewModel>>(viewModelsCompanyCompliancePointsAllocationEditViewModel.Factory)
    .toFactory(viewModelsCompanyCompliancePointsAllocationEditViewModel.Factory);
  container
    .bind<interfaces.Factory<viewModelsCompanyComplianceSafetyIndicatorsViewModel>>(viewModelsCompanyComplianceSafetyIndicatorsViewModel.Factory)
    .toFactory(viewModelsCompanyComplianceSafetyIndicatorsViewModel.Factory);
  container
    .bind<interfaces.Factory<viewModelsCompanyComplianceSafetyProgramsEditViewModel>>(viewModelsCompanyComplianceSafetyProgramsEditViewModel.Factory)
    .toFactory(viewModelsCompanyComplianceSafetyProgramsEditViewModel.Factory);
  container.bind<viewModelsCompanyComplianceSubModuleViewModel>("ISubModule").to(viewModelsCompanyComplianceSubModuleViewModel);
  container
    .bind<interfaces.Factory<viewModelsCompanyComplianceThresholdEditModalViewModel>>(viewModelsCompanyComplianceThresholdEditModalViewModel.Factory)
    .toFactory(viewModelsCompanyComplianceThresholdEditModalViewModel.Factory);
  container
    .bind<interfaces.Factory<viewModelsCompanyCredentialsExportCredentialsViewModel>>(viewModelsCompanyCredentialsExportCredentialsViewModel.Factory)
    .toFactory(viewModelsCompanyCredentialsExportCredentialsViewModel.Factory);
  container
    .bind<interfaces.Factory<viewModelsCompanyCredentialsImportCredentialsViewModel>>(viewModelsCompanyCredentialsImportCredentialsViewModel.Factory)
    .toFactory(viewModelsCompanyCredentialsImportCredentialsViewModel.Factory);
  container.bind<viewModelsCompanyCredentialsSubModuleViewModel>("ISubModule").to(viewModelsCompanyCredentialsSubModuleViewModel);
  container
    .bind<interfaces.Factory<viewModelsCompanyEmployeesActiveEmployeesViewModel>>(viewModelsCompanyEmployeesActiveEmployeesViewModel.Factory)
    .toFactory(viewModelsCompanyEmployeesActiveEmployeesViewModel.Factory);
  container
    .bind<interfaces.Factory<viewModelsCompanyEmployeesExportWorkersViewModel>>(viewModelsCompanyEmployeesExportWorkersViewModel.Factory)
    .toFactory(viewModelsCompanyEmployeesExportWorkersViewModel.Factory);
  container
    .bind<interfaces.Factory<viewModelsCompanyEmployeesImportWorkersViewModel>>(viewModelsCompanyEmployeesImportWorkersViewModel.Factory)
    .toFactory(viewModelsCompanyEmployeesImportWorkersViewModel.Factory);
  container
    .bind<interfaces.Factory<viewModelsCompanyEmployeesPendingEmployeesViewModel>>(viewModelsCompanyEmployeesPendingEmployeesViewModel.Factory)
    .toFactory(viewModelsCompanyEmployeesPendingEmployeesViewModel.Factory);
  container
    .bind<interfaces.Factory<viewModelsCompanyEmployeesRemoveWorkerViewModel>>(viewModelsCompanyEmployeesRemoveWorkerViewModel.Factory)
    .toFactory(viewModelsCompanyEmployeesRemoveWorkerViewModel.Factory);
  container.bind<viewModelsCompanyEmployeesSubModuleViewModel>("ISubModule").to(viewModelsCompanyEmployeesSubModuleViewModel);
  container
    .bind<interfaces.Factory<viewModelsCompanyMyConnectionsConnectionDetailViewModel>>(viewModelsCompanyMyConnectionsConnectionDetailViewModel.Factory)
    .toFactory(viewModelsCompanyMyConnectionsConnectionDetailViewModel.Factory);
  container.bind<viewModelsCompanyMyConnectionsSubModuleViewModel>("ISubModule").to(viewModelsCompanyMyConnectionsSubModuleViewModel);
  container
    .bind<interfaces.Factory<viewModelsCompanyProjectsEditProjectViewModel>>(viewModelsCompanyProjectsEditProjectViewModel.Factory)
    .toFactory(viewModelsCompanyProjectsEditProjectViewModel.Factory);
  container
    .bind<interfaces.Factory<viewModelsCompanyProjectsNewProjectViewModel>>(viewModelsCompanyProjectsNewProjectViewModel.Factory)
    .toFactory(viewModelsCompanyProjectsNewProjectViewModel.Factory);
  container
    .bind<interfaces.Factory<viewModelsCompanyProjectsProjectDetailViewModel>>(viewModelsCompanyProjectsProjectDetailViewModel.Factory)
    .toFactory(viewModelsCompanyProjectsProjectDetailViewModel.Factory);
  container.bind<viewModelsCompanyProjectsSubModuleViewModel>("ISubModule").to(viewModelsCompanyProjectsSubModuleViewModel);
  container
    .bind<interfaces.Factory<viewModelsNotificationsNotificationItemsConnectionRequestViewModel>>(viewModelsNotificationsNotificationItemsConnectionRequestViewModel.Factory)
    .toFactory(viewModelsNotificationsNotificationItemsConnectionRequestViewModel.Factory);
  container
    .bind<interfaces.Factory<viewModelsNotificationsNotificationItemsCredentialExpireViewModel>>(viewModelsNotificationsNotificationItemsCredentialExpireViewModel.Factory)
    .toFactory(viewModelsNotificationsNotificationItemsCredentialExpireViewModel.Factory);
  container
    .bind<interfaces.Factory<viewModelsNotificationsNotificationItemsNotificationItemViewModel>>(viewModelsNotificationsNotificationItemsNotificationItemViewModel.Factory)
    .toFactory(viewModelsNotificationsNotificationItemsNotificationItemViewModel.Factory);
  container
    .bind<interfaces.Factory<viewModelsNotificationsNotificationItemsProjectViewModel>>(viewModelsNotificationsNotificationItemsProjectViewModel.Factory)
    .toFactory(viewModelsNotificationsNotificationItemsProjectViewModel.Factory);
  container
    .bind<interfaces.Factory<viewModelsNotificationsNotificationItemsWorkerJoinedViewModel>>(viewModelsNotificationsNotificationItemsWorkerJoinedViewModel.Factory)
    .toFactory(viewModelsNotificationsNotificationItemsWorkerJoinedViewModel.Factory);
  container.bind<viewModelsProfileAccountSettingsAccountSettingsListViewModel>(viewModelsProfileAccountSettingsAccountSettingsListViewModel).toSelf();
  container.bind("Factory<AccountSettingsListViewModel>").toAutoFactory(viewModelsProfileAccountSettingsAccountSettingsListViewModel);
  container.bind<viewModelsProfileAccountSettingsAccountSettingsViewModel>(viewModelsProfileAccountSettingsAccountSettingsViewModel).toSelf();
  container.bind("Factory<AccountSettingsViewModel>").toAutoFactory(viewModelsProfileAccountSettingsAccountSettingsViewModel);
  container.bind<viewModelsProfileAccountSettingsEmailConfirmedViewModel>(viewModelsProfileAccountSettingsEmailConfirmedViewModel).toSelf();
  container.bind("Factory<EmailConfirmedViewModel>").toAutoFactory(viewModelsProfileAccountSettingsEmailConfirmedViewModel);
  container.bind<viewModelsProfileAccountSettingsSuccessEmailChangeViewModel>(viewModelsProfileAccountSettingsSuccessEmailChangeViewModel).toSelf();
  container.bind("Factory<SuccessEmailChangeViewModel>").toAutoFactory(viewModelsProfileAccountSettingsSuccessEmailChangeViewModel);
  container
    .bind<interfaces.Factory<viewModelsWorkerCredentialsCredentialOverviewViewModel>>(viewModelsWorkerCredentialsCredentialOverviewViewModel.Factory)
    .toFactory(viewModelsWorkerCredentialsCredentialOverviewViewModel.Factory);
  container
    .bind<interfaces.Factory<viewModelsWorkerCredentialsCredentialsAddViewModel>>(viewModelsWorkerCredentialsCredentialsAddViewModel.Factory)
    .toFactory(viewModelsWorkerCredentialsCredentialsAddViewModel.Factory);
  container
    .bind<interfaces.Factory<viewModelsWorkerCredentialsCredentialsOverviewViewModel>>(viewModelsWorkerCredentialsCredentialsOverviewViewModel.Factory)
    .toFactory(viewModelsWorkerCredentialsCredentialsOverviewViewModel.Factory);
  container
    .bind<interfaces.Factory<viewModelsWorkerModalsAboutDetailViewModel>>(viewModelsWorkerModalsAboutDetailViewModel.Factory)
    .toFactory(viewModelsWorkerModalsAboutDetailViewModel.Factory);
  container
    .bind<interfaces.Factory<viewModelsWorkerModalsAddEducationViewModel>>(viewModelsWorkerModalsAddEducationViewModel.Factory)
    .toFactory(viewModelsWorkerModalsAddEducationViewModel.Factory);
  container
    .bind<interfaces.Factory<viewModelsWorkerModalsAvailabilityViewModel>>(viewModelsWorkerModalsAvailabilityViewModel.Factory)
    .toFactory(viewModelsWorkerModalsAvailabilityViewModel.Factory);
  container.bind<viewModelsWorkerModalsCompleteWorkerProfileViewModel>(viewModelsWorkerModalsCompleteWorkerProfileViewModel).toSelf();
  container.bind("Factory<CompleteWorkerProfileViewModel>").toAutoFactory(viewModelsWorkerModalsCompleteWorkerProfileViewModel);
  container
    .bind<interfaces.Factory<viewModelsWorkerModalsContactInformationViewModel>>(viewModelsWorkerModalsContactInformationViewModel.Factory)
    .toFactory(viewModelsWorkerModalsContactInformationViewModel.Factory);
  container
    .bind<interfaces.Factory<viewModelsWorkerModalsEducationDetailViewModel>>(viewModelsWorkerModalsEducationDetailViewModel.Factory)
    .toFactory(viewModelsWorkerModalsEducationDetailViewModel.Factory);
  container
    .bind<interfaces.Factory<viewModelsWorkerModalsExperienceDetailViewModel>>(viewModelsWorkerModalsExperienceDetailViewModel.Factory)
    .toFactory(viewModelsWorkerModalsExperienceDetailViewModel.Factory);
  container
    .bind<interfaces.Factory<viewModelsWorkerModalsPersonalQrViewModel>>(viewModelsWorkerModalsPersonalQrViewModel.Factory)
    .toFactory(viewModelsWorkerModalsPersonalQrViewModel.Factory);
  container
    .bind<interfaces.Factory<viewModelsWorkerModalsSkillsDetailViewModel>>(viewModelsWorkerModalsSkillsDetailViewModel.Factory)
    .toFactory(viewModelsWorkerModalsSkillsDetailViewModel.Factory);
  container
    .bind<interfaces.Factory<viewModelsCompanyCompanyInfoModalsAddSafetyPolicyViewModel>>(viewModelsCompanyCompanyInfoModalsAddSafetyPolicyViewModel.Factory)
    .toFactory(viewModelsCompanyCompanyInfoModalsAddSafetyPolicyViewModel.Factory);
  container
    .bind<interfaces.Factory<viewModelsCompanyCompanyInfoModalsCompanyContactsViewModel>>(viewModelsCompanyCompanyInfoModalsCompanyContactsViewModel.Factory)
    .toFactory(viewModelsCompanyCompanyInfoModalsCompanyContactsViewModel.Factory);
  container
    .bind<interfaces.Factory<viewModelsCompanyCompanyInfoModalsCompanyInfoViewModel>>(viewModelsCompanyCompanyInfoModalsCompanyInfoViewModel.Factory)
    .toFactory(viewModelsCompanyCompanyInfoModalsCompanyInfoViewModel.Factory);
  container
    .bind<interfaces.Factory<viewModelsCompanyCompanyInfoModalsCompanyLocationsViewModel>>(viewModelsCompanyCompanyInfoModalsCompanyLocationsViewModel.Factory)
    .toFactory(viewModelsCompanyCompanyInfoModalsCompanyLocationsViewModel.Factory);
  container
    .bind<interfaces.Factory<viewModelsCompanyCompanyInfoModalsCompanyServicesViewModel>>(viewModelsCompanyCompanyInfoModalsCompanyServicesViewModel.Factory)
    .toFactory(viewModelsCompanyCompanyInfoModalsCompanyServicesViewModel.Factory);
  container.bind<viewModelsCompanyCompanyInfoModalsCompleteCompanyProfileViewModel>(viewModelsCompanyCompanyInfoModalsCompleteCompanyProfileViewModel).toSelf();
  container.bind("Factory<CompleteCompanyProfileViewModel>").toAutoFactory(viewModelsCompanyCompanyInfoModalsCompleteCompanyProfileViewModel);
  container
    .bind<interfaces.Factory<viewModelsCompanyComplianceQuestionaireExperienceModifierViewModel>>(viewModelsCompanyComplianceQuestionaireExperienceModifierViewModel.Factory)
    .toFactory(viewModelsCompanyComplianceQuestionaireExperienceModifierViewModel.Factory);
  container
    .bind<interfaces.Factory<viewModelsCompanyComplianceQuestionaireInsuranceUploadModalViewModel>>(viewModelsCompanyComplianceQuestionaireInsuranceUploadModalViewModel.Factory)
    .toFactory(viewModelsCompanyComplianceQuestionaireInsuranceUploadModalViewModel.Factory);
  container
    .bind<interfaces.Factory<viewModelsCompanyComplianceQuestionaireSafetyCultureQuestionaireViewModel>>(viewModelsCompanyComplianceQuestionaireSafetyCultureQuestionaireViewModel.Factory)
    .toFactory(viewModelsCompanyComplianceQuestionaireSafetyCultureQuestionaireViewModel.Factory);
  container
    .bind<interfaces.Factory<viewModelsCompanyComplianceQuestionaireSafetyMetricsQuestionnaireViewModel>>(viewModelsCompanyComplianceQuestionaireSafetyMetricsQuestionnaireViewModel.Factory)
    .toFactory(viewModelsCompanyComplianceQuestionaireSafetyMetricsQuestionnaireViewModel.Factory);
  container
    .bind<interfaces.Factory<viewModelsCompanyComplianceQuestionaireSafetyProgramsViewModel>>(viewModelsCompanyComplianceQuestionaireSafetyProgramsViewModel.Factory)
    .toFactory(viewModelsCompanyComplianceQuestionaireSafetyProgramsViewModel.Factory);
  container
    .bind<interfaces.Factory<viewModelsCompanyComplianceQuestionaireWorkHoursQuestionnaireViewModel>>(viewModelsCompanyComplianceQuestionaireWorkHoursQuestionnaireViewModel.Factory)
    .toFactory(viewModelsCompanyComplianceQuestionaireWorkHoursQuestionnaireViewModel.Factory);
  container
    .bind<interfaces.Factory<viewModelsCompanyProjectsDocumentsDocumentsListViewModel>>(viewModelsCompanyProjectsDocumentsDocumentsListViewModel.Factory)
    .toFactory(viewModelsCompanyProjectsDocumentsDocumentsListViewModel.Factory);
  container
    .bind<interfaces.Factory<viewModelsCompanyProjectsDocumentsUploadDocumentViewModel>>(viewModelsCompanyProjectsDocumentsUploadDocumentViewModel.Factory)
    .toFactory(viewModelsCompanyProjectsDocumentsUploadDocumentViewModel.Factory);
  container
    .bind<interfaces.Factory<viewModelsCompanyProjectsTeamsAddWorkersViewModel>>(viewModelsCompanyProjectsTeamsAddWorkersViewModel.Factory)
    .toFactory(viewModelsCompanyProjectsTeamsAddWorkersViewModel.Factory);
  container
    .bind<interfaces.Factory<viewModelsCompanyProjectsTeamsEditTeamViewModel>>(viewModelsCompanyProjectsTeamsEditTeamViewModel.Factory)
    .toFactory(viewModelsCompanyProjectsTeamsEditTeamViewModel.Factory);
  container
    .bind<interfaces.Factory<viewModelsCompanyProjectsTeamsFormTeamViewModel>>(viewModelsCompanyProjectsTeamsFormTeamViewModel.Factory)
    .toFactory(viewModelsCompanyProjectsTeamsFormTeamViewModel.Factory);
  container
    .bind<interfaces.Factory<viewModelsCompanyProjectsTeamsNewTeamViewModel>>(viewModelsCompanyProjectsTeamsNewTeamViewModel.Factory)
    .toFactory(viewModelsCompanyProjectsTeamsNewTeamViewModel.Factory);
  container
    .bind<interfaces.Factory<viewModelsCompanyProjectsTeamsTeamsListViewModel>>(viewModelsCompanyProjectsTeamsTeamsListViewModel.Factory)
    .toFactory(viewModelsCompanyProjectsTeamsTeamsListViewModel.Factory);
}
