import { ILocaleProvider, IResourcesContainer } from "@emanprague/shared-services";

export default class JsonLocaleProvider implements ILocaleProvider {
  getAvailableLocales() {
    return Promise.resolve(["en"]);
  }

  async fetchResources(locale: string) {
    const data = await import(`../assets/locale/en.json`);
    return data as IResourcesContainer;
  }
}
