import { ILocalizationService } from "@emanprague/shared-services";
import { Dropdown } from "@emanprague/veralinkkit";
import { IPagingFilter, SortingDirection } from "@frui.ts/data";
import { action } from "mobx";
import { observer } from "mobx-react-lite";
import SortOption from "models/sortOption";
import React from "react";

export interface SortSelectProps {
  items: SortOption[];
  paging: IPagingFilter;
  model: string;
  localization: ILocalizationService;
}

const SortSelect: React.FunctionComponent<SortSelectProps> = observer(({ items, paging, model, localization }) => {
  const getTitle = (item: SortOption) =>
    localization.translateAttribute(model, `${item[0]}:${item[1] === SortingDirection.Descending ? "dsc" : "asc"}`);

  const setOption = action((item: SortOption) => ([paging.sortColumn, paging.sortDirection] = item));

  let currentTitle = "";
  const dropdownItems = items.map(option => {
    const title = getTitle(option);

    // optimization
    if (option[0] === paging.sortColumn && option[1] === paging.sortDirection) {
      currentTitle = title;
    }

    return (
      <Dropdown.Item key={title} onClick={() => setOption(option)}>
        {title}
      </Dropdown.Item>
    );
  });

  return (
    <Dropdown title={currentTitle} variant="link" id="sortBy">
      {dropdownItems}
    </Dropdown>
  );
});

export default SortSelect;
