import { ILocalizationService, IEventBus } from "@emanprague/shared-services";
import { bound } from "@frui.ts/helpers";
import { BusyWatcher, ScreenBase, watchBusy } from "@frui.ts/screens";
import { observable, action, computed, runInAction } from "mobx";
import { interfaces } from "inversify";
import QuestionnaireAnswer from "entities/questionnaireAnswer";
import ComplianceRepository from "repositories/complianceRepository";
import QuestionnaireSection from "entities/questionnaireSection";
import QuestionnaireQuestion from "entities/questionnaireQuestion";
import QuestionnaireAnswers from "entities/questionnaireAnswers";
import UserContext from "services/userContext";
import AccountType from "entities/accountType";
import { CompanyEvents } from "services/events";

export type QuestionaireWithAnswer = QuestionnaireQuestion & { answer?: QuestionnaireAnswer };

export type SectionWithAnswer = QuestionnaireSection & { questions: QuestionaireWithAnswer[]; index?: number };

export default class SafetyCultureQuestionaireViewModel extends ScreenBase {
  navigationName = "SafetyCultureQuestionaire";
  busyWatcher = new BusyWatcher();
  @observable selectedSection: SectionWithAnswer;
  @observable model: SectionWithAnswer[];

  constructor(
    private type: string,
    public localization: ILocalizationService,
    private connectionId: string,
    private repository: ComplianceRepository,
    private userContext: UserContext,
    private eventBus: IEventBus
  ) {
    super();

    this.name = this.translate(`title.${type}`);
  }

  async onInitialize() {
    await this.loadQuestionnaire();
  }

  @computed
  get isOperator() {
    return this.userContext.identity?.accountType === AccountType.Operator;
  }

  @action prepareModel(questions: SectionWithAnswer[], answers: QuestionnaireAnswer[]) {
    this.model = questions.map(section => {
      return {
        ...section,
        questions: section.questions.map(question => {
          const answer = answers?.find(({ questionId }) => questionId === question.id) ?? new QuestionnaireAnswer();
          if (question.type === "MULTI") {
            answer.multiAnswer = answer.multiAnswer?.length ? answer.multiAnswer : [];
          }
          // attach validator
          answer.questionId = question.id as string;
          return {
            ...question,
            answer,
          };
        }),
      };
    });
    this.selectedSection = this.model[0];
    this.selectedSection.index = 0;
  }

  @bound
  @watchBusy
  async loadQuestionnaire() {
    const res = await this.repository.loadQuestions(this.connectionId, this.type);
    const resAnswers = await this.repository.loadAnswers(this.type, this.connectionId);

    if (!res.success || !resAnswers.success) {
      return;
    }

    this.prepareModel(res.payload.sections, resAnswers.payload.answers);
  }

  @bound translate(key: string, params = {}) {
    return this.localization.translateGeneral(`compliance.safetyCultureQuestionaire.${key}`, params);
  }

  @bound
  @watchBusy
  async autofill() {
    const resAnswers = await this.repository.loadLatestAnswers(this.type);

    if (!resAnswers.success) {
      return;
    }

    this.prepareModel(this.model, resAnswers.payload.answers);
  }

  @bound async cancel() {
    this.requestClose();
  }

  @bound
  @watchBusy
  async save() {
    // TODO: Add validation
    const answers = this.model
      .map(section =>
        section.questions.map(question => {
          const answer = (question as QuestionaireWithAnswer).answer!;
          if (question.type === "MULTI" && answer.multiAnswer!.length === 0) {
            const copy = { ...answer };
            runInAction(() => {
              copy.multiAnswer = undefined;
            });
            return copy;
          }
          return answer;
        })
      )
      .flat();
    const quesitonaireAnswer = new QuestionnaireAnswers();
    quesitonaireAnswer.answers = answers as QuestionnaireAnswer[];
    const saved = await this.repository.saveAnswers(this.type, this.connectionId, quesitonaireAnswer);

    if (saved.success && saved.payload.status === 204) {
      this.requestClose();
      this.eventBus.publish(CompanyEvents.connectionPointsUpdated(undefined));
    }
  }

  @bound
  @action
  selectSection(idx: number) {
    this.selectedSection = this.model[idx];
    this.selectedSection.index = idx;
  }

  static Factory({ container }: interfaces.Context) {
    return (connectionId: string, type: string) =>
      new SafetyCultureQuestionaireViewModel(
        type,
        container.get("ILocalizationService"),
        connectionId,
        container.get(ComplianceRepository),
        container.get(UserContext),
        container.get("IEventBus")
      );
  }
}
