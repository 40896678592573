import { IEventBus, ILocalizationService } from "@emanprague/shared-services";
import { bound, IDisposable } from "@frui.ts/helpers";
import { BusyWatcher, ScreenBase, watchBusy } from "@frui.ts/screens";
import EnumsService from "services/enumsService";
import { FilteredListViewModel } from "@frui.ts/datascreens";
import DetailMessageViewModel from "viewModels/messages/detailMessageViewModel";
import MessagesRepository from "repositories/messagesRepository";
import Message from "entities/message";
import MessageFilter from "models/messageFilter";
import MessageType from "entities/messageType";
import { computed } from "mobx";
import { MESSAGES_REFRESH_TIME } from "config";
import MessageReadStatusType from "entities/messageReadStatusType";
import { MessagesEvents } from "services/events";

export default class NotifyMessagesViewModel extends FilteredListViewModel<Message, MessageFilter, ScreenBase> {
  navigationName = "notifyMessages";
  busyWatcher = new BusyWatcher();

  private eventHandlers: IDisposable[];

  constructor(
    private repository: MessagesRepository,
    private enums: EnumsService,
    public localization: ILocalizationService,
    private detailMessageFactory: ReturnType<typeof DetailMessageViewModel.Factory>,
    private eventBus: IEventBus
  ) {
    super();

    this.name = this.translate("title");
    this.pagingFilter.limit = 20;
    this.filter.messageType = MessageType.Received;
    this.filter.read = MessageReadStatusType.Unread;
    this.eventHandlers = [this.eventBus.subscribe(MessagesEvents.messageRead, this.applyFilterAndLoad)];
  }

  protected async onDeactivate(isClosing: boolean) {
    await super.onDeactivate(isClosing);

    if (isClosing) {
      this.eventHandlers?.forEach(x => x.dispose());
    }
  }

  onInitialize() {
    this.applyFilterAndLoad();
    setInterval(() => this.applyFilterAndLoad(), MESSAGES_REFRESH_TIME);
  }

  async navigate(subPath: string | undefined, params: any) {}

  @computed
  get unreadCount() {
    return this.currentPaging?.totalItems;
  }

  @computed
  get notificationMessages() {
    return this.items?.slice(0, 4);
  }

  @bound
  @watchBusy
  async loadData() {
    const result = await this.repository.getMessages(this.appliedFilter, this.pagingFilter);
    if (result.success) {
      this.setData(result.payload);
    }
  }

  @bound
  openDetailMessage(messageId: any) {
    const vm = this.detailMessageFactory(messageId);
    return this.tryActivateChild(vm);
  }

  @bound translate(key: string) {
    return this.localization.translateGeneral(`messages.notify.${key}`);
  }

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  protected resetFilterValues(filter: any) {}
}
