import { Button, LoaderOverlay, Form, Row, Col } from "@emanprague/veralinkkit";
import { Input } from "@frui.ts/bootstrap";
import { registerView } from "@frui.ts/views";
import React from "react";
import Plus from "@emanprague/veralinkkit/dist/Icons/Plus";
import CompanyContactsViewModel from "viewModels/company/companyInfo/modals/companyContactsViewModel";
import ModalView from "views/common/modalView";
import Delete from "@emanprague/veralinkkit/dist/Icons/Delete";
import "./modals.scss";

class CompanyContactsModalView extends ModalView<CompanyContactsViewModel> {
  renderBody(vm: CompanyContactsViewModel) {
    const ta = (code: string) => vm.localization.translateAttribute("companyContact", code);

    return (
      <LoaderOverlay className="company-info__modal" loading={vm.busyWatcher.isBusy} variant="light">
        {vm.contacts.map((contact, index) => (
          <div key={index} className="company-info__modal-contact">
            <Row>
              <h6>
                {vm.translate("contact")} {index + 1}
              </h6>
              <Delete fontSize={24} color="#888F91" onClick={() => vm.markToDelete(contact)} />
            </Row>
            <Row>
              <Form.Group className="mr-md-2">
                <Form.Label>{ta("fullName")}</Form.Label>
                <Input type="text" target={contact} property="fullName" />
              </Form.Group>

              <Row>
                <Col md={6}>
                  <Form.Group>
                    <Form.Label>{ta("division")}</Form.Label>
                    <Input type="text" target={contact} property="division" />
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <Form.Group>
                    <Form.Label>{ta("role")}</Form.Label>
                    <Input type="text" target={contact} property="role" />
                  </Form.Group>
                </Col>
              </Row>

              <Row>
                <Col md={6}>
                  <Form.Group>
                    <Form.Label>
                      {ta("email")}&nbsp;
                      <span>({vm.translate("optional")})</span>
                    </Form.Label>
                    <Input type="text" target={contact} property="email" />
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <Form.Group>
                    <Form.Label>
                      {ta("phoneNumber")}&nbsp;
                      <span>({vm.translate("optional")})</span>
                    </Form.Label>
                    <Input type="text" target={contact} property="phoneNumber" />
                  </Form.Group>
                </Col>
              </Row>
            </Row>
          </div>
        ))}

        <Row>
          <Button variant="outline-primary" onClick={vm.addEmptyContact}>
            <Plus fontSize={20} />
            {vm.translate("addContact")}
          </Button>
        </Row>
      </LoaderOverlay>
    );
  }

  renderFooter(vm: CompanyContactsViewModel) {
    return (
      <Button variant="primary" disabled={vm.busyWatcher.isBusy || !vm.canSave} onClick={vm.save}>
        {vm.translate("save")}
      </Button>
    );
  }
}

registerView(CompanyContactsModalView as any, CompanyContactsViewModel);
