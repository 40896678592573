import RepositoryBase from "./repositoryBase";
import EmployeeFilter from "models/employeeFilter";
import CompanyEmployeesSearchResult from "entities/companyEmployeesSearchResult";
import { IPagingFilter } from "@frui.ts/data";
import { EmployeesEvents } from "services/events";
import CompanyEmployeesPendingList from "entities/companyEmployeesPendingList";

export default class EmployeeRepository extends RepositoryBase {
  // /userprofile/company/{companyId}/employees/{workerId}/resend_invitation
  sendResendMessage(companyId: string, workerId: string) {
    return this.callApi(api =>
      api.one("userprofile/company", companyId).one("employees", workerId).all("resend_invitation").postOnly({})
    );
  }

  searchEmployees(companyId: string, filter: EmployeeFilter, paging: IPagingFilter) {
    return this.callApi(api =>
      api
        .one("userprofile/company", companyId)
        .all("employees/search")
        .getPagedEntities(CompanyEmployeesSearchResult, x => x.employees, paging, filter)
    );
  }

  searchEmployeesPending(companyId: string, filter: EmployeeFilter, paging: IPagingFilter) {
    return this.callApi(api =>
      api
        .one("userprofile/company", companyId)
        .all("employees/pending")
        .getPagedEntities(CompanyEmployeesPendingList, x => x.pending, paging, filter)
    );
  }

  async removeWorker(companyId: string, workerId: string) {
    const result = await this.callApi(api =>
      api.one("userprofile/company", companyId).one("employees", workerId).all("disconnect").postOnly({})
    );

    if (result.success) {
      this.eventBus.publish(EmployeesEvents.workerRemoved(undefined));
    }

    return result;
  }

  async importEmployees(companyId: string, file: File) {
    const response = await this.callApi(api => api.one("userprofile/company", companyId).all("employees/import").postData(file));

    if (response.success) {
      this.eventBus.publish(EmployeesEvents.workersImported(undefined));
    }

    return response;
  }

  async exportEmployees(companyId: string, workerIds: string[]) {
    return this.callApi(api => api.one("userprofile/company", companyId).all("employees/export").getRaw({ workerIds }));
  }
}
