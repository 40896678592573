import { ILocalizationService } from "@emanprague/shared-services";
import { DetailViewModel } from "@frui.ts/datascreens";
import { bound } from "@frui.ts/helpers";
import { BusyWatcher, watchBusy } from "@frui.ts/screens";
import { attachAutomaticValidator, validate } from "@frui.ts/validation";
import { classToClass } from "class-transformer";
import WorkerProfile from "entities/workerProfile";
import WorkerProfileSkills from "entities/workerProfileSkills";

import { interfaces } from "inversify";
import { action, computed, observable, reaction, runInAction } from "mobx";
import WorkerProfileRepository from "repositories/workerProfileRepository";
import EnumsService from "services/enumsService";

type WorkerSkill = {
  name: string;
};

export default class SkillsDetailViewModel extends DetailViewModel<WorkerProfile> {
  navigationName = "skills";
  busyWatcher = new BusyWatcher();

  @observable formSkills: WorkerSkill[] = [];

  get industries() {
    return this.enums.industries;
  }

  @computed
  get professions() {
    return this.enums.professions?.filter(item => item.industryId === this.item.skills.industryId);
  }

  constructor(
    workerProfile: WorkerProfile,
    public localization: ILocalizationService,
    private repository: WorkerProfileRepository,
    private enums: EnumsService
  ) {
    super();
    this.name = this.translate("title");

    this.setItem(classToClass(workerProfile) ?? new WorkerProfile());
    this.formSkills = (workerProfile.skills.otherSkills?.map(value => ({ name: value })) as WorkerSkill[]) ?? [];

    attachAutomaticValidator(this.item.skills, WorkerProfileSkills.ValidationRules);

    reaction(() => this.item.skills.industryId, this.resetProfession);
  }

  @bound
  resetProfession() {
    runInAction(() => {
      this.item.skills.professionId = undefined;
    });
  }

  protected loadDetail() {
    return this.item;
  }

  @action.bound
  @watchBusy
  async updateSkills() {
    this.item.skills.otherSkills = this.formSkills.map(({ name }) => name).filter(name => name !== "");

    if (validate(this.item.skills)) {
      const result = await this.repository.updateWorkerProfile(this.item.id, this.item);
      if (result.success) {
        this.requestClose();
      }
    }
  }

  @action.bound
  addOtherSkill() {
    const skill = observable({ name: "" });
    this.formSkills.push(skill);

    attachAutomaticValidator(skill, { name: { required: true } });
  }

  @action.bound
  removeSkill(index: number) {
    this.formSkills.splice(index, 1);
  }

  @bound translate(key: string) {
    return this.localization.translateGeneral(`worker.skillsDetail.${key}`);
  }

  static Factory({ container }: interfaces.Context) {
    return (workerProfile: WorkerProfile) =>
      new SkillsDetailViewModel(
        workerProfile,
        container.get("ILocalizationService"),
        container.get(WorkerProfileRepository),
        container.get(EnumsService)
      );
  }
}
