import CompanyContact from "entities/companyContact";
import CompanyLocation from "entities/companyLocation";
import CompanyProfile from "entities/companyProfile";
import { CompanyEvents } from "services/events";
import RepositoryBase from "./repositoryBase";
import CompanySafetyPolicies from "entities/companySafetyPolicies";
import { FileBox } from "controls/fileUpload";
import { action } from "mobx";

export default class CompanyProfileRepository extends RepositoryBase {
  /* Profile */
  getProfile(companyId: string) {
    return this.callApi(api => api.all("userprofile").one("company", companyId).all("profile").getEntity(CompanyProfile));
  }

  async updateCompanyProfile(companyId: string, profile: Partial<CompanyProfile>) {
    const result = await this.callApi(api =>
      api.all("userprofile").one("company", companyId).all("profile").patchEntity(profile, CompanyProfile)
    );

    if (result.success) {
      this.eventBus.publish(CompanyEvents.profileUpdated(result.payload));
    }

    return result;
  }

  /* Photo */
  updatePhoto(companyId: string, file: File) {
    return this.callApi(api => api.all("userprofile").one("company", companyId).all("profile").all("photo").putData(file));
  }

  /* Contacts */
  getContacts(companyId: string) {
    return this.callApi(api => api.all("userprofile").one("company", companyId).all("contacts").getEntities(CompanyContact));
  }

  addContact(companyId: string, newContact: CompanyContact) {
    return this.callApi(api =>
      api.all("userprofile").one("company", companyId).all("contacts").postEntity(newContact, CompanyContact)
    );
  }

  updateContact(companyId: string, contactId: string, contact: CompanyContact) {
    return this.callApi(api =>
      api.all("userprofile").one("company", companyId).one("contacts", contactId).putEntity(contact, CompanyContact)
    );
  }

  deleteContact(companyId: string, contactId: string) {
    return this.callApi(api => api.all("userprofile").one("company", companyId).one("contacts", contactId).delete());
  }

  /* Services */
  updateServices(companyId: string, services: string[]) {
    return this.updateCompanyProfile(companyId, { services });
  }

  /* Locations */
  getLocations(companyId: string) {
    return this.callApi(api => api.all("userprofile").one("company", companyId).all("locations").getEntities(CompanyLocation));
  }

  updateLocation(companyId: string, locationId: string, location: CompanyLocation) {
    return this.callApi(api =>
      api.all("userprofile").one("company", companyId).one("locations", locationId).putEntity(location, CompanyLocation)
    );
  }

  deleteLocation(companyId: string, locationId: string) {
    return this.callApi(api => api.all("userprofile").one("company", companyId).one("locations", locationId).delete());
  }

  addLocation(companyId: string, location: CompanyLocation) {
    return this.callApi(api =>
      api.all("userprofile").one("company", companyId).all("locations").postEntity(location, CompanyLocation)
    );
  }

  // Safety policies

  getSafetyPolicies(companyId: string) {
    return this.callApi(api =>
      api.all("userprofile").one("company", companyId).all("safety_policies").getEntity(CompanySafetyPolicies)
    );
  }

  async saveSafetyPolicies(companyId: string, safetyPolicies: CompanySafetyPolicies) {
    const result = await this.callApi(api =>
      api.all("userprofile").one("company", companyId).all("safety_policies").putEntity(safetyPolicies, CompanySafetyPolicies)
    );

    if (result.success) {
      this.eventBus.publish(CompanyEvents.safetyPoliciesUpdated(result.payload));
    }

    return result;
  }

  removePolicyFile(companyId: string, workTypeId: string, policyId: string) {
    return this.callApi(api =>
      api
        .all("userprofile")
        .one("company", companyId)
        .one("safety_policies", policyId)
        .one("work_type", workTypeId)
        .all("attachment")
        .delete()
    );
  }

  uploadPolicy(companyId: string, workTypeId: string, policyId: string, file: FileBox<File>) {
    return this.callApi(api =>
      api
        .all("userprofile")
        .one("company", companyId)
        .one("safety_policies", policyId)
        .one("work_type", workTypeId)
        .all("attachment")
        .postBlob(
          file.file!,
          action(a => (file.progress = a)),
          { attachmentName: file.file!.name }
        )
    );
  }
}
