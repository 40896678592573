import { Transform } from "class-transformer";
import { observable } from "mobx";
import InsuranceDocumentStatus from "./insuranceDocumentStatus";
import { formatISO } from "date-fns";
import parseISO from "date-fns/parseISO";

// This entity has been generated, do not change its content, your changes might get lost. You CAN modify the rest of the file.
export default class InsuranceDocument {
  /**
   * Insurance document ID
   */
  id!: string;

  /**
   * @example
   * insurance.pdf
   */
  @observable
  fileName!: string;

  /**
   * URL to download the insurance file
   */
  @observable
  fileUrl!: string;

  @observable
  @Transform(value => (value ? (typeof value === "string" ? parseISO(value) : value) : undefined), { toClassOnly: true })
  @Transform(value => (value ? formatISO(value, { representation: "date" }) : undefined), { toPlainOnly: true })
  uploadedAt!: Date;

  @observable
  status!: InsuranceDocumentStatus;

  /**
   * Message from the Operator when he rejects the submission
   */
  @observable
  rejectedMessage?: string;

  static ValidationRules = {
    id: { required: true },
    fileName: { required: true },
    fileUrl: { required: true },
    uploadedAt: { format: "date", required: true },
    status: { required: true },
  };
}
