import { observable } from "mobx";
import AccountType from "./accountType";

// This entity has been generated, do not change its content, your changes might get lost. You CAN modify the rest of the file.
class CompanyProfile {
  @observable
  accountType!: AccountType;

  /**
   * Company ID (read-only)
   */
  id!: string;

  /**
   * Company name
   */
  @observable
  companyName!: string;

  /**
   * Tax identification number
   */
  @observable
  taxId!: string;

  /**
   * Year founded
   */
  @observable
  yearFounded?: number;

  /**
   * Contact phone number
   */
  @observable
  phoneNumber!: string;

  /**
   * Company website
   */
  @observable
  website?: string;

  /**
   * Short info about company
   */
  @observable
  bio?: string;

  /**
   * Services provided by company
   */
  @observable
  services?: string[];

  /**
   * read-only
   */
  @observable
  profilePhotoUrl?: string;

  static ValidationRules = {
    accountType: { required: true },
    id: { required: true },
    companyName: { required: true },
    taxId: { required: true },
    yearFounded: { number: true },
    phoneNumber: { required: true },
  };
}

export default class CompanyProfileExtended extends CompanyProfile {
  static ValidationRules = {
    id: { required: false },
    accountType: { required: false },

    companyName: { required: true },
    taxId: { required: true },
    phoneNumber: { required: true, isPhone: true },
    yearFounded: { number: true },
  };
}
