import { Router, ScreenBase, UrlNavigationAdapter } from "@frui.ts/screens";
import { View } from "@frui.ts/views";
import { IMPORT_TOKEN } from "config";
import { Container } from "inversify";
import { reaction } from "mobx";
import React from "react";
import ReactDOM from "react-dom";
import StorageProvider from "services/storageProvider";
import UserContext from "services/userContext";
import AnonymousRootViewModel from "viewModels/anonymousRootViewModel";
import InitializationService from "./services/initializationService";
import RootViewModel from "./viewModels/rootViewModel";

let urlAdapter: UrlNavigationAdapter;
let currentRootViewModel: ScreenBase;

function renderViewModel(viewModel: any) {
  ReactDOM.render(<View vm={viewModel} useLifecycle />, document.getElementById("root"));
}

async function renderRoot(newRootViewModel: ScreenBase, router?: Router) {
  await currentRootViewModel?.deactivate(true);
  urlAdapter?.stop();

  urlAdapter = new UrlNavigationAdapter();
  router?.start(newRootViewModel, urlAdapter);
  await urlAdapter.start(newRootViewModel);

  await newRootViewModel.activate();
  currentRootViewModel = newRootViewModel;
  renderViewModel(newRootViewModel);
}

async function bootApp(isLogged: boolean, container: Container, initializationService: InitializationService) {
  urlAdapter?.stop();

  if (!initializationService.isPreLoginInitialized) {
    initializationService.preLoginInitialize();
    renderViewModel(initializationService);
    return;
  }

  const router = container.get(Router);

  if (!isLogged) {
    if (initializationService.isPostLoginInitialized) {
      await initializationService.postLogoutCleanup();
    }

    const anonymousViewModel = container.get(AnonymousRootViewModel);
    await renderRoot(anonymousViewModel, router);
    return;
  }

  if (!initializationService.isPostLoginInitialized) {
    initializationService.postLoginInitialize();
    renderViewModel(initializationService);
    return;
  }

  const rootViewModel = container.get(RootViewModel);
  await renderRoot(rootViewModel, router);
}

const parseQuery = (queryString: string): Record<string, string> => {
  const query: Record<string, string> = {};
  const pairs = (queryString[0] === "?" ? queryString.substr(1) : queryString).split("&");
  pairs.forEach((pair: string) => {
    const tempPair = pair.split("=");
    query[decodeURIComponent(tempPair[0])] = decodeURIComponent(tempPair[1] || "");
  });

  return query;
};

const getQueryString = () => {
  const startUrl = window.location.href;
  const queryStart = startUrl.indexOf("?");
  if (queryStart > -1) {
    return startUrl.slice(queryStart);
  }
  return "";
};

const checkAndSaveImportToken = (storageProvider: StorageProvider) => {
  const queryString = getQueryString();
  if (!!queryString) {
    const params = parseQuery(queryString);

    if (!!params.token) {
      storageProvider.set(IMPORT_TOKEN, params.token);
    }
  }
};

export default function runApp(container: Container) {
  const userContext = container.get<UserContext>(UserContext);
  const initializationService = container.get<InitializationService>("IInitializationService");
  const storageProvider = container.get<StorageProvider>(StorageProvider);

  checkAndSaveImportToken(storageProvider);

  reaction(
    () => [userContext.isLogged, initializationService.isPreLoginInitialized, initializationService.isPostLoginInitialized],
    ([isLogged]) => bootApp(isLogged, container, initializationService),
    { fireImmediately: true }
  );
}
