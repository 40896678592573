import { BusyWatcher, Router, ScreenBase, watchBusy } from "@frui.ts/screens";
import { IMPORT_TOKEN } from "config";
import { observable, runInAction } from "mobx";
import StorageProvider from "services/storageProvider";
import UserContext from "services/userContext";
import LoginRepository from "repositories/loginRepository";
import { bound } from "@frui.ts/helpers";
import WorkerImportPending from "entities/workerImportPending";
import WorkerImportPendingAction from "entities/workerImportPendingAction";
import LoginViewModel from "./profile/loginViewModel";
import WorkerImportService from "services/workerImportService";
import WorkerFormViewModel from "./signup/workerFormViewModel";
import { ILocalizationService } from "@emanprague/shared-services";

export const navigationName = "worker-import";

export default class WorkerImportViewModel extends ScreenBase {
  hideFromMenu = true;
  navigationName = navigationName;
  busyWatcher = new BusyWatcher();

  @observable importToken?: string;
  @observable workerImport: WorkerImportPending;

  constructor(
    private userContext: UserContext,
    private storageProvider: StorageProvider,
    private repository: LoginRepository,
    private router: Router,
    private workerImportService: WorkerImportService,
    public localization: ILocalizationService
  ) {
    super();

    this.name = "Worker import";
  }

  onActivate() {
    runInAction(() => {
      this.importToken = this.storageProvider.get(IMPORT_TOKEN) as string;
    });

    if (this.importToken !== undefined) {
      this.checkWorkImport();
    } else {
      window.location.href = this.userContext.isLogged ? "#/" : "#/login";
      this.requestClose();
    }
  }

  @bound
  @watchBusy
  async checkWorkImport() {
    const result = await this.repository.checkWorkerImport(this.importToken!);
    if (result.success) {
      runInAction(() => {
        this.workerImport = result.payload;
        if (this.workerImport.action === WorkerImportPendingAction.Registration && this.userContext.isLogged) {
          this.storageProvider.remove(IMPORT_TOKEN);
          this.workerImportService.data = undefined;
          window.location.href = "#/";
          this.requestClose();
        }
      });
    }
  }

  @bound
  @watchBusy
  async completeConnectionRequest() {
    const result = await this.repository.completeConnectionRequest(this.importToken!);
    this.storageProvider.remove(IMPORT_TOKEN);
    if (result.success) {
      window.location.href = "#/";
    }
  }

  @bound
  cancel() {
    this.storageProvider.remove(IMPORT_TOKEN);
    runInAction(() => {
      this.workerImportService.data = undefined;
    });
    window.location.href = this.userContext.isLogged ? "#/" : "#/login";
    this.requestClose();
  }

  @bound
  next() {
    if (!this.userContext.isLogged) {
      if (this.workerImport.action === WorkerImportPendingAction.Registration) {
        runInAction(() => {
          this.workerImportService.data = this.workerImport;
        });
        this.router.navigate(WorkerFormViewModel, undefined, { workerImport: true });
      } else {
        this.router.navigate(LoginViewModel, undefined, { connectionRequest: true });
      }
    } else {
      this.completeConnectionRequest();
    }
  }

  @bound translate(key: string, params?: any) {
    return this.localization.translateGeneral(`workerImport.${key}`, params);
  }
}
