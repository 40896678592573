import { Exclude } from "class-transformer";
import merge from "lodash/merge";
import { observable } from "mobx";
import { EntityValidationRules } from "services/validation/entityValidationRules";

// This entity has been generated, do not change its content, your changes might get lost. You CAN modify the rest of the file.
export class NewWorker {
  /**
   * First name
   */
  @observable
  firstName!: string;

  /**
   * Middle name
   */
  @observable
  middleName?: string;

  /**
   * Last name
   */
  @observable
  lastName!: string;

  /**
   * Profession Title
   */
  @observable
  professionTitle?: string;

  /**
   * Email address
   */
  @observable
  email!: string;

  /**
   * Mobile number
   */
  @observable
  mobile!: string;

  /**
   * Social Security Number (last 4 digits)
   */
  @observable
  socialSecurityNumber!: string;

  /**
   * User name
   */
  @observable
  username!: string;

  /**
   * User password
   */
  @observable
  password!: string;

  static ValidationRules = {
    firstName: { required: true },
    lastName: { required: true },
    email: { format: "email", required: true },
    mobile: { required: true },
    socialSecurityNumber: { maxLength: 4, minLength: 4, required: true },
    username: { required: true },
    password: { required: true },
  };
}

export default class NewWorkerPassword extends NewWorker {
  @Exclude()
  @observable
  repeatPassword!: string;

  @observable consent: boolean;

  static ValidationRules = merge(
    {
      socialSecurityNumber: { digit: true, maxLength: 4, minLength: 4, required: true },
      email: { isEmail: true },
      repeatPassword: { required: true, equalWith: "password" },
      consent: { isChecked: true },
    } as EntityValidationRules<NewWorkerPassword>,
    NewWorker.ValidationRules
  );
}
