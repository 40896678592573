import { Button, Col, Form, LoaderOverlay, Row } from "@emanprague/veralinkkit";
import { registerView } from "@frui.ts/views";
import FormCheck from "controls/formCheck";
import React from "react";
import AvailabilityViewModel from "viewModels/worker/modals/availabilityViewModel";
import ModalView from "views/common/modalView";
import CustomInput from "controls/input";

class AvailabilityModalView extends ModalView<AvailabilityViewModel> {
  renderBody(vm: AvailabilityViewModel) {
    const ta = vm.localization.translateAttribute;

    return (
      <LoaderOverlay loading={vm.busyWatcher.isBusy} variant="light">
        <Row className="justify-content-center">
          <Col>
            <Form.Row className="justify-content-center">
              <Form.Group>
                <FormCheck
                  label={ta("availability", "available")}
                  id="availableForHire"
                  target={vm}
                  property="isAvailable"
                  type="switch"
                />
              </Form.Group>
            </Form.Row>
            {vm.isAvailable && (
              <>
                <Form.Row className="justify-content-center">
                  <Col xs={10}>
                    <p className="text-center">{vm.translate("description")}</p>
                  </Col>
                </Form.Row>
                <Form.Row className="justify-content-center">
                  <div>
                    {["availableNow", "availableFrom"].map(name => (
                      <Form.Group key={name}>
                        <FormCheck id={name} label={vm.translate(name)} type="radio" target={vm} property="availableType" />
                      </Form.Group>
                    ))}
                  </div>
                </Form.Row>
                {vm.availableType === "availableFrom" && (
                  <Form.Row className="justify-content-center">
                    <Col xs={6}>
                      <Form.Group>
                        <CustomInput type="date" target={vm} property="availableFrom" />
                      </Form.Group>
                    </Col>
                  </Form.Row>
                )}
              </>
            )}
          </Col>
        </Row>
      </LoaderOverlay>
    );
  }

  renderFooter(vm: AvailabilityViewModel) {
    return (
      <Button variant="primary" disabled={vm.busyWatcher.isBusy} onClick={vm.updateAvailability}>
        {vm.translate("save")}
      </Button>
    );
  }
}

registerView(AvailabilityModalView as any, AvailabilityViewModel);
