import React, { FunctionComponent } from "react";
import { RoundedButton } from "@emanprague/veralinkkit";
import { Edit } from "@emanprague/veralinkkit/dist/Icons";
import { observer } from "mobx-react-lite";

interface CompanyPhotoProps {
  tg: (a: string) => string;
  photoUrl?: string;
  onChange: (file: File) => void;
}

const CompanyPhoto: FunctionComponent<CompanyPhotoProps> = observer(({ tg, photoUrl, onChange }) => {
  const changeInput = React.createRef<HTMLInputElement>();

  const onChangeInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      onChange(e.target.files[0]);
    }
  };

  const onClick = () => {
    changeInput.current?.click();
  };

  return (
    <div className="company__logo">
      {photoUrl && <img src={photoUrl} alt="logo" />}
      <input id="file" ref={changeInput} type="file" onChange={onChangeInput} accept="image/*" hidden />
      <RoundedButton size="sm" onClick={onClick}>
        <Edit fontSize={14} />
      </RoundedButton>

      {!photoUrl && <p className="company__logo-empty">{tg("companyLogo")}</p>}
    </div>
  );
});

export default CompanyPhoto;
