import { Alert, Button, Card, Col, Container, Form, Row } from "@emanprague/veralinkkit";
import { preventDefault, registerView, ViewComponent } from "@frui.ts/views";
import { observer } from "mobx-react-lite";
import React from "react";
import SetupPasswordViewModel from "viewModels/profile/setupPasswordViewModel";
import { PasswordInput } from "controls/passwordInput";

const setupPasswordListView: ViewComponent<SetupPasswordViewModel> = observer(({ vm }) => (
  <Container>
    <Row className="justify-content-center">
      <Col md={6}>
        <Card className="setup-password">
          <Card.Title className="text-center">
            <h1>{vm.name}</h1>
          </Card.Title>
          <Card.Body>
            <Row className="justify-content-center">
              <Col md={10}>
                <Form onSubmit={preventDefault(vm.sendReset)}>
                  <PasswordInput label={vm.translate("password")} target={vm.model} property="newPassword" />
                  <PasswordInput target={vm.model} label={vm.translate("confirmPassword")} property="confirmNewPassword" />

                  {vm.errorMessage && <Alert variant="danger">{vm.errorMessage}</Alert>}

                  <Button block type="submit">
                    {vm.translate("submit")}
                  </Button>
                </Form>
              </Col>
            </Row>
          </Card.Body>
        </Card>
      </Col>
    </Row>
  </Container>
));

registerView(setupPasswordListView, SetupPasswordViewModel);
