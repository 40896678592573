import { IEventBus, ILocalizationService } from "@emanprague/shared-services";
import { bound, IDisposable } from "@frui.ts/helpers";
import { ScreenBase, ConductorOneChildActive, Router, watchBusy } from "@frui.ts/screens";
import WorkerProfile from "entities/workerProfile";
import { computed, runInAction, observable } from "mobx";
import SecurityService from "services/securityService";
import UserContext from "services/userContext";
import ChangePasswordViewModel from "viewModels/profile/changePasswordViewModel";
import AccountSettingsListViewModel from "./accountSettings/accountSettingsListViewModel";
import WorkerProfileRepository from "repositories/workerProfileRepository";
import { WorkerEvents } from "services/events";

export default class StatusViewModel extends ConductorOneChildActive<ScreenBase> {
  navigationName = "status";
  @observable photoUrl: string;
  private eventHandlers: IDisposable[];

  get canBeNavigationActiveScreen() {
    return false;
  }

  @computed get userName() {
    const profile = this.userContext.profile;

    if (profile instanceof WorkerProfile) {
      return `${profile.firstName} ${profile.lastName}`;
    } else {
      return profile?.companyName;
    }
  }

  constructor(
    private localization: ILocalizationService,
    private securityService: SecurityService,
    private userContext: UserContext,
    private changePasswordFactory: () => ChangePasswordViewModel,
    private router: Router,
    private repository: WorkerProfileRepository,
    private eventBus: IEventBus
  ) {
    super();
    this.name = localization.translateGeneral("profile.title");

    this.eventHandlers = [this.eventBus.subscribe(WorkerEvents.userImageUpdated, this.loadPhoto)];
  }

  protected async onDeactivate(isClosing: boolean) {
    await super.onDeactivate(isClosing);

    if (isClosing) {
      this.eventHandlers?.forEach(x => x.dispose());
    }
  }

  @bound openSettings() {
    return this.router.navigate(AccountSettingsListViewModel);
  }

  @bound changePassword() {
    return this.navigate("changePassword", undefined);
  }

  protected findNavigationChild(navigationName: string | undefined) {
    if (navigationName === "changePassword") {
      return this.changePasswordFactory();
    }
    return undefined;
  }

  @bound logout() {
    this.securityService.logout();
    window.location.href = "#/";
  }
  @bound translate(key: string) {
    return this.localization.translateGeneral(`status.${key}`);
  }

  onInitialize() {
    if (!this.photoUrl) {
      this.loadPhoto();
    }
  }

  @bound
  @watchBusy
  async loadPhoto() {
    const profile = this.userContext.profile;
    if (profile?.profilePhotoUrl) {
      const photoUrl = await this.repository.getWorkerPhotoObjectUrl(profile?.profilePhotoUrl);
      if (photoUrl) {
        runInAction(() => {
          this.photoUrl = photoUrl;
        });
      }
    }
  }
}
