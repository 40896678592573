import { IEventBus, ILocalizationService } from "@emanprague/shared-services";
import { bound } from "@frui.ts/helpers";
import { ScreenBase } from "@frui.ts/screens";
import AccountType from "entities/accountType";
import AuthenticationIdentity from "entities/authenticationIdentity";
import Notification from "entities/notification";
import NotificationConnectionRequestContent from "entities/notificationConnectionRequestContent";
import { interfaces } from "inversify";
import { runInAction } from "mobx";
import ContractorPortalRepository from "repositories/contractorPortalRepository";
import NotificationsRepository from "repositories/notificationsRepository";
import WorkerPortalRepository from "repositories/workerPortalRepository";
import { StatusbarEvents } from "services/events";
import UserContext from "services/userContext";
import { getProfileUrl } from "utils/viewUtils";

export default class ConnectionRequestViewModel extends ScreenBase {
  content: NotificationConnectionRequestContent;
  identity: AuthenticationIdentity;

  constructor(
    public notification: Notification,
    private workerPortalRepository: WorkerPortalRepository,
    private contractorPortalRepository: ContractorPortalRepository,
    private notificationsRepository: NotificationsRepository,
    public localization: ILocalizationService,
    private eventBus: IEventBus,
    userContext: UserContext
  ) {
    super();

    this.content = notification.content as NotificationConnectionRequestContent;

    if (!userContext.identity) return;
    this.identity = userContext.identity;
  }

  @bound
  async acceptConnectionRequest() {
    const { accountType, accountId } = this.identity;
    let result;

    if (accountType === AccountType.Worker) {
      result = await this.workerPortalRepository.acceptConnection(accountId, this.content.connectionId);
    } else {
      result = await this.contractorPortalRepository.acceptConnection(accountId, this.content.connectionId);
    }

    if (result.success) {
      this.markAsRead();
    }
  }

  // todo: can be inherited
  get profileUrl() {
    return getProfileUrl(this.content.accountId, this.content.accountType);
  }

  // todo: can be inherited
  @bound
  async markAsRead() {
    const result = await this.notificationsRepository.markOneRead(this.notification.id!);

    if (result.success) {
      runInAction(() => (this.notification.read = true));
      this.eventBus.publish(StatusbarEvents.notificationRead(undefined));
    }
  }

  @bound translate(key: string, placeholders?: Record<string, string>) {
    return this.localization.translateGeneral(`notifications.connectionRequest.${key}`, placeholders);
  }

  static Factory({ container }: interfaces.Context) {
    return (notification: Notification) =>
      new ConnectionRequestViewModel(
        notification,
        container.get(WorkerPortalRepository),
        container.get(ContractorPortalRepository),
        container.get(NotificationsRepository),
        container.get("ILocalizationService"),
        container.get("IEventBus"),
        container.get(UserContext)
      );
  }
}
