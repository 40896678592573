import React, { useState } from "react";
import { observer } from "mobx-react-lite";
import Down from "@emanprague/veralinkkit/dist/Icons/Arrow Simple/Down";
import Up from "@emanprague/veralinkkit/dist/Icons/Arrow Simple/Up";
import CompanyContact from "entities/companyContact";
import CompanyContactView from "./companyContact";

interface ICompanyContactsGroupProps {
  label: string;
  contacts: CompanyContact[];
}

const CompanyContactsGroup: React.FunctionComponent<ICompanyContactsGroupProps> = observer(({ label, contacts }) => {
  const [open, setOpen] = useState(false); // Mobx observable doesn't work for FunctionComponents

  return (
    <div className="company-info__contacts-group">
      <div onClick={() => setOpen(!open)}>
        <h5>{label}</h5>
        <span>{contacts.length}</span>
        {open ? <Up fontSize={24} color="#888F91" /> : <Down fontSize={24} color="#888F91" />}
      </div>

      {open && (
        <div>
          {contacts.map(contact => (
            <CompanyContactView key={contact.id} contact={contact} />
          ))}
        </div>
      )}
    </div>
  );
});

export default CompanyContactsGroup;
