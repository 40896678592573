import RepositoryBase from "./repositoryBase";
import { IPagingFilter } from "@frui.ts/data";
import { ContractorPortalFilter } from "models/contractorPortalFilter";
import ContractorProfileSearchResults from "entities/contractorProfileSearchResults";
import Connection from "entities/connection";

export default class ContractorPortalRepository extends RepositoryBase {
  searchContractors(filter: ContractorPortalFilter, paging: IPagingFilter) {
    return this.callApi(api =>
      api
        .all("userprofile/contractor_portal")
        .all("search")
        .getPagedEntities(ContractorProfileSearchResults, x => x.contractors, paging, filter)
    );
  }

  sendConnection(workerId: string) {
    return this.callApi(api =>
      api.all("userprofile/contractor_portal").one("contractor", workerId).all("connections").postOnly({})
    );
  }

  getMyContractors(id: string) {
    return this.callApi(api =>
      api.all("userprofile/contractor_portal").one("contractor", id).all("connections").getEntities(Connection)
    );
  }

  acceptConnection(workerId: string, connectionId: string) {
    return this.callApi(api =>
      api
        .all("userprofile/contractor_portal")
        .one("contractor", workerId)
        .one("connections", connectionId)
        .all("accept")
        .postOnly({})
    );
  }
}
