import { observable } from "mobx";
import { Type } from "class-transformer";
import OshaFormYear from "./oshaFormYear";
import OshaFormQuestion from "./oshaFormQuestion";

const readOnlyAttributes = [
  "totalRecordableIncidentRate",
  "lostTimeCasesRate",
  "lostWorkDayRate",
  "restrictedWorkDayCaseRateIncludingJobTransferWorkDayCases",
  "severityRate",
  "daysAwayRestrictedOrTransferRate",
  "numberOfExposureOrEmployeeHoursPerEmployee",
  "totalNumberOfNonFatalRecordableCases",
  "nonFatalTotalRecordableIncidentRate",
  "fatalityRateBasedOn100Employees",
  "fatalityRateBasedOn100000Employees",
  "severityRatePer200000HoursWorked",
  "vehicleAccidentRate",
  "otherRecordableCasesRate",
  "seriousInjuryCaseRate",
];

// This entity has been generated, do not change its content, your changes might get lost. You CAN modify the rest of the file.
export class OshaFormAnswers {
  @observable
  @Type(() => OshaFormYear)
  years?: OshaFormYear[];
}

export default class OshaFormAnswersExtended extends OshaFormAnswers {
  prepareRequestValues(oshaFormYears: any[]) {
    this.years?.forEach(year => {
      const oshaFormYear = oshaFormYears.find(y => y.year === year.year);

      return year?.quarters?.forEach((quarter, index) => {
        let qs = { ...quarter?.questions } as any;

        for (const key in qs) {
          if (oshaFormYear && (oshaFormYear as any)?.calculator?.readOnly[key]) {
            qs = { ...qs, [key]: (oshaFormYear as any)?.calculator?.readOnly[key][index] ?? 0 };
          }

          if (key !== "__validation" && !readOnlyAttributes.includes(key)) {
            const value = qs[key as keyof OshaFormQuestion] ?? 0;
            qs[key as keyof OshaFormQuestion] = value;
          }
        }

        if (quarter) {
          quarter.questions = qs;
        }
      });
    });
  }
}
