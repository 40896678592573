import { Button, Uploader } from "@emanprague/veralinkkit";
import { registerView } from "@frui.ts/views";
import React from "react";
import ImportWorkersViewModel from "viewModels/company/employees/importWorkersViewModel";
import ModalView, { ModalSize } from "views/common/modalView";
import FileUploadProgress from "views/worker/credentials/components/FileUploadProgress";
import StateOk from "@emanprague/veralinkkit/dist/Icons/StateOk";
import StateNotOk from "@emanprague/veralinkkit/dist/Icons/StateNotOk";

class ImportWorkersModalView extends ModalView<ImportWorkersViewModel> {
  size: ModalSize = "lg";
  renderBody(vm: ImportWorkersViewModel) {
    return (
      <div className="importEmployee__content">
        {!vm.file && (
          <>
            <Uploader
              accept={[".xls", ".xlsx"]}
              onDrop={(files: File[]) => vm.selectFile(files[0])}
              localization={{
                button: vm.translate("upload"),
                or: vm.translate("dragFile"),
                types: vm.translate("fileValidation"),
              }}
            />
            <h6>{vm.translate("makeSure")}</h6>
            <a href={vm.templateUrl} download={vm.translate("templateName")}>
              {vm.translate("downloadTemplate")}
            </a>
          </>
        )}
        {!vm.busyWatcher.isBusy && !vm.importResult && (
          <FileUploadProgress progressBar={0} file={vm.file} onRemove={vm.removeFile} />
        )}
        {vm.busyWatcher.isBusy && (
          <div>
            {/** TODO insert inline Spinner with text */}
            <span>{vm.translate("importing")}</span>
          </div>
        )}
        {vm.importResult && (
          <>
            <h5>{vm.translate("importFinished")}</h5>
            <div className="importEmployee__results">
              {vm.importResult?.nNew >= 0 && (
                <p className="resultSucceeded">
                  <StateOk />
                  &nbsp;{`${vm.importResult?.nNew} ${vm.translate("profileCreated")}`}
                </p>
              )}
              {vm.importResult?.nExisting >= 0 && (
                <p className="resultExisting">
                  <StateNotOk />
                  &nbsp;{`${vm.importResult?.nExisting} ${vm.translate("notCreatedExist")}`}
                </p>
              )}
              {vm.importResult?.nFailed >= 0 && (
                <p className="resultFailed">
                  <StateNotOk />
                  &nbsp;{`${vm.importResult?.nFailed} ${vm.translate("notCreatedError")}`}
                </p>
              )}
            </div>
          </>
        )}
      </div>
    );
  }

  renderFooter(vm: ImportWorkersViewModel) {
    return (
      <>
        <Button
          variant={!vm.importResult ? "outline-primary" : "primary"}
          disabled={vm.busyWatcher.isBusy}
          onClick={() => vm.requestClose()}>
          {vm.translate(!vm.importResult ? "cancel" : "continue")}
        </Button>
        {!vm.importResult && (
          <Button variant="primary" disabled={vm.busyWatcher.isBusy || !vm.file} onClick={vm.importWorkers}>
            {vm.translate("import")}
          </Button>
        )}
      </>
    );
  }
}

registerView(ImportWorkersModalView as any, ImportWorkersViewModel);
