import {
  SectionWithAnswer,
  QuestionaireWithAnswer,
} from "viewModels/company/compliance/questionaire/SafetyCultureQuestionaireViewModel";
import LoadingContainer from "views/common/loadingContainer";
import { Row, Col } from "@emanprague/veralinkkit";
import classnames from "classnames";
import React from "react";
import QuestionSection from "./questionSection";
import Question from "./question";

type Props = {
  sectionsVisible?: boolean;
  model?: SectionWithAnswer[];
  selectedSection?: SectionWithAnswer;
  isBusy: boolean;
  selectSection?: (a: number) => void;
  tg: (a: string, b?: any) => string;
  readOnly?: boolean;
};

const Questionnaire: React.FC<Props> = ({
  sectionsVisible = true,
  tg,
  selectSection,
  selectedSection,
  isBusy,
  model,
  readOnly,
}) => {
  const questions = (
    <div className="questionaire__questions">
      {selectedSection?.questions?.map((question: QuestionaireWithAnswer, index) => (
        <Question key={question.id} {...question} index={index + 1} tg={tg} context={selectedSection} readOnly={readOnly} />
      ))}
    </div>
  );

  return (
    <LoadingContainer loading={isBusy}>
      <Row>
        {sectionsVisible && (
          <Col md={4} xs={12}>
            {model?.map(({ name, questions }, index) => {
              const classes = classnames("questionaire__section", {
                "is-active": index === selectedSection?.index,
              });
              return (
                <QuestionSection
                  className={classes}
                  onSelectSection={() => !!selectSection && selectSection(index)}
                  questions={questions}
                  name={name}
                />
              );
            })}
          </Col>
        )}

        {sectionsVisible && (
          <Col md={{ offset: 1, span: 5 }} xs={12}>
            {sectionsVisible && <h4>{selectedSection?.name}</h4>}

            {questions}
          </Col>
        )}
        {!sectionsVisible && <Col md={12}>{questions}</Col>}
      </Row>
    </LoadingContainer>
  );
};

export default Questionnaire;
