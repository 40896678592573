import Comment from "@emanprague/veralinkkit/dist/Icons/Comment";
import { Col, Card, Button } from "@emanprague/veralinkkit";
import { Document, Upload } from "@emanprague/veralinkkit/dist/Icons";
import React, { useMemo } from "react";
import { Accordion } from "react-bootstrap";
import "./employeeCredentials.scss";
import Toggle from "./toggle";
import CredentialsSection from "views/common/credentialsSection";
import Credential, { CredentialPhoto } from "entities/credential";
import { expiredState } from "views/worker/credentials/utils";
import { groupBy } from "lodash";
import { ILocalizationService } from "@emanprague/shared-services";
import EmployeeInfo from "views/common/employeeInfo";
import EnumsService from "services/enumsService";

type States = {
  willExpire: number;
  isExpired: number;
  isGood: number;
};

const countStates = (credentials: Credential[]) => {
  const withStates = groupBy(
    credentials.map(el => {
      const status = expiredState(el.validityFrom, el.validityTo) as keyof States;

      return { ...el, status };
    }),
    el => el.status
  );

  return Object.keys(withStates).reduce(
    (acc, key) => {
      const count = withStates[key].length;
      return { ...acc, [key]: count };
    },
    {
      willExpire: 0,
      isExpired: 0,
      isGood: 0,
    }
  );
};

type Props = {
  localization: ILocalizationService;
  worker: any;
  openProfile: (accountId: string) => void;
  openOverview: (c: CredentialPhoto) => Promise<void>;
  uploadCredentials: (a: string) => void;
  onSendMessage: (accountId: string, aacountName: string) => void;
  enums: EnumsService;
};

const EmployeeCredentials = ({
  openProfile,
  worker,
  localization,
  openOverview,
  uploadCredentials,
  onSendMessage,
  enums,
}: Props) => {
  const tg = (code: string, params?: any) =>
    localization.translateGeneral(`credentials.subModule.employeeCredentials.${code}`, params);
  const tm = localization.translateModel;
  const states = useMemo(() => countStates(worker.credentials), [worker.credentials]);
  const hasCredentials = worker.credentials.length > 0;
  const isPending = worker.pendingRegistration;

  return (
    <Card className="mb-4">
      <Accordion>
        <Card.Header>
          <EmployeeInfo tg={tg} employee={worker} enums={enums} />
          <div className="cursor-pointer">{hasCredentials && <Toggle eventKey={worker.accountId.toString()} />}</div>
        </Card.Header>
        <Card.Body>
          <>
            {isPending && <p>{worker.professionTitle}</p>}
            {!isPending && (
              <p>
                <b>{worker.primaryProfessionName || tg("unknownProfession")}</b>
                &nbsp;
                <span>{`(${worker.primaryProfessionYears} ${tm("year", worker.primaryProfessionYears)} ${tg(
                  "experience"
                )})`}</span>
              </p>
            )}
            <div className="credentials-search-card__status">
              {!hasCredentials && (
                <div className="status__document">
                  <div className="status__icon is-empty">
                    <Document className="ico" />
                  </div>
                  <p>{tg("empty")}</p>
                </div>
              )}
              {hasCredentials && (
                <>
                  <div className="status__document">
                    <div className="status__icon is-valid">
                      <Document className="ico" />
                    </div>
                    <p>
                      {states.isGood} {tg("valid")}
                    </p>
                  </div>

                  <div className="status__document">
                    <div className="status__icon is-expired">
                      <Document className="ico" />
                    </div>

                    <p>
                      {states.isExpired} {tg("expired")}
                    </p>
                  </div>

                  <div className="status__document">
                    <div className="status__icon will-expire">
                      <Document className="ico" />
                    </div>

                    <p>
                      {states.willExpire} {tg("willExpire")}
                    </p>
                  </div>
                </>
              )}
            </div>
          </>
        </Card.Body>
        <Card.Footer>
          <Button onClick={() => openProfile(worker.accountId)}>{tg("viewProfile")}</Button>
          <Button
            variant="outline-primary"
            disabled={isPending}
            onClick={() =>
              onSendMessage(
                worker.accountId,
                [worker.firstName, worker.middleName, worker.lastName].filter(s => s !== undefined).join(" ")
              )
            }>
            <Comment fontSize={24} {...(isPending ? { opacity: 0.4 } : {})} color="#00949E" />
            {tg("sendMessage")}
          </Button>

          <Button variant="outline-primary" onClick={() => uploadCredentials(worker.accountId)}>
            <Upload fontSize={24} color="#00949E" />
            {tg("upload")}
          </Button>
        </Card.Footer>
        <Accordion.Collapse eventKey={worker.accountId.toString()}>
          <Col xs={12}>
            <CredentialsSection
              isEdit={false}
              localization={localization}
              credentials={worker.credentialsSection}
              uploadCredentials={() => uploadCredentials(worker.accountId)}
              openCredentialsOverview={openOverview}
            />
          </Col>
        </Accordion.Collapse>
      </Accordion>
    </Card>
  );
};

export default EmployeeCredentials;
