import { registerView, View, ViewComponent } from "@frui.ts/views";
import { observer } from "mobx-react-lite";
import React from "react";
import ActiveEmployeesViewModel from "viewModels/company/employees/activeEmployeesViewModel";

import Pager from "controls/pager";

import EmployeeSearchCard from "./employeeSearchCard";
import "./index.scss";
import LoadingContainer from "views/common/loadingContainer";

const activeEmployeesView: ViewComponent<ActiveEmployeesViewModel> = observer(({ vm }) => {
  return (
    <LoadingContainer loading={vm.busyWatcher.isBusy}>
      {vm.items?.map(employee => (
        <EmployeeSearchCard
          enums={vm.enums}
          key={employee.accountId}
          employee={employee}
          onRemove={vm.openRemoveModal}
          onResend={vm.sendResendMessage}
          localization={vm.localization}
          onViewProfile={vm.viewProfile}
          onSendMessage={vm.openSendMessageModal}
        />
      ))}
      {vm.items?.length > 0 && (
        <div className="paginationBar">
          <Pager paging={vm.currentPaging} filter={vm.pagingFilter} onPageChanged={vm.changePage} />
        </div>
      )}
      <View vm={vm.activeChild} />
    </LoadingContainer>
  );
});

registerView(activeEmployeesView, ActiveEmployeesViewModel);
