import { ILocalizationService } from "@emanprague/shared-services";
import { bound } from "@frui.ts/helpers";
import { BusyWatcher, ScreenBase, watchBusy } from "@frui.ts/screens";
import LoginRepository from "repositories/loginRepository";
import { observable, action } from "mobx";

const navigationName = "emailSent";

export default class SuccessEmailChangeViewModel extends ScreenBase {
  navigationName = navigationName;
  busyWatcher = new BusyWatcher();

  @observable email: string;
  oldEmail: string;

  constructor(public localization: ILocalizationService, private repository: LoginRepository) {
    super();
    this.name = this.translate("title");
  }

  @bound translate(key: string) {
    return this.localization.translateGeneral(`profile.successEmailChange.${key}`);
  }

  @bound
  @watchBusy
  async resend() {
    await this.repository.changeSettings({ email: this.email });
  }

  @action navigate(_subpath: any, params: any) {
    if (params?.email) {
      this.oldEmail = params.oldEmail;
      this.email = params.email;
    } else {
      this.requestClose();
    }
  }
}
