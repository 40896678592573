import { Row, Button, Col } from "@emanprague/veralinkkit";
import React from "react";
import { MailBig } from "@emanprague/veralinkkit/dist/Icons";

const EmptySafety = ({ translate }: any) => {
  return (
    <Row className="text-center">
      <Col className="success-link-sent">
        <Row className="justify-content-center align-items-center mb-4">
          <div className="card__hero ">
            <MailBig className="card__hero-icon" />
          </div>
        </Row>
        <h4>{translate("emptyInfo")}</h4>

        <Button
          className="card__btn mt-4"
          onClick={() => (window.location.href = "#/dashboard/companyInfo/profile/addSafetyPolicy")}>
          {translate("upload")}
        </Button>
      </Col>
    </Row>
  );
};

export default EmptySafety;
