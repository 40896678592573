import AuthenticationIdentity from "entities/authenticationIdentity";
import CompanyProfile from "entities/companyProfile";
import WorkerProfile from "entities/workerProfile";
import { computed, observable } from "mobx";

export default class UserContext {
  @computed get isLogged() {
    return !!this.identity;
  }

  @observable token?: string;
  @observable.ref identity?: AuthenticationIdentity;
  @observable.ref profile?: WorkerProfile | CompanyProfile;
}
