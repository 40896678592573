import { ILocalizationService } from "@emanprague/shared-services";
import { bound } from "@frui.ts/helpers";
import { BusyWatcher, ScreenBase, watchBusy } from "@frui.ts/screens";
import CompanyProject from "entities/companyProject";
import LocationSimple from "entities/locationSimple";
import { interfaces } from "inversify";
import { observable } from "mobx";
import ProjectRepository from "repositories/projectRepository";
import EnumsService from "services/enumsService";
import { attachAutomaticValidator, validateAll } from "@frui.ts/validation";

export default class NewProjectViewModel extends ScreenBase {
  navigationName = "new";
  busyWatcher = new BusyWatcher();
  @observable item: CompanyProject = new CompanyProject();

  constructor(
    private companyId: string,
    public localization: ILocalizationService,
    private repository: ProjectRepository,
    public enums: EnumsService
  ) {
    super();

    this.name = this.translate("title");
    this.item.location = new LocationSimple();
    attachAutomaticValidator(this.item, CompanyProject.ValidationRules);
    attachAutomaticValidator(this.item.location, LocationSimple.ValidationRules);
  }

  get states() {
    return this.enums.states;
  }

  @bound
  @watchBusy
  async createProject() {
    if (validateAll([this.item, this.item.location])) {
      const result = await this.repository.createProject(this.companyId, this.item);
      if (result.success) {
        this.requestClose();
      }
    }
  }

  @bound translate(key: string) {
    return this.localization.translateGeneral(`projects.new.${key}`);
  }

  static Factory({ container }: interfaces.Context) {
    return (companyId: string) =>
      new NewProjectViewModel(
        companyId,
        container.get("ILocalizationService"),
        container.get(ProjectRepository),
        container.get(EnumsService)
      );
  }
}
