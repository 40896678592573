import { ILocalizationService, IEventBus } from "@emanprague/shared-services";
import { bound } from "@frui.ts/helpers";
import { BusyWatcher, ScreenBase, watchBusy } from "@frui.ts/screens";
import { interfaces } from "inversify";
import ComplianceRepository from "repositories/complianceRepository";
import { observable, action, runInAction, computed, toJS, IReactionDisposer, reaction } from "mobx";
import OshaFormYear from "entities/oshaFormYear";
import { SectionWithAnswer, QuestionaireWithAnswer } from "./SafetyCultureQuestionaireViewModel";
import QuestionnaireAnswer from "entities/questionnaireAnswer";
import questions from "./helpers/questions";
import { makeOshaForm } from "./helpers/generators";
import QuestionnaireAnswers from "entities/questionnaireAnswers";
import OshaFormAnswers from "entities/oshaFormAnswers";
import UserContext from "services/userContext";
import AccountType from "entities/accountType";
import { CompanyEvents } from "services/events";
import OshaFormQuarter from "entities/oshaFormQuarter";
import { WithWorkOshaQuestion } from "entities/oshaFormQuestion";
import { attachAutomaticValidator, validate } from "@frui.ts/validation";
import { inputFields } from "./helpers/calculator";

const OSHAValidation = questions
  .filter(el => el.type === "number")
  .map(el => el.name)
  .reduce((acc, nxt) => {
    return {
      ...acc,
      [nxt]: {
        isPositiveNumber: true,
      },
    };
  }, {});

const markUndefined = (questions: WithWorkOshaQuestion) => {
  return (Object.keys(questions) as Array<keyof WithWorkOshaQuestion>)
    .filter(key => key !== "workPerformed")
    .reduce((acc: any, nxt) => {
      const value = questions[nxt];
      return {
        ...acc,
        [nxt]: value == null || isNaN(value as number) || ((value as unknown) as string) === "" ? undefined : value,
      };
    }, {});
};

const markTotalUndefined = (questions: WithWorkOshaQuestion) => {
  const nullified = markUndefined(questions);
  return Object.keys(nullified).reduce((acc, nxt) => {
    if (inputFields.includes(nxt)) {
      return {
        ...acc,
        [nxt]: nullified[nxt] === 0 ? undefined : nullified[nxt],
      };
    } else {
      return {
        ...acc,
        [nxt]: undefined,
      };
    }
  }, {});
};

export default class SafetyMetricsQuestionnaireViewModel extends ScreenBase {
  navigationName = "safetyMetricsQuestionnaire";
  busyWatcher = new BusyWatcher();
  @observable selectedForm = "oshaForm";
  @observable selectedSection: SectionWithAnswer;
  @observable selectedYear?: string;
  @observable model: { oshaForm: OshaFormYear[]; questionnaire: SectionWithAnswer[] } = {
    oshaForm: [],
    questionnaire: [],
  };

  listeners: IReactionDisposer[] = [];

  get oshaQuestions() {
    return questions;
  }

  get oshaYears() {
    return this.model.oshaForm.map(form => form.year!.toString());
  }

  get isPrevYearVisible() {
    if (this.model.oshaForm.length > 0) {
      return this.selectedYear !== this.model.oshaForm[0].year?.toString();
    }
    return false;
  }

  get isLastYear() {
    if (this.model.oshaForm.length > 0) {
      return this.selectedYear === this.model.oshaForm[this.model.oshaForm.length - 1].year?.toString();
    }
    return false;
  }

  constructor(
    private connectionId: string,
    public localization: ILocalizationService,
    private repository: ComplianceRepository,
    private userContext: UserContext,
    private eventBus: IEventBus
  ) {
    super();

    this.name = this.translate("title");

    if (this.isOperator) {
      this.switch("questionnaire");
    }
  }

  @computed
  get isOperator() {
    return this.userContext.identity?.accountType === AccountType.Operator;
  }

  @action
  selectCurrentYear(name: string) {
    this.selectedYear = name;
  }

  @bound previousYear() {
    const id = this.oshaYears.findIndex(val => val === this.selectedYear);

    if (id - 1 <= 0) {
      this.selectCurrentYear(this.oshaYears[0]);
    } else {
      this.selectCurrentYear(this.oshaYears[id - 1]);
    }
  }

  @bound nextYear() {
    const id = this.oshaYears.findIndex(val => val === this.selectedYear);

    if (id + 1 >= this.oshaYears.length) {
      this.selectCurrentYear(this.oshaYears[this.oshaYears.length - 1]);
    } else {
      this.selectCurrentYear(this.oshaYears[id + 1]);
    }
  }

  @action switch(name: string) {
    this.selectedForm = name;
  }

  @bound translate(key: string, args?: any) {
    return this.localization.translateGeneral(`compliance.safetyMetricsQuestionnaire.${key}`, args);
  }

  onDeactivate() {
    this.listeners.forEach(list => list());
  }

  async onInitialize() {
    await Promise.all([this.loadQuestionnaire(), this.loadOshaAnswers()]);
  }

  @bound
  @action
  selectSection(idx: number) {
    this.selectedSection = this.model.questionnaire[idx];
    this.selectedSection.index = idx;
  }

  @action prepareModel(questions: SectionWithAnswer[], answers: QuestionnaireAnswer[]) {
    this.model.questionnaire = questions.map(section => {
      return {
        ...section,
        questions: section.questions.map(question => {
          const answer = answers?.find(({ questionId }) => questionId === question.id) ?? new QuestionnaireAnswer();
          // attach validator
          answer.questionId = question.id as string;
          return {
            ...question,
            answer,
          };
        }),
      };
    });
    this.selectedSection = this.model.questionnaire[0];
    this.selectedSection.index = 0;
  }

  @bound
  @watchBusy
  async loadOshaAnswers() {
    const res = await this.repository.loadOshaAnswers(this.connectionId);

    runInAction(() => {
      this.model.oshaForm = makeOshaForm(2020, res.success ? res.payload : undefined);
      this.selectedYear = this.model.oshaForm.length > 0 ? this.model.oshaForm[0].year?.toString() : undefined;
    });

    this.model.oshaForm.forEach(year => {
      year.quarters!.forEach(quarter => {
        runInAction(() => {
          attachAutomaticValidator(quarter.questions, OSHAValidation);
        });
        const subscription = reaction(
          () => (quarter.questions as WithWorkOshaQuestion).workPerformed,
          isPerformed => {
            if (!isPerformed) {
              runInAction(() => {
                quarter.questions = new WithWorkOshaQuestion();
                attachAutomaticValidator(quarter.questions, OSHAValidation);
              });
            }
          }
        );

        this.listeners.push(subscription);
      });
    });
  }

  @bound
  @watchBusy
  async saveOsha() {
    const oshaRequest = new OshaFormAnswers();

    const valid = this.model.oshaForm
      .flatMap(year => year.quarters!.map(quarter => validate(quarter.questions)))
      .every(el => !!el);

    if (!valid) {
      return;
    }

    runInAction(() => {
      oshaRequest.years = [...toJS(this.model.oshaForm)].map(yearData => {
        const quarters = yearData?.quarters
          ?.map(quarter => {
            return { ...quarter, questions: markUndefined(quarter.questions as WithWorkOshaQuestion) };
          })
          .filter(el => !!el) as OshaFormQuarter[];

        yearData.quarters = quarters;
        return { ...yearData, total: markTotalUndefined(yearData.total as WithWorkOshaQuestion) };
      });
    });

    oshaRequest.prepareRequestValues(this.model.oshaForm);

    await this.repository.saveOshaForm(this.connectionId, oshaRequest);
    this.eventBus.publish(CompanyEvents.connectionPointsUpdated(undefined));
    await this.loadQuestionnaire();
  }

  @bound
  @watchBusy
  async saveQuestionnaire() {
    const answers = this.model.questionnaire
      .map(section => section.questions.map((question: QuestionaireWithAnswer) => question.answer))
      .flat();

    const quesitonaireAnswer = new QuestionnaireAnswers();
    quesitonaireAnswer.answers = answers as QuestionnaireAnswer[];

    const res = await this.repository.saveAnswers("safety_metrics", this.connectionId, quesitonaireAnswer);

    if (res.success) {
      this.requestClose();
      this.eventBus.publish(CompanyEvents.connectionPointsUpdated(undefined));
    }
  }

  @bound cancel() {
    this.requestClose();
  }

  @bound
  @watchBusy
  async loadQuestionnaire() {
    const res = await this.repository.loadQuestions(this.connectionId, "safety_metrics");
    const resAnswers = await this.repository.loadAnswers("safety_metrics", this.connectionId);

    if (!res.success || !resAnswers.success) {
      return;
    }

    this.prepareModel(res.payload.sections, resAnswers.payload.answers);
  }

  static Factory({ container }: interfaces.Context) {
    return (connectionId: string) =>
      new SafetyMetricsQuestionnaireViewModel(
        connectionId,
        container.get("ILocalizationService"),
        container.get(ComplianceRepository),
        container.get(UserContext),
        container.get("IEventBus")
      );
  }
}
