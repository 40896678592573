import { ILocalizationService } from "@emanprague/shared-services";
import { bound } from "@frui.ts/helpers";
import { BusyWatcher, ScreenBase, watchBusy } from "@frui.ts/screens";
import { attachAutomaticValidator, validate } from "@frui.ts/validation";
import ForgotPassword from "entities/forgotPassword";
import ForgotPasswordEmail from "entities/forgotPasswordEmail";
import ForgotPasswordUsername from "entities/forgotPasswordUsername";
import { action, observable, runInAction } from "mobx";
import LoginRepository from "repositories/loginRepository";

export const navigationName = "forgot-password";

export default class ForgotPasswordViewModel extends ScreenBase {
  navigationName = navigationName;
  busyWatcher = new BusyWatcher();
  @observable forgotPassword: ForgotPassword;
  @observable isSent = false;

  constructor(public localization: ILocalizationService, private repository: LoginRepository) {
    super();

    this.name = this.translate("title");
    this.reset();
  }

  @action
  protected onActivate() {
    this.reset();
  }

  @action
  private reset() {
    this.isSent = false;
    this.forgotPassword = new ForgotPassword();
    attachAutomaticValidator(this.forgotPassword, ForgotPassword.ValidationRules);
  }

  @bound translate(key: string) {
    return this.localization.translateGeneral(`profile.forgotPassword.${key}`);
  }

  @bound
  @watchBusy
  async sendInstructions() {
    if (validate(this.forgotPassword)) {
      let resetModel: ForgotPasswordEmail | ForgotPasswordUsername;
      if (this.forgotPassword.email) {
        resetModel = new ForgotPasswordEmail();
        resetModel.email = this.forgotPassword.email;
      } else {
        resetModel = new ForgotPasswordUsername();
        resetModel.username = this.forgotPassword.username;
      }

      const result = await this.repository.requestPasswordReset(resetModel);
      if (result.success) {
        runInAction(() => (this.isSent = true));
      }
    }
  }
}
