import { IEventBus, ILocalizationService } from "@emanprague/shared-services";
import { bound, IDisposable } from "@frui.ts/helpers";
import { BusyWatcher, ScreenBase, watchBusy } from "@frui.ts/screens";
import AccountType from "entities/accountType";
import AuthenticationIdentity from "entities/authenticationIdentity";
import { ISubModule } from "viewModels/types";
import ProjectRepository from "repositories/projectRepository";
import { FilteredListViewModel } from "@frui.ts/datascreens";
import CompanyProject from "entities/companyProject";
import { computed } from "mobx";
import UserContext from "services/userContext";
import CompanyProfile from "entities/companyProfile";
import ProjectDetailViewModel from "./projectDetailViewModel";
import NewProjectViewModel from "./newProjectViewModel";
import EditProjectViewModel from "./editProjectViewModel";
import { ProjectsEvents } from "services/events";
import EnumsService from "services/enumsService";
import DialogService from "services/dialogService";

export default class SubModuleViewModel extends FilteredListViewModel<CompanyProject, any, ScreenBase> implements ISubModule {
  orderIndex = 7;
  navigationName = "projects";
  busyWatcher = new BusyWatcher();

  private eventHandlers: IDisposable[];

  constructor(
    private userContext: UserContext,
    public localization: ILocalizationService,
    private repository: ProjectRepository,
    private projectDetailFactory: ReturnType<typeof ProjectDetailViewModel.Factory>,
    private newProjectFactory: ReturnType<typeof NewProjectViewModel.Factory>,
    private editProjectFactory: ReturnType<typeof EditProjectViewModel.Factory>,
    private eventBus: IEventBus,
    public enums: EnumsService,
    private dialog: DialogService
  ) {
    super();

    this.name = this.translate("title");

    this.eventHandlers = [
      this.eventBus.subscribe(ProjectsEvents.listUpdated, this.loadData),
      this.eventBus.subscribe(ProjectsEvents.teamsUpdated, this.loadData),
    ];
  }

  onInitialize() {
    this.loadData();
  }

  protected async onDeactivate(isClosing: boolean) {
    await super.onDeactivate(isClosing);

    if (isClosing) {
      this.eventHandlers?.forEach(x => x.dispose());
    }
  }

  @computed get companyProfileId() {
    return (this.userContext.profile as CompanyProfile)?.id ?? "";
  }

  isVisible(identity: AuthenticationIdentity) {
    return identity.accountType === AccountType.Contractor || identity.accountType === AccountType.Operator;
  }

  @bound
  @watchBusy
  async loadData() {
    const result = await this.repository.getProjects(this.companyProfileId);
    if (result.success) {
      this.setData([result.payload, this.currentPaging]);
    }
  }

  isProjectOwner(operatorId?: string) {
    if (!operatorId) {
      return true;
    } else {
      return this.companyProfileId === operatorId ? true : false;
    }
  }

  @bound
  openProjectDetail(projectId: string, projectName: string, operatorId?: string) {
    const projectOwner = this.isProjectOwner(operatorId);
    const vm = this.projectDetailFactory(operatorId ? operatorId : this.companyProfileId, projectId, projectName, projectOwner);
    this.tryActivateChild(vm);
  }

  @bound
  openNewProjectModal() {
    const vm = this.newProjectFactory(this.companyProfileId);
    this.tryActivateChild(vm);
  }

  @bound
  openEditProjectModal(item: CompanyProject) {
    const vm = this.editProjectFactory(this.companyProfileId, item);
    this.tryActivateChild(vm);
  }

  @bound
  @watchBusy
  async removeProject(item: CompanyProject) {
    const confirmationResult = await this.dialog.showConfirmation(
      this.translate("deleteMessage"),
      this.translate("deleteTitle"),
      this.translate("deleteOk"),
      this.translate("deleteCancel")
    );
    if (confirmationResult) {
      return this.repository.removeProject(this.companyProfileId, item.id!);
    }
  }

  @bound translate(key: string) {
    return this.localization.translateGeneral(`projects.subModule.${key}`);
  }

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  protected resetFilterValues(filter: any) {}
}
