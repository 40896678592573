import RepositoryBase from "./repositoryBase";
import { IPagingFilter } from "@frui.ts/data";
import CompanyCredentialsSearchResult from "entities/companyCredentialsSearchResult";
import { observable } from "mobx";
import CompanyCredentialsStats from "entities/companyCredentialsStats";

export default class CompanyCredentialsRepository extends RepositoryBase {
  @observable progressNotificaton = 0;

  loadCredentials(companyId: string, paging: IPagingFilter, filter: any) {
    return this.callApi(api =>
      api
        .all("userprofile")
        .one("company", companyId)
        .all("credentials/search")
        .getPagedEntities(CompanyCredentialsSearchResult, x => x.employees, paging, filter)
    );
  }

  export(companyId: string, workerIds: string[]) {
    return this.callApi(api =>
      api
        .one("userprofile/company", companyId)
        .all("credentials/export")
        .getRaw({ workerIds: workerIds.join(",") })
    );
  }

  async import(companyId: string, file: File) {
    const response = await this.callApi(api =>
      api
        .one("userprofile/company", companyId)
        .all("credentials/import")
        .postData(file as any)
    );

    if (response.success) {
      // this.eventBus.publish(EmployeesEvents.workersImported(undefined));
    }

    return response;
  }

  getStats(companyId: string) {
    return this.callApi(api =>
      api.all("userprofile").one("company", companyId).all("credentials/stats").getEntity(CompanyCredentialsStats)
    );
  }
}
