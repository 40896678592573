import { Button, LoaderOverlay } from "@emanprague/veralinkkit";
import { registerView } from "@frui.ts/views";
import React from "react";
import EditProjectViewModel from "viewModels/company/projects/editProjectViewModel";
import ModalView from "views/common/modalView";
import ProjectFormView from "./projectFormView";

class EditProjectModalView extends ModalView<EditProjectViewModel> {
  renderBody(vm: EditProjectViewModel) {
    return (
      <>
        <LoaderOverlay loading={vm.busyWatcher.isBusy} variant="light">
          <ProjectFormView vm={vm} />
        </LoaderOverlay>
      </>
    );
  }

  renderFooter(vm: EditProjectViewModel) {
    return (
      <>
        <Button variant="outline-primary" onClick={vm.requestClose}>
          {vm.translate("cancel")}
        </Button>
        <Button variant="primary" onClick={vm.updateProject}>
          {vm.translate("save")}
        </Button>
      </>
    );
  }
}

registerView(EditProjectModalView as any, EditProjectViewModel);
