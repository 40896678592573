import { Alert, Button, Card, Col, Container, Form, LoaderOverlay, Row } from "@emanprague/veralinkkit";
import { Check, Input, Select } from "@frui.ts/bootstrap";
import { preventDefault, registerView, ViewComponent } from "@frui.ts/views";
import { observer } from "mobx-react-lite";
import React from "react";
import CompleteWorkerProfileViewModel from "viewModels/worker/modals/completeWorkerProfileViewModel";
import "assets/themes/signup.scss";
import Field from "controls/field";

const completeWorkerProfileView: ViewComponent<CompleteWorkerProfileViewModel> = observer(({ vm }) => {
  const ta = vm.localization.translateAttribute;
  return (
    <Container>
      <Row className="justify-content-center">
        <Col md={10}>
          <Card>
            <Card.Body className="signup-success signup">
              <Row className="justify-content-center">
                <Col md={8}>
                  <h1 className="display-5 text-center">{vm.translate("title")}</h1>
                  <p className="subtitle text-center">{vm.translate("description")}</p>

                  <LoaderOverlay loading={vm.busyWatcher.isBusy} variant="light">
                    <fieldset>
                      <Form onSubmit={preventDefault(vm.confirm)}>
                        <Form.Row>
                          <Col md={12}>
                            <Field
                              label={ta("workerProfile", "industry")}
                              info={ta("workerProfile", "industryInfo")}
                              localization={vm.localization}>
                              <Select
                                mode="key"
                                target={vm.model.skills}
                                placeholder={vm.translate("industryPlaceholder")}
                                property="industryId"
                                items={vm.industries}
                                textProperty="name"
                              />
                            </Field>
                          </Col>
                          <Col md={12}>
                            <Field
                              label={ta("workerProfile", "professionTitle")}
                              info={ta("workerProfile", "professionTitleInfo")}
                              localization={vm.localization}>
                              <Input target={vm.model.skills} property="professionTitle" type="text" />
                            </Field>
                          </Col>
                          <Col md={6}>
                            <Field
                              label={ta("workerProfile", "profession")}
                              info={ta("workerProfile", "professionInfo")}
                              localization={vm.localization}>
                              <Select
                                mode="key"
                                target={vm.model.skills}
                                placeholder={vm.translate("professionPlaceholder")}
                                property="professionId"
                                items={vm.professions}
                                textProperty="name"
                                disabled={!vm.model.skills.industryId}
                              />
                            </Field>
                          </Col>
                          <Col md={6}>
                            <Field label={vm.translate("yearsExperience")} localization={vm.localization}>
                              <Input
                                target={vm.model.skills}
                                type="number"
                                property="professionYears"
                                disabled={!vm.model.skills.professionId}
                              />
                            </Field>
                          </Col>
                        </Form.Row>

                        <Form.Row>
                          <Col xs={6}>
                            <Form.Group>
                              <Form.Label>{ta("location", "city")}</Form.Label>
                              <Input target={vm.model.location} property="city" />
                            </Form.Group>
                          </Col>
                          <Col xs={6}>
                            <Form.Group>
                              <Form.Label>{ta("location", "state")}</Form.Label>
                              <Select
                                mode="key"
                                value={vm.model.location?.state}
                                target={vm.model.location}
                                property="state"
                                items={vm.states}
                                keyProperty="id"
                                textProperty="name"
                              />
                            </Form.Group>
                          </Col>
                        </Form.Row>

                        <Form.Group>
                          <Check
                            label={ta("workerProfile", "USMilitaryVeteran")}
                            target={vm.model}
                            property="USMilitaryVeteran"
                          />
                        </Form.Group>

                        <Form.Group>
                          <Check label={ta("workerProfile", "unionMember")} target={vm.model} property="unionMember" />
                        </Form.Group>

                        {vm.errorMessage && <Alert variant="danger">{vm.errorMessage}</Alert>}

                        <Button block disabled={vm.busyWatcher.isBusy || !vm.canConfirm} type="submit">
                          {vm.translate("submit")}
                        </Button>
                      </Form>
                    </fieldset>
                  </LoaderOverlay>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
});

registerView(completeWorkerProfileView, CompleteWorkerProfileViewModel);
