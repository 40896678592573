import { ILocalizationService } from "@emanprague/shared-services";
import { bound } from "@frui.ts/helpers";
import { BusyWatcher, ScreenBase, watchBusy } from "@frui.ts/screens";
import { attachAutomaticValidator, validate } from "@frui.ts/validation";
import UserSettingsPassword from "entities/userSettingsPassword";
import { action, observable } from "mobx";
import LoginRepository from "repositories/loginRepository";

export default class ChangePasswordViewModel extends ScreenBase {
  navigationName = "password";
  busyWatcher = new BusyWatcher();

  @observable userSettings: UserSettingsPassword = new UserSettingsPassword();

  constructor(public localization: ILocalizationService, private repository: LoginRepository) {
    super();
    this.name = this.translate("title");

    attachAutomaticValidator(this.userSettings, UserSettingsPassword.ValidationRules);
  }

  @action.bound
  @watchBusy
  async changePassword() {
    if (validate(this.userSettings)) {
      const result = await this.repository.changePassword(this.userSettings);
      if (result.success) {
        this.requestClose();
      }
    }
  }

  @bound translate(key: string) {
    return this.localization.translateGeneral(`worker.password.${key}`);
  }
}
